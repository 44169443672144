import { Button, Form, Input, InputNumber, Modal, Select } from "antd";
// tslint:disable-next-line:no-submodule-imports
import { FormComponentProps } from "antd/lib/form";
import { GoogleApiWrapper, Map } from "google-maps-react";
import * as React from "react";
import { Points } from ".";
import { PRIORITIES } from "../../../shared/constants";

const FormItem = Form.Item;
const { Option } = Select;

// Not best solution but google maps are not working so well for react, yet
export interface AcceptencePointFormProps extends FormComponentProps {
    visible: boolean;
    modalTitle: string;
    modalOkText: string;
    onCancel(): void;
    onCreate(): void;
    changePointAdress(address: HTMLInputElement): void;
    setMapRef(ref: any): void;
    setFormRef(ref: any): void;
    getPointAddress(): string | null;
    google: any;
    saveFormRef(ref: any): void;
    isProcessingDatabase: boolean;
    modalInitdata: null | Points;
    ruleIsValid(key: string): boolean;
}

interface AcceptencePointFormState {
    pointAddress: string | null;
}

class AcceptencePointWrapper extends React.Component<AcceptencePointFormProps, AcceptencePointFormState> {
    typingTimer;
    doneTypingInterval = 600;
    map;
    form;

    constructor(props) {
        super(props);

        this.state = {
            pointAddress: this.props.modalInitdata ? this.props.modalInitdata.address : null,
        };
    }

    componentWillUnmount() {
        clearTimeout(this.typingTimer);
    }

    componentDidMount() {
        if (this.state.pointAddress) {
            // Quickfix - for some reason map reference is saved after init
            // That's why we cannot set marker
            setTimeout(() => {
                this.renderAddressInMap();
            }, 100);
        }
    }

    componentDidUpdate(prevProps) {
        // Hide map if close modal
        if (this.props !== prevProps.map) {
            // If state changed - render new map
            this.renderAddressInMap();
        }
    }

    changePointAddress = (addressInput: any) => {
        clearTimeout(this.typingTimer);

        this.typingTimer = setTimeout(
            (address) => this.setState({ pointAddress: address }),
            this.doneTypingInterval,
            addressInput.target.value,
        );
    }

    getPointAddress = () => this.state.pointAddress;

    setMapRef = (ref) => this.map = ref;

    setFormRef = (ref) => {
        this.form = ref;
        this.props.saveFormRef(ref);
    }

    geocodePosition = (geocoder, pos) => {
        geocoder.geocode({ latLng: pos }, (responses) => {
            if (responses && responses.length > 0) {
                this.setState({ pointAddress: responses[0].formatted_address });
                this.form.setFieldsValue({ address: responses[0].formatted_address });
            }
        });
    }

    renderAddressInMap = () => {
        const { google } = this.props;
        const mapReference = this.map;

        if (!google || !mapReference) { return; }
        // Some new prototypes to make life esier
        google.maps.Map.prototype.markers = new Array();
        google.maps.Map.prototype.addMarker = function(marker) {
            this.markers[this.markers.length] = marker;
        };
        google.maps.Map.prototype.getMarkers = function() {
            return this.markers;
        };
        google.maps.Map.prototype.clearMarkers = function() {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.markers.length; i += 1) {
                this.markers[i].setMap(null);
            }
            this.markers = new Array();
        };

        const map = mapReference.map;
        const geocoder = new google.maps.Geocoder();
        // tslint:disable-next-line:no-this-assignment
        const self = this;
        // Trying to get location based on entered address
        geocoder.geocode({ address: this.state.pointAddress }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                // Remove old markers
                map.clearMarkers();
                // Set map center to new location
                map.setCenter(results[0].geometry.location);
                // Add new marker
                const marker = new google.maps.Marker({
                    map,
                    position: results[0].geometry.location,
                    draggable: true,
                });

                google.maps.event.addListener(marker, "dragend", () => {
                    self.geocodePosition(geocoder, marker.getPosition());
                });
                // Cache marke
                map.addMarker(marker);

                this.form.setFieldsValue({
                    address_lat: results[0].geometry.location.lat(),
                    address_lng: results[0].geometry.location.lng(),
                });
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const pointAdress = this.getPointAddress();
        const mapWrapperStyle: any = {
            width: 700,
            height: 300,
            position: "relative",
        };

        this.setFormRef(this.props.form);

        return (
            <Modal
                wrapClassName="vertical-center-modal"
                visible={this.props.visible}
                title={this.props.modalTitle}
                destroyOnClose={true}
                width={750}
                maskClosable={false}
                onCancel={this.props.onCancel}
                footer={[
                    <Button key="back" onClick={this.props.onCancel}>Atcelt</Button>,
                    <Button key="submit" type="primary" loading={this.props.isProcessingDatabase} onClick={this.props.onCreate}>
                        {this.props.modalOkText}
                    </Button>,
                ]}
            >
                <Form layout="vertical">
                    <FormItem label="Nosaukums">
                        {getFieldDecorator("name", {
                            rules: [{
                                required: true,
                                message: "Lūdzu norādiet nosaukumu!",
                            }],
                            initialValue: this.props.modalInitdata ? this.props.modalInitdata.name : null,
                        })(<Input/>)}
                    </FormItem>
                    <FormItem label="Adrese">
                        {getFieldDecorator("address", {
                            rules: [{
                                required: true,
                                message: "Lūdzu norādiet adresi!",
                            }],
                            onChange: this.changePointAddress,
                            initialValue: this.props.modalInitdata ? this.props.modalInitdata.address : null,
                        } as any)(<Input autoComplete="off"/>)}
                    </FormItem>

                    <FormItem label="Prioritāte">
                        {getFieldDecorator("priority", {
                            initialValue: this.props.modalInitdata ? this.props.modalInitdata.priority : null,
                        })(
                            <Select>
                                {PRIORITIES.map(priority => (
                                    <Option key={priority.id} value={priority.id}>{priority.label}</Option>
                                ))}
                            </Select>,
                        )}
                    </FormItem>

                    {pointAdress && <FormItem>
                        <div style={mapWrapperStyle}>
                            <Map
                                ref={this.setMapRef}
                                google={this.props.google}
                                zoom={15}
                                initialCenter={{
                                    lat: 56.946285,
                                    lng: 24.105078,
                                }}
                            />

                            <FormItem style={{ display: "none" }}>
                                {getFieldDecorator("address_lat", {
                                    initialValue: this.props.modalInitdata ? this.props.modalInitdata.lat : null,
                                })(<Input/>)}
                            </FormItem>
                            <FormItem style={{ display: "none" }}>
                                {getFieldDecorator("address_lng", {
                                    initialValue: this.props.modalInitdata ? this.props.modalInitdata.lng : null,
                                })(<Input/>)}
                            </FormItem>
                            <FormItem style={{ display: "none" }}>
                                {getFieldDecorator("point_id", {
                                    initialValue: this.props.modalInitdata ? this.props.modalInitdata.id : null,
                                })(<Input/>)}
                            </FormItem>
                        </div>
                    </FormItem>}
                    {this.props.ruleIsValid("AcceptancePoints:ViewBonuses") && <div>
                        <FormItem label="Piemaksa (%)">
                            {getFieldDecorator("bonus", {
                                initialValue: this.props.modalInitdata ? this.props. modalInitdata.bonus : 0,
                            })(<InputNumber min={0} max={100} style={{ width: "100%" }}/>)}
                        </FormItem>
                        <FormItem label="Plāns">
                            {getFieldDecorator("plan", {
                                initialValue: this.props.modalInitdata ? this.props.modalInitdata.plan : 0,
                            })(<InputNumber style={{ width: "100%" }}/>)}
                        </FormItem>
                        <FormItem label="Uzmērījuma koeficients (%)">
                            {getFieldDecorator("surface_coefficient", {
                                initialValue: this.props.modalInitdata && this.props.modalInitdata.surface_coefficient ? this.props.modalInitdata.surface_coefficient : 0,
                            })(<InputNumber style={{ width: "100%" }} min={0} max={100}/>)}
                        </FormItem>
                    </div>}
                </Form>
            </Modal>
        );

    }
}

export default GoogleApiWrapper({
    apiKey: process.env.CLIENT_GOOGLE_MAPS_API_KEY,
})(Form.create()(AcceptencePointWrapper));

import { Button, Card, Checkbox, Col, Form, InputNumber, message, Row, Select } from "antd";
import axios from "axios";
import * as React from "react";
import { Redirect } from "react-router-dom";
import { BonusesForm } from "../../components/AcceptancePointAssortment/bonuses";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { PageContent } from "../../components/PageContent/index";
import { Helpers } from "../../utils/helpers";
import { Points } from "../AcceptancePoints";
import { NewTableAttribute } from "./newTableAttribute";

interface CreateAcceptancePointAssortmentState {
    redirect: boolean;
    pointId: number;
    isLoading: boolean;
    pointData: null | Points;
    assortment: null | Assortment[];
    lengths: number[];
    diameters: number[];

    bonus: number;
    fsc_bonus: number;
    fsc_bonus_100: number;

    bonus1: number;
    bonus2: number;
    bonus3: number;
    bonus4: number;

    amount1: number;
    amount2: number;
    amount3: number;
    amount4: number;

    active: boolean;
}

export interface Assortment {
    id: number;
    code: string;
    name: string;
    cargo_volume: number;
}

interface CreateAcceptancePointAssortmentProps {
    form: any;
    ruleIsValid(key: string): boolean;
}

const { Option } = Select;
const FormItem = Form.Item;

class CreateAcceptancePointAssortmentForm extends React.Component<CreateAcceptancePointAssortmentProps, CreateAcceptancePointAssortmentState> {
    formRef;

    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            pointId: props.match.params.pointId,
            isLoading: true,
            pointData: null,
            assortment: null,

            lengths: [],
            diameters: [],

            bonus: 0,
            fsc_bonus: 0,
            fsc_bonus_100: 0,

            bonus1: 0,
            bonus2: 0,
            bonus3: 0,
            bonus4: 0,

            amount1: 500,
            amount2: 1000,
            amount3: 5000,
            amount4: 10000,

            active: true
        };
    }

    componentDidMount() {
        this.fetchData();
        document.title = `Pievienot ${this.state.pointData && this.state.pointData.name} sortimentu`;
    }

    fetchPoint = () => axios.get(`${process.env.baseUrl}/api/acceptance_points_fetch/${this.state.pointId}`);

    fetchAssortment = () => axios.get(`${process.env.baseUrl}/assortment`);

    setNewLength = (length: number) => this.setState(prevState => ({
        lengths: [...prevState.lengths, length].sort((a, b) => a - b),
    }))

    setNewDiameter = (diameter: number) => this.setState(prevState => ({
        diameters: [...prevState.diameters, diameter].sort((a, b) => a - b),
    }))

    fetchData = () =>
        axios.all([
            this.fetchPoint(),
            this.fetchAssortment(),
        ]).then(axios.spread((point, assortment) => {
            if (point.data.success === false) {
                message.error("Neizdevās ielādēt pieņemšanas punktu");
            }

            if (assortment.data.success === false) {
                message.error("Neizdevās ielādēt sortimentus");
            }

            this.setState({
                isLoading: false,
                pointData: point.data.data,
                assortment: assortment.data.data,
            });
        }))

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let assortment: null | string = null;

                if (this.state.assortment) {
                    const assortmentTemp = this.state.assortment.find(assortmentT => assortmentT.id === values.assortment);
                    if (assortmentTemp) {
                        assortment = assortmentTemp.name;
                    }
                }

                const postData = {
                    assortment: values.assortment,
                    bonus: this.state.bonus,
                    fsc_bonus: this.state.fsc_bonus,
                    fsc_bonus_100: this.state.fsc_bonus_100,
                    assortment_price: Object.keys(values.assortment_price).reduce((newObject, key) =>
                        ({...newObject,
                          [key.replace("#", ".")]: Object.keys(values.assortment_price[key]).reduce((newObject2, key2) =>
                              ({...newObject2,
                                [key2.replace("#", ".")]: values.assortment_price[key][key2]}), {})}), {}),
                    acceptance_point_id: this.state.pointId,
                    assortment_name: assortment,
                    bonus_1: this.state.bonus1,
                    bonus_2: this.state.bonus2,
                    bonus_3: this.state.bonus3,
                    bonus_4: this.state.bonus4,
                    amount_1: this.state.amount1,
                    amount_2: this.state.amount2,
                    amount_3: this.state.amount3,
                    amount_4: this.state.amount4,
                    active: this.state.active,
                };

                axios.post(`${process.env.baseUrl}/api/acceptance_point/assortment`, postData, {
                        headers: {
                            Authorization: Helpers.getSessionId(),
                        },
                    })
                    .then(response => {
                        if (response.data.success === false) {
                            message.error("Neizdevās saglabāt sortimentu");
                        } else {
                            this.setState({ redirect: true });
                        }
                    })
                    .catch(error => {
                        Helpers.handleAPIError(error, "Neizdevās saglabāt sortimentu");
                    });
            }
        });
    }

    onBonusChange = (bonus) => {
        this.setState({ bonus });
    }
    onFscBonusChange = (fsc_bonus) => {
        this.setState({ fsc_bonus });
    }
    onFscBonus100Change = (fsc_bonus_100) => {
        this.setState({ fsc_bonus_100 });
    }
    onBonus1Change = (bonus1) => {
        this.setState({ bonus1 });
    }
    onBonus2Change = (bonus2) => {
        this.setState({ bonus2 });
    }
    onBonus3Change = (bonus3) => {
        this.setState({ bonus3 });
    }
    onBonus4Change = (bonus4) => {
        this.setState({ bonus4 });
    }
    onAmount1Change = (amount1) => {
        this.setState({ amount1 });
    }
    onAmount2Change = (amount2) => {
        this.setState({ amount2 });
    }
    onAmount3Change = (amount3) => {
        this.setState({ amount3 });
    }
    onAmount4Change = (amount4) => {
        this.setState({ amount4 });
    }

    onActiveChange = (active) => {
        this.setState({ active })
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={`/cenas/acceptance_points/${this.state.pointId}/assortment`}/>;
        }

        const pageContentProps = {
            headerTitle: `Pievienot "${this.state.pointData && this.state.pointData.name}" sortimentu`,
        };

        const { getFieldDecorator } = this.props.form;

        return (
            <PageContent {...pageContentProps}>
                {this.state.isLoading ? <LoadingIndicator/> :
                    <Card>
                        <Form onSubmit={this.handleSubmit}>
                            <Row>
                                <Col span={7}>
                                    <FormItem label="Sortiments">
                                        {getFieldDecorator("assortment", {
                                            rules: [{ required: true, message: "Lūdzu izvēlieties sortimentu!" }],
                                        })(
                                            <Select
                                                showSearch
                                                style={{ width: 400 }}
                                                placeholder="Izvēlēties sortimentu"
                                                optionFilterProp="children"
                                            >
                                                {this.state.assortment && this.state.assortment.map(assortmentValue => (
                                                    <Option value={assortmentValue.id} key={assortmentValue.id.toString()}>
                                                        {assortmentValue.name} - <strong>{assortmentValue.code}</strong>
                                                    </Option>
                                                ))}
                                            </Select>,
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>

                            <FormItem>
                                <BonusesForm 
                                    form={this.props.form}
                                    bonus={this.state.bonus}
                                    fsc_bonus={this.state.fsc_bonus}
                                    fsc_bonus_100={this.state.fsc_bonus_100}
                                    onBonusChange={this.onBonusChange}
                                    onFscBonusChange={this.onFscBonusChange}
                                    onFscBonus100Change={this.onFscBonus100Change}
                                    bonus1={this.state.bonus1}
                                    bonus2={this.state.bonus2}
                                    bonus3={this.state.bonus3}
                                    bonus4={this.state.bonus4}
                                    onBonus1Change={this.onBonus1Change}
                                    onBonus2Change={this.onBonus2Change}
                                    onBonus3Change={this.onBonus3Change}
                                    onBonus4Change={this.onBonus4Change}
                                    amount1={this.state.amount1}
                                    amount2={this.state.amount2}
                                    amount3={this.state.amount3}
                                    amount4={this.state.amount4}
                                    onAmount1Change={this.onAmount1Change}
                                    onAmount2Change={this.onAmount2Change}
                                    onAmount3Change={this.onAmount3Change}
                                    onAmount4Change={this.onAmount4Change}
                                />
                            </FormItem>

                            <FormItem label="Sortimenta cenas">
                                <NewTableAttribute
                                    exportValue={this.setNewLength}
                                    buttonText="Pievienot garumu"
                                    modalTitle="Pievienot garumu"
                                    modalLabel="Garums (m)"
                                />

                                <NewTableAttribute
                                    exportValue={this.setNewDiameter}
                                    buttonText="Pievienot diametru"
                                    modalTitle="Pievienot diametru"
                                    modalLabel="Diametrs (cm)"
                                />

                                <FormItem>
                                    <Checkbox checked={!this.state.active} onChange={(e) => this.onActiveChange(!e.target.checked)}>Nepērk</Checkbox>
                                </FormItem>

                                {this.state.lengths.length > 0 &&
                                    <div className="ant-table assortment-price-table">
                                        <table style={{ marginTop: 15 }}>
                                            <thead className="ant-table-thead">
                                                <tr>
                                                    <th></th>
                                                    {this.state.lengths.map(length => (
                                                        <th key={length}>{length} m</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody className="ant-table-tbody">
                                                {this.state.diameters.length > 0 &&
                                                    this.state.diameters.map(diameter => (
                                                        <PriceTableRow
                                                            key={diameter}
                                                            getFieldDecorator={getFieldDecorator}
                                                            data={{
                                                                diameter,
                                                                lengths: this.state.lengths,
                                                            }}
                                                        />
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                }
                            </FormItem>

                            <FormItem>
                                <Button type="primary" htmlType="submit">Saglabāt</Button>
                            </FormItem>
                        </Form>
                    </Card>
                }
            </PageContent>
        );
    }
}

const PriceTableRow = (props) => (
    <tr>
        <td>{ props.data.diameter } cm</td>

        {props.data.lengths && props.data.lengths.map(length => (
            <td key={length}>
                <FormItem>
                    {props.getFieldDecorator(`assortment_price[length_${length.toString().replace(".", "#")}][diameter_${props.data.diameter.toString().replace(".", "#")}]`, {
                        rules: [{ required: true, message: "Lūdzu izvēlieties cenu!" }],
                    })(
                        <InputNumber style={{ width: "100%" }}/>,
                    )}
                </FormItem>
            </td>
        ))}
    </tr>
);

export const CreateAcceptancePointAssortment = Form.create()(CreateAcceptancePointAssortmentForm as any);

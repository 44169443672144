import { Button, Icon, message, Table } from "antd";
import axios from "axios";
import * as React from "react";
import { PageContent } from "../../components/PageContent/index";
import { Helpers } from "../../utils/helpers";
import { SecuritRulesModal } from "./modal";

interface SecurityRulesState {
    isLoading: boolean;
    isProcessingDatabase: boolean;
    modalVisible: boolean;
    securityGroups: Group[];
    editModePointId: null | number;
}

export interface Group {
    id: number;
    name: string;
    rules: string;
    updated_at: string;
}

export class SecurityRules extends React.Component<{}, SecurityRulesState> {
    formRef;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isProcessingDatabase: false,
            editModePointId: null,
            modalVisible: false,
            securityGroups: [],
        };
    }

    componentDidMount() {
        this.fetchSecurityRules();
        document.title = "Dižozols - Pieeju grupas";
    }

    saveFormRef = (formRef) => this.formRef = formRef;

    showModal = () =>
        this.setState({ modalVisible: true })

    handleCancel = () =>
        this.setState({ modalVisible: false, editModePointId: null }, () => {
            this.formRef.resetFields();
        })

    fetchSecurityRules = () => {
        this.setState({ isLoading: true }, () => {
            axios.get(`${process.env.baseUrl}/api/admin/security_rules`, {
                headers: {
                    Authorization: Helpers.getSessionId(),
                },
            })
            .then(response => {
                if (response.data.success === false) {
                    return this.setState({ isLoading: false }, () => {
                        message.error("Neizdevās pieeju grupas");
                    });
                }

                this.setState({
                    isLoading: false,
                    securityGroups: response.data.data,
                });

            })
            .catch(() => {
                message.error("Neizdevās pieeju grupas");
            });
        });
    }

    handleCreate = () => {
        const form = this.formRef;

        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            this.setState({ isProcessingDatabase: true, modalVisible: false }, () => {
                // If we need to update point
                if (this.state.editModePointId) {
                    return axios.put(`${process.env.baseUrl}/api/admin/security_rules`, {
                        id: this.state.editModePointId,
                        ...values,
                        access_rules: form.access_rules,
                    }, {
                        headers: {
                            Authorization: Helpers.getSessionId(),
                        },
                    })
                    .then(response => {
                        if (response.data.success === false) {
                            return message.error("Neizdevās saglabāt grupu");
                        }

                        form.resetFields();

                        const currentsGroups = [...this.state.securityGroups];
                        const userIndex = currentsGroups.findIndex(currentPoint =>
                            currentPoint.id === this.state.editModePointId);

                        currentsGroups[userIndex] = {
                            ...currentsGroups[userIndex],
                            name: values.name,
                            rules: form.access_rules,
                            updated_at: new Date().toISOString(),
                        };

                        return this.setState({
                            isProcessingDatabase: false,
                            modalVisible: false,
                            editModePointId: null,
                            securityGroups: currentsGroups,
                        });
                    })
                    .catch(error => {
                        this.setState({ isProcessingDatabase: false }, () => {
                            Helpers.handleAPIError(error, "Neizdevās saglabāt grupu");
                        });
                    });
                } else {
                    return axios.post(`${process.env.baseUrl}/api/admin/security_rules`, {
                        ...values,
                        access_rules: form.access_rules,
                    }, {
                        headers: {
                            Authorization: Helpers.getSessionId(),
                        },
                    })
                    .then(response => {
                        if (response.data.success === false) {
                            message.error("Neizdevās saglabāt grupu");
                        }

                        form.resetFields();

                        this.setState({
                            isProcessingDatabase: false,
                            securityGroups: [...this.state.securityGroups, {
                                id: response.data.groupId,
                                name: values.name,
                                rules: form.access_rules,
                                updated_at: new Date().toISOString(),
                            }],
                        });
                    })
                    .catch(error => {
                        this.setState({ isProcessingDatabase: false }, () => {
                            Helpers.handleAPIError(error, "Neizdevās saglabāt grupu");
                        });
                    });
                }
            });
        });
    }

    editUser = (pointId: number) => {
        this.setState({ editModePointId: pointId }, () => {
            this.showModal();
        });
    }

    render() {
        const pageContentHeaderActions = <div>
            <Button size="large" onClick={this.showModal}>
                <Icon type="plus" style={{ paddingRight: 5 }}/> Pievienot jaunu grupu
            </Button>
        </div>;

        const pageContentProps = {
            headerTitle: "Pieeju grupas",
            headerActions: pageContentHeaderActions,
        };

        const columns = [{
            title: "ID",
            dataIndex: "id",
            key: "id",
        }, {
            title: "Nosaukums",
            dataIndex: "name",
            key: "name",
        }, {
            title: "Pēdējās izmaiņas",
            dataIndex: "updated_at",
            key: "updated_at",
            render: (text) => (
                <span>{Helpers.formatDateWithTime(text)}</span>
            ),
        }, {
            title: "",
            dataIndex: "operation",
            key: "operation",
            width: 80,
            render: (_text, record) => (
                <Button size="small" onClick={() => this.editUser(record.id)}>Labot grupu</Button>
            ),
        }];

        return (
            <div>
                <PageContent {...pageContentProps}>
                    <Table
                        dataSource={this.state.securityGroups as any}
                        columns={columns}
                        bordered={true}
                        loading={this.state.isLoading}
                        pagination={false}
                        size="small"
                        locale={{
                            filterTitle: "Filtrēt",
                            filterConfirm: "Ok",
                            filterReset: "Nodzēst",
                            emptyText: "Nav datu",
                        }}
                    />

                    {this.state.modalVisible && <SecuritRulesModal
                        visible={this.state.modalVisible}
                        isProcessingDatabase={this.state.isProcessingDatabase}
                        saveFormRef={this.saveFormRef}
                        onCancel={this.handleCancel}
                        onCreate={this.handleCreate}
                        modalTitle={this.state.editModePointId
                            ? "Labot grupas datus"
                            : "Pievienot jaunu grupu"}
                        modalOkText={this.state.editModePointId
                            ? "Labot"
                            : "Pievienot"}
                        modalInitdata={this.state.editModePointId
                            ? this.state.securityGroups.find(point => point.id === this.state.editModePointId)
                            : null}
                    />}
                </PageContent>
            </div>
        );
    }
}

import { Badge, Checkbox, Tooltip, Select } from "antd";
const { Option } = Select;

import * as classNames from "classnames";
import * as React from "react";
import { getPriceByDistance } from "../../constants/distance-expenses";
import { BonusesFields, AssortmentPageAssortment } from "../../containers/AssortmentPage";
import { PricePageAssortment, FilterFields } from "../../containers/PricePageClient";
import { Helpers } from "../../utils/helpers";
import { groupBy, values, clone } from 'lodash';
import { NavLink } from "react-router-dom";

interface AssortmentPricePageProps {
    acceptancePoints: AcceptancePointPricePage[];
    assortment: PricePageAssortment[];
    // bonuses: BonusesFields;
    distanceFilter: number[];
    highlightHighestPrice: boolean;
    // checkPoint(a: boolean, b: string): void;
    showOnlyChecked: boolean;
    filterFields: FilterFields;
    ruleIsValid(ruleId: any): boolean;
    fellingPoint: any;

    selectedAcceptancePoints: Map<string, number>;
    onAcceptancePointSelected(category: string, index: number);
    checkedCategories: Set<string>;
    onCategoryChecked(category: string, checked: boolean): void;
    allChecked: boolean;
    onAllChecked(allChecked: boolean): void;
    
    
}

interface AssortmentPricePageClientState {

}

export interface AcceptancePointPricePage {
    kind: string;
    assortments: AcceptancePointPricePageAssortments[];
}

export interface AcceptancePointPricePageAssortments {
    assortment: string;
    checked: boolean;
    show: boolean;
    code: string;
    diameters: AcceptancePointPricePageAssortmentsDiameters[];
}

export interface AcceptancePointPricePageAssortmentsDiameters {
    minDiameter: number;
    maxDiameter: number
    lengths: AcceptancePointPricePageAssortmentsDiametersLengths[];
}

export interface AcceptancePointPricePageAssortmentsDiametersLengths {
    length: number;
    itemCategoryKey: string;
    items: AcceptancePointPricePageAssortmentLengthsDiametersItem[];
    transporterCode: number;
}

export interface AcceptancePointPricePageAssortmentLengthsDiametersItem {
    diameter: number;
    maxDiameter: number;
    diameterMax: number;
    price: number;
    assortment_id: number;
    surface_coefficient: number;
    acceptance_point_bonus: number;
    calculated_bonus: null | number;
    full_price: null | number; /* price + calculated_bonus */
    calculated_price: null | number; /* full_price - expenses */
    category: string;
    id: number;
    name: string;
    address: string;
    plan: number;
    assortment_bonus: number;
    lat: number;
    lng: number;
    assortmentCode: string;
    updated_at: string;
    calculationError: boolean;
    transportExpenses: number;
    transportExpensesPerCubicMeter: number;
    hash: string;
    checked: boolean;
    comment?: string;
    distance: number;
    priority: string;
}

export class AssortmentPricePageClient extends React.Component<AssortmentPricePageProps, AssortmentPricePageClientState> {

    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    kindColumnFormat = (assortmentItem: AcceptancePointPricePage) => {
        const spanSize = assortmentItem.assortments.reduce((kindTotal, kind) => {
            return kindTotal + kind.diameters.reduce((diameterTotal, diameter) => {
                return diameterTotal + diameter.lengths.length;
            }, 0)
        }, 0);
        return assortmentItem.assortments.length > 0 ? { rowSpan: spanSize } : { colSpan: spanSize  };
    }

    sortimentDiameterColumnFormat = (assortmentData: AcceptancePointPricePageAssortmentsDiameters) => ({
        rowSpan: assortmentData.lengths.length,
    })

    lengthColumnFormat = (assortmentData: AcceptancePointPricePageAssortmentsDiametersLengths) => ({
        rowSpan: 1,
    })

    checkPoints = (e, itemCategoryKey) => {
        this.props.onCategoryChecked(itemCategoryKey, e.target.checked);
    }

    assortmentColumnFormat = (assortmentItem: AcceptancePointPricePageAssortments) => {
        return {
            rowSpan: assortmentItem.diameters.reduce((diameterTotal, diameter) => {
                return diameterTotal + diameter.lengths.length;
            }, 0)
        }
    }

    getHighestPriceForDuplicates = (list) => list.reduce((newList, item) => {
        const itemIndex = newList.findIndex(newListItem => newListItem.diameter === item.diameter);
        if (itemIndex > -1) {
            if (newList[itemIndex].price < item.price) {
               newList[itemIndex] = item;
            }
        } else {
            newList.push({ ...item });
        }

        return newList;
    }, [])

    reducerPlaces(list, minDiameter, maxDiameter){
        let newList: any[] = [];
        let l = list.map(listItem => {
            return {
                ...listItem,
                diameter: Math.max(listItem.diameter, minDiameter),
                maxDiameter: Math.min(listItem.maxDiameter, maxDiameter)
            }
        })
        let criticalPoints = new Set<number>();
        l.forEach(item => {
            criticalPoints.add(item.diameter)
            criticalPoints.add(item.maxDiameter)
        })
        Array.from(criticalPoints).sort((a,b) => a - b).forEach(criticalPoint => {
            let bestMatch = l.filter(p => p.diameter <= criticalPoint && p.maxDiameter > criticalPoint).reduce((lastBestMatch, item) => {
                if(!lastBestMatch || lastBestMatch.price < item.price){
                    return item
                }
                return lastBestMatch
            }, null)
            if(bestMatch && (newList.length === 0 || newList[newList.length - 1].hash !== bestMatch.hash)){
                if(newList.length > 0){
                    newList[newList.length - 1].diameterMax = criticalPoint
                }
                
                newList.push({
                    ...bestMatch,
                    diameter: criticalPoint
                })
            }
        })
        if(newList.length > 0){
            newList[newList.length - 1].diameterMax = Math.min(newList[newList.length - 1].maxDiameter, maxDiameter)
        }
        return newList
    }
    
    onChangeSelectedAcceptancePoint(key, index){
        this.props.onAcceptancePointSelected(key, index);
    }

    checkAllPoints(e){
        this.props.onAllChecked(e.target.checked);
    }

    render() {
        let acceptancePointsFilteredByMaxAmount = this.props.acceptancePoints;

        const renderTableContent = () => {
            if (acceptancePointsFilteredByMaxAmount.length === 0) {
                return <tr key="table-content-no-data">
                    <td colSpan={7} style={{ textAlign: "center" }}>Nav datu</td>
                </tr>;
            }
            return acceptancePointsFilteredByMaxAmount.map((assortmentItem, kindIndex) => {
                let isFirstKindItem = true;
                return assortmentItem.assortments.map((assortmentData, assortmentIndex) => {
                    let isFirstAssortmentItem = true;
                    return (assortmentData.diameters && assortmentData.diameters.map((assortmentDataDiameters, diameterIndex) => {
                        let isFirstDiameterItem = true;
                        return assortmentDataDiameters.lengths && assortmentDataDiameters.lengths.map((assortmentDataLengths, lengthIndex) => {
                            let itemCategoryKey = assortmentDataLengths.itemCategoryKey;
                            let itemCategoryIndex = this.props.selectedAcceptancePoints.has(itemCategoryKey) ? this.props.selectedAcceptancePoints.get(itemCategoryKey) : 0;
                            
                            let assortmentDataItem = assortmentDataLengths.items[itemCategoryIndex]; 
                            
                            return (<tr key={itemCategoryKey}>
                                {isFirstKindItem &&
                                    <td {...this.kindColumnFormat(assortmentItem)} className="centeredText">
                                        {assortmentItem.kind}
                                    </td>
                                }
                                {isFirstAssortmentItem &&
                                    <td {...this.assortmentColumnFormat(assortmentData)} className="centeredText">
                                        {assortmentData.assortment}
                                    </td>
                                }
                                {isFirstDiameterItem && 
                                    <td {...this.sortimentDiameterColumnFormat(assortmentDataDiameters)} className="centeredText">
                                        {assortmentDataDiameters.minDiameter.toFixed(0)} - {assortmentDataDiameters.maxDiameter.toFixed(0)}
                                    </td>
                                }

                                {isFirstKindItem = false}
                                {isFirstAssortmentItem = false}
                                {isFirstDiameterItem = false}
                                
                                <td {...this.lengthColumnFormat(assortmentDataLengths)} className="centeredText">
                                    {assortmentDataLengths.length.toFixed(1)}m
                                </td>

                                {this.props.filterFields.transportCost &&
                                    <td className="centeredText">
                                        €{getPriceByDistance(assortmentDataItem.distance).toFixed(2)}
                                        {assortmentDataItem.calculationError && <Badge status="error" style={{ marginLeft: 5 }}/>}
                                    </td>
                                }

                                {(this.props.filterFields.fullPrice /* && this.props.ruleIsValid("PricePage:ShowOnlyChecked")*/) && <td className="centeredText">
                                    €{ assortmentDataItem.full_price.toFixed(2) }
                                </td>}

                                {(this.props.filterFields.price) && 
                                <td className="centeredText">
                                    <Tooltip placement="top" title={`Pēdējās izmaiņas: ${Helpers.formatTimeAgo(assortmentDataItem.updated_at)}`}>
                                        €{assortmentDataItem.calculated_price.toFixed(2)}

                                        {assortmentDataItem.calculationError && <Badge status="error" style={{ marginLeft: 5 }}/>}
                                    </Tooltip>
                                </td>}

                                {(this.props.filterFields.showDistance /* && this.props.ruleIsValid("PricePage:ShowOnlyChecked")*/) && <td className="centeredText">
                                    {/* <a href={'https://www.google.com/maps/dir/?api=1&origin=' + this.props.fellingPoint[0] + ',' + this.props.fellingPoint[1] + '&destination=' + assortmentDataItem.lat + ',' + assortmentDataItem.lng + '&travelmode=driving'} target="_blank" rel="noopener noreferrer">
                                        { (assortmentDataItem.distance / 1000).toFixed(1) }
                                    </a> */}
                                    { (assortmentDataItem.distance / 1000).toFixed(1) }
                                </td>}

                                {(this.props.filterFields.showAcceptancePoint) && <td>
                                    {
                                        <Select defaultValue={itemCategoryIndex} onChange={(value) => 
                                            this.onChangeSelectedAcceptancePoint(itemCategoryKey, value)}>
                                            {  assortmentDataLengths.items.slice(0, 3).map((item, index) => (
                                                <Option value={index}>{ item.name }  <span className="hide-selected"><NavLink to={`/cenas/acceptance_points/${item.id}/assortment`} target="_blank">(cenas)</NavLink> ({ item.calculated_price.toFixed(2) })</span></Option>
                                            )) }
                                        </Select>
                                    }
                                </td>}
                                
                                {(this.props.filterFields.acceptancePointPriority /* && this.props.ruleIsValid("PricePage:ShowOnlyChecked")*/) && <td className="centeredText">
                                    { assortmentDataItem.priority }
                                </td>}

                                {(this.props.filterFields.showTransporterCode /* && this.props.ruleIsValid("PricePage:ShowOnlyChecked")*/) && <td className="centeredText">
                                    { assortmentDataLengths.transporterCode }
                                </td>}
                                
                                {(!this.props.showOnlyChecked && this.props.ruleIsValid("PricePage:ShowOnlyChecked")) && <td style={{ textAlign: "center" }}>
                                    <Checkbox
                                        checked={this.props.checkedCategories.has(itemCategoryKey)}
                                        onChange={(e) => this.checkPoints(e, itemCategoryKey)}
                                    />
                                </td>}
                            </tr>)
                                
                            })}
                        ))}
                )});
        };

        return (
            <table className="price-table">
                <thead>
                    <tr>
                        <th>Suga</th>
                        <th>Sortiments</th>
                        <th>Sortimenta diametrs</th>
                        <th>Garums, m</th>
                        {this.props.filterFields.transportCost && <th>Transporta izmaksas</th>}
                        {(this.props.filterFields.fullPrice /* && this.props.ruleIsValid("PricePage:ShowOnlyChecked")*/)  && <th>
                            Pilna Cena
                        </th>}
                        {(this.props.filterFields.price /* && this.props.ruleIsValid("PricePage:ShowOnlyChecked")*/)  && <th>
                            Cena
                        </th>}
                        {(this.props.filterFields.showDistance /* && this.props.ruleIsValid("PricePage:ShowOnlyChecked")*/)  && <th>
                            Attālums, km
                        </th>}
                        {(this.props.filterFields.showAcceptancePoint /* && this.props.ruleIsValid("PricePage:ShowOnlyChecked")*/)  && <th>
                            Piezīmes
                        </th>}
                        {(this.props.filterFields.acceptancePointPriority /* && this.props.ruleIsValid("PricePage:ShowOnlyChecked")*/)  && <th>
                            Prioritāte
                        </th>}
                        {(this.props.filterFields.showTransporterCode /* && this.props.ruleIsValid("PricePage:ShowOnlyChecked")*/)  && <th>
                            Kods izvedējam
                        </th>}
                        {(!this.props.showOnlyChecked && this.props.ruleIsValid("PricePage:ShowOnlyChecked")) && <th>
                            <Checkbox checked={this.props.allChecked} onChange={(e) => this.checkAllPoints(e)}></Checkbox>
                        </th>}
                    </tr>
                </thead>
                <tbody>
                    {renderTableContent()}
                </tbody>
            </table>
        );
    }
}

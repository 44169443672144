import { Button, message, Select, Table } from "antd";
import axios from "axios";
import * as React from "react";
import { PageContent } from "../../components/PageContent/index";
import { Helpers } from "../../utils/helpers";

interface SecurityRulesState {
    isLoading: boolean;
    isProcessingDatabase: boolean;
    apiSettings: Setting[];
}

export interface Setting {
    id: number;
    key: string;
    name: string;
    value: string;
    type: string;
    updated_at: string;
}

const Option = Select.Option;

export class ApiSettings extends React.Component<{}, SecurityRulesState> {
    formRef;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isProcessingDatabase: false,
            apiSettings: [],
        };
    }

    componentDidMount() {
        this.fetchApiSettings();
        document.title = "Dižozols - API iestatījumi";
    }

    fetchApiSettings = () => {
        this.setState({ isLoading: true }, () => {
            axios.get(`${process.env.baseUrl}/api/admin/api_settings`, {
                headers: {
                    Authorization: Helpers.getSessionId(),
                },
            })
            .then(response => {
                if (response.data.success === false) {
                    return this.setState({ isLoading: false }, () => {
                        message.error("Neizdevās ielādēt API iestatījumus");
                    });
                }

                this.setState({
                    isLoading: false,
                    apiSettings: response.data.data,
                });

            })
            .catch(() => {
                message.error("Neizdevās ielādēt API iestatījumus");
            });
        });
    }

    saveSettings = () => {
        //
    }

    settingValueChange = (settingId, value) => {
        const savingIndicator = message.loading("Saglabā..", 0);

        axios.put(`${process.env.baseUrl}/api/admin/api_settings`, {
            settingId,
            value,
        }, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            const tempSettings = [ ...this.state.apiSettings ];
            const settingsIndex = tempSettings.findIndex(setting => setting.id === settingId);

            if (response.data.success === false || settingsIndex === -1) {
                savingIndicator();
                message.error("Neizdevās ielādēt API iestatījumus");
                return;
            }

            tempSettings[settingsIndex].value = value;
            tempSettings[settingsIndex].updated_at = new Date().toISOString();

            this.setState({ apiSettings: tempSettings });

            savingIndicator();
        })
        .catch(() => {
            savingIndicator();
            message.error("Neizdevās ielādēt API iestatījumus");
        });
    }

    renderSettingValue = (setting: Setting) => {
        let valueObject: any = null;

        switch (setting.type) {
            case "boolean":
                valueObject = <Select defaultValue={setting.value} onChange={value => this.settingValueChange(setting.id, value)}>
                    <Option value="true">Jā</Option>
                    <Option value="false">Nē</Option>
                </Select>;
                break;
            default:
        }

        return valueObject;
    }

    render() {
        const pageContentProps = {
            headerTitle: "API iestatījumi",
        };

        const columns = [{
            title: "Atslēga",
            dataIndex: "key",
            key: "key",
        }, {
            title: "Apraksts",
            dataIndex: "description",
            key: "description",
        }, {
            title: "Vērtība",
            dataIndex: "value",
            key: "value",
            render: (_text, record) => (
                <span>{this.renderSettingValue(record)}</span>
            ),
        }, {
            title: "Pēdējās izmaiņas",
            dataIndex: "updated_at",
            key: "updated_at",
            render: (date) => (
                <span>{Helpers.formatDateWithTime(date)}</span>
            ),
        }];

        return (
            <div>
                <PageContent {...pageContentProps}>
                    <Table
                        dataSource={this.state.apiSettings as any}
                        columns={columns}
                        bordered={true}
                        loading={this.state.isLoading}
                        pagination={false}
                        size="small"
                        locale={{
                            filterTitle: "Filtrēt",
                            filterConfirm: "Ok",
                            filterReset: "Nodzēst",
                            emptyText: "Nav datu",
                        }}
                    />
                </PageContent>
            </div>
        );
    }
}

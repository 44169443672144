import { Button, Form, Icon, Input, InputNumber, message, Modal, Select } from "antd";
// tslint:disable-next-line:no-submodule-imports
import { FormComponentProps } from "antd/lib/form";
import axios from "axios";
import * as React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Helpers } from "../../utils/helpers";
import { DEAL_TYPES, STATES } from "../EditFellingPoint";

const FormItem = Form.Item;

export interface FellingPointsMainProps extends FormComponentProps {
    refreshData(): void;
    ruleIsValid(key: string): boolean;
}

interface FellingPointsMainState {
    modalVisible: boolean;
    isProcessingDatabase: boolean;
}

const Option = Select.Option;

class FellingPointsMain extends React.Component<FellingPointsMainProps, FellingPointsMainState> {
    form;

    constructor(props) {
        super(props);

        this.state = {
            modalVisible: false,
            isProcessingDatabase: false,
        };
    }

    showModal = () => this.setState({ modalVisible: true });

    handleCancel = () =>
        this.setState({ modalVisible: false, isProcessingDatabase: false }, () => {
            if (this.props.form) {
                this.props.form.resetFields();
            }
        })

    handleCreate = () => {
        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }
            this.setState({ isProcessingDatabase: true }, () => {
                axios.post(`${process.env.baseUrl}/api/felling_points`, values, {
                    headers: {
                        Authorization: Helpers.getSessionId(),
                    },
                })
                .then(response => {
                    if (response.data.success === false) {
                        message.error("Neizdevās saglabāt cirsmas datus");
                    } else {

                        this.setState({
                            modalVisible: false,
                            isProcessingDatabase: false,
                        });

                        this.props.refreshData();
                    }
                })
                .catch(error => {
                    this.setState({ isProcessingDatabase: false });
                    Helpers.handleAPIError(error, "Neizdevās saglabāt cirsmas datus");
                });
            });
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <div>
                {/* <Button size="large" onClick={this.showModal}>
                    <Icon type="plus" style={{ paddingRight: 5 }}/> Pievienot cirsmu
                </Button> */}

                <Modal
                    wrapClassName="vertical-center-modal"
                    visible={this.state.modalVisible}
                    title="Pievienot cirsmu"
                    destroyOnClose={true}
                    width={750}
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>Atcelt</Button>,
                        <Button key="submit" type="primary" loading={this.state.isProcessingDatabase} onClick={this.handleCreate}>
                            Pievienot
                        </Button>,
                    ]}
                >
                    <Form layout="vertical">
                        <Scrollbars style={{ height: "60vh" }}>
                            <div style={{ paddingRight: 25 }}>
                                <FormItem label="Nosaukums" style={{ marginBottom: 0 }}>
                                    {getFieldDecorator("felling_area_name", {
                                        rules: [{
                                            required: true,
                                            message: "Lūdzu norādiet nosaukumu!",
                                        }],
                                    })(<Input/>)}
                                </FormItem>

                                <FormItem label="Kadastra nr." style={{ marginBottom: 0 }}>
                                    {getFieldDecorator("cadastre_number", {
                                        rules: [{
                                            required: true,
                                            message: "Lūdzu norādiet kadastra nr.!",
                                        }],
                                    })(<Input/>)}
                                </FormItem>

                                <FormItem label="Darījuma tips" style={{ marginBottom: 0 }}>
                                    {getFieldDecorator("deal_type")(
                                        <Select>
                                            {DEAL_TYPES.map(dealType => (
                                                <Option value={dealType.value} key={dealType.value}>{dealType.text}</Option>
                                            ))}
                                        </Select>,
                                    )}
                                </FormItem>

                                <FormItem label="Stadija" style={{ marginBottom: 0 }}>
                                    {getFieldDecorator("state")(
                                        <Select>
                                            {STATES.map(dealType => (
                                                <Option value={dealType.value} key={dealType.value}>{dealType.text}</Option>
                                            ))}
                                        </Select>,
                                    )}
                                </FormItem>

                                <FormItem label="Plānotie m3" style={{ marginBottom: 0 }}>
                                    {getFieldDecorator("planed_cubic_meter", {
                                        initialValue: 0,
                                    })(<InputNumber style={{ width: "100%" }}/>)}
                                </FormItem>

                                <FormItem label="Izstrādātie m3" style={{ marginBottom: 0 }}>
                                    {getFieldDecorator("develop_cubic_meter", {
                                        initialValue: 0,
                                    })(<InputNumber style={{ width: "100%" }}/>)}
                                </FormItem>

                                <FormItem label="Atlikušie %" style={{ marginBottom: 0 }}>
                                    {getFieldDecorator("percents_left", {
                                        initialValue: 0,
                                    })(
                                        <InputNumber style={{ width: "100%" }}/>,
                                    )}
                                </FormItem>

                                <FormItem label="Priede" style={{ marginBottom: 0 }}>
                                    {getFieldDecorator("priede_type_value", {
                                        initialValue: 0,
                                    })(
                                        <InputNumber style={{ width: "100%" }}/>,
                                    )}
                                </FormItem>

                                <FormItem label="Egle" style={{ marginBottom: 0 }}>
                                    {getFieldDecorator("egle_type_value", {
                                        initialValue: 0,
                                    })(
                                        <InputNumber style={{ width: "100%" }}/>,
                                    )}
                                </FormItem>

                                <FormItem label="Bērzs" style={{ marginBottom: 0 }}>
                                    {getFieldDecorator("berzs_type_value", {
                                        initialValue: 0,
                                    })(
                                        <InputNumber style={{ width: "100%" }}/>,
                                    )}
                                </FormItem>

                                <FormItem label="Apse" style={{ marginBottom: 0 }}>
                                    {getFieldDecorator("apse_type_value", {
                                        initialValue: 0,
                                    })(
                                        <InputNumber style={{ width: "100%" }}/>,
                                    )}
                                </FormItem>

                                <FormItem label="Baltalksnis" style={{ marginBottom: 0 }}>
                                    {getFieldDecorator("baltalksnis_type_value", {
                                        initialValue: 0,
                                    })(
                                        <InputNumber style={{ width: "100%" }}/>,
                                    )}
                                </FormItem>

                                <FormItem label="Ozols, Osis" style={{ marginBottom: 0 }}>
                                    {getFieldDecorator("ozols_type_value", {
                                        initialValue: 0,
                                    })(
                                        <InputNumber style={{ width: "100%" }}/>,
                                    )}
                                </FormItem>

                                <FormItem label="Melnalksnins" style={{ marginBottom: 0 }}>
                                    {getFieldDecorator("melnalksnis_type_value", {
                                        initialValue: 0,
                                    })(
                                        <InputNumber style={{ width: "100%" }}/>,
                                    )}
                                </FormItem>

                                <FormItem label="Cits" style={{ marginBottom: 0 }}>
                                    {getFieldDecorator("other_type_value", {
                                        initialValue: 0,
                                    })(
                                        <InputNumber style={{ width: "100%" }}/>,
                                    )}
                                </FormItem>

                                <FormItem label="Kopā" style={{ marginBottom: 0 }}>
                                    {getFieldDecorator("total_type_value", {
                                        initialValue: 0,
                                    })(
                                        <InputNumber style={{ width: "100%" }}/>,
                                    )}
                                </FormItem>
                            </div>
                        </Scrollbars>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export const AddFellingPoint = Form.create()(FellingPointsMain as any);

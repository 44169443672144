import { Card, Checkbox, Divider, Form, message, Row, Switch, InputNumber, Radio, Button, Col } from "antd";
import axios from "axios";
import * as loadGoogleMapsApi from "load-google-maps-api";
import * as React from "react";
import { LabaledField } from "../../components/LabeledField";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { Helpers } from "../../utils/helpers";
import { POLYGON_STATE_COLORS, PolygonSateColor } from "../../utils/map_settings";
import { DEAL_TYPES, STATES_MATRIX } from "../EditFellingPoint";
import { AcceptancePointPricePage, PricePageAssortment } from "../PricePage";
import RadioGroup from "antd/lib/radio/group";
import { getPriceByDistance } from "../../constants/distance-expenses";
import { RadioChangeEvent } from "antd/lib/radio";
import { debounce } from 'lodash';


let DistinctColors = require('distinct-colors');

interface MapRootProps {
    form: any;
    map: any;
    google: any;
    ruleIsValid(ruleId: any): boolean;
}

interface MapRootState {
    acceptancePoints: AcceptancePoint[];
    fellingAreas: any[];
    dataLoaded: boolean;
    activeMarker: any;
    showingInfoWindow: boolean;
    selectedPlace: any;
    assortment: null | PricePageAssortment[];
    allChecked: boolean;
    showAcceptancePoints: boolean;
    filters: any[];
    markers: any[];
    initMarkers: boolean;
    filterTypeFellingAreas: FellingAreaType[];
    loadingText: string;
    distances: number[][],
    assortmentFilter: string,
    targetFilter: "profit" | "turnover",
    acceptancePointFilter: Set<string>,
    infoPanelShow: boolean
}

interface Sortiment {
    id: number;
    diameter: number;
    length: number;
    price: number;
    bonus: number;
    predictedM3?: number;

}

interface AcceptancePoint {
    address: string;
    assortments: Sortiment[];
    id: number;
    lat: number;
    lng: number;
    name: string;
    plan: number;
    updated_at: string;
    color: string;
    show: boolean;
    intermediateAverageTransportCosts?: number;
    priority: string,
    manualBonus: number,
    manualProfit: number,
    manualM3: number
}

interface FellingAreaType {
    type: number;
    show: boolean;
}

const groupFilters: any[] = [{
    id: "group_egle",
    title: "Egle",
    assortments: [1, 4, 6, 10, 11, 13, 22],
    show: true,
}, {
    id: "group_priede",
    title: "Priede",
    assortments: [1, 5, 6, 10, 11, 13, 21, 28],
    show: true,
}, {
    id: "group_apse",
    title: "Apse",
    assortments: [2, 3, 7, 12, 15, 20, 29],
    show: true,
}, {
    id: "group_berzs",
    title: "Bērzs",
    assortments: [2, 8, 12, 14, 17, 18, 19],
    show: true,
}, {
    id: "group_baltalksnis",
    title: "Baltalksnis",
    assortments: [2, 9, 12],
    show: true,
}, {
    id: "group_melnalksnins",
    title: "Melnalksnins",
    assortments: [2, 12, 24, 30],
    show: true,
}, {
    id: "group_ozols",
    title: "Ozols",
    assortments: [2, 12, 26],
    show: true,
}, {
    id: "group_osis",
    title: "Osis",
    assortments: [2, 12, 27],
    show: true,
}, {
    id: "group_parejie",
    title: "Pārējie",
    assortments: [16, 23, 25],
    show: true,
}];

class Material{
    amount: number = 0;
    public assortmentCode: string[];

    constructor(assortmentCode: string | string[],public filterType: string, public minDiameter: number, public maxDiameter: number, public acceptableLengths: number[]){
        if(Array.isArray(assortmentCode)){
            this.assortmentCode = assortmentCode;
        }else{
            this.assortmentCode = [assortmentCode];
        }
    }
}

class FellingPointMaterials{
    materials = new Map<string, Material>();

    constructor(fellingArea: any){
        this.materials.set("PZB_A", new Material("P zb", "pzb_a", 28, 100, [3.1]));
        this.materials.set("PZB_RESNIE", new Material("P zb", "pzb_resnie", 44, 100, [3.7, 4.3, 4.9, 5.4, 6.1]));
        this.materials.set("PZB_VIDEJIE", new Material("P zb", "pzb_videjie", 18, 44, [3.7, 4.3, 4.9, 5.4, 6.1]));
        this.materials.set("PZB_TIEVIE", new Material("P zb", "pzb_tievie", 14, 18, [3.7, 4.3, 4.9, 5.4, 6.1]));
        // this.materials.set("P_SK_PM", new Material(["P pm", "SK pm"], "sk_pm", 6, 100, [3.0]));
        
        // this.materials.set("P_SKG", new Material(["P gulsnis", "Gulsnis"], "gul", 0, 100, []));
        this.materials.set("SK_TARA", new Material(["SK tara"], "sk_tara", 14, 100, [3.1]))
        this.materials.set("SK_MALKA", new Material(["SK malka"], "sk_malka", 6, 100, [3.0]));

        // this.materials.set("E_SKG", new Material("Gulsnis", "gul", 0, 100));
        // this.materials.set("E_SK_PM", new Material(["E pm", "SK pm"], "sk_pm", 6, 100, [3.0]));
        this.materials.set("EZB_A", new Material("E zb", "ezb_a", 24, 100, [3.1]));
        this.materials.set("EZB_RESNIE", new Material("E zb", "ezb_resnie", 44, 100, [3.7, 4.3, 4.9, 5.4, 6.1]));
        this.materials.set("EZB_VIDEJIE", new Material("E zb", "ezb_videjie", 18, 44, [3.7, 4.3, 4.9, 5.4, 6.1]));
        this.materials.set("EZB_TIEVIE", new Material("E zb", "ezb_tievie", 14, 18, [3.7, 4.3, 4.9, 5.4, 6.1]));
    
        this.materials.set("SK_PM", new Material(["spec_SK_PM"], "sk_pm", 0, 100, [0]));

        /* Special sortiment for complex logic purposes */
        this.materials.set("BF", new Material(["spec_B_FIN"], "b_fin", 0, 100, [0]))
        // this.materials.set("BF_A", new Material("B fin. A", "b_fin", 20, 100, [2.8, 3.1]));
        // this.materials.set("BF_26", new Material("B fin. B", "b_fin", 20, 100, [2.8, 3.1]));
        // this.materials.set("BF_18_26", new Material("B fin. C", "b_fin", 20, 100, [2.8, 3.1]));
        this.materials.set("B_PM", new Material("B pm", "b_pm", 6, 100, [3.0]));
        this.materials.set("B_LK_TARA", new Material(["B tara", "LK tara"], "b_tara", 14, 100, [2.5]));
        
        this.materials.set("A_ZB", new Material(["A zb", "A zb II klase"], "a_zb", 22, 100, [3.1]));
        this.materials.set("A_PM", new Material("A pm", "a_pm", 6, 100, [3.0]));
        this.materials.set("A_LK_TARA", new Material(["A tara", "LK tara"], "a_tara", 14, 100, [2.5]));
        
        this.materials.set("MA_ZB", new Material(["MAL", "MAL II klase"], "ma_zb", 22, 100, [3.1]));

        // this.materials.set("O_M", new Material(["O", "OS"], "", 0, 100));

        this.materials.set("LK_TARA", new Material("LK tara", "lk_tara", 14, 100, [2.5]));

        this.materials.set("LK_MALKA", new Material("LK malka", "lk_malka", 6, 100, [3.0]));

        let priede = fellingArea.priede_type_value || 0;
        let egle = fellingArea.egle_type_value || 0;
        let berzs = fellingArea.berzs_type_value || 0;
        let apse = fellingArea.apse_type_value || 0;
        let baltalksnis = fellingArea.baltalknis_type_value || 0;
        let ozols = fellingArea.ozols_type_value || 0;
        let melnalksnis = fellingArea.melnalksnis_type_value || 0;
        let other = fellingArea.other_type_value || 0;

        /* No cenu lapas darba uzdevuma pielikuma #1 */

        (this.materials.get("PZB_A") as Material).amount += 0.15 * priede;
        (this.materials.get("PZB_RESNIE") as Material).amount += 0.03 * priede;
        (this.materials.get("PZB_VIDEJIE") as Material).amount += 0.42 * priede;
        (this.materials.get("PZB_TIEVIE") as Material).amount += 0.20 * priede;
        (this.materials.get("SK_TARA") as Material).amount += 0.03 * priede;
        // (this.materials.get("P_SK_PM") as Material).amount += 0.10 * priede;
        (this.materials.get("SK_MALKA") as Material).amount += 0.04 * priede;
        this.materials.get("SK_PM")!.amount += 0.10 * priede;

        (this.materials.get("EZB_A") as Material).amount += 0.15 * egle;
        (this.materials.get("EZB_RESNIE") as Material).amount += 0.03 * egle;
        (this.materials.get("EZB_VIDEJIE") as Material).amount += 0.32 * egle;
        (this.materials.get("EZB_TIEVIE") as Material).amount += 0.20 * egle;
        (this.materials.get("SK_TARA") as Material).amount += 0.03 * egle;
        // (this.materials.get("E_SK_PM") as Material).amount += 0.18 * egle;
        (this.materials.get("SK_MALKA") as Material).amount += 0.04 * egle;
        this.materials.get("SK_PM")!.amount += 0.18 * egle;
        
        this.materials.get("BF")!.amount += 0.25 * berzs;
        // (this.materials.get("BF_A") as Material).amount += 0.03 * berzs;
        // (this.materials.get("BF_26") as Material).amount += 0.07 * berzs;
        // (this.materials.get("BF_18_26") as Material).amount += 0.15 * berzs;
        (this.materials.get("B_PM") as Material).amount += 0.50 * berzs;
        (this.materials.get("B_LK_TARA") as Material).amount += 0.25 * berzs;
        (this.materials.get("LK_TARA") as Material).amount += 0.25 * berzs;
        
        (this.materials.get("A_ZB") as Material).amount += 0.10 * apse;
        (this.materials.get("A_PM") as Material).amount += 0.60 * apse;
        (this.materials.get("A_LK_TARA") as Material).amount += 0.30 * apse;
        (this.materials.get("LK_TARA") as Material).amount += 0.30 * apse;

        (this.materials.get("LK_TARA") as Material).amount += 0.30 * melnalksnis;
        (this.materials.get("MA_ZB") as Material).amount += 0.10 * melnalksnis;
        (this.materials.get("LK_MALKA") as Material).amount += 0.60 * melnalksnis;

        (this.materials.get("LK_TARA") as Material).amount += 0.25 * ozols;
        (this.materials.get("LK_MALKA") as Material).amount += 0.75 * ozols;

        (this.materials.get("LK_TARA") as Material).amount += 0.30 * baltalksnis;
        (this.materials.get("LK_MALKA") as Material).amount += 0.70 * baltalksnis;

        (this.materials.get("LK_MALKA") as Material).amount += 1.00 * other;
    }
}

function getAssortmentIdByCode(code: string, assortment: any[]){
    return assortment.find(assortmentItem => {
        return assortmentItem.code == code;
    }).id;
}

function getAssortmentCodeById(id: number, assortment: any[]){
    return assortment.find(assortmentItem => {
        return assortmentItem.id == id;
    }).code;
}

class MatrixRoot extends React.Component<MapRootProps, MapRootState> {
    map;
    infoWindow;
    googleMaps;
    markers: any[] = [];
    polygons: any[] = [];

    constructor(props) {
        super(props);

        this.state = {
            dataLoaded: false,
            acceptancePoints: [],
            fellingAreas: [],
            activeMarker: {},
            selectedPlace: {},
            showingInfoWindow: false,
            assortment: null,
            allChecked: true,
            filters: groupFilters,
            assortmentFilter: "ezb_a",
            markers: [],

            showAcceptancePoints: true,
            filterTypeFellingAreas: STATES_MATRIX.map(state => ({
                type: state.value,
                show: true,
            } as any)),

            initMarkers: false,

            loadingText: "Ielādē sortimentus...",
            distances: [],
            targetFilter: "profit",
            acceptancePointFilter: new Set(["level_a", "level_b", "level_c", "level_d"]),
            infoPanelShow: true
        };
    }

    componentDidMount() {
        document.title = "Dižozols - Matrica";

        this.fetchData(() => {
            // Load map
            loadGoogleMapsApi({
                key: process.env.CLIENT_GOOGLE_MAPS_API_KEY,
                language: "lv",
                region: "LV",
                libraries: ["drawing"],
                v: "3.33",
            }).then((googleMaps) => {
                this.googleMaps = googleMaps;

                this.map = new this.googleMaps.Map(document.querySelector(".map-selector"), {
                    center: {
                        lat: 56.9496487,
                        lng: 24.1051865,
                    },
                    zoom: 8,
                    mapTypeId: "satellite",
                });

                this.infoWindow = new this.googleMaps.InfoWindow({
                    size: new this.googleMaps.Size(150, 50),
                });

                this.googleMaps.event.addListener(this.map, "click", () => {
                    this.infoWindow.close();
                });

                this.drawMarkers();

                this.setState({ initMarkers: true }, () => {
                    this.calculateOptimalSellingPlaces();
                });

            }).catch(_error => {
                message.error("Neizdevās ielādēt karti");
            });
        });
    }

    getStateName = (id) => {
        const stateObject = STATES_MATRIX.find(state => state.value === parseInt(id, 10));
        return stateObject ? stateObject.text : "-";
    }

    getDealTypeName = (id) => {
        const stateObject = DEAL_TYPES.find(state => state.value === parseInt(id, 10));
        return stateObject ? stateObject.text : "-";
    }

    drawMarkers = () => {
        this.drawAcceptancePointMarkers();
        this.drawFellingPointMarkers();
        // this.redrawMarkers();
    }

    redrawMarkers = () => {
        this.drawAcceptancePointMarkers();

        this.state.filterTypeFellingAreas.map(filter => {
            if (!filter.show) {
                this.clearMarkersByState(filter.type);
            } else {
                this.showMarkersByState(filter.type);
            }
        });

        // Hide "Izstrāde pabeigta" and "Meža atjaunošana"
        // this.clearMarkersByState(100000003);
        // this.clearMarkersByState(100000004);
    }

    getDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371000; // KM (change this constant to get miles)
        const dLat = (lat2 - lat1) * Math.PI / 180;
        const dLon = (lon2 - lon1) * Math.PI / 180;
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c;
        if (d > 1) return Math.round(d);
        else if (d <= 1) return Math.round(d * 1000);
        return d;
    }

    calculateNearestAcceptPoint = (point) => {
        /* should be modified to calculate most cost effective point */
        let closest: any = false;
        let closestDistance = 0;
        this.state.acceptancePoints.forEach((item, index) => {
            //console.log('point.map_center_lat, point.map_center_lng, item.lat, item.lon', point.map_center_lat, point.map_center_lng, item.lat, item.lng);
            const distance = this.getDistance(point.map_center_lat, point.map_center_lng, item.lat, item.lng);
            if (closestDistance == 0 || closestDistance > distance) {
                closestDistance = distance;
                closest = item;
            }
        });
        return closest;
    }

    drawFellingPointMarkers = (drawOnlyPolygons = false) => {
        if (this.state.fellingAreas) {
            this.state.fellingAreas.map(point => {
                if (!point.state || point.state > 100000002) {
                    return;
                }
                const pointLatLang = new this.googleMaps.LatLng(point.map_center_lat, point.map_center_lng);
                // Otherwise just add marker to map
                // const acceptPoint = this.calculateNearestAcceptPoint(point);
                // console.log('Nearest ', acceptPoint);
                
                const marker = new this.googleMaps.Marker({
                    map: this.map,
                    position: pointLatLang,
                    clickable: true,
                    // icon: {
                    //     //url: `${process.env.baseUrl}/assets/images/icons/${point.state}.png`,
                    //     url: "http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=|" + acceptPoint.color.replace('#', '') + "|000000",
                    // },
                });

                this.markers.push({
                    id: point.id,
                    type: "felling_area",
                    state: point.state,
                    marker,
                });

                google.maps.event.addListener(marker, "click", () => {
                    const paragraphStyle = "margin-bottom: 4px; font-size: 15px";
                    const contentString = `<div>
                        <h3>${point.felling_area_name}</h3>
                        <p style="${paragraphStyle}">Kadastra numurs: ${point.cadastre_number}</p>
                        <p style="${paragraphStyle}">Plānotie m3: ${point.planed_cubic_meter}</p>
                        <p style="${paragraphStyle}">Izstrādātie m3: ${point.develop_cubic_meter}</p>
                        <p style="${paragraphStyle}">Darījuma tips: ${this.getDealTypeName(point.deal_type)}</p>
                        <p style="${paragraphStyle}">Stadija: ${this.getStateName(point.state)}</p>
                        <p style="${paragraphStyle}">Pieņemšanas punkti: ${point.acceptancePoints}</p>
                        <p style="${paragraphStyle}">
                            <a href="/cenas/cirsmas/labot/${point.id}">
                                Apskatit cirsmu
                            </a>
                        </p>
                    </div>`;

                    this.infoWindow.setContent(contentString);
                    this.infoWindow.open(this.map, marker);
                });
                
            });
        }
    }

    drawAcceptancePointMarkers = () => {

        let colors = new DistinctColors({
            count: this.state.acceptancePoints.length,
            lightMin: 50
        });
        if (this.state.acceptancePoints && this.state.showAcceptancePoints) {
            this.state.acceptancePoints.filter(point => !!point.show).map((point, index) => {
                const pointLatLang = new this.googleMaps.LatLng(point.lat, point.lng);
                // Otherwise just add marker to map
                point.color = colors[index].hex();
                const marker = new this.googleMaps.Marker({
                    map: this.map,
                    position: pointLatLang,
                    clickable: true,
                    icon: {
                        // url: `${process.env.baseUrl}/assets/images/icons/1.png`,
                        url: "http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=" + point.name.substr(0, 1) + "|" + colors[index].hex().replace('#', '') + "|000000",
                        scaledSize: new google.maps.Size(19, 31)
                    },
                });

                this.markers.push({
                    id: point.id,
                    type: "acceptance_point",
                    marker,
                });

                google.maps.event.addListener(marker, "click", () => {
                    const paragraphStyle = "margin-bottom: 4px; font-size: 15px";
                    let totalM3 = point.assortments.reduce((a, b) => a + (b.predictedM3 || 0), 0);
                    let intermediatePrice: number = 0;
                    let materialSum = 0;

                    let materials = new FellingPointMaterials({
                        priede_type_value: 1,
                        egle_type_value: 1,
                        berzs_type_value: 1,
                        apse_type_value: 1,
                        melnalksnis_type_value: 1,
                        ozols_type_value: 1,
                        baltalknis_type_value: 1,
                        other_type_value: 1
                    });
                    materials.materials.forEach(material => {
                        let price = this.getPaymentForMaterialAtAcceptancePoint(material.assortmentCode, material.minDiameter, material.maxDiameter, point, material.acceptableLengths);
                        if(material.filterType === this.state.assortmentFilter){
                            if(price !== null){
                                intermediatePrice += price.price * material.amount;
                                materialSum += material.amount;
                            }
                        }
                    });
                    const contentString = `<div>
                        <h3>${point.name}</h3>
                        <p style="${paragraphStyle}">Plāns: ${point.plan}</p>
                        <p style="${paragraphStyle}">Piemaksa: ${point.manualBonus}</p>
                        <p style="${paragraphStyle}">Peļņa: ${point.manualProfit}</p>
                        <p style="${paragraphStyle}">Pieļaujamie m3: ${point.manualM3}</p>
                        <p style="${paragraphStyle}">Plānotie m3: ${totalM3.toFixed(0)}</p>
                        <p style="${paragraphStyle}">Vidējā samaksa: ${((intermediatePrice / materialSum) || 0).toFixed(2)}</p>
                        <p style="${paragraphStyle}">Vidējais svērtais transports: ${(((point.intermediateAverageTransportCosts || 0) / totalM3) || 0).toFixed(2)}</p>
                        <p style="${paragraphStyle}">Sortiments: ${point.assortments.map(sortiment => getAssortmentCodeById(sortiment.id, this.state.assortment as PricePageAssortment[])).filter(code => code !== 'spec_B_FIN').filter(/* distinct */(v, i, a) => a.indexOf(v) === i).join(", ")}</p>
                        <p style="${paragraphStyle}">
                            <a href="/cenas/acceptance_points/${point.id}/assortment">
                                Apskatit sortimentu
                            </a>
                        </p>
                    </div>`;

                    this.infoWindow.setContent(contentString);
                    this.infoWindow.open(this.map, marker);
                });
            });
        }
    }

    hideFellingPointMarkers = () => {
        this.markers.filter(marker => marker.type === "felling_area").map(point => {
            point.marker.setVisible(false);
        });
    }

    clearAllPolygons = () => {
        this.polygons.map(point => {
            point.polygon.setVisible(false);
        });

        this.polygons = [];
    }

    showAllAvailablePoints = () => {
        const tempFellingAreas = [ ...this.state.fellingAreas ];
        const modifiedFellingAreas = tempFellingAreas.map(acceptancePoint => ({
            ...acceptancePoint,
            show: true,
        }));

        this.setState({ fellingAreas: modifiedFellingAreas }, () => {
            this.markers.filter(marker => marker.type === "acceptance_point").map(point => {
                point.marker.setVisible(true);
            });

            this.state.fellingAreas.map(pointData => {
                if (!pointData.state || pointData.state > 100000002) {
                    return;
                }
                const marker = this.markers.find(markerItem => markerItem.type === "felling_area" && markerItem.id === pointData.id);

                if (marker) {
                    if (pointData.show && this.isFellingPointGroupActive(marker.state)) {
                        if (!marker.marker.getMap()) {
                            marker.marker.setVisible(true);
                        }
                    } else {
                        marker.marker.setVisible(false);
                    }
                }
            });
        });
    }

    clearAllAvailablePoints = () => {
        const tempFellingAreas = [ ...this.state.fellingAreas ];
        const modifiedFellingAreas = tempFellingAreas.map(acceptancePoint => ({
            ...acceptancePoint,
            show: false,
        }));

        this.setState({ fellingAreas: modifiedFellingAreas }, () => {
            this.markers.map(point => {
                point.marker.setVisible(false);
            });
        });
    }

    clearMarkersByState = (state) => {
        this.markers.filter(marker => marker.state === state).forEach(point => {
            point.marker.setVisible(false);
        });
    }

    clearPolygonsByState = (state) => {
        this.polygons.filter(marker => marker.state === state).map(point => {
            point.polygon.setVisible(false);
        });
    }

    showMarkersByState = (state) => {
        this.markers.filter(marker => marker.state === state && this.isFellingPointVisible(marker.id)).map(point => {
            point.marker.setVisible(true);
        });
    }

    isFellingPointVisible = (markerId) => {
        const fellingArea = this.state.fellingAreas.find(pointData => pointData.id === markerId);
        return fellingArea ? fellingArea.show : true;
    }

    showPolygonsByState = (state) => {
        this.polygons.filter(marker => marker.state === state && this.isFellingPointVisible(marker.id)).map(point => {
            point.polygon.setVisible(true);
        });
    }

    filterCheckedByType = (type) => {
        const tempFeelingAreaStates = [ ...this.state.filters ];
        const typeIndex = tempFeelingAreaStates.findIndex(state => state.id === type);

        if (typeIndex > -1) {
            return tempFeelingAreaStates[typeIndex].show;
        }

        return false;
    }

    // tslint:disable-next-line:cyclomatic-complexity
    showFellingAreaCategory = (fellingArea) => {
        const allZero = (
            fellingArea.priede_type_value === 0 &&
            fellingArea.egle_type_value === 0 &&
            fellingArea.berzs_type_value === 0 &&
            fellingArea.apse_type_value === 0 &&
            fellingArea.baltalksnis_type_value === 0 &&
            fellingArea.ozols_type_value === 0 &&
            fellingArea.melnalksnis_type_value === 0
        ) ? true : false;

        if (allZero) {
            return this.filterCheckedByType("group_parejie");
        }

        if (fellingArea.egle_type_value > 0 && this.filterCheckedByType("group_egle")) {
            return true;
        }

        if (fellingArea.priede_type_value > 0 && this.filterCheckedByType("group_priede")) {
            return true;
        }

        if (fellingArea.berzs_type_value > 0 && this.filterCheckedByType("group_berzs")) {
            return true;
        }

        if (fellingArea.apse_type_value > 0 && this.filterCheckedByType("group_apse")) {
            return true;
        }

        if (fellingArea.baltalksnis_type_value > 0 && this.filterCheckedByType("group_baltalksnis")) {
            return true;
        }

        if (fellingArea.ozols_type_value > 0 && this.filterCheckedByType("group_ozols")) {
            return true;
        }

        if (fellingArea.melnalksnis_type_value > 0 && this.filterCheckedByType("group_melnalksnins")) {
            return true;
        }

        return false;
    }

    calculateBFinPrices(acceptancePoints: AcceptancePoint[]){
        /* For every acceptance point calculate weighed price for B Fin */
        acceptancePoints.forEach(acceptancePoint => {
            let priceForBFinA = this.getPaymentForMaterialAtAcceptancePoint(['B fin. A'], 0, 100, acceptancePoint,  [2.8, 3.1])
            let priceForBFinB26Plus = this.getPaymentForMaterialAtAcceptancePoint(['B fin. B'], 26, 100, acceptancePoint,  [2.8, 3.1])
            let priceForBFinB18_26 = this.getPaymentForMaterialAtAcceptancePoint(['B fin. B'], 18, 26, acceptancePoint,  [2.8, 3.1])
            let weighedPrice = 0.12 * (priceForBFinA ? priceForBFinA.price : 0) +
                               0.28 * (priceForBFinB26Plus ? priceForBFinB26Plus.price : 0) + 
                               0.60 * (priceForBFinB18_26 ? priceForBFinB18_26.price : 0);
            
            if(weighedPrice > Number.EPSILON){ /* greater than 0 */
                acceptancePoint.assortments.push({
                    id: -1,
                    diameter: 0,
                    length: 0,
                    price: weighedPrice,
                    bonus: 0,
                    predictedM3: 0,
                })
            }

        })
    }

    calculateSkPmPrices(acceptancePoints: AcceptancePoint[]){
        /* For every acceptance point calculate  */
        acceptancePoints.forEach(acceptancePoint => {
            let priceForSkPm = this.getPaymentForMaterialAtAcceptancePoint(['SK pm'], 6, 100, acceptancePoint, [3.0])
            let priceForEPm = this.getPaymentForMaterialAtAcceptancePoint(['E pm'], 6, 100, acceptancePoint, [3.0])
            let priceForPPm = this.getPaymentForMaterialAtAcceptancePoint(['P pm'], 6, 100, acceptancePoint, [3.0])
            let weighedPrice: number
            if(priceForEPm && priceForPPm){
                weighedPrice = Math.max(priceForSkPm ? priceForSkPm.price : 0, 0.675 * priceForEPm.price + 0.325 * priceForPPm.price)
            }else{
                weighedPrice = Math.max(priceForSkPm ? priceForSkPm.price : 0, priceForPPm ? priceForPPm.price : 0, priceForEPm ? priceForEPm.price : 0)
            }
            console.log(weighedPrice, acceptancePoint)
            if(weighedPrice > Number.EPSILON){ /* greater than 0 */
                acceptancePoint.assortments.push({
                    id: -2,
                    diameter: 0,
                    length: 0,
                    price: weighedPrice,
                    bonus: 0,
                    predictedM3: 0,
                })
            }
        })
    }

    fetchData = (cb) => {
        axios.get(`${process.env.baseUrl}/assortment`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(assortment => {
            if (assortment.status !== 200 || assortment.data.success === false) {
                throw new Error();
            }

            const assortmentData = assortment.data.data.map(assortmentItem => ({
                ...assortmentItem,
                show: true,
            }));

            /* Add special assortment for B fin logic */
            assortmentData.push({
                id: -1,
                code: "spec_B_FIN",
                name: "Bērza finieris",
                groups: null,
                category: "lk_tievie"
            })

            /* Add special assortment for SK pm logic */
            assortmentData.push({
                id: -2,
                code: "spec_SK_PM",
                name: "Skuju papīrmalka",
                groups: null,
                category: "sk_tievie"
            })

            this.setState({ assortment: assortmentData, loadingText: "Ielādē pieņemšanas punktus..." }, () => {
                // Load acceptance points
                axios.get(`${process.env.baseUrl}/api/matrix/acceptance_points`, {
                    headers: {
                        Authorization: Helpers.getSessionId(),
                    },
                })
                .then(acceptancePoints => {
                    if (acceptancePoints.status !== 200 || acceptancePoints.data.success === false) {
                        throw new Error();
                    }
                    
                    const acceptancePointData: AcceptancePoint[] = (acceptancePoints.data.data).map(acceptancePoint => ({
                        ...acceptancePoint,
                        show: acceptancePoint.assortments.some(item => this.showAssortmentInit(item.id, assortmentData)),
                        manualBonus: 0,
                        manualProfit: 0,
                        manualM3: 34
                    }));

                    this.calculateBFinPrices(acceptancePointData);
                    this.calculateSkPmPrices(acceptancePointData);

                    this.setState({ acceptancePoints: acceptancePointData, loadingText: "Ielādē cirsmas..."  }, () => {
                        // Load acceptance points
                        axios.get(`${process.env.baseUrl}/api/matrix/felling_areas`, {
                            headers: {
                                Authorization: Helpers.getSessionId(),
                            },
                        })
                        .then(fellingAreas => {
                            if (fellingAreas.status !== 200 || fellingAreas.data.success === false) {
                                throw new Error();
                            }

                            const fellingAreasData = fellingAreas.data.data.map(assortmentItem => ({
                                ...assortmentItem,
                                show: this.showFellingAreaCategory(assortmentItem),
                                materials: new FellingPointMaterials(assortmentItem)
                            }));

                            this.setState({ fellingAreas: fellingAreasData, loadingText: "Ielādē attālumus no cirsmām līdz pieņemšanas punktiem" }, () => {
                                axios.get(`${process.env.baseUrl}/api/distances`, {
                                    headers: {
                                        Authorization: Helpers.getSessionId()
                                    }
                                }).then(distances => {
                                    console.log("DISTANCES", distances);
                                    this.setState({distances: distances.data.data, dataLoaded: true}, () => {
                                        cb();
                                    });
                                }).catch(_error => {
                                    console.error(_error);
                                    message.error("Neizdevās ielādēt attālumus no cirsmām līdz pieņemšanas punktiem")
                                    this.setState({
                                        dataLoaded: true,
                                    });
                                });
                            });
                        })
                        .catch(_error => {
                            message.error("Neizdevās ielādēt cirsmas");
                            console.error(_error);
                            this.setState({
                                dataLoaded: true,
                            });
                        });
                    });
                })
                .catch(_error => {
                    message.error("Neizdevās ielādēt sortimentu sarakstu");

                    this.setState({
                        dataLoaded: true,
                    });
                });
            });
        })
        .catch(_error => {
            message.error("Neizdevās ielādēt sortimentu sarakstu");

            this.setState({
                dataLoaded: true,
            });
        });
    }

    fetchFellingAreas = () => axios.get(`${process.env.baseUrl}/api/matrix/felling_areas`, {
        headers: {
            Authorization: Helpers.getSessionId(),
        },
    })

    fetchAssortment = () => axios.get(`${process.env.baseUrl}/assortment`, {
        headers: {
            Authorization: Helpers.getSessionId(),
        },
    })

    fetchAcceptancePoints = () => axios.get(`${process.env.baseUrl}/api/matrix/acceptance_points`, {
        headers: {
            Authorization: Helpers.getSessionId(),
        },
    })

    showAssortmentInit = (assortmentId, data) => {
        const filter = data.find(assortmentItem => assortmentItem.id === assortmentId);

        if (!filter) {
            return false;
        }

        return filter.show;
    }

    showAssortment = (assortmentId) => {
        if (!this.state.assortment) {
            return false;
        }

        const filter = this.state.assortment.find(assortmentItem => assortmentItem.id === assortmentId);

        if (!filter) {
            return false;
        }

        return filter.show;
    }

    setMapRef = (ref) => this.map = ref;

    onMarkerClick = (props, marker) =>
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
        })

    onMapClicked = () => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null,
            });
        }
    }

    toggleAllAssortment = (e) => {
        let tempAssortment = [...this.state.assortment!];
        tempAssortment = tempAssortment.map(item => ({
            ...item,
            show: e.target.checked,
        }));

        let tempFilters = [...this.state.filters!];
        tempFilters = tempFilters.map(item => ({
            ...item,
            show: e.target.checked,
        }));

        this.setState({ assortment: tempAssortment, allChecked: !this.state.allChecked, filters: tempFilters }, () => {
            if (e.target.checked) {
                this.showAllAvailablePoints();
            } else {
                this.clearAllAvailablePoints();
            }
        });
    }

    filterMapPlaces = () => {
        let tempAcceptancePoints = [...this.state.acceptancePoints] as any;
        let tempFellingAreas = [...this.state.fellingAreas] as any;

        if (this.state.acceptancePoints) {
            tempAcceptancePoints = tempAcceptancePoints.map(acceptancePoint => ({
                ...acceptancePoint,
                show: acceptancePoint.assortments.some(this.showAssortment),
            }));

            tempAcceptancePoints.map(pointData => {
                const markerIndex = this.markers.findIndex(marker => marker.type === "acceptance_point" && marker.id === pointData.id);

                if (markerIndex > -1) {
                    if (pointData.show) {
                        if (!this.markers[markerIndex].marker.getMap()) {
                            this.markers[markerIndex].marker.setMap();
                        }
                    } else {
                        this.markers[markerIndex].marker.setVisible(false);
                    }
                }
            });
        }

        if (this.state.fellingAreas) {
            tempFellingAreas = tempFellingAreas.map(acceptancePoint => ({
                ...acceptancePoint,
                show: this.showFellingAreaCategory(acceptancePoint),
            }));

            tempFellingAreas.map(pointData => {
                const marker = this.markers.find(markerItem => markerItem.type === "felling_area" && markerItem.id === pointData.id);

                if (marker) {
                    if (pointData.show && this.isFellingPointGroupActive(marker.state)) {
                        if (!marker.marker.getMap()) {
                            marker.marker.setMap();
                        }
                    } else {
                        marker.marker.setVisible(false);
                    }
                }
            });
        }

        this.setState({
            acceptancePoints: tempAcceptancePoints,
            fellingAreas: tempFellingAreas,
        });
    }

    filterFellingAreasByType = (checked, type) => {
        const tempFeelingAreaStates = [ ...this.state.filterTypeFellingAreas ];
        const typeIndex = tempFeelingAreaStates.findIndex(state => state.type === type);

        if (typeIndex > -1) {
            tempFeelingAreaStates[typeIndex].show = checked;
            
            this.state.fellingAreas.forEach(fellingArea => {
                fellingArea.show = tempFeelingAreaStates.find(state => state.type == fellingArea.state).show;
            });

            this.setState({ filterTypeFellingAreas: tempFeelingAreaStates }, () => {
                this.calculateOptimalSellingPlaces();
            });
        }
    }

    isFellingPointGroupActive = (type) => {
        const fellingAreaState = this.state.filterTypeFellingAreas.find(state => state.type === type);
        return fellingAreaState ? fellingAreaState.show : true;
    }

    getAssortmentNameById = (id) => {
        const assortment = this.state.assortment!.find(assortmentItem => assortmentItem.id === id);
        return assortment ? assortment.name : "-";
    }

    getPaymentForMaterialAtAcceptancePoint(assortmentCodes: string[], minDiameter: number, maxDiameter: number, acceptancePoint: AcceptancePoint, acceptableLengths: number[]): {price: number, sortiment: Sortiment} | null{ //per m3
        let validAssortments = assortmentCodes.map(code => getAssortmentIdByCode(code, this.state.assortment as any[]));
        //get all assortments that match query
        let matchingAssortments: Sortiment[] = 
            acceptancePoint.assortments
                .filter(assortment => validAssortments.includes(assortment.id) && (assortment.price + assortment.bonus > 0) && acceptableLengths.find(length => Math.abs(assortment.length - length) < Number.EPSILON) !== undefined) //filter out only matching materials
                .map(sortiment => {
                    let nextLargestSortiment = acceptancePoint.assortments
                    .filter(sortiment2 => validAssortments.includes(sortiment2.id) && sortiment.diameter < sortiment2.diameter && sortiment.length === sortiment2.length) /* get all sortiments with matching length and greater diameter */
                    .reduce((prevVal, currVal) => {
                        return prevVal === null ? currVal : (prevVal.diameter > currVal.diameter ? currVal : prevVal); //get smallest diameter sortiment
                    }, null);
                    return [sortiment, sortiment.diameter, nextLargestSortiment === null ? 100 : nextLargestSortiment.diameter];
                }).filter(([sortiment, minDiam, maxDiam]) => 
                    minDiameter <= (maxDiam as number) && maxDiameter > (minDiam as number)
                ).map(([sortiment, minDiam, maxDiam]) => sortiment as Sortiment);

        if(matchingAssortments.length === 0){
            return null;
        }

        //get the highest paying one
        let bestPayment;
        for(let assortment of matchingAssortments){
            let price = assortment.price + assortment.bonus + (this.state.targetFilter === 'turnover' ? acceptancePoint.manualBonus : acceptancePoint.manualProfit);
            if(!bestPayment || price > bestPayment.price){
                bestPayment = {
                    price: price,
                    sortiment: assortment
                }
            }
        }
        return bestPayment;
    }

    getOptimalAcceptancePoint(fellingArea: any, assortmentCodes: string[], minDiameter: number, maxDiameter: number, acceptableLengths: number[]): {bestIncome: number, bestProfit: number, bestAcceptancePoint: AcceptancePoint, sortiment: Sortiment, distance: number} | null {
        let res: {bestIncome: number, bestProfit: number, bestAcceptancePoint: AcceptancePoint, sortiment: Sortiment, distance: number} | null = null;
        for(let acceptancePoint of this.state.acceptancePoints.filter(acceptancePoint => this.state.acceptancePointFilter.has(acceptancePoint.priority))){
            /* Special logic for B fin */
            if(assortmentCodes.includes("B fin. A") && assortmentCodes.includes("B fin. B")){
                let bestPaymentBFinA = this.getPaymentForMaterialAtAcceptancePoint([''], minDiameter, maxDiameter, acceptancePoint, acceptableLengths)
            }else{
                let bestPayment = this.getPaymentForMaterialAtAcceptancePoint(assortmentCodes, minDiameter, maxDiameter, acceptancePoint, acceptableLengths)
                if(bestPayment){
                    //calculate transport expenses
                    let distance;
                    if(this.state.distances[fellingArea.id] && this.state.distances[fellingArea.id][acceptancePoint.id]){
                        distance = this.state.distances[fellingArea.id][acceptancePoint.id];
                    }else{
                        distance = this.getDistance(fellingArea.map_center_lat, fellingArea.map_center_lng, acceptancePoint.lat, acceptancePoint.lng);
                    }
                    let transportCosts = getPriceByDistance(distance) * (34 / acceptancePoint.manualM3);
                    
                    let profitPerM3 = bestPayment.price - transportCosts;
                    if(!res || profitPerM3 > res.bestProfit){
                        res = {
                            bestProfit: profitPerM3,
                            bestIncome: bestPayment.price,
                            bestAcceptancePoint: acceptancePoint,
                            sortiment: bestPayment.sortiment,
                            distance: distance
                        }
                    }
                }
            }
        }
        return res;
    }

    calculateOptimalSellingPlaces = debounce(() => {
        let fellingAreaMarkers = new Set<number>()
        let acceptancePointMarkers = new Set<number>()
        let fellingAreas = this.state.fellingAreas;
        this.clearAcceptancePointProfits();
        for(let fellingArea of fellingAreas.filter(fellingArea => fellingArea.show)){
            let showOnMap = false;
            let materials: FellingPointMaterials = fellingArea.materials;
            let profitsPerAcceptancePoint = new Map<number, number>();
            
            materials.materials.forEach((material) => {
                if(material.amount > 0 && material.filterType === this.state.assortmentFilter){
                    showOnMap = true;
                    let optimalAcceptancePoint = this.getOptimalAcceptancePoint(fellingArea, material.assortmentCode, material.minDiameter, material.maxDiameter, material.acceptableLengths);
                    if(optimalAcceptancePoint && optimalAcceptancePoint.bestAcceptancePoint !== null){
                        optimalAcceptancePoint.sortiment.predictedM3 = (optimalAcceptancePoint.sortiment.predictedM3 || 0) + material.amount;
                        optimalAcceptancePoint.bestAcceptancePoint.intermediateAverageTransportCosts = (optimalAcceptancePoint.bestAcceptancePoint.intermediateAverageTransportCosts || 0) + (getPriceByDistance(optimalAcceptancePoint.distance) * material.amount * (34 / optimalAcceptancePoint.bestAcceptancePoint.manualM3));
                        profitsPerAcceptancePoint.set(optimalAcceptancePoint.bestAcceptancePoint.id, (profitsPerAcceptancePoint.get(optimalAcceptancePoint.bestAcceptancePoint.id) || 0) + (optimalAcceptancePoint.bestProfit * material.amount));
                        // if(fellingArea.id == 6598){
                        //     console.log(optimalAcceptancePoint);
                        //     console.log(material);
                        // }
                    }
                }
            });
            let maxProfit = -Infinity;
            let color: string = "";
            let acceptancePoints: string[] = [];
            profitsPerAcceptancePoint.forEach((profit, acceptancePointId) => {
                if(profit > maxProfit){
                    maxProfit = profit;
                    let acceptancePoint = this.state.acceptancePoints.find(acceptancePoint => acceptancePoint.id === acceptancePointId) as AcceptancePoint;
                    color = acceptancePoint.color;
                    acceptancePoints.push(acceptancePoint.name);
                }
            });
            fellingArea.acceptancePoints = acceptancePoints.join(", ");
            let marker = this.markers.find(marker => marker.type === "felling_area" && marker.id === fellingArea.id);
            if(marker){
                marker.marker.setIcon({
                    url: "http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=|" + color.replace('#', '') + "|000000",
                    scaledSize: new google.maps.Size(19, 31) //21x34 -> 19x31 
                });
            }
            
            if(showOnMap){
                fellingAreaMarkers.add(fellingArea.id);
            }
        }
        /* Simulate which acceptance points accept current sortiment */
        let materials = new FellingPointMaterials({
            priede_type_value: 1,
            egle_type_value: 1,
            berzs_type_value: 1,
            apse_type_value: 1,
            melnalksnis_type_value: 1,
            ozols_type_value: 1,
            baltalknis_type_value: 1,
            other_type_value: 1
        });
        this.state.acceptancePoints.forEach(acceptancePoint => {
            materials.materials.forEach(material => {
                if(material.filterType === this.state.assortmentFilter){
                    let price = this.getPaymentForMaterialAtAcceptancePoint(material.assortmentCode, material.minDiameter, material.maxDiameter, acceptancePoint, material.acceptableLengths);
                    if(price !== null && price.price > Number.EPSILON){
                        acceptancePointMarkers.add(acceptancePoint.id)
                    }
                }
            });
        })
        this.markers.filter(marker => marker.type === 'felling_area').forEach(point => {
            point.marker.setVisible(fellingAreaMarkers.has(point.id));
        });
        this.markers.filter(marker => marker.type === 'acceptance_point').forEach(point => {
            point.marker.setVisible(acceptancePointMarkers.has(point.id));
        });
        this.forceUpdate();
    }, 200);

    clearAcceptancePointProfits(){
        for(let acceptancePoint of this.state.acceptancePoints){
            acceptancePoint.intermediateAverageTransportCosts = 0;
            for(let sortiment of acceptancePoint.assortments){
                sortiment.predictedM3 = 0;
            }
        }
    }

    renderInformationForecast(): JSX.Element[]{
        if (this.state.acceptancePoints.length === 0) {
            return [<tr key="table-content-no-data">
                <td colSpan={4} style={{ textAlign: "center" }}>Nav datu</td>
            </tr>];
        }

        const onChangeBonus = (value: number, acceptancePoint: AcceptancePoint) => {
            acceptancePoint.manualBonus = value;
            acceptancePoint.manualProfit = value;
            this.calculateOptimalSellingPlaces();
        }

        const onChangeProfit = (value: number, acceptancePoint: AcceptancePoint) => {
            acceptancePoint.manualProfit = value;
            this.calculateOptimalSellingPlaces();
        }

        const onChangeM3 = (value: number, acceptancePoint: AcceptancePoint) => {
            acceptancePoint.manualM3 = value;
            this.calculateOptimalSellingPlaces();
        }

        return this.state.acceptancePoints.filter(acceptancePoint => acceptancePoint.show).map(acceptancePoint => {
            return <tr key={"acceptance-point-" + acceptancePoint.id}>
                <td>{ acceptancePoint.name }</td>
                <td>
                    <InputNumber value={acceptancePoint.manualBonus} onChange={(e) => onChangeBonus(e as number, acceptancePoint)} style={{ width: '4rem' }} size="small"/>
                </td>
                <td>
                    <InputNumber value={acceptancePoint.manualProfit} onChange={(e) => onChangeProfit(e as number, acceptancePoint)} style={{ width: '4rem' }} size="small"/>
                </td>
                <td>
                    <InputNumber value={acceptancePoint.manualM3} onChange={(e) => onChangeM3(e as number, acceptancePoint)} style={{ width: '4rem' }} size="small"/>
                </td>
            </tr>
        });
    }

    renderDisposition(): JSX.Element[]{
        if (this.state.acceptancePoints.length === 0) {
            return [<tr key="table-content-no-data">
                <td colSpan={4} style={{ textAlign: "center" }}>Nav datu</td>
            </tr>];
        }
        return this.state.acceptancePoints.filter(acceptancePoint => {
            return acceptancePoint.show;
        }).map(acceptancePoint => {
            let totalM3 = acceptancePoint.assortments.reduce((a, b) => a + (b.predictedM3 || 0), 0);
            if(totalM3 >= 1){
                let intermediatePrice: number = 0;
                let materialSum = 0;

                let materials = new FellingPointMaterials({
                    priede_type_value: 1,
                    egle_type_value: 1,
                    berzs_type_value: 1,
                    apse_type_value: 1,
                    melnalksnis_type_value: 1,
                    ozols_type_value: 1,
                    baltalknis_type_value: 1,
                    other_type_value: 1
                });
                materials.materials.forEach(material => {
                    let price = this.getPaymentForMaterialAtAcceptancePoint(material.assortmentCode, material.minDiameter, material.maxDiameter, acceptancePoint, material.acceptableLengths);
                    if(material.filterType === this.state.assortmentFilter){
                        if(price !== null){
                            intermediatePrice += price.price * material.amount;
                            materialSum += material.amount;
                        }
                    }
                });
                return {
                    id: acceptancePoint.id,
                    name: acceptancePoint.name,
                    m3: totalM3,
                    averagePrice: (intermediatePrice / materialSum),
                    averageTransport: (acceptancePoint.intermediateAverageTransportCosts || 0) / totalM3
                }
            }else{
                return null;
            }
        }).filter(el => el !== null).sort((a: any, b: any) => b.m3 - a.m3).map((data: any) => {
            return <tr key={"acceptance-point-" + data.id}>
                    <td>{ data.name }</td>
                    <td>
                        { data.m3.toFixed(0) }
                    </td>
                    <td>
                        { data.averagePrice.toFixed(2) }
                    </td>
                    <td>
                        { data.averageTransport.toFixed(2) }
                    </td>
                </tr>
        });
    }

    render() {
        if (!this.state.dataLoaded) {
            return (
                <LoadingIndicator loadingText={true} loadingTextPlaceholder={this.state.loadingText} verticalCenter={true}/>
            );
        }

        const mapWrapperStyle: any = {
            width: this.state.infoPanelShow ? "calc(100% - 400px)" : '100%',
            height: "calc(100% - 64px)",
            position: "absolute",
        };

        const filterBoxStyle: any = {
            position: "absolute",
            left: 30,
            bottom: 30,
            width: 350,
            zIndex: 9999,
        };

        const filterRowStyle: any = {
            marginBottom: 10,
        };

        const groupDropdownStyle: any = {
            borderLeft: "1px solid #c3c3c3",
            paddingLeft: 16,
            marginLeft: 7,
            marginTop: 6,
        };

        const informationBoxStyle: any = {
            position: "absolute",
            right: 0,
            top: 64,
            bottom: 0,
            width: this.state.infoPanelShow ? 400 : 0,
            overflowY: "auto"
        }

        const matrixWrapperStyle: any = {
            overflowX: 'hidden'
        }

        const infoBoxToggleStyle: any = {
            position: 'absolute',
            top: 114, //64 header + 50 gmaps fullscreen button
            right: this.state.infoPanelShow ? 400 : 0,
            margin: 10,
            height: 40,
            width: 40,
            
        }

        const assortmentPreScrollContent = <div>
            <Checkbox
                onChange={show => this.toggleAllAssortment(show)}
                checked={this.state.allChecked}
            >
                Parādīt visus
            </Checkbox>

            <Divider style={{ marginBottom: 10, marginTop: 10 }}/>
        </div>;

        const onAssortmentFilterChanged =(e: RadioChangeEvent) => {
            this.setState({assortmentFilter: e.target.value}, () => {
                this.calculateOptimalSellingPlaces();
            });
        };

        const onTargetFilterChanged =(e: RadioChangeEvent) => {
            this.setState({targetFilter: e.target.value}, () => {
                this.calculateOptimalSellingPlaces();
            });
        };

        const onAcceptancePointPriorityChanged = (enabled, priority) => {
            if(enabled){
                this.state.acceptancePointFilter.add(priority);
            }else{
                this.state.acceptancePointFilter.delete(priority);
            }
            this.markers.forEach(point => {
                if(point.type === 'acceptance_point'){
                    let acceptancePoint = this.state.acceptancePoints.find(acceptancePoint => acceptancePoint.id === point.id) as AcceptancePoint;
                    let show = this.state.acceptancePointFilter.has(acceptancePoint.priority);
                    point.marker.setVisible(show);
                    acceptancePoint.show = show;
                }
            });
            this.calculateOptimalSellingPlaces();
        }

        const onInfoPanelToggle = () => {
            this.setState({infoPanelShow: !this.state.infoPanelShow})
        }

        return (
            <div style={matrixWrapperStyle}>
                <div style={mapWrapperStyle}>
                    <div className="map-selector" style={{ height: "calc(100vh - 64px)" }}></div>
                    <div>
                        {this.props.ruleIsValid("Map:AllowFilters") && <div style={filterBoxStyle}>
                            <Card>
                                <Row style={filterRowStyle}>
                                    <LabaledField title="Īpašuma stadija" nextLine={true}>
                                        {STATES_MATRIX.map(state => (
                                            <LabaledField title={state.text}>
                                                <Switch
                                                    size="small"
                                                    className={POLYGON_STATE_COLORS[state.value].switchClassName}
                                                    defaultChecked
                                                    onChange={checked => this.filterFellingAreasByType(checked, state.value)}
                                                />
                                            </LabaledField>                                     
                                        ))}
                                    </LabaledField>
                                </Row>
                                
                                <Row style={filterRowStyle}>
                                    <LabaledField title="Piegādes vietu prioritāte" nextLine={true}>
                                        <LabaledField title="A līmenis">
                                            <Switch
                                                size="small"
                                                defaultChecked
                                                className="greenSwitch"
                                                onChange={(checked) => onAcceptancePointPriorityChanged(checked, "level_a") }
                                            />
                                        </LabaledField>
                                        <LabaledField title="B līmenis">
                                            <Switch
                                                size="small"
                                                defaultChecked
                                                className="yellowSwitch"
                                                onChange={(checked) => onAcceptancePointPriorityChanged(checked, "level_b") }
                                            />
                                        </LabaledField>
                                        <LabaledField title="C līmenis">
                                            <Switch
                                                size="small"
                                                defaultChecked
                                                className="orangeSwitch"
                                                onChange={(checked) => onAcceptancePointPriorityChanged(checked, "level_c") }
                                            />
                                        </LabaledField>
                                        <LabaledField title="D līmenis">
                                            <Switch
                                                size="small"
                                                defaultChecked
                                                className="redSwitch"
                                                onChange={(checked) => onAcceptancePointPriorityChanged(checked, "level_d") }
                                            />
                                        </LabaledField>
                                    </LabaledField>
                                </Row>
                                
                                <Row style={filterRowStyle}>
                                    <LabaledField title="Sortimenta tips" nextLine={true}>
                                        <RadioGroup value={this.state.assortmentFilter} onChange={(e) => onAssortmentFilterChanged(e)}>
                                            <Row><Col span={12}><Radio value="ezb_a">EZB A klase</Radio></Col>          <Col span={12}><Radio value="sk_tara">Skuju koku tara</Radio></Col></Row>
                                            <Row><Col span={12}><Radio value="ezb_resnie">EZB Resnie</Radio></Col>      <Col span={12}><Radio value="a_zb">Apses ZB</Radio></Col></Row>
                                            <Row><Col span={12}><Radio value="ezb_videjie">EZB Vidējie</Radio></Col>    <Col span={12}><Radio value="b_tara">Bērza tara</Radio></Col></Row>
                                            <Row><Col span={12}><Radio value="ezb_tievie">EZB Tievie</Radio></Col>      <Col span={12}><Radio value="a_tara">Apses tara</Radio></Col></Row>
                                            <Row><Col span={12}><Radio value="pzb_a">PZB A klase</Radio></Col>          <Col span={12}><Radio value="ma_zb">Melnalkšņa ZB</Radio></Col></Row>
                                            <Row><Col span={12}><Radio value="pzb_resnie">PZB Resnie</Radio></Col>      <Col span={12}><Radio value="lk_tara">Lapu koku tara</Radio></Col></Row>
                                            <Row><Col span={12}><Radio value="pzb_videjie">PZB Vidējie</Radio></Col>    <Col span={12}><Radio value="sk_pm">Skuju papīrmalka</Radio></Col></Row>
                                            <Row><Col span={12}><Radio value="pzb_tievie">PZB Tievie</Radio></Col>      <Col span={12}><Radio value="b_pm">Bērza papīrmalka</Radio></Col></Row>
                                            <Row><Col span={12}><Radio value="b_fin">Bērza Finieris</Radio></Col>       <Col span={12}><Radio value="a_pm">Apses papīrmalka</Radio></Col></Row>
                                            <Row><Col span={12}><Radio value="sk_malka">Skuju koku malka</Radio></Col>  <Col span={12}><Radio value="lk_malka">Lapu koku malka</Radio></Col></Row>
                                        </RadioGroup>
                                    </LabaledField>
                                </Row>
                                
                                <Row>
                                    <LabaledField title="Mērķis" nextLine={true}>
                                        <RadioGroup value={this.state.targetFilter}  onChange={(e) => onTargetFilterChanged(e)}>
                                            <Row><Radio value="profit">Peļņa</Radio></Row>
                                            <Row><Radio value="turnover">Apgrozījums</Radio></Row>
                                        </RadioGroup>
                                    </LabaledField>
                                </Row>
                            </Card>
                        </div>}
                    </div>
                </div>
                <Button style={infoBoxToggleStyle} onClick={onInfoPanelToggle}>{this.state.infoPanelShow ? '>' : '<'}</Button>
                <Card style={informationBoxStyle}>
                    <LabaledField title="Piemaksas prognoze" nextLine={true} maxHeight={(window.innerHeight - 130) / 2}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Piegādes vieta</th>
                                    <th>Piemaksa</th>
                                    <th>Peļņa</th>
                                    <th>Pieļaujamie m3</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.renderInformationForecast() }
                            </tbody>
                        </table>
                    </LabaledField>
                    <LabaledField title=""><br/></LabaledField>
                    <LabaledField title="Kubikmetru sadalījums" nextLine={true} maxHeight={(window.innerHeight - 130) / 2}>
                        <table> 
                            <thead>
                                <tr>
                                    <th>Piegādes vieta</th>
                                    <th>Plānotie m3</th>
                                    <th>Vidējā samaksa</th>
                                    <th>Vidējais svērtais transports</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.renderDisposition() }
                            </tbody>
                        </table>
                    </LabaledField>
                </Card>
            </div>
        );
    }
}

// tslint:disable-next-line:no-default-export
export default Form.create()(MatrixRoot as any);

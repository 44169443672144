import { Alert, AutoComplete, Button, Card, Checkbox, Col, Dropdown, Form, Icon, InputNumber, Menu, message, Row, Spin, Switch, Divider, Input } from "antd";
import axios from "axios";
import { GoogleApiWrapper, Map } from "google-maps-react";
import * as React from "react";
import * as ReactToPrint from "react-to-print";
import { AssortmentPricePage } from "../../components/AssortmentPricePage";
import { LabaledField } from "../../components/LabeledField";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { PageContent } from "../../components/PageContent";
import { Helpers } from "../../utils/helpers";
import { Assortment } from "../AcceptancePointAssortment/create";
import { BonusesFields, FilterFields } from "../AssortmentPage";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { getPriceByDistance } from "../../constants/distance-expenses";
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { clone } from 'lodash';

export interface PricePageAssortment extends Assortment {
    show: boolean;
    category: string;
}

interface CreateFellingPointsState {
    fellingAreas: any[];
    assortmentIsLoaded: boolean;
    totalRecords: number;
    currentPage: number;
    searchKeyword: string;
    assortment: null | PricePageAssortment[];
    pointAddress: string | null;
    acceptancePointsLoaded: boolean;
    acceptancePoints: null | AcceptancePointPricePage[];
    pointLat: null | number;
    pointLng: null | number;
    pointName: null | string;

    distanceFilter: number[];
    bonuses: BonusesFields;
    filterFields: FilterFields;
    highlightHighestPrice: boolean;
    onlyChecked: boolean;
    fetchingAssortmentData: boolean;
    firstFetchingAssortmentData: boolean;
    filters: any[];
    allChecked: boolean;
    allRowsChecked: boolean;
}

export interface AcceptancePointPricePage {
    kind: string;
    assortments: AcceptancePointPricePageAssortments[];
}

export interface AcceptancePointPricePageAssortments {
    assortment: string;
    checked: boolean;
    show: boolean;
    lengths: AcceptancePointPricePageAssortmentsLengths[];
    code: string;
}

export interface AcceptancePointPricePageAssortmentsLengths {
    length: number;
    diameters: AcceptancePointPricePageAssortmentsLengthsDiameters[];
}

export interface AcceptancePointPricePageAssortmentsLengthsDiameters {
    diameter: number;
    diameterMax: number;
    price: number;
    assortment_id: number;
    surface_coefficient: number;
    acceptance_point_bonus: number;
    category: string;
    id: number;
    name: string;
    address: string;
    plan: number;
    assortment_bonus: number;
    lat: number;
    assortmentCode: string;
    updated_at: string;
    calculationError: boolean;
    transportExpenses: number;
    transportExpensesPerCubicMeter: number;
    hash: string;
    checked: boolean;
    comment?: string;
}

interface CreateFellingPointsStateProps {
    form: any;
    map: any;
    google: any;
    ruleIsValid(ruleId: any): boolean;
}

const groupFilters: any[] = [{
    id: "group_zb",
    title: "Zāģbaļķi",
    assortments: ['P zb', 'E zb', 'P zb1', 'P zb2', 'P zbA'],
    show: true,
}, {
    id: "group_sk_pm",
    title: "Skuju papīrmalka",
    assortments: ['SK pm', 'E pm', 'SK pm'],
    show: true,
}, {
    id: "group_gulsnis",
    title: "Gulsnis",
    assortments: ['Gulsnis', 'Špala', 'P gulsnis', 'E Gulsnis'],
    show: true,
}, {
    id: "group_berzs",
    title: "Bērzs",
    assortments: ['B fin. A', 'B fin. B', 'B fin. C', 'B pm'],
    show: true,
}, {
    id: "group_apse",
    title: "Apse",
    assortments: ['A zb', 'A pm', 'A zb II klase'],
    show: true,
}, {
    id: "group_lk",
    title: "Vērtīgie lapu koki",
    assortments: ['OS', 'O', 'MAL', 'MAL II klase', 'O zb1', 'O zb2', 'O zbA', 'A zb1', 'A zb2', 'A zbA', 'M zb1', 'M zb2', 'M zbA'],
    show: true,
}, {
    id: "group_sk_tara",
    title: "SK tara",
    assortments: ['SK tara'],
    show: true,
}, {
    id: "group_lk_tara",
    title: "LK tara",
    assortments: ['B tara', 'A tara', 'LK tara'],
    show: true,
}, {
    id: "group_low_value",
    title: "Mazvērtīgais",
    assortments: ['A malka', 'AL pm', 'LK malka', 'SK malka', 'JK malka', 'Ozola malka'],
    show: true,
},{
    id: "group_parejie",
    title: "Pārējie",
    assortments: ['Mieti', 'Brāķis', 'ZM', 'E mieti', 'P mieti'],
    show: true,
}];
class PricePageWrapped extends React.Component<CreateFellingPointsStateProps, CreateFellingPointsState> {
    map;
    componentRef;
    searchInput;

    constructor(props) {
        super(props);

        this.state = {
            fellingAreas: [],
            assortment: null,
            assortmentIsLoaded: false,
            pointAddress: null,
            pointLat: null,
            pointLng: null,
            pointName: null,
            // Search
            searchKeyword: "",
            // Pagination
            totalRecords: 0,
            currentPage: 1,
            // Acceptance points
            acceptancePointsLoaded: false,
            acceptancePoints: null,
            // Filters
            distanceFilter: [0, 200],
            fetchingAssortmentData: false,
            firstFetchingAssortmentData: true,
            bonuses: {
                surface_coefficient: false,
                acceptance_point_bonus: false,
            },
            filterFields: {
                sawCost: 0,
                importCost: 0,
                transportCost: false,
            },
            highlightHighestPrice: false,
            onlyChecked: false,
            filters: groupFilters,
            allChecked: true,
            allRowsChecked: false
        };
    }

    componentDidMount() {
        this.fetchAssortment();
        document.title = "Dižozols - Cenu lapa";
    }

    componentDidUpdate(prevProps, prevState) {
        // Hide map if close modal
        if (this.props !== prevProps.map && this.state.pointAddress !== prevState.pointAddress) {
            // If state changed - render new map
            this.renderAddressInMap();
        }
    }

    fetchAssortment = () => axios.get(`${process.env.baseUrl}/assortment`)
        .then(response => {
            if (response.data.success === false) {
                message.error("Neizdevās ielādēt sortimentus");
            }

            this.setState({
                assortment: response.data.data.map(assortmentItem => ({
                    ...assortmentItem,
                    show: true,
                })),
                assortmentIsLoaded: true,
            });
        }).catch(_error => {
            message.error("Neizdevās ielādēt sortimentu sarakstu");
        })

    fetchAcceptancePointAssortment = () => this.setState({
        fetchingAssortmentData: true,
    }, () => {
        axios.post(`${process.env.baseUrl}/acceptance_points/assortment`, {
            pointLat: this.state.pointLat,
            pointLng: this.state.pointLng,
        })
        .then(response => {
            if (response.data.success === false) {
                return message.error("Neizdevās ielādēt sortimentus");
            }

            return this.setState({
                fetchingAssortmentData: false,
                firstFetchingAssortmentData: false,
                acceptancePoints: (response.data.data as AcceptancePointPricePage[]).map(acceptancePoint => ({
                    ...acceptancePoint,
                    assortments: acceptancePoint.assortments.map(assortment => ({
                        ...assortment,
                        show: this.showAssortment(assortment.lengths[0].diameters[0].assortment_id),
                    })),
                })),
            });
        })
        .catch(_error => {
            message.error("Neizdevās ielādēt pieņemšanas punktus");
        });
    })

    showAssortment = (assortmentId) => {
        if (!this.state.assortment) {
            return false;
        }

        //const assortmentId = assortmentGroup ? `${assortmentKind} ${assortmentGroup}` : assortmentKind;
        const filter = this.state.assortment.find(assortmentItem => assortmentItem.id === assortmentId);

        if (!filter) {
            return false;
        }

        return filter.show;
    }

    calculateAddress = (acceptancePoint, response) => {
        if (this.state.pointLat && this.state.pointLng) {
            const { google } = this.props;
            const service = new google.maps.DistanceMatrixService();

            service.getDistanceMatrix({
                origins: [new google.maps.LatLng(this.state.pointLat, this.state.pointLng)],
                destinations: [new google.maps.LatLng(acceptancePoint.lat, acceptancePoint.lng)],
                travelMode: "DRIVING",
            }, response);
        }

        return {};
    }

    renderAddressInMap = () => {
        const { google } = this.props;
        const mapReference = this.map;

        if (!google || !mapReference || !this.state.pointAddress) { return; }
        // Some new prototypes to make life easier
        google.maps.Map.prototype.markers = new Array();
        google.maps.Map.prototype.addMarker = function(marker) {
            this.markers[this.markers.length] = marker;
        };
        google.maps.Map.prototype.getMarkers = function() {
            return this.markers;
        };
        google.maps.Map.prototype.clearMarkers = function() {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.markers.length; i++) {
                this.markers[i].setMap(null);
            }
            this.markers = new Array();
        };

        const map = mapReference.map;
        const geocoder = new google.maps.Geocoder();
        // Trying to get location based on entered address
        geocoder.geocode({ address: this.state.pointAddress }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                // Remove old markers
                map.clearMarkers();
                // Set map center to new location
                map.setCenter(results[0].geometry.location);
                // Add new marker
                const marker = new google.maps.Marker({
                    map,
                    position: results[0].geometry.location,
                    draggable: true,
                });

                google.maps.event.addListener(marker, "dragend", () => {
                    this.setState({
                        pointName: null,
                        pointLat: marker.getPosition().lat(),
                        pointLng: marker.getPosition().lng(),
                    }, () => {
                        this.fetchAcceptancePointAssortment();
                    });
                });

                map.addMarker(marker);

                this.setState({
                    pointLat: results[0].geometry.location.lat(),
                    pointLng: results[0].geometry.location.lng(),
                }, () => {
                    this.fetchAcceptancePointAssortment();
                });
            }
        });
    }

    setMapRef = (ref) => this.map = ref;

    toggleAssortment = (assortmentId, show: CheckboxChangeEvent) => {
        let newAcceptancePoints = this.state.acceptancePoints as AcceptancePointPricePage[];
        newAcceptancePoints.forEach(acceptancePoint => {
            acceptancePoint.assortments.forEach(assortment => {
                if(assortment.lengths[0].diameters[0].assortment_id === assortmentId){
                    assortment.show = show.target.checked;
                }
            })
        });
        const tempAssortment = [...this.state.assortment!];
        const assortmentIndex = tempAssortment.findIndex(assortmentItem => assortmentItem.id === assortmentId);
        if (assortmentIndex > -1) {
            tempAssortment[assortmentIndex].show = !tempAssortment[assortmentIndex].show;
        }
        this.setState({
            acceptancePoints: newAcceptancePoints,
            assortment: tempAssortment
        });
    }

    onBonusesChange = (checked, bonusName) => {
        const objectField = {};
        objectField[bonusName] = checked;

        const newBonusesState = {
            ...this.state.bonuses,
            ...objectField,
        };

        this.setState({ bonuses: newBonusesState });
    }

    onOnlyCheckedChange = (checked) => {
        this.setState({ onlyChecked: checked });
    }

    checkPoint = (checked: boolean, hash: string) => {
        if (this.state.acceptancePoints) {
            const acceptancePointsTemp = [...this.state.acceptancePoints];
            const filteredList = acceptancePointsTemp.map(group1 => ({
                ...group1,
                assortments: group1.assortments.map(group2 => ({
                    ...group2,
                    lengths: group2.lengths.map(group3 => ({
                        ...group3,
                        diameters: group3.diameters.map(group4 => ({
                            ...group4,
                            checked: (group4.hash === hash) ? checked : group4.checked,
                        })),
                    })),
                })),
            }));

            this.setState({ acceptancePoints: filteredList });
        }
    }

    changeFilterField = (value, type) => {
        const objectField = {};
        objectField[type] = value;

        const newFiltersState = {
            ...this.state.filterFields,
            ...objectField,
        };

        this.setState({ filterFields: newFiltersState });
    }

    onHighlightHighestPriceChange = (checked) => {
        this.setState({ highlightHighestPrice: checked });
    }

    itemsToShow = () => {
        if (!this.state.acceptancePoints) {
            return [];
        }

        return this.state.acceptancePoints.map(acceptancePoint => ({
            ...acceptancePoint,
            assortments: acceptancePoint.assortments.map(item => {
                if (!item.show) {
                    return {};
                }
                return item;
            }).filter(value => Object.keys(value as any).length !== 0),
        })).filter(value => value.assortments.length > 0);
    }

    toggleAllAssortment = (e: CheckboxChangeEvent) => {
        const groupFilters = [...this.state.filters!];
        const tempAssortment = [...this.state.assortment!];
        const newAcceptancePoints = this.state.acceptancePoints as AcceptancePointPricePage[];
        groupFilters.forEach(groupFilter => {
            groupFilter.show = e.target.checked;
        });
        
        tempAssortment.forEach(assortment => {
            assortment.show = e.target.checked;
        });

        newAcceptancePoints.forEach(acceptancePoint => {
            acceptancePoint.assortments.forEach(assortment => {
                assortment.show = e.target.checked;
            });
        });

        this.setState({
            allChecked: e.target.checked,
            assortment: tempAssortment,
            filters: groupFilters
        })
    }

    toggleAssortmentGroup = (assortmentGroupId, e: CheckboxChangeEvent) => {
        let newFilter: FilterFields = this.state.filterFields;
        let groupFilters = this.state.filters;
        let groupFilter = groupFilters.find(group => group.id === assortmentGroupId);
        const tempAssortment = [...this.state.assortment!];
        let newAcceptancePoints = this.state.acceptancePoints as AcceptancePointPricePage[];
        if(e.target.checked){
            groupFilter.show = true;
        }else{
            groupFilter.show = false;
        }

        groupFilter.assortments.forEach(assortmentCode => {
            const assortmentIndex = tempAssortment.findIndex(assortmentItem => assortmentItem.code === assortmentCode);
            if (assortmentIndex > -1) {
                tempAssortment[assortmentIndex].show = e.target.checked;
            }
        });
        newAcceptancePoints.forEach(acceptancePoint => {
            acceptancePoint.assortments.forEach(assortment => {
                if(groupFilter.assortments.includes(assortment.lengths[0].diameters[0].assortmentCode)){
                    assortment.show = e.target.checked;
                }
            })
        });
        this.setState({
            filterFields: newFilter,
            filters: groupFilters,
            assortment: tempAssortment
        });
    }

    exportXlsx = () => {

        let rows = [];
        let merges = [];

        let acceptancePoints = this.aggregateTableData();

        for(let kindRow of acceptancePoints){ 
            let kindStartRow = rows.length;
            for(let assortmentRow of kindRow.assortments){
                let assortmentStartRow = rows.length;
                for(let lengthRow of assortmentRow.lengths){
                    let lengthStartRow = rows.length;
                    
                    for(let diameterRow of lengthRow.diameters){

                        rows.push({
                            kind: kindRow.kind,
                            assortment: assortmentRow.assortment,
                            diameter: diameterRow.diameter.toFixed(1) + ' - ' + diameterRow.diameterMax.toFixed(1),
                            length: lengthRow.length,
                            transport_expenses: getPriceByDistance((diameterRow as any).distance),
                            price: diameterRow.price,
                            acceptance_point: diameterRow.name,
                        });
                    }
                    if(lengthStartRow < rows.length - 1){
                        merges.push({
                            s: {
                                r: lengthStartRow,
                                key: 'length'    
                            },
                            e: {
                                r: rows.length - 1,
                                key: 'length'
                            }
                        });
                    }
                }
                if(assortmentStartRow < rows.length - 1){
                    merges.push({
                        s: {
                            r: assortmentStartRow,
                            key: 'assortment'    
                        },
                        e: {
                            r: rows.length - 1,
                            key: 'assortment'
                        }
                    });
                }
            }

            if(kindStartRow < rows.length - 1){
                merges.push({
                    s: {
                        r: kindStartRow,
                        key: 'kind'    
                    },
                    e: {
                        r: rows.length - 1,
                        key: 'kind'
                    }
                });
            }
        }

        axios.post(`${process.env.baseUrl}/api/generate-xlsx`, {
            rows: rows,
            merges: merges,
            columns: [
                { header: 'Suga', key: 'kind', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, width: 6 },
                { header: 'Sortiments', key: 'assortment', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, width: 10 },
                { header: 'Garums, m', key: 'length', style: { numFmt: '0.0' }, width: 10 },
                { header: 'Diametrs, cm', key: 'diameter', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, width: 16 },
                ... this.state.filterFields.transportCost ? [{ header: 'Transporta izmakasas', key: 'transport_expenses', style: { numFmt: '€0.00' }, width: 20 }] : [],
                { header: 'Cena', key: 'price', style: { numFmt: '€0.00' }, width: 8 },
                { header: 'Piezīmes', key: 'acceptance_point', width: 20 },
            ],
        }, {
            responseType: 'blob',
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            let blob = new Blob([response.data], {type: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
            saveAs(blob, (this.state.pointName ? this.state.pointName + ' - ' : '') + 'Cenu Lapa ' +  moment().format('DD-MM-YY HH:mm') + '.xlsx');
        })
        .catch(_error => {
            message.error("Neizdevās veikt eksportu");
        });
    }

    getHighestPriceForDuplicates = (list) => list.reduce((newList, item) => {
        const itemIndex = newList.findIndex(newListItem => newListItem.diameter === item.diameter);
        if (itemIndex > -1) {
            if (newList[itemIndex].price < item.price) {
               newList[itemIndex] = item;
            }
        } else {
            newList.push({ ...item });
        }

        return newList;
    }, [])

    reducerPlaces(list){
        let newList: any[] = [];
        let l = list.map(listItem => {
            return {
                ...listItem
            }
        })
        let criticalPoints = new Set<number>();
        l.forEach(item => {
            criticalPoints.add(item.diameter)
            criticalPoints.add(item.maxDiameter)
        })
        Array.from(criticalPoints).sort((a,b) => a - b).forEach(criticalPoint => {
            let bestMatch = l.filter(p => p.diameter <= criticalPoint && p.maxDiameter > criticalPoint).reduce((lastBestMatch, item) => {
                if(!lastBestMatch || lastBestMatch.price < item.price){
                    return item
                }
                return lastBestMatch
            }, null)
            if(bestMatch && (newList.length === 0 || newList[newList.length - 1].hash !== bestMatch.hash)){
                if(newList.length > 0){
                    newList[newList.length - 1].diameterMax = criticalPoint
                }
                
                newList.push({
                    ...bestMatch,
                    diameter: criticalPoint
                })
            }
        })
        if(newList.length > 0){
            newList[newList.length - 1].diameterMax = newList[newList.length - 1].maxDiameter
        }
        
        return newList
    }

    calculatePrice = (object) => {
        const basePrice = object.price - getPriceByDistance(object.distance);
        let highestBonus = 0;
        let surfaceCoefficient = 0;
        let acceptancePointBonus = 0;

        if (this.state.bonuses.surface_coefficient && object.surface_coefficient) {
            surfaceCoefficient = object.surface_coefficient;
        }

        if (this.state.bonuses.acceptance_point_bonus && object.acceptance_point_bonus) {
            acceptancePointBonus = object.acceptance_point_bonus;
        }

        const withAssortmentBonuses = basePrice + highestBonus;
        // tslint:disable-next-line:binary-expression-operand-order
        let finalPrice = (1 + (surfaceCoefficient / 100)) * (withAssortmentBonuses * (1 + (acceptancePointBonus / 100)));

        if (this.state.filterFields.importCost) {
            finalPrice -= this.state.filterFields.importCost;
        }

        if (this.state.filterFields.sawCost) {
            finalPrice -= this.state.filterFields.sawCost;
        }

        return +(finalPrice).toFixed(2);
    }

    aggregateTableData = () => {
        let acceptancePointsFilteredByMaxAmount: AcceptancePointPricePage[];
        // Calculate prices
        const calculatedPrices = this.state.acceptancePoints.map(currentKind => {
            let filteredAssortments = currentKind.assortments.filter(assortment => {
                const filter = this.state.assortment.find(assortmentItem => assortmentItem.code === assortment.code);
                if(filter){
                    return filter.show;
                }
                return false;
            });
            return {
                ...currentKind,
                assortments: filteredAssortments.map(assortment => ({
                    ...assortment,
                    lengths: assortment.lengths.map(length => ({
                        ...length,
                        diameters: this.reducerPlaces(
                            this.getHighestPriceForDuplicates(
                                length.diameters.map(diameter => ({
                                    ...diameter,
                                    price: this.calculatePrice(diameter),
                                })),
                            )
                        ),
                    })),
                })),
            }
        }).filter(kind => kind.assortments.length) as AcceptancePointPricePage[];

        if (this.state.onlyChecked) {
            acceptancePointsFilteredByMaxAmount = calculatedPrices.map(acceptancePoint => ({
                ...acceptancePoint,
                assortments: acceptancePoint.assortments.map(pointAssortments => ({
                    ...pointAssortments,
                    lengths: pointAssortments.lengths.map(length => ({
                        ...length,
                        diameters: length.diameters.filter(diameter => diameter.checked),
                    })).filter(lengthFilter => lengthFilter.diameters.length > 0),
                })).filter(assortmentsFilter => assortmentsFilter.lengths.length > 0),
            })).filter(assortmentsFilter => assortmentsFilter.assortments.length > 0);
        } else {
            acceptancePointsFilteredByMaxAmount = calculatedPrices;
        }

        return acceptancePointsFilteredByMaxAmount;
    }

    onAllRowsChecked(allChecked: boolean){
        const newAcceptancePoints = clone(this.state.acceptancePoints);
        newAcceptancePoints.forEach(kind => {
            kind.assortments.forEach(assortment => {
                assortment.lengths.forEach(length => {
                    length.diameters.forEach(diameter => {
                        diameter.checked = allChecked;
                    });
                });
            });
        });

        this.setState({
            acceptancePoints: newAcceptancePoints,
            allRowsChecked: allChecked
        })
    }

    // tslint:disable-next-line:cyclomatic-complexity
    render() {
        const pageContentProps = {
            headerTitle: "Cenu lapa",
            fullPage: true,
            showHeader: true,
        };

        const mapWrapperStyle: any = {
            width: "100%",
            height: 250,
            position: "relative",
            marginTop: 5,
        };

        const exportMenu = (
            <Menu>
                <Menu.Item onClick={() => this.exportXlsx()} key="1"><Icon type="file-excel" /> Excel</Menu.Item>
            </Menu>
        );

        const titleStyle = {
            fontWeight: 400,
            margin: 0,
            marginBottom: 3,
        } as any;

        const assortmentPreScrollContent = <div>
            <Checkbox
                onChange={show => this.toggleAllAssortment(show)}
                checked={this.state.allChecked}
            >
                Parādīt visus
            </Checkbox>

            <Divider style={{ marginBottom: 10, marginTop: 10 }}/>
        </div>;

        const groupDropdownStyle: any = {
            borderLeft: "1px solid #c3c3c3",
            paddingLeft: 16,
            marginLeft: 7,
            marginTop: 6,
        };

        const getGroupAssortments = (groupId) => {
            const groupData = this.state.filters.find(group => group.id === groupId);

            if (!groupData || !groupData.show) {
                return <div></div>;
            }

            return <div style={groupDropdownStyle}>
                {this.state.assortment!
                    .filter(assortment => groupData.assortments.indexOf(assortment.code) > - 1)
                    .map(assortmentValue => (
                        <Row key={assortmentValue.code} style={{ marginBottom: 5 }}>
                            <Checkbox
                                onChange={(show) => this.toggleAssortment(assortmentValue.id, show)}
                                checked={assortmentValue.show}
                            >
                                {assortmentValue.name} - {assortmentValue.code}
                            </Checkbox>
                        </Row>
                    ))}
            </div>;
        };

        return (
            <div>
                <PageContent {...pageContentProps}>
                    <Form>
                        <Row>
                        <Col span={6}>
                                <Card>
                                    <Row>
                                        {this.props.ruleIsValid("PricePage:PricePageAllowSearch") && <div>
                                            <h4 style={titleStyle}>Cirsmas atrašanās vieta</h4>
                                            <Input
                                                allowClear={true}
                                                onPressEnter={(e) => this.setState({ pointAddress: e.target.value })}
                                                onBlur={(e) => this.setState({ pointAddress: e.target.value })}
                                            />
                                        </div>}

                                        <div style={mapWrapperStyle}>
                                            <Map
                                                ref={this.setMapRef}
                                                google={this.props.google}
                                                zoom={13}
                                                initialCenter={{
                                                    lat: 56.946285,
                                                    lng: 24.105078,
                                                }}
                                            />
                                        </div>
                                    </Row>
                                    <Row>
                                        <Row style={{ marginBottom: 15, marginTop: 25 }}>
                                            <LabaledField title="Sortimentu saraksts" nextLine={true} maxHeight={400} preScrollContent={assortmentPreScrollContent}>
                                                {this.state.assortment && this.state.filters.map(filterGroup => (
                                                        <Row key={filterGroup.code} style={{ marginBottom: 5 }}>
                                                            <Checkbox
                                                                onChange={(show) => this.toggleAssortmentGroup(filterGroup.id, show)}
                                                                checked={filterGroup.show}
                                                                className=""
                                                            >
                                                                {filterGroup.title}
                                                            </Checkbox>

                                                            {getGroupAssortments(filterGroup.id)}
                                                        </Row>
                                                    ))
                                                }
                                            </LabaledField>
                                        </Row>

                                        {this.props.ruleIsValid("PricePage:AllowAcceptancePointBonus") && <Row style={{ marginBottom: 15 }}>
                                            <LabaledField title="Pieņemšanas punkta piemaksa" description="Iekļaut pieņemšanas punkta piemaksu pie cenu aprēķiniem">
                                                <Switch
                                                    checkedChildren={<Icon type="check"/>}
                                                    unCheckedChildren={<Icon type="cross"/>}
                                                    defaultChecked={this.state.bonuses.acceptance_point_bonus}
                                                    onChange={(checked) => this.onBonusesChange(checked, "acceptance_point_bonus")}
                                                />
                                            </LabaledField>
                                        </Row>}

                                        {this.props.ruleIsValid("PricePage:AllowCoefficient") && <Row style={{ marginBottom: 15 }}>
                                            <LabaledField title="Uzmērījuma koeficients" description="Iekļaut uzmērījuma koeficientu pie cenu aprēķiniem">
                                                <Switch
                                                    checkedChildren={<Icon type="check"/>}
                                                    unCheckedChildren={<Icon type="cross"/>}
                                                    defaultChecked={this.state.bonuses.surface_coefficient}
                                                    onChange={(checked) => this.onBonusesChange(checked, "surface_coefficient")}
                                                />
                                            </LabaledField>
                                        </Row>}

                                        {this.props.ruleIsValid("PricePage:ShowTransportExpenses") && <Row style={{ marginBottom: 15 }}>
                                            <LabaledField title="Transporta izmaksas" description="Parādīt transporta izmaksas katram punktam">
                                                <Switch
                                                    checkedChildren={<Icon type="check"/>}
                                                    unCheckedChildren={<Icon type="cross"/>}
                                                    defaultChecked={this.state.filterFields.transportCost}
                                                    onChange={(value) => this.changeFilterField(value, "transportCost")}
                                                />
                                            </LabaledField>
                                        </Row>}

                                        {this.props.ruleIsValid("PricePage:ExtraPriceAllow") && <Row style={{ marginBottom: 15 }}>
                                            <LabaledField title="Zāģēšanas izmaksas (€)" description="Summa, kura tiks noņemta no kopējās summas" large={true}>
                                                <InputNumber value={this.state.filterFields.sawCost} onChange={(value) => this.changeFilterField(value, "sawCost")} min={0} max={9999}/>
                                            </LabaledField>
                                        </Row>}

                                        {this.props.ruleIsValid("PricePage:ExtraPriceAllow") && <Row style={{ marginBottom: 15 }}>
                                            <LabaledField title="Ievešanas izmaksas (€)" description="Summa, kura tiks noņemta no kopējās summas" large={true}>
                                                <InputNumber value={this.state.filterFields.importCost} onChange={(value) => this.changeFilterField(value, "importCost")} min={0} max={9999}/>
                                            </LabaledField>
                                        </Row>}

                                        {this.props.ruleIsValid("PricePage:HighlightHighestPrice") && <Row style={{ marginBottom: 15 }}>
                                            <LabaledField title="Izcelt augstāko cenu" description="Vizuāli izcel augstāko cenu no katras diametru grupas">
                                                <Switch
                                                    checkedChildren={<Icon type="check"/>}
                                                    unCheckedChildren={<Icon type="cross"/>}
                                                    defaultChecked={this.state.highlightHighestPrice}
                                                    onChange={this.onHighlightHighestPriceChange}
                                                />
                                            </LabaledField>
                                        </Row>}

                                        {this.props.ruleIsValid("PricePage:ShowOnlyChecked") && <Row style={{ marginBottom: 15 }}>
                                            <LabaledField title="Rādīt tikai atzīmētos" description="Parādīt sarakstu tikai ar atzīmētajām cenām">
                                                <Switch
                                                    checkedChildren={<Icon type="check"/>}
                                                    unCheckedChildren={<Icon type="cross"/>}
                                                    defaultChecked={this.state.onlyChecked}
                                                    onChange={this.onOnlyCheckedChange}
                                                />
                                            </LabaledField>
                                        </Row>}
                                    </Row>
                                </Card>
                            </Col>

                            <Col span={17} offset={1}>
                                {this.state.onlyChecked && <div style={{ marginBottom: 10, textAlign: "right" }}>
                                    <ReactToPrint
                                        trigger={() => <Button type="primary"><Icon type="printer"/> Printēt</Button>}
                                        content={() => this.componentRef}
                                        copyStyles={false}
                                        pageStyle="table{border-collapse:collapse;width:100%;background:#fff}table td,table th{border:1px solid #8a8a8a;padding:3px;text-align:center}table th{text-align:center}"
                                    />

                                    <Dropdown overlay={exportMenu}>
                                        <Button type="primary" style={{ marginLeft: 5 }}>
                                            <Icon type="export"/> Eksportēt
                                        </Button>
                                    </Dropdown>
                                </div>}

                                {this.state.assortmentIsLoaded &&
                                this.state.assortment &&
                                this.state.acceptancePoints ?
                                    <Spin spinning={this.state.fetchingAssortmentData} tip="Lūdzu uzgaidiet...">
                                        <AssortmentPricePage
                                            ref={ el => (this.componentRef = el)}
                                            acceptancePoints={this.aggregateTableData() as any}
                                            bonuses={this.state.bonuses}
                                            assortment={this.state.assortment}
                                            distanceFilter={this.state.distanceFilter}
                                            highlightHighestPrice={this.state.highlightHighestPrice}
                                            checkPoint={this.checkPoint}
                                            showOnlyChecked={this.state.onlyChecked}
                                            filterFields={this.state.filterFields}
                                            ruleIsValid={this.props.ruleIsValid}
                                            allChecked={this.state.allRowsChecked}
                                            onAllChecked={this.onAllRowsChecked.bind(this)}
                                        />
                                    </Spin>
                                : this.state.firstFetchingAssortmentData && this.state.fetchingAssortmentData ? <LoadingIndicator loadingText={true}/>
                                : <Alert message="Lūdzu norādiet cirsmas atrašanās vietu!" type="warning" showIcon/>}
                            </Col>
                        </Row>
                    </Form>
                </PageContent>
            </div>
        );
    }
}

const PricePage = Form.create()(PricePageWrapped as any);
// tslint:disable-next-line:no-default-export
export default GoogleApiWrapper({
    apiKey: process.env.CLIENT_GOOGLE_MAPS_API_KEY,
    LoadingContainer: (_props) => (
        <div></div>
    ),
})(PricePage);

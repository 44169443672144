import { Col, Form, InputNumber, Row, Divider } from "antd";
import FormItem from "antd/lib/form/FormItem";
import * as React from "react";

interface BonusesFormProps {
    form: any;

    bonus: number;
    fsc_bonus: number;
    fsc_bonus_100: number;

    amount1: number;
    amount2: number;
    amount3: number;
    amount4: number;
    
    bonus1: number;
    bonus2: number;
    bonus3: number;
    bonus4: number;

    onBonusChange: (val: number) => void;
    onFscBonusChange: (val: number) => void;
    onFscBonus100Change: (val: number) => void;
    
    onAmount1Change: (val: number) => void;
    onAmount2Change: (val: number) => void;
    onAmount3Change: (val: number) => void;
    onAmount4Change: (val: number) => void;

    onBonus1Change: (val: number) => void;
    onBonus2Change: (val: number) => void;
    onBonus3Change: (val: number) => void;
    onBonus4Change: (val: number) => void;
}


export class BonusesForm extends React.Component<BonusesFormProps> {
    formRef;

    constructor(props) {
        super(props);
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <div>
                <Row>
                    {<Col span={6}>
                        <FormItem label="Piemaksa" style={{ marginBottom: "0px" }}>
                            {getFieldDecorator("bonus", {
                                initialValue: this.props.bonus,
                            })(
                                <InputNumber step={0.01} style={{ width: "100%" }} onChange={(amount) => this.props.onBonusChange(amount)}/>,
                            )}
                        </FormItem>
                    </Col>}
                    {<Col span={6} offset={2}>
                        <FormItem label="FSC Piemaksa" style={{ marginBottom: "0px" }}>
                            {getFieldDecorator("fsc_bonus", {
                                initialValue: this.props.fsc_bonus,
                            })(
                                <InputNumber step={0.01} style={{ width: "100%" }} onChange={(amount) => this.props.onFscBonusChange(amount)}/>,
                            )}
                        </FormItem>
                    </Col>}
                    {<Col span={6} offset={2}>
                        <FormItem label="FSC Piemaksa 100%" style={{ marginBottom: "0px" }}>
                            {getFieldDecorator("fsc_bonus_100", {
                                initialValue: this.props.fsc_bonus_100,
                            })(
                                <InputNumber step={0.01} style={{ width: "100%" }} onChange={(amount) => this.props.onFscBonus100Change(amount)}/>,
                            )}
                        </FormItem>
                    </Col>}
                </Row>
                <Divider></Divider>
                <div className="ant-table assortment-bonus-table">
                    <table>
                        <tbody className="ant-table-tbody">
                            <tr>
                                <th>Apjoms virs:</th>
                                <td>
                                    {getFieldDecorator("amount_1", {
                                        initialValue: this.props.amount1,
                                    })(
                                        <InputNumber
                                            style={{ width: "100%" }}
                                            onChange={(amount) => this.props.onAmount1Change(amount)}
                                        />
                                    )}
                                </td>
                                <td>
                                    {getFieldDecorator("amount_2", {
                                        initialValue: this.props.amount2,
                                    })(
                                        <InputNumber
                                            style={{ width: "100%" }}
                                            onChange={(amount) => this.props.onAmount2Change(amount)}
                                        />
                                    )}
                                </td>
                                <td>
                                    {getFieldDecorator("amount_3", {
                                        initialValue: this.props.amount3,
                                    })(
                                        <InputNumber
                                            style={{ width: "100%" }}
                                            onChange={(amount) => this.props.onAmount3Change(amount)}
                                        />
                                    )}
                                </td>
                                <td>
                                    {getFieldDecorator("amount_4", {
                                        initialValue: this.props.amount4,
                                    })(
                                        <InputNumber
                                            style={{ width: "100%" }}
                                            onChange={(amount) => this.props.onAmount4Change(amount)}
                                        />
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>Piemaksa:</th>
                                <td>
                                    {getFieldDecorator("bonus_1", {
                                        initialValue: this.props.bonus1,
                                    })(
                                        <InputNumber
                                            step={0.01}
                                            style={{ width: "100%" }}
                                            onChange={(bonus) => this.props.onBonus1Change(bonus)}
                                        />
                                    )}
                                </td>
                                <td>
                                    {getFieldDecorator("bonus_2", {
                                        initialValue: this.props.bonus2,
                                    })(
                                        <InputNumber
                                            step={0.01}
                                            style={{ width: "100%" }}
                                            onChange={(bonus) => this.props.onBonus2Change(bonus)}
                                        />
                                    )}
                                </td>
                                <td>
                                    {getFieldDecorator("bonus_3", {
                                        initialValue: this.props.bonus3,
                                    })(
                                        <InputNumber
                                            step={0.01}
                                            style={{ width: "100%" }}
                                            onChange={(bonus) => this.props.onBonus3Change(bonus)}
                                        />
                                    )}
                                </td>
                                <td>
                                    {getFieldDecorator("bonus_4", {
                                        initialValue: this.props.bonus4,
                                    })(
                                        <InputNumber
                                            step={0.01}
                                            style={{ width: "100%" }}
                                            onChange={(bonus) => this.props.onBonus4Change(bonus)}
                                        />
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

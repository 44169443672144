import { Button, Form, Input, message, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import * as loadGoogleMapsApi from "load-google-maps-api";
import * as React from "react";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { Helpers } from "../../utils/helpers";
import { POLYGON_STATE_COLORS, PolygonSateColor } from "../../utils/map_settings";
import { DEAL_TYPES, STATES, STATES_MIN } from "../EditFellingPoint";
import { AcceptancePointPricePage, PricePageAssortment } from "../PricePage";

interface MapRootProps {
    form: any;
    map: any;
    google: any;
    ruleIsValid(ruleId: any): boolean;
}

interface MapRootState {
    acceptancePoints: any[];
    fellingAreas: any[];
    dataLoaded: boolean;
    activeMarker: any;
    showingInfoWindow: boolean;
    selectedPlace: any;
    assortment: null | PricePageAssortment[];
    allChecked: boolean;
    showAcceptancePoints: boolean;
    filters: any[];
    markers: any[];
    initMarkers: boolean;
    enteredGeoZoomLevel: boolean;
    filterTypeFellingAreas: FellingAreaType[];
    loadingText: string;
    cadastreNumbers: string[];
    dataSubmited: true;
}

interface FellingAreaType {
    type: number;
    show: boolean;
}

const groupFilters: any[] = [{
    id: "group_egle",
    title: "Egle",
    assortments: [1, 4, 6, 10, 11, 13, 22],
    show: true,
}, {
    id: "group_priede",
    title: "Priede",
    assortments: [1, 5, 6, 10, 11, 13, 21, 28],
    show: true,
}, {
    id: "group_apse",
    title: "Apse",
    assortments: [2, 3, 7, 12, 15, 20, 29],
    show: true,
}, {
    id: "group_berzs",
    title: "Bērzs",
    assortments: [2, 8, 12, 14, 17, 18, 19],
    show: true,
}, {
    id: "group_baltalksnis",
    title: "Baltalksnis",
    assortments: [2, 9, 12],
    show: true,
}, {
    id: "group_melnalksnins",
    title: "Melnalksnins",
    assortments: [2, 12, 24, 30],
    show: true,
}, {
    id: "group_ozols",
    title: "Ozols",
    assortments: [2, 12, 26],
    show: true,
}, {
    id: "group_osis",
    title: "Osis",
    assortments: [2, 12, 27],
    show: true,
}, {
    id: "group_parejie",
    title: "Pārējie",
    assortments: [16, 23, 25],
    show: true,
}];

const { TextArea } = Input;

class FullMapRoot extends React.Component<MapRootProps, MapRootState> {
    map;
    infoWindow;
    googleMaps;
    markers: any[] = [];
    polygons: any[] = [];

    constructor(props) {
        super(props);

        this.state = {
            dataLoaded: false,
            dataSubmited: false,

            cadastreNumbers: [],

            acceptancePoints: [],
            fellingAreas: [],
            activeMarker: {},
            selectedPlace: {},
            showingInfoWindow: false,
            assortment: null,
            allChecked: true,
            filters: groupFilters,
            markers: [],

            showAcceptancePoints: true,
            filterTypeFellingAreas: STATES_MIN.map(state => ({
                type: state.value,
                show: true,
            } as any)),

            initMarkers: false,
            enteredGeoZoomLevel: false,

            loadingText: "Ielādē sortimentus...",
        };
    }

    componentDidMount() {
        document.title = "Dižozols - Karte";
    }

    enterNumbers = (e) => {
        this.setState({ cadastreNumbers: e.target.value.split(",").map(item => item.trim()) });
    }

    showInMap = () => {
        this.setState({ dataSubmited: true }, () => {
            this.fetchData(() => {
                // Load map
                loadGoogleMapsApi({
                    key: process.env.CLIENT_GOOGLE_MAPS_API_KEY,
                    language: "lv",
                    region: "LV",
                    libraries: ["drawing"],
                    v: "3.33",
                }).then((googleMaps) => {
                    this.googleMaps = googleMaps;

                    this.map = new this.googleMaps.Map(document.querySelector(".map-selector"), {
                        center: {
                            lat: 56.9496487,
                            lng: 24.1051865,
                        },
                        zoom: 8,
                        mapTypeId: "satellite",
                    });

                    this.infoWindow = new this.googleMaps.InfoWindow({
                        size: new this.googleMaps.Size(150, 50),
                    });

                    this.googleMaps.event.addListener(this.map, "click", () => {
                        this.infoWindow.close();
                    });

                    google.maps.event.addListener(this.map, "zoom_changed", () => {
                        const zoomLevel = this.map.getZoom();
                        // Show geometry if reached this zoom level
                        if (zoomLevel >= 15) {
                            this.setState({ enteredGeoZoomLevel: true }, () => {
                                if (zoomLevel === 15) {
                                    this.hideFellingPointMarkers();
                                }

                                this.drawDetailedFellingAreas();
                            });
                        } else {
                            if (this.state.enteredGeoZoomLevel) {
                                this.setState({ enteredGeoZoomLevel: false }, () => {
                                    this.clearAllPolygons();
                                    this.redrawMarkers();
                                });
                            }
                        }
                    });

                    this.drawMarkers();

                    this.setState({ initMarkers: true });

                }).catch(_error => {
                    message.error("Neizdevās ielādēt karti");
                });
            });
        });
    }

    getStateName = (id) => {
        const stateObject = STATES.find(state => state.value === parseInt(id, 10));
        return stateObject ? stateObject.text : "-";
    }

    getDealTypeName = (id) => {
        const stateObject = DEAL_TYPES.find(state => state.value === parseInt(id, 10));
        return stateObject ? stateObject.text : "-";
    }

    drawMarkers = () => {
        this.drawAcceptancePointMarkers();
        this.drawFellingPointMarkers();
    }

    redrawMarkers = () => {
        this.drawAcceptancePointMarkers();
        this.showMarkers();
    }

    drawDetailedFellingAreas = (drawOnlyPolygons = false) => {
        const bounds = this.map.getBounds();
        const sw = bounds.getSouthWest();
        const ne = bounds.getNorthEast();

        const left = sw.lng();
        const down = sw.lat();
        const right = ne.lng();
        const up = ne.lat();
        const centerLng = this.map.getCenter().lng();

        const leftHalf = new this.googleMaps.LatLngBounds(
          new this.googleMaps.LatLng(down, left),
          new this.googleMaps.LatLng(up, centerLng),
        );

        const rightHalf = new this.googleMaps.LatLngBounds(
          new this.googleMaps.LatLng(down, centerLng),
          new this.googleMaps.LatLng(up, right),
        );

        let tempFellingAreas = [ ...this.state.fellingAreas ];

        tempFellingAreas = tempFellingAreas.map(fellingArea => {
            const point = new this.googleMaps.LatLng(fellingArea.map_center_lat, fellingArea.map_center_lng);
            let isInArea = leftHalf.contains(point) || rightHalf.contains(point);
            const filterType = this.state.filterTypeFellingAreas.find(filter => filter.type === fellingArea.state);

            if (filterType && !filterType.show) {
                isInArea = false;
            }

            return {
                ...fellingArea,
                show: isInArea,
            };
        });

        this.setState({ fellingAreas: tempFellingAreas }, () => {
            this.drawFellingPointMarkers(drawOnlyPolygons);
        });
    }

    drawFellingPointMarkers = (drawOnlyPolygons = false) => {
        if (this.state.fellingAreas) {
            this.state.fellingAreas.filter(point => point.show).map(point => {
                const pointLatLang = new this.googleMaps.LatLng(point.map_center_lat, point.map_center_lng);
                // Otherwise just add marker to map

                if (!this.state.enteredGeoZoomLevel && !drawOnlyPolygons) {
                    const marker = new this.googleMaps.Marker({
                        map: this.map,
                        position: pointLatLang,
                        clickable: true,
                        icon: {
                            url: `${process.env.baseUrl}/assets/images/icons/${point.state}.png`,
                        },
                    });

                    this.markers.push({
                        id: point.id,
                        type: "felling_area",
                        state: point.state,
                        marker,
                    });

                    google.maps.event.addListener(marker, "click", () => {
                        const paragraphStyle = "margin-bottom: 4px; font-size: 15px";
                        const contentString = `<div>
                            <h3>${point.felling_area_name}</h3>
                            <p style="${paragraphStyle}">Kadastra numurs: ${point.cadastre_number}</p>
                            <p style="${paragraphStyle}">Plānotie m3: ${point.planed_cubic_meter}</p>
                            <p style="${paragraphStyle}">Izstrādātie m3: ${point.develop_cubic_meter}</p>
                            <p style="${paragraphStyle}">Darījuma tips: ${this.getDealTypeName(point.deal_type)}</p>
                            <p style="${paragraphStyle}">Stadija: ${this.getStateName(point.state)}</p>
                            <p style="${paragraphStyle}">Sortiments: ${this.getAssortmentNameById(point.sortiment_id)}</p>
                            <p style="${paragraphStyle}">
                                <a href="/cenas/cirsmas/labot/${point.id}">
                                    Apskatit cirsmu
                                </a>
                            </p>
                        </div>`;

                        this.infoWindow.setContent(contentString);
                        this.infoWindow.open(this.map, marker);
                    });
                } else {
                    point.geometry.forEach(element => {
                        const stateSettings = POLYGON_STATE_COLORS[point.state] as PolygonSateColor;

                        if (stateSettings) {
                            const strokeColor = stateSettings.strokeColor;
                            const strokeWeight = 1;

                            const kdElement = new this.googleMaps.Polygon({
                                paths: element.coordinates,
                                fillColor: stateSettings.fillColor,
                                strokeColor,
                                strokeOpacity: 0.5,
                                fillOpacity: 0.4,
                                strokeWeight,
                            });

                            kdElement.setMap(this.map);

                            this.polygons.push({
                                id: point.id,
                                state: point.state,
                                polygon: kdElement,
                            });

                            const showArrays = (event) => {
                                const paragraphStyle = "margin-bottom: 4px; font-size: 15px";
                                const contentString = `<div>
                                    <h3>${point.felling_area_name}</h3>
                                    <p style="${paragraphStyle}">Kadastra numurs: ${point.cadastre_number}</p>
                                    <p style="${paragraphStyle}">Plānotie m3: ${point.planed_cubic_meter}</p>
                                    <p style="${paragraphStyle}">Izstrādātie m3: ${point.develop_cubic_meter}</p>
                                    <p style="${paragraphStyle}">Darījuma tips: ${this.getDealTypeName(point.deal_type)}</p>
                                    <p style="${paragraphStyle}">Kvartāls: ${element.kvartals}</p>
                                    <p style="${paragraphStyle}">Nogabals: ${element.nogabals}</p>
                                    <p style="${paragraphStyle}">
                                        <a href="/cenas/cirsmas/labot/${point.id}">
                                            Apskatit cirsmu
                                        </a>
                                    </p>
                                </div>`;

                                this.infoWindow.setContent(contentString);
                                this.infoWindow.setPosition(event.latLng);
                                this.infoWindow.open(this.map);
                            };

                            kdElement.addListener("click", showArrays);
                        }
                    });
                }
            });
        }
    }

    drawAcceptancePointMarkers = () => {
        if (this.state.acceptancePoints && this.state.showAcceptancePoints) {
            this.state.acceptancePoints.filter(point => !!point.show).map(point => {
                const pointLatLang = new this.googleMaps.LatLng(point.lat, point.lng);
                // Otherwise just add marker to map
                const marker = new this.googleMaps.Marker({
                    map: this.map,
                    position: pointLatLang,
                    clickable: true,
                    icon: {
                        url: `${process.env.baseUrl}/assets/images/icons/1.png`,
                    },
                });

                this.markers.push({
                    id: point.id,
                    type: "acceptance_point",
                    marker,
                });

                google.maps.event.addListener(marker, "click", () => {
                    const paragraphStyle = "margin-bottom: 4px; font-size: 15px";
                    const contentString = `<div>
                        <h3>${point.name}</h3>
                        <p style="${paragraphStyle}">Adrese: ${point.address}</p>
                        <p style="${paragraphStyle}">Plāns: ${point.plan}</p>
                        <p style="${paragraphStyle}">Pēdējās izmaiņas: ${Helpers.formatDateWithTime(point.updated_at)}</p>
                        <p style="${paragraphStyle}">
                            <a href="/cenas/acceptance_points/${point.id}/assortment">
                                Apskatit sortimentu
                            </a>
                        </p>
                    </div>`;

                    this.infoWindow.setContent(contentString);
                    this.infoWindow.open(this.map, marker);
                });
            });
        }
    }

    hideFellingPointMarkers = () => {
        this.markers.filter(marker => marker.type === "felling_area").map(point => {
            point.marker.setMap(null);
        });
    }

    clearAllPolygons = () => {
        this.polygons.map(point => {
            point.polygon.setMap(null);
        });

        this.polygons = [];
    }

    showAllAvailablePoints = () => {
        const tempFellingAreas = [ ...this.state.fellingAreas ];
        const modifiedFellingAreas = tempFellingAreas.map(acceptancePoint => ({
            ...acceptancePoint,
            show: true,
        }));

        this.setState({ fellingAreas: modifiedFellingAreas }, () => {
            this.markers.filter(marker => marker.type === "acceptance_point").map(point => {
                point.marker.setMap(this.map);
            });

            this.state.fellingAreas.map(pointData => {
                const marker = this.markers.find(markerItem => markerItem.type === "felling_area" && markerItem.id === pointData.id);

                if (marker) {
                    if (pointData.show && this.isFellingPointGroupActive(marker.state)) {
                        if (!marker.marker.getMap()) {
                            marker.marker.setMap(this.map);
                        }
                    } else {
                        marker.marker.setMap(null);
                    }
                }
            });
        });
    }

    clearAllAvailablePoints = () => {
        const tempFellingAreas = [ ...this.state.fellingAreas ];
        const modifiedFellingAreas = tempFellingAreas.map(acceptancePoint => ({
            ...acceptancePoint,
            show: false,
        }));

        this.setState({ fellingAreas: modifiedFellingAreas }, () => {
            this.markers.map(point => {
                point.marker.setMap(null);
            });
        });
    }

    clearMarkersByState = (state) => {
        this.markers.filter(marker => marker.state === state).map(point => {
            point.marker.setMap(null);
        });
    }

    showMarkers = () => {
        console.log(this.markers.length);

        this.markers.map(point => {
            point.marker.setMap(this.map);
        });
    }

    isFellingPointVisible = (markerId) => {
        const fellingArea = this.state.fellingAreas.find(pointData => pointData.id === markerId);
        return fellingArea ? fellingArea.show : true;
    }

    filterCheckedByType = (type) => {
        const tempFeelingAreaStates = [ ...this.state.filters ];
        const typeIndex = tempFeelingAreaStates.findIndex(state => state.id === type);

        if (typeIndex > -1) {
            return tempFeelingAreaStates[typeIndex].show;
        }

        return false;
    }

    // tslint:disable-next-line:cyclomatic-complexity
    showFellingAreaCategory = (fellingArea) => {
        const allZero = (
            fellingArea.priede_type_value === 0 &&
            fellingArea.egle_type_value === 0 &&
            fellingArea.berzs_type_value === 0 &&
            fellingArea.apse_type_value === 0 &&
            fellingArea.baltalksnis_type_value === 0 &&
            fellingArea.ozols_type_value === 0 &&
            fellingArea.melnalksnis_type_value === 0
        ) ? true : false;

        if (allZero) {
            return this.filterCheckedByType("group_parejie");
        }

        if (fellingArea.egle_type_value > 0 && this.filterCheckedByType("group_egle")) {
            return true;
        }

        if (fellingArea.priede_type_value > 0 && this.filterCheckedByType("group_priede")) {
            return true;
        }

        if (fellingArea.berzs_type_value > 0 && this.filterCheckedByType("group_berzs")) {
            return true;
        }

        if (fellingArea.apse_type_value > 0 && this.filterCheckedByType("group_apse")) {
            return true;
        }

        if (fellingArea.baltalksnis_type_value > 0 && this.filterCheckedByType("group_baltalksnis")) {
            return true;
        }

        if (fellingArea.ozols_type_value > 0 && this.filterCheckedByType("group_ozols")) {
            return true;
        }

        if (fellingArea.melnalksnis_type_value > 0 && this.filterCheckedByType("group_melnalksnins")) {
            return true;
        }

        return false;
    }

    fetchData = (cb) => {
        axios.get(`${process.env.baseUrl}/assortment`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(assortment => {
            if (assortment.status !== 200 || assortment.data.success === false) {
                throw new Error();
            }

            const assortmentData = assortment.data.data.map(assortmentItem => ({
                ...assortmentItem,
                show: true,
            }));

            this.setState({ assortment: assortmentData, loadingText: "Ielādē cirsmas..."  }, () => {
                // Load acceptance points
                axios.post(`${process.env.baseUrl}/api/felling_areas`, {
                    list: this.state.cadastreNumbers,
                }, {
                    headers: {
                        Authorization: Helpers.getSessionId(),
                    },
                })
                .then(fellingAreas => {
                    if (fellingAreas.status !== 200 || fellingAreas.data.success === false) {
                        throw new Error();
                    }

                    const fellingAreasData = fellingAreas.data.data.map(assortmentItem => ({
                        ...assortmentItem,
                        show: this.showFellingAreaCategory(assortmentItem),
                    }));

                    this.setState({ fellingAreas: fellingAreasData, dataLoaded: true }, () => {
                        cb();
                    });
                })
                .catch(_error => {
                    message.error("Neizdevās ielādēt cirsmas");

                    this.setState({
                        dataLoaded: true,
                    });
                });
            });
        })
        .catch(_error => {
            message.error("Neizdevās ielādēt sortimentu sarakstu");

            this.setState({
                dataLoaded: true,
            });
        });
    }

    fetchFellingAreas = () => axios.get(`${process.env.baseUrl}/api/felling_areas`, {
        headers: {
            Authorization: Helpers.getSessionId(),
        },
    })

    fetchAssortment = () => axios.get(`${process.env.baseUrl}/assortment`, {
        headers: {
            Authorization: Helpers.getSessionId(),
        },
    })

    fetchAcceptancePoints = () => axios.get(`${process.env.baseUrl}/api/map/acceptance_points`, {
        headers: {
            Authorization: Helpers.getSessionId(),
        },
    })

    showAssortmentInit = (assortmentId, data) => {
        const filter = data.find(assortmentItem => assortmentItem.id === assortmentId);

        if (!filter) {
            return false;
        }

        return filter.show;
    }

    showAssortment = (assortmentId) => {
        if (!this.state.assortment) {
            return false;
        }

        const filter = this.state.assortment.find(assortmentItem => assortmentItem.id === assortmentId);

        if (!filter) {
            return false;
        }

        return filter.show;
    }

    filterMapPlaces = () => {
        let tempAcceptancePoints = [...this.state.acceptancePoints] as any;
        let tempFellingAreas = [...this.state.fellingAreas] as any;

        if (this.state.acceptancePoints) {
            tempAcceptancePoints = tempAcceptancePoints.map(acceptancePoint => ({
                ...acceptancePoint,
                show: acceptancePoint.assortments.some(this.showAssortment),
            }));

            tempAcceptancePoints.map(pointData => {
                const markerIndex = this.markers.findIndex(marker => marker.type === "acceptance_point" && marker.id === pointData.id);

                if (markerIndex > -1) {
                    if (pointData.show) {
                        if (!this.markers[markerIndex].marker.getMap()) {
                            this.markers[markerIndex].marker.setMap(this.map);
                        }
                    } else {
                        this.markers[markerIndex].marker.setMap(null);
                    }
                }
            });
        }

        if (this.state.fellingAreas) {
            tempFellingAreas = tempFellingAreas.map(acceptancePoint => ({
                ...acceptancePoint,
                show: this.showFellingAreaCategory(acceptancePoint),
            }));

            tempFellingAreas.map(pointData => {
                const marker = this.markers.find(markerItem => markerItem.type === "felling_area" && markerItem.id === pointData.id);

                if (marker) {
                    if (pointData.show && this.isFellingPointGroupActive(marker.state)) {
                        if (!marker.marker.getMap()) {
                            marker.marker.setMap(this.map);
                        }
                    } else {
                        marker.marker.setMap(null);
                    }
                }
            });
        }

        this.setState({
            acceptancePoints: tempAcceptancePoints,
            fellingAreas: tempFellingAreas,
        });
    }

    isFellingPointGroupActive = (type) => {
        const fellingAreaState = this.state.filterTypeFellingAreas.find(state => state.type === type);
        return fellingAreaState ? fellingAreaState.show : true;
    }

    getAssortmentNameById = (id) => {
        const assortment = this.state.assortment!.find(assortmentItem => assortmentItem.id === id);
        return assortment ? assortment.name : "-";
    }

    render() {
        if (!this.state.dataSubmited) {
            return (
                <div style={{ padding: "50px 15%" }}>
                    <Row>
                        <h3>Kadastru numuri</h3>
                        <TextArea placeholder="Kadastru numuriem ir jābūt atdalītiem ar komatu" rows={10} onChange={this.enterNumbers}></TextArea>
                    </Row>
                    <Row style={{ marginTop: 15 }}>
                        <Button type="primary" size="large" onClick={this.showInMap}>Parādīt kartē</Button>
                    </Row>
                </div>
            );
        }

        if (!this.state.dataLoaded) {
            return (
                <LoadingIndicator loadingText={true} loadingTextPlaceholder={this.state.loadingText} verticalCenter={true}/>
            );
        }

        const mapWrapperStyle: any = {
            width: "100%",
            height: "calc(100% - 64px)",
            position: "absolute",
        };

        return (
            <div style={mapWrapperStyle}>
                <div className="map-selector" style={{ height: "calc(100vh - 64px)" }}></div>
            </div>
        );
    }
}

// tslint:disable-next-line:no-default-export
export default Form.create()(FullMapRoot as any);

import { Alert, Button, Card, Checkbox, Col, Dropdown, Form, Icon, InputNumber, Menu, message, Row, Spin, Switch, Slider, Divider, Input } from "antd";
import axios from "axios";
import { GoogleApiWrapper, Map as GoogleMap } from "google-maps-react";
import * as React from "react";
import * as ReactToPrint from "react-to-print";
import { AssortmentPricePageClient, AcceptancePointPricePage, AcceptancePointPricePageAssortmentLengthsDiametersItem } from "../../components/AssortmentPricePageClient";
import { LabaledField } from "../../components/LabeledField";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { PageContent } from "../../components/PageContent";
import { Helpers } from "../../utils/helpers";
import { Assortment } from "../AcceptancePointAssortment/create";
import { BonusesFields } from "../AssortmentPage";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { saveAs } from 'file-saver';
import { getPriceByDistance } from "../../constants/distance-expenses";
import groupByAcceptancePointsWeighted from '../../utils/group_acceptance_points';
import moment = require("moment");

export interface PricePageAssortment extends Assortment {
    show: boolean;
    category: string;
}

interface CreateFellingPointsState {
    fellingAreas: any[];
    assortmentIsLoaded: boolean;
    totalRecords: number;
    currentPage: number;
    searchKeyword: string;
    assortment: null | PricePageAssortment[];
    pointAddress: string | null;
    acceptancePointsLoaded: boolean;
    acceptancePoints: null | AcceptancePointPricePage[];
    pointLat: null | number;
    pointLng: null | number;
    pointName: null | string;
    distanceFilter: number[];
    filterFields: FilterFields;
    highlightHighestPrice: boolean;
    onlyChecked: boolean;
    fetchingAssortmentData: boolean;
    firstFetchingAssortmentData: boolean;
    filters: any[];
    acceptancePointPriority: boolean;

    allAssortmentsChecked: boolean;
    selectedAcceptancePoints: Map<string, number>;
    checkedCategories: Set<string>;
    allRowsChecked: boolean;
}

export interface FilterFields {
    sawCost: number;
    importCost: number;
    transportCost: boolean;
    acceptancePointPriority: boolean;
    price: boolean;
    fullPrice: boolean;
    includeBonus: boolean; /* include bonus in full price */
    showTransporterCode: boolean;
    showAcceptancePoint: boolean;
    showDistance: boolean;

    level_a: boolean;
    level_b: boolean;
    level_c: boolean;
    level_d: boolean;
}

interface CreateFellingPointsStateProps {
    form: any;
    map: any;
    google: any;
    ruleIsValid(ruleId: any): boolean;
}

const groupFilters: any[] = [{
    id: "group_zb",
    title: "Zāģbaļķi",
    assortments: ['P zb', 'E zb', 'P zb1', 'P zb2', 'P zbA'],
    show: true,
}, {
    id: "group_sk_pm",
    title: "Skuju papīrmalka",
    assortments: ['SK pm'],
    show: true,
}, {
    id: "group_gulsnis",
    title: "Gulsnis",
    assortments: ['Gulsnis', 'Špala', 'P gulsnis', 'E gulsnis'],
    show: true,
}, {
    id: "group_berzs",
    title: "Bērzs",
    assortments: ['B fin. A', 'B fin. B', 'B fin. C', 'B pm'],
    show: true,
}, {
    id: "group_apse",
    title: "Apse",
    assortments: ['A zb', 'A pm', 'A malka'],
    show: true,
}, {
    id: "group_lk",
    title: "Vērtīgie lapu koki",
    assortments: ['OS', 'O', 'MAL', 'O zb1', 'O zb2', 'O zbA', 'A zb1', 'A zb2', 'A zbA', 'M zb1', 'M zb2', 'M zbA'],
    show: true,
}, {
    id: "group_sk_tara",
    title: "SK tara",
    assortments: ['SK tara'],
    show: true,
}, {
    id: "group_lk_tara",
    title: "LK tara",
    assortments: ['LK tara'],
    show: true,
}, {
    id: "group_low_value",
    title: "Mazvērtīgais",
    assortments: ['LK malka', 'SK malka', 'JK malka', 'Ozola malka'],
    show: true,
}];

class PricePageWrapped extends React.Component<CreateFellingPointsStateProps, CreateFellingPointsState> {
    map;
    componentRef;
    searchInput;

    constructor(props) {
        super(props);

        this.state = {
            fellingAreas: [],
            assortment: null,
            assortmentIsLoaded: false,
            pointAddress: null,
            pointName: null,
            pointLat: null,
            pointLng: null,
            // Search
            searchKeyword: "",
            // Pagination
            totalRecords: 0,
            currentPage: 1,
            // Acceptance points
            acceptancePointsLoaded: false,
            acceptancePoints: null,
            // Filters
            distanceFilter: [0, 200],
            fetchingAssortmentData: false,
            firstFetchingAssortmentData: true,
            filterFields: {
                sawCost: 0,
                importCost: 0,
                transportCost: false,
                acceptancePointPriority: false,
                fullPrice: false,
                includeBonus: true,
                showTransporterCode: false,
                price: true,
                showAcceptancePoint: true,
                showDistance: false,
                level_a: true,
                level_b: true,
                level_c: true,
                level_d: true
            },
            highlightHighestPrice: false,
            onlyChecked: false,
            filters: groupFilters,
            selectedAcceptancePoints: new Map(),
            checkedCategories: new Set(),
            allAssortmentsChecked: false,
            acceptancePointPriority: false,
            allRowsChecked: false,
        };
    }

    componentDidMount() {
        this.fetchAssortment();
        document.title = "Dižozols - Cenu lapa klientam";
    }

    componentDidUpdate(prevProps, prevState) {
        // Hide map if close modal
        if (this.props !== prevProps.map && this.state.pointAddress !== prevState.pointAddress) {
            // If state changed - render new map
            this.renderAddressInMap();
        }
    }

    fetchAssortment = () => axios.get(`${process.env.baseUrl}/assortment`)
        .then(response => {
            if (response.data.success === false) {
                message.error("Neizdevās ielādēt sortimentus");
            }

            this.setState({
                assortment: response.data.data.map(assortmentItem => ({
                    ...assortmentItem,
                    show: true,
                })),
                assortmentIsLoaded: true,
            });
        }).catch(_error => {
            message.error("Neizdevās ielādēt sortimentu sarakstu");
        })

    fetchAcceptancePointAssortment = () => this.setState({
        fetchingAssortmentData: true,
    }, () => {
        axios.post(`${process.env.baseUrl}/acceptance_points_prices/assortment`, {
            pointLat: this.state.pointLat,
            pointLng: this.state.pointLng,
        })
        .then(response => {
            if (response.data.success === false) {
                return message.error("Neizdevās ielādēt sortimentus");
            }

            return this.setState({
                fetchingAssortmentData: false,
                firstFetchingAssortmentData: false,
                acceptancePoints: (response.data.data as AcceptancePointPricePage[]).map(acceptancePoint => ({
                    ...acceptancePoint,
                    assortments: acceptancePoint.assortments.map(assortment => ({
                        ...assortment,
                        show: this.showAssortment(assortment.diameters[0].lengths[0].items[0].assortment_id),
                    })),
                })),
            });
        })
        .catch(_error => {
            message.error("Neizdevās ielādēt pieņemšanas punktus");
        });
    })

    showAssortment = (assortmentId) => {
        if (!this.state.assortment) {
            return false;
        }

        //const assortmentId = assortmentGroup ? `${assortmentKind} ${assortmentGroup}` : assortmentKind;
        const filter = this.state.assortment.find(assortmentItem => assortmentItem.id === assortmentId);

        if (!filter) {
            return false;
        }

        return filter.show;
    }

    calculateAddress = (acceptancePoint, response) => {
        if (this.state.pointLat && this.state.pointLng) {
            const { google } = this.props;
            const service = new google.maps.DistanceMatrixService();

            service.getDistanceMatrix({
                origins: [new google.maps.LatLng(this.state.pointLat, this.state.pointLng)],
                destinations: [new google.maps.LatLng(acceptancePoint.lat, acceptancePoint.lng)],
                travelMode: "DRIVING",
            }, response);
        }

        return {};
    }

    renderAddressInMap = () => {
        const { google } = this.props;
        const mapReference = this.map;

        if (!google || !mapReference || !this.state.pointAddress) { return; }
        // Some new prototypes to make life easier
        google.maps.Map.prototype.markers = new Array();
        google.maps.Map.prototype.addMarker = function(marker) {
            this.markers[this.markers.length] = marker;
        };
        google.maps.Map.prototype.getMarkers = function() {
            return this.markers;
        };
        google.maps.Map.prototype.clearMarkers = function() {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.markers.length; i++) {
                this.markers[i].setMap(null);
            }
            this.markers = new Array();
        };

        const map = mapReference.map;
        const geocoder = new google.maps.Geocoder();
        // Trying to get location based on entered address
        geocoder.geocode({ address: this.state.pointAddress }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                // Remove old markers
                map.clearMarkers();
                // Set map center to new location
                map.setCenter(results[0].geometry.location);
                // Add new marker
                const marker = new google.maps.Marker({
                    map,
                    position: results[0].geometry.location,
                    draggable: true,
                });

                google.maps.event.addListener(marker, "dragend", () => {
                    this.setState({
                        pointName: null,
                        pointLat: marker.getPosition().lat(),
                        pointLng: marker.getPosition().lng(),
                    }, () => {
                        this.fetchAcceptancePointAssortment();
                    });
                });

                map.addMarker(marker);

                this.setState({
                    pointLat: results[0].geometry.location.lat(),
                    pointLng: results[0].geometry.location.lng(),
                }, () => {
                    this.fetchAcceptancePointAssortment();
                });
            }
        });
    }

    setMapRef = (ref) => this.map = ref;

    onOnlyCheckedChange = (checked) => {
        this.setState({ onlyChecked: checked });
    }

    checkPoint = (checked: boolean, hash: string) => {
        if (this.state.acceptancePoints) {
            const acceptancePointsTemp = [...this.state.acceptancePoints];
            const filteredList = acceptancePointsTemp.map(group1 => ({
                ...group1,
                assortments: group1.assortments.map(group2 => ({
                    ...group2,
                    diameters: group2.diameters.map(group3 => ({
                        ...group3,
                        lengths: group3.lengths.map(group4 => ({
                            ...group4,
                            items: group4.items.map(group5 => ({
                                ...group5,
                                checked: (group5.hash === hash) ? checked : group5.checked,
                            }))
                            
                        })),
                    })),
                })),
            }));

            this.setState({ acceptancePoints: filteredList });
        }
    }

    changeFilterField = (value, type) => {
        const objectField = {};
        objectField[type] = value;

        const newFiltersState = {
            ...this.state.filterFields,
            ...objectField,
        };

        this.setState({ filterFields: newFiltersState });
    }

    onHighlightHighestPriceChange = (checked) => {
        this.setState({ highlightHighestPrice: checked });
    }

    toggleAssortment = (assortmentId, show: CheckboxChangeEvent) => {
        let newAcceptancePoints = this.state.acceptancePoints as AcceptancePointPricePage[];
        newAcceptancePoints.forEach(acceptancePoint => {
            acceptancePoint.assortments.forEach(assortment => {
                if(assortment.diameters[0].lengths[0].items[0].assortment_id === assortmentId){
                    assortment.show = show.target.checked;
                }
            })
        });
        const tempAssortment = [...this.state.assortment!];
        const assortmentIndex = tempAssortment.findIndex(assortmentItem => assortmentItem.id === assortmentId);
        if (assortmentIndex > -1) {
            tempAssortment[assortmentIndex].show = !tempAssortment[assortmentIndex].show;
        }
        this.setState({
            acceptancePoints: newAcceptancePoints,
            assortment: tempAssortment
        });
    }

    toggleAllAssortment = (e: CheckboxChangeEvent) => {
        const groupFilters = [...this.state.filters];
        const tempAssortment = [...this.state.assortment];
        
        groupFilters.forEach(groupFilter => {
            groupFilter.show = e.target.checked;
        });
        
        tempAssortment.forEach(assortment => {
            assortment.show = e.target.checked;
        });

        this.setState({
            allAssortmentsChecked: e.target.checked,
            assortment: tempAssortment,
            filters: groupFilters
        })
    }

    toggleAssortmentGroup = (assortmentGroupId, e: CheckboxChangeEvent) => {
        let newFilter: FilterFields = this.state.filterFields;
        let groupFilters = this.state.filters;
        let groupFilter = groupFilters.find(group => group.id === assortmentGroupId);
        const tempAssortment = [...this.state.assortment!];
        let newAcceptancePoints = this.state.acceptancePoints as AcceptancePointPricePage[];
        if(e.target.checked){
            groupFilter.show = true;
        }else{
            groupFilter.show = false;
        }

        groupFilter.assortments.forEach(assortmentCode => {
            const assortmentIndex = tempAssortment.findIndex(assortmentItem => assortmentItem.code === assortmentCode);
            if (assortmentIndex > -1) {
                tempAssortment[assortmentIndex].show = e.target.checked;
            }
        });
        newAcceptancePoints.forEach(acceptancePoint => {
            acceptancePoint.assortments.forEach(assortment => {
                if(groupFilter.assortments.includes(assortment.diameters[0].lengths[0].items[0].assortmentCode)){
                    assortment.show = e.target.checked;
                }
            })
        });
        this.setState({
            filterFields: newFilter,
            filters: groupFilters,
            assortment: tempAssortment
        });
    }


    itemsToShow = () => {
        if (!this.state.acceptancePoints) {
            return [];
        }
        return this.state.acceptancePoints.map(acceptancePoint => ({
           ...acceptancePoint,
           assortments: acceptancePoint.assortments.map(item => {
               if (!item.show) {
                   return {};
               }
               return item;
           }).filter(value => Object.keys(value as any).length !== 0),
        })).filter(value => value.assortments.length > 0);
    }

    saveAsExcel(){
        let rows = [];
        let merges = [];

        let acceptancePoints = this.aggregateTableData();

        for(let kindRow of acceptancePoints){ 
            let kindStartRow = rows.length;
            for(let assortmentRow of kindRow.assortments){
                let assortmentStartRow = rows.length;
                for(let diameterRow of assortmentRow.diameters){
                    let diameterStartRow = rows.length;
                    
                    for(let lengthRow of diameterRow.lengths){
                        let itemCategoryKey = lengthRow.itemCategoryKey;
                        let itemCategoryIndex = this.state.selectedAcceptancePoints.has(itemCategoryKey) ? this.state.selectedAcceptancePoints.get(itemCategoryKey) : 0;
                        let assortmentDataItem = lengthRow.items[itemCategoryIndex];

                        rows.push({
                            kind: kindRow.kind,
                            assortment: assortmentRow.assortment,
                            diameter: diameterRow.minDiameter + ' - ' + diameterRow.maxDiameter,
                            length: lengthRow.length,
                            transport_expenses: getPriceByDistance(assortmentDataItem.distance),
                            bonus: assortmentDataItem.calculated_bonus,
                            full_price: assortmentDataItem.full_price,
                            price: assortmentDataItem.calculated_price,
                            acceptance_point: assortmentDataItem.name,
                            priority: assortmentDataItem.priority,
                            transporter_code: lengthRow.transporterCode,
                            distance: assortmentDataItem.distance / 1000
                        });
                    }
                    if(diameterStartRow < rows.length - 1){
                        merges.push({
                            s: {
                                r: diameterStartRow,
                                key: 'diameter'    
                            },
                            e: {
                                r: rows.length - 1,
                                key: 'diameter'
                            }
                        });
                    }
                }
                if(assortmentStartRow < rows.length - 1){
                    merges.push({
                        s: {
                            r: assortmentStartRow,
                            key: 'assortment'    
                        },
                        e: {
                            r: rows.length - 1,
                            key: 'assortment'
                        }
                    });
                }
            }

            if(kindStartRow < rows.length - 1){
                merges.push({
                    s: {
                        r: kindStartRow,
                        key: 'kind'    
                    },
                    e: {
                        r: rows.length - 1,
                        key: 'kind'
                    }
                });
            }
        }

        axios.post(`${process.env.baseUrl}/api/generate-xlsx`, {
            rows: rows,
            merges: merges,
            columns: [
                { header: 'Suga', key: 'kind', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, width: 6 },
                { header: 'Sortiments', key: 'assortment', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, width: 10 },
                { header: 'Diametrs, cm', key: 'diameter', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, width: 16 },
                { header: 'Garums, m', key: 'length', style: { numFmt: '0.0' }, width: 10 },
                ... this.state.filterFields.transportCost ? [{ header: 'Transporta izmaksas', key: 'transport_expenses', style: { numFmt: '€0.00' }, width: 20 }] : [],
                ... this.state.filterFields.fullPrice ? [{ header: 'Pilna Cena', key: 'full_price', style: { numFmt: '€0.00' }, width: 10 }] : [],
                ... this.state.filterFields.price ? [{ header: 'Cena', key: 'price', style: { numFmt: '€0.00' }, width: 8 }] : [],
                ... this.state.filterFields.showDistance ? [{ header: 'Attālums, km', key: 'distance', width: 14, style: { numFmt: '0.0' } }] : [],
                ... this.state.filterFields.showAcceptancePoint ? [{ header: 'Piezīmes', key: 'acceptance_point', width: 20 }] : [],
                ... this.state.filterFields.acceptancePointPriority ? [{ header: 'Prioritāte', key: 'priority', width: 10 }] : [],
                ... this.state.filterFields.showTransporterCode ? [{ header: 'Kods Izvedējam', key: 'transporter_code', width: 14 }] : [],
            ],
        }, {
            responseType: 'blob',
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            // if (response.data.success === false) {
            //     return message.error("Neizdevās uzģenerēt xlsx");
            // }

            let blob = new Blob([response.data], {type: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
            saveAs(blob, (this.state.pointName ? this.state.pointName + ' - ' : '') + 'Cenu Lapa ' +  moment().format('DD-MM-YY HH:mm') + '.xlsx');
        })
        .catch(_error => {
            message.error("Neizdevās veikt eksportu");
        });
    }

    onAcceptancePointSelected(category: string, index: number): void{
        const newSelection = new Map<string, number>(this.state.selectedAcceptancePoints);
        newSelection.set(category, index);
        this.setState(Object.assign(
            {},
            { 
                ...this.state,
                selectedAcceptancePoints: newSelection 
            }
        ));
    }

    onCategoryChecked(category: string, checked: boolean): void{
        const newCheckedCategories = new Set(this.state.checkedCategories);
        if(checked){
            newCheckedCategories.add(category);
        }else{
            newCheckedCategories.delete(category);
        }
        this.setState({
            checkedCategories: newCheckedCategories
        });
    }

    onAllRowsChecked(allChecked: boolean): void{
        let newCheckedCategories = new Set<string>();

        if(allChecked){
            /* fill with all categories */
            newCheckedCategories = new Set(this.state.acceptancePoints.reduce((codes1, kind) => {
                return codes1.concat(kind.assortments.reduce((codes2, assortment) => {
                    return codes2.concat(assortment.diameters.reduce((codes3, diameter) => {
                        return codes3.concat(diameter.lengths.reduce((codes4, length) => {
                            return [...codes4, length.itemCategoryKey];
                        }, []))
                    }, []))
                }, []))
            }, []));    
        }

        this.setState({
            allRowsChecked: allChecked,
            checkedCategories: newCheckedCategories
        });
    }

    calculatePrices = (object: AcceptancePointPricePageAssortmentLengthsDiametersItem): { calculated_bonus: number, full_price: number, calculated_price: number } => {
        let acceptancePointBonus = 0;
        let expenses = 0;

        if (object.acceptance_point_bonus) {
            acceptancePointBonus = object.acceptance_point_bonus;
        }

        if(object.transportExpenses){
            expenses += getPriceByDistance(object.distance);
        }

        if (this.state.filterFields.importCost) {
            expenses += this.state.filterFields.importCost;
        }

        if (this.state.filterFields.sawCost) {
            expenses += this.state.filterFields.sawCost;
        }

        const withAssortmentBonuses = object.price;
        
        const calculated_bonus = withAssortmentBonuses * (acceptancePointBonus / 100);
        const full_price = object.price + (this.state.filterFields.includeBonus ? calculated_bonus : 0);
        const calculated_price = full_price - expenses;

        return {
            calculated_bonus,
            full_price,
            calculated_price
        }
    }

    /* object must have a bonus property that is calculated taking the current filters into account */
    calculatePrice = (object) => {
        const basePrice = object.price - getPriceByDistance(object.distance);
        let highestBonus = 0;
        let surfaceCoefficient = 0;
        let acceptancePointBonus = 0;

        if (object.surface_coefficient) {
            surfaceCoefficient = object.surface_coefficient;
        }

        if (object.acceptance_point_bonus) {
            acceptancePointBonus = object.acceptance_point_bonus;
        }

        const withAssortmentBonuses = basePrice + highestBonus;
        // tslint:disable-next-line:binary-expression-operand-order
        let finalPrice = (1 + (surfaceCoefficient / 100)) * (withAssortmentBonuses * (1 + (acceptancePointBonus / 100)));

        if (this.state.filterFields.importCost) {
            finalPrice -= this.state.filterFields.importCost;
        }

        if (this.state.filterFields.sawCost) {
            finalPrice -= this.state.filterFields.sawCost;
        }

        return +(finalPrice).toFixed(2);
    }


    aggregateTableData(): AcceptancePointPricePage[]{
        return this.state.acceptancePoints.map(kind => {
            let filteredAssortments = kind.assortments.filter(assortment => {
                const filter = this.state.assortment.find(assortmentItem => assortmentItem.code === assortment.code);
                if(filter){
                    return filter.show;
                }
                return false;
            }).map(assortment => {
                const filteredDiameters = assortment.diameters.map(diameter => {
                    const filteredLengths = diameter.lengths.map(length => {
                        const filteredItems = length.items.filter(item => {
                            return item.priority && this.state.filterFields['level_' + item.priority.toLowerCase()];
                        });
                        return {
                            ...length,
                            items: filteredItems
                        }
                    }).filter(length => length.items.length > 0);
                    return {
                        ...diameter,
                        lengths: filteredLengths
                    }
                }).filter(diameter => diameter.lengths.length > 0);
                return {
                    ...assortment,
                    diameters: filteredDiameters
                }
            }).filter(assortment => assortment.diameters.length > 0);
            return {
                ...kind,
                assortments: filteredAssortments.map(assortment => {
                    return {
                        ...assortment,
                        diameters: assortment.diameters.map(diameter => {
                            let filteredLengths = diameter.lengths.filter((lengthItem) => {
                                return (!this.state.onlyChecked || this.state.checkedCategories.has(lengthItem.itemCategoryKey));
                            });

                            return {
                                ...diameter,
                                lengths: filteredLengths.map(length => {
                                    let itemsWithAdjustedPrices = length.items.map(item => {
                                        return {
                                            ...item,
                                            ...this.calculatePrices(item)
                                        }
                                    })
                                    return {
                                        ...length,
                                        items: groupByAcceptancePointsWeighted(diameter.minDiameter, diameter.maxDiameter, itemsWithAdjustedPrices).sort((a, b) => b.calculated_price - a.calculated_price)
                                    }
                                })
                            }
                        })
                    }
                })
            }
        })
    }

    // tslint:disable-next-line:cyclomatic-complexity
    render() {
        const pageContentProps = {
            headerTitle: "Cenu lapa klientam",
            fullPage: true,
            showHeader: true,
        };

        const mapWrapperStyle: any = {
            width: "100%",
            height: 250,
            position: "relative",
            marginTop: 5,
        };

        const exportMenu = (
            <Menu>
                <Menu.Item key="1" onClick={this.saveAsExcel.bind(this)}><Icon type="file-excel" /> Excel</Menu.Item>
            </Menu>
        );

        const assortmentPreScrollContent = <div>
            <Checkbox
                onChange={show => this.toggleAllAssortment(show)}
                checked={this.state.allAssortmentsChecked}
            >
                Parādīt visus
            </Checkbox>

            <Divider style={{ marginBottom: 10, marginTop: 10 }}/>
        </div>;

        const titleStyle = {
            fontWeight: 400,
            margin: 0,
            marginBottom: 3,
        } as any;

        const groupDropdownStyle: any = {
            borderLeft: "1px solid #c3c3c3",
            paddingLeft: 16,
            marginLeft: 7,
            marginTop: 6,
        };

        const getGroupAssortments = (groupId) => {
            const groupData = this.state.filters.find(group => group.id === groupId);

            if (!groupData || !groupData.show) {
                return <div></div>;
            }

            return <div style={groupDropdownStyle}>
                {this.state.assortment!
                    .filter(assortment => groupData.assortments.indexOf(assortment.code) > - 1)
                    .map(assortmentValue => (
                        <Row key={assortmentValue.code} style={{ marginBottom: 5 }}>
                            <Checkbox
                                onChange={(show) => this.toggleAssortment(assortmentValue.id, show)}
                                checked={assortmentValue.show}
                            >
                                {assortmentValue.name} - {assortmentValue.code}
                            </Checkbox>
                        </Row>
                    ))}
            </div>;
        };

        return (
            <div>
                <PageContent {...pageContentProps}>
                    <Form>
                        <Row>
                        <Col span={6}>
                                <Card>
                                    <Row>
                                        {this.props.ruleIsValid("PricePageClient:PricePageAllowSearch") && <div>
                                            <h4 style={titleStyle}>Cirsmas atrašanās vieta</h4>
                                            <Input
                                                allowClear={true}
                                                onPressEnter={(e) => this.setState({ pointAddress: e.target.value })}
                                                onBlur={(e) => this.setState({ pointAddress: e.target.value })}
                                            />
                                        </div>}

                                        <div style={mapWrapperStyle}>
                                            <GoogleMap
                                                ref={this.setMapRef}
                                                google={this.props.google}
                                                zoom={13}
                                                initialCenter={{
                                                    lat: 56.946285,
                                                    lng: 24.105078,
                                                }}
                                            />
                                        </div>
                                    </Row>
                                    <Row>
                                        <Row style={{ marginBottom: 15, marginTop: 25 }}>
                                            <LabaledField title="Sortimentu saraksts" nextLine={true} maxHeight={400} preScrollContent={assortmentPreScrollContent}>
                                                {this.state.assortment && this.state.filters.map(filterGroup => (
                                                        <Row key={filterGroup.code} style={{ marginBottom: 5 }}>
                                                            <Checkbox
                                                                onChange={(show) => this.toggleAssortmentGroup(filterGroup.id, show)}
                                                                checked={filterGroup.show}
                                                                className=""
                                                            >
                                                                {filterGroup.title}
                                                            </Checkbox>

                                                            {getGroupAssortments(filterGroup.id)}
                                                        </Row>
                                                    ))
                                                }
                                            </LabaledField>
                                        </Row>

                                        <Row style={{ marginBottom: 15 }}>
                                            <LabaledField title="Piegādes vietu prioritāte" nextLine={true}>
                                                <LabaledField title="A līmenis">
                                                    <Switch
                                                        size="small"
                                                        defaultChecked={this.state.filterFields.level_a}
                                                        className="greenSwitch"
                                                        onChange={(checked) => this.changeFilterField(checked, "level_a") }
                                                    />
                                                </LabaledField>
                                                <LabaledField title="B līmenis">
                                                    <Switch
                                                        size="small"
                                                        defaultChecked={this.state.filterFields.level_b}
                                                        className="yellowSwitch"
                                                        onChange={(checked) => this.changeFilterField(checked, "level_b") }
                                                    />
                                                </LabaledField>
                                                <LabaledField title="C līmenis">
                                                    <Switch
                                                        size="small"
                                                        defaultChecked={this.state.filterFields.level_c}
                                                        className="orangeSwitch"
                                                        onChange={(checked) => this.changeFilterField(checked, "level_c") }
                                                    />
                                                </LabaledField>
                                                <LabaledField title="D līmenis">
                                                    <Switch
                                                        size="small"
                                                        defaultChecked={this.state.filterFields.level_d}
                                                        className="redSwitch"
                                                        onChange={(checked) => this.changeFilterField(checked, "level_d") }
                                                    />
                                                </LabaledField>
                                            </LabaledField>
                                        </Row>

                                        {/* this.state.assortmentIsLoaded && this.state.assortment && this.state.acceptancePoints &&
                                            <FormItem label="Attālums">
                                                {getFieldDecorator("filterDistance", {
                                                    initialValue: [0, 200],
                                                })(
                                                    <Slider
                                                        range
                                                        onChange={this.handleDistanceChange}
                                                        tipFormatter={this.formatter}
                                                        min={0}
                                                        max={200}
                                                        marks={{
                                                            0: "0km",
                                                            200: "> 200km",
                                                        }}
                                                    />,
                                                )}
                                            </FormItem>
                                         */}

                                        {this.props.ruleIsValid("PricePageClient:AllowIncludeBonuses") && <Row style={{ marginBottom: 15 }}>
                                            <LabaledField title="Piemaksa" description="Iekļaut piemaksu pie cenu aprēķiniem">
                                                <Switch
                                                    checkedChildren={<Icon type="check"/>}
                                                    unCheckedChildren={<Icon type="cross"/>}
                                                    defaultChecked={this.state.filterFields.includeBonus}
                                                    onChange={(checked) => this.changeFilterField(checked, "includeBonus")}
                                                />
                                            </LabaledField>
                                        </Row>}

                                        {this.props.ruleIsValid("PricePageClient:ShowTransportExpenses") && <Row style={{ marginBottom: 15 }}>
                                            <LabaledField title="Transporta izmaksas" description="Parādīt transporta izmaksas katram punktam">
                                                <Switch
                                                    checkedChildren={<Icon type="check"/>}
                                                    unCheckedChildren={<Icon type="cross"/>}
                                                    defaultChecked={this.state.filterFields.transportCost}
                                                    onChange={(value) => this.changeFilterField(value, "transportCost")}
                                                />
                                            </LabaledField>
                                        </Row>}

                                        {this.props.ruleIsValid("PricePageClient:ExtraPriceAllow") && <Row style={{ marginBottom: 15 }}>
                                            <LabaledField title="Zāģēšanas izmaksas (€)" description="Summa, kura tiks noņemta no kopējās summas" large={true}>
                                                <InputNumber value={this.state.filterFields.sawCost} onChange={(value) => this.changeFilterField(value, "sawCost")} min={0} max={9999}/>
                                            </LabaledField>
                                        </Row>}

                                        
                                        {this.props.ruleIsValid("PricePageClient:ExtraPriceAllow") && <Row style={{ marginBottom: 15 }}>
                                            <LabaledField title="Ievešanas izmaksas (€)" description="Summa, kura tiks noņemta no kopējās summas" large={true}>
                                                <InputNumber value={this.state.filterFields.importCost} onChange={(value) => this.changeFilterField(value, "importCost")} min={0} max={9999}/>
                                            </LabaledField>
                                        </Row>}

                                        {this.props.ruleIsValid("PricePageClient:HighlightHighestPrice") && <Row style={{ marginBottom: 15 }}>
                                            <LabaledField title="Izcelt augstāko cenu" description="Vizuāli izcel augstāko cenu no katras diametru grupas">
                                                <Switch
                                                    checkedChildren={<Icon type="check"/>}
                                                    unCheckedChildren={<Icon type="cross"/>}
                                                    defaultChecked={this.state.highlightHighestPrice}
                                                    onChange={this.onHighlightHighestPriceChange}
                                                />
                                            </LabaledField>
                                        </Row>}

                                        {this.props.ruleIsValid("PricePageClient:ShowOnlyChecked") && <Row style={{ marginBottom: 15 }}>
                                            <LabaledField title="Rādīt tikai atzīmētos" description="Parādīt sarakstu tikai ar atzīmētajām cenām">
                                                <Switch
                                                    checkedChildren={<Icon type="check"/>}
                                                    unCheckedChildren={<Icon type="cross"/>}
                                                    defaultChecked={this.state.onlyChecked}
                                                    onChange={this.onOnlyCheckedChange}
                                                />
                                            </LabaledField>
                                        </Row>}

                                        {/* this.props.ruleIsValid("PricePageClient:ExtraPriceAllow") */true && <Row style={{ marginBottom: 15 }}>
                                        <LabaledField title="Piegādes vietas prioritāte" large={true} description="Rādīt piegādes vietas prioritāti">
                                                <Switch
                                                    checkedChildren={<Icon type="check"/>}
                                                    unCheckedChildren={<Icon type="cross"/>}
                                                    defaultChecked={this.state.filterFields.acceptancePointPriority}
                                                    onChange={(value) => this.changeFilterField(value, "acceptancePointPriority")}
                                                />
                                            </LabaledField>
                                        </Row>}

                                        

                                        {/* this.props.ruleIsValid("PricePageClient:ExtraPriceAllow") */true && <Row style={{ marginBottom: 15 }}>
                                        <LabaledField title="Pilna Cena" large={true} description="Rādīt pilnu cenu, kurā nav iekļautas transporta un citas izmaksas">
                                                <Switch
                                                    checkedChildren={<Icon type="check"/>}
                                                    unCheckedChildren={<Icon type="cross"/>}
                                                    defaultChecked={this.state.filterFields.fullPrice}
                                                    onChange={(value) => this.changeFilterField(value, "fullPrice")}
                                                />
                                            </LabaledField>
                                        </Row>}


                                        {/* this.props.ruleIsValid("PricePageClient:ExtraPriceAllow") */true && <Row style={{ marginBottom: 15 }}>
                                        <LabaledField title="Kods izvedējam" large={true} description="Rādīt kodu izvedējam">
                                                <Switch
                                                    checkedChildren={<Icon type="check"/>}
                                                    unCheckedChildren={<Icon type="cross"/>}
                                                    defaultChecked={this.state.filterFields.showTransporterCode}
                                                    onChange={(value) => this.changeFilterField(value, "showTransporterCode")}
                                                />
                                            </LabaledField>
                                        </Row>}

                                        {/* this.props.ruleIsValid("PricePageClient:ExtraPriceAllow") */true && <Row style={{ marginBottom: 15 }}>
                                        <LabaledField title="Cena" large={true} description="Rādīt cenu, kurā ir iekļautas izmaksas">
                                                <Switch
                                                    checkedChildren={<Icon type="check"/>}
                                                    unCheckedChildren={<Icon type="cross"/>}
                                                    defaultChecked={this.state.filterFields.price}
                                                    onChange={(value) => this.changeFilterField(value, "price")}
                                                />
                                            </LabaledField>
                                        </Row>}

                                        {/* this.props.ruleIsValid("PricePageClient:ExtraPriceAllow") */true && <Row style={{ marginBottom: 15 }}>
                                        <LabaledField title="Piezīmes" large={true} description="Rādīt pieņemšanas punktu">
                                                <Switch
                                                    checkedChildren={<Icon type="check"/>}
                                                    unCheckedChildren={<Icon type="cross"/>}
                                                    defaultChecked={this.state.filterFields.showAcceptancePoint}
                                                    onChange={(value) => this.changeFilterField(value, "showAcceptancePoint")}
                                                />
                                            </LabaledField>
                                        </Row>}
                                        <Row style={{ marginBottom: 15 }}>
                                            <LabaledField title="Attālums" large={true} description="Rādīt attālumu līdz pieņemšanas punktam">
                                                <Switch
                                                    checkedChildren={<Icon type="check"/>}
                                                    unCheckedChildren={<Icon type="cross"/>}
                                                    defaultChecked={this.state.filterFields.showDistance}
                                                    onChange={(value) => this.changeFilterField(value, "showDistance")}
                                                />
                                            </LabaledField>
                                        </Row>
                                    </Row>
                                </Card>
                            </Col>

                            <Col span={17} offset={1}>
                                {this.state.onlyChecked && <div style={{ marginBottom: 10, textAlign: "right" }}>
                                    <ReactToPrint
                                        trigger={() => <Button type="primary"><Icon type="printer"/> Printēt</Button>}
                                        content={() => this.componentRef}
                                        copyStyles={false}
                                        pageStyle="table{border-collapse:collapse;width:100%;background:#fff}table td,table th{border:1px solid #8a8a8a;padding:3px;text-align:center}table th{text-align:center}"
                                    />

                                    <Dropdown overlay={exportMenu}>
                                        <Button type="primary" style={{ marginLeft: 5 }}>
                                            <Icon type="export"/> Eksportēt
                                        </Button>
                                    </Dropdown>
                                </div>}

                                {this.state.assortmentIsLoaded &&
                                this.state.assortment &&
                                this.state.acceptancePoints ?
                                    <Spin spinning={this.state.fetchingAssortmentData} tip="Lūdzu uzgaidiet...">
                                        <AssortmentPricePageClient
                                            ref={ el => (this.componentRef = el)}
                                            acceptancePoints={this.aggregateTableData() as any}
                                            assortment={this.state.assortment}
                                            distanceFilter={this.state.distanceFilter}
                                            highlightHighestPrice={this.state.highlightHighestPrice}
                                            showOnlyChecked={this.state.onlyChecked}
                                            filterFields={this.state.filterFields}
                                            ruleIsValid={this.props.ruleIsValid}
                                            allChecked={this.state.allRowsChecked}
                                            selectedAcceptancePoints={this.state.selectedAcceptancePoints}
                                            checkedCategories={this.state.checkedCategories}
                                            fellingPoint={[this.state.pointLat, this.state.pointLng]}

                                            onAcceptancePointSelected={this.onAcceptancePointSelected.bind(this)}
                                            onCategoryChecked={this.onCategoryChecked.bind(this)}
                                            onAllChecked={this.onAllRowsChecked.bind(this)}
                                        />
                                    </Spin>
                                : this.state.firstFetchingAssortmentData && this.state.fetchingAssortmentData ? <LoadingIndicator loadingText={true}/>
                                : <Alert message="Lūdzu norādiet cirsmas atrašanās vietu!" type="warning" showIcon/>}
                            </Col>
                        </Row>
                    </Form>
                </PageContent>
            </div>
        );
    }
}

const PricePage = Form.create()(PricePageWrapped as any);
// tslint:disable-next-line:no-default-export
export default GoogleApiWrapper({
    apiKey: process.env.CLIENT_GOOGLE_MAPS_API_KEY,
    LoadingContainer: (_props) => (
        <div></div>
    ),
})(PricePage);

import { Icon, message, Modal, Table, Tabs } from "antd";
import axios from "axios";
import * as React from "react";
import { PageContent } from "../../components/PageContent";
import { TABLE_TRANSLATIONS } from "../../utils/constants";
import { Helpers } from "../../utils/helpers";

interface DynamicsImportLogsState {
    isLoading: boolean;
    logs: any[];
    modalVisible: boolean;
    activeLog: null | string;
}

const TabPane = Tabs.TabPane;

export const IMPORT_CONSTANTS = {
    LEVEL: {
        ERROR: "ERROR",
        SUCCESS: "SUCCESS",
    },
    ERRORS: {
        INVALID_CADASTRE_NUMBER: "INVALID_CADASTRE_NUMBER",
        SH_NOVADS_LOOKUP_IS_EMPTY: "SH_NOVADS_LOOKUP_IS_EMPTY",
        DUPLICATE_CADASTRE_NUMBER: "DUPLICATE_CADASTRE_NUMBER",
    },
    WARNINGS: {
        NO_GEO_DATA: "NO_GEO_DATA",
    },
};

export class DynamicsImportLogs extends React.Component<{}, DynamicsImportLogsState> {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            logs: [],
            modalVisible: false,
            activeLog: null,
        };
    }

    componentDidMount() {
        this.fetchLogs();
        document.title = "Dižozols - Importēšanas vēsture";
    }

    fetchLogs = () => {
        this.setState({ isLoading: true }, () => {
            axios.get(`${process.env.baseUrl}/api/admin/dynamics/logs`, {
                headers: {
                    Authorization: Helpers.getSessionId(),
                },
            })
            .then(response => {
                if (response.data.success === false) {
                    return this.setState({ isLoading: false }, () => {
                        message.error("Neizdevās ielādēt import logs");
                    });
                }

                this.setState({
                    isLoading: false,
                    logs: response.data.data,
                });

            })
            .catch(() => {
                message.error("Neizdevās ielādēt import logs");
            });
        });
    }

    showLogs = (logId) => {
        this.setState({ activeLog: logId, modalVisible: true });
    }

    closeModal = () => this.setState({ activeLog: null, modalVisible: false });

    getSuccessLogs = () => {
        const logs = this.state.logs.find(log => log.id === this.state.activeLog);

        if (!logs) {
            return [];
        }

        return logs.logs.filter(log => log.level === "SUCCESS").slice(0, 50);
    }

    getInvalidCadastreNumbers = () => {
        const logs = this.state.logs.find(log => log.id === this.state.activeLog);

        if (!logs) {
            return [];
        }

        return logs.logs.filter(logItem =>
            logItem.level === IMPORT_CONSTANTS.LEVEL.ERROR &&
            logItem.error === IMPORT_CONSTANTS.ERRORS.INVALID_CADASTRE_NUMBER,
        );
    }

    getEmptyLookup = () => {
        const logs = this.state.logs.find(log => log.id === this.state.activeLog);

        if (!logs) {
            return [];
        }

        return logs.logs.filter(logItem =>
            logItem.level === IMPORT_CONSTANTS.LEVEL.ERROR &&
            logItem.error === IMPORT_CONSTANTS.ERRORS.SH_NOVADS_LOOKUP_IS_EMPTY,
        );
    }

    getDuplicates = () => {
        const logs = this.state.logs.find(log => log.id === this.state.activeLog);

        if (!logs) {
            return [];
        }

        return logs.logs.filter(logItem =>
            logItem.level === IMPORT_CONSTANTS.LEVEL.ERROR &&
            logItem.error === IMPORT_CONSTANTS.ERRORS.DUPLICATE_CADASTRE_NUMBER,
        );
    }

    render() {
        const pageContentProps = {
            headerTitle: "Importēšanas vēsture",
        };

        const properties = this.state.logs.filter(log => log.category === "properties") as any;
        const users = this.state.logs.filter(log => log.category === "users") as any;

        const successLogsColumns = [{
            title: "Kadastra numurs",
            dataIndex: "cadastre_number",
            key: "cadastre_number",
        }, {
            title: "Īpašuma ID",
            dataIndex: "sh_ipasumsid",
            key: "sh_ipasumsid",
        }];

        const columns = [{
            title: "Sākuma datums",
            dataIndex: "import_start_date",
            key: "import_start_date",
            render: (date) => (
                Helpers.formatDateWithTime(date)
            ),
        }, {
            title: "Beigu datums",
            dataIndex: "import_end_date",
            key: "import_end_date",
            render: (date) => (
                Helpers.formatDateWithTime(date)
            ),
        }, {
            title: "Importēšanas ilgums",
            dataIndex: "duration",
            key: "duration",
            render: (_, record) => (
                Helpers.logTime(record.import_start_date, record.import_end_date)
            ),
        }, {
            title: "Kopā ieimportēts",
            dataIndex: "total_imports",
            key: "total_imports",
        }, {
            title: "Kopā laboti ieraksti",
            dataIndex: "total_updates",
            key: "total_updates",
        }];

        const columnsProperties = [...columns].concat([{
            title: "Žurnāls",
            dataIndex: "logs_action",
            key: "logs_action",
            render: (_, record) => (
                <span onClick={() => this.showLogs(record.id)} style={{ cursor: "pointer" }}><Icon type="book" /> Apskatīt žurnālu</span>
            ),
        }] as any[]);

        return (
            <div>
                <PageContent {...pageContentProps}>

                    <Tabs defaultActiveKey="properties" animated={false} className="fullTabs">
                        <TabPane tab={`Cirsmas (${properties.length})`} key="properties">
                            <Table
                                dataSource={properties}
                                columns={columnsProperties}
                                bordered={true}
                                loading={this.state.isLoading}
                                pagination={false}
                                size="small"
                                locale={TABLE_TRANSLATIONS}
                                style={{ marginTop: 15 }}
                            />
                        </TabPane>
                        <TabPane tab={`Lietotāji (${users.length})`} key="users">
                            <Table
                                dataSource={users}
                                columns={columns}
                                bordered={true}
                                loading={this.state.isLoading}
                                pagination={false}
                                size="small"
                                locale={TABLE_TRANSLATIONS}
                                style={{ marginTop: 15 }}
                            />
                        </TabPane>
                    </Tabs>

                    <Modal
                        style={{ top: 20 }}
                        visible={this.state.modalVisible}
                        onCancel={() => this.closeModal()}
                        width={900}
                    >
                        <Tabs defaultActiveKey="success_items" animated={false} className="fullTabs">
                            <TabPane tab="Veiksmīgi ieimportēti" key="success_items">
                                <Table
                                    dataSource={this.getSuccessLogs()}
                                    columns={successLogsColumns}
                                    bordered={true}
                                    pagination={false}
                                    size="small"
                                    locale={TABLE_TRANSLATIONS}
                                />
                            </TabPane>
                            <TabPane tab="Nepareizs kadastra numurs" key="invalid_cadastre_number">
                                <Table
                                    dataSource={this.getInvalidCadastreNumbers()}
                                    columns={successLogsColumns}
                                    bordered={true}
                                    pagination={false}
                                    size="small"
                                    locale={TABLE_TRANSLATIONS}
                                />
                            </TabPane>
                            <TabPane tab="Tukšs sh_novads_lookup" key="empty_lookup">
                                <Table
                                    dataSource={this.getEmptyLookup()}
                                    columns={successLogsColumns}
                                    bordered={true}
                                    pagination={false}
                                    size="small"
                                    locale={TABLE_TRANSLATIONS}
                                />
                            </TabPane>
                            <TabPane tab="Duplikāti" key="duplicates">
                                <Table
                                    dataSource={this.getDuplicates()}
                                    columns={successLogsColumns}
                                    bordered={true}
                                    pagination={false}
                                    size="small"
                                    locale={TABLE_TRANSLATIONS}
                                />
                            </TabPane>
                        </Tabs>
                    </Modal>

                </PageContent>
            </div>
        );
    }
}

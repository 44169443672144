import * as React from "react";
import cookie from "react-cookies";
import { Authorization } from "../../containers/Authorization";
import { LayoutWrapper } from "../../containers/LayoutWrapper";
import { Helpers } from "../../utils/helpers";
import { FatalError } from "../Error/Fatal";
import { heartBeat } from "../SessionCheck";

interface ApplicationState {
    isAuthorized: boolean;
    initHeartbeat: boolean;
    activeUser: any;
    haveError: boolean;
}

export class App extends React.Component<{}, ApplicationState> {
    pathAfterLogin = null;

    constructor(props) {
        super(props);

        this.state = {
            isAuthorized: false,
            activeUser: null,
            initHeartbeat: false,
            haveError: false
        };
    }

    componentDidCatch() {
        this.setState({
            haveError: true,
        });
    }

    componentWillMount() {
        // Check if user have already logged in by checking session cookie
        // If cookie exist send it to backend to check if it's valid
        // If it's valid set use as authorized and skip login form
        heartBeat(cookie.load("ozols-prices-token")).then(response => {
            this.setState({
                isAuthorized: response.success,
                initHeartbeat: true,
                activeUser: response.success ? response.data : null,
            });
        });
        this.pathAfterLogin = window.location.pathname;
    }

    resetAppState = () => {
        this.setState({
            isAuthorized: false,
            activeUser: null,
        });
    }

    /**
     * Hook to check if user has been authorized
     * This will be passed to children component
     */
    isAuthorizedCallback = (data: any) => {
        this.setState({
            isAuthorized: true,
            activeUser: data
        });
    }

    render() {
        if (this.state.haveError) {
            return FatalError;
        } else if (!this.state.initHeartbeat) {
            // Check if we checked initial heartbeat
             // This is used to know which page should be shown as first
            return null;
        } else if (!this.state.isAuthorized) {
            Helpers.rootPageUrl();
            return (<Authorization isAuthorized={this.isAuthorizedCallback}/>);
        }

        return <LayoutWrapper
            activeUser={this.state.activeUser}
            resetAppState={this.resetAppState}
            redirectPath={this.pathAfterLogin}
        />;
    }
}

import { Button, Col, Dropdown, Icon, Input, Menu, message, Modal, Pagination, Row, Select, Table, Tooltip } from "antd";
import axios from "axios";
import * as React from "react";
import { NavLink } from "react-router-dom";
import { PageContent } from "../../components/PageContent/index";
import { PaginationWrapper } from "../../components/Wrappers";
import { Helpers } from "../../utils/helpers";
import { Assortment } from "../AcceptancePointAssortment/create";
import { AddFellingPoint } from "../AddFellingPoint";
import { DEAL_TYPES, STATES } from "../EditFellingPoint";

interface Filters {
    deal_type: number;
    state: number;
}

interface FellingPointsState {
    isLoading: boolean;
    totalRecords: number;
    currentPage: number;
    searchKeyword: string;
    fellingAreas: any[];
    assortment: null | Assortment[];
    sortData: any;
    filters: Filters;
}

const Search = Input.Search;
const Option = Select.Option;
const confirm = Modal.confirm;
const filterDealType = [{ text: "Visi", value: 0 }].concat(DEAL_TYPES);
const filterState = [{ text: "Visas", value: 0 }].concat(STATES);

export class FellingPoints extends React.Component<{ ruleIsValid(ruleId: any): boolean }, FellingPointsState> {
    formRef;

    constructor(props) {
        super(props);

        const pageNumber = props.match.params.pageNumber ? parseInt(props.match.params.pageNumber, 10) : 1;

        window.onpopstate = (event) => {
            if (event.state && event.state.pageNumber) {
                this.setState({ currentPage: event.state.pageNumber, isLoading: true }, () => {
                    this.fetchPoints();
                });
            }
        };

        this.state = {
            isLoading: true,
            searchKeyword: "",
            totalRecords: 0,
            currentPage: pageNumber,
            fellingAreas: [],
            assortment: null,
            // Filters
            sortData: {},
            filters: {
                deal_type: filterDealType[0].value,
                state: filterState[0].value,
            },
        };
    }

    handleSorting = (value) => {
        const splitValue = value.split("##");
        const sortField = splitValue[0];
        const sortOrder = splitValue[1];

        this.setState({
            sortData: { sortField, sortOrder },
        }, () => this.fetchPoints());
    }

    handleFiltering = (filterName, value) => {
        const tempFilters = { ...this.state.filters };
        tempFilters[filterName] = value;

        this.setState({
            filters: tempFilters,
        }, () => this.fetchPoints());
    }

    componentDidMount() {
        document.title = "Dižozols - Cirsmas";
        this.fetchAssortment(() => {
            this.fetchPoints();
        });
    }

    fetchAssortment = (cb) => axios.get(`${process.env.baseUrl}/assortment`)
        .then(response => {
            if (response.data.success === false) {
                message.error("Neizdevās ielādēt sortimentus");
            }

            this.setState({
                assortment: response.data.data,
            }, () => { cb(); });
        }).catch(() => {
            message.error("Neizdevās ielādēt sortimentu sarakstu");
        })

    onSearch = (searchKeyword) =>
        this.setState({ currentPage: 1, isLoading: true, searchKeyword }, () => {
            this.fetchPoints();
            Helpers.scrollTopTop();
        })

    onPageChange = (page) =>
        this.setState({ currentPage: page, isLoading: true }, () => {
            this.fetchPoints();
            Helpers.scrollTopTop();

            const newUrl = `${window.location.protocol}//${window.location.host}/cenas/cirsmas/${page}`;
            window.history.pushState({ path: newUrl, pageNumber: page }, "", newUrl);
        })

    fetchPoints = () => {
        this.setState({ isLoading: true }, () => {
            axios.post(`${process.env.baseUrl}/api/felling_areas/list`, {
                pageNumber: this.state.currentPage,
                searchKeyword: this.state.searchKeyword,
                filters: this.state.filters,
                ...this.state.sortData,
            }, {
                headers: {
                    Authorization: Helpers.getSessionId(),
                },
            }).then(response => {
                if (response.data.success === false) {
                    return this.setState({ isLoading: false }, () => {
                        message.error("Neizdevās ielādēt cirsmas");
                    });
                }

                this.setState({
                    isLoading: false,
                    totalRecords: parseInt(response.data.data.totalRecords, 10),
                    fellingAreas: response.data.data.felling_areas.map(itemData => ({
                        ...itemData,
                        updated_at: Helpers.formatDateWithTime(itemData.updated_at),
                        created_at: Helpers.formatDateWithTime(itemData.created_at),
                    })),
                });

            }).catch(() => {
                message.error("Neizdevās ielādēt cirsmas");
            });
        });
    }

    deletePoint = (fellingAreaId: number) => {
        this.setState({ isLoading: true }, () => {
            axios.delete(`${process.env.baseUrl}/api/felling_areas/${fellingAreaId}`, {
                headers: {
                    Authorization: Helpers.getSessionId(),
                },
            })
                .then(response => {
                    if (response.data.success === false) {
                        message.error("Neizdevās dzēst cirsmas datus");
                    }

                    this.setState({
                        isLoading: false,
                        fellingAreas: this.state.fellingAreas.filter(filterPoint => filterPoint.id !== fellingAreaId),
                    });
                }).catch(() => {
                    message.error("Neizdevās dzēst cirsmas datus");
                });
        });
    }

    showDeleteConfirmPopup = (acceptancePointId) => {
        // tslint:disable-next-line:no-this-assignment
        const self = this;

        confirm({
            title: "Dzēst cirsmas datus",
            content: "Vai tiešām vēlaties dzēst šo cirsmu?",
            okType: "danger",
            cancelText: "Atcelt",
            okText: "Dzēst",
            onOk() {
                self.deletePoint(acceptancePointId);
            },
          });
    }

    refreshData = () => {
        this.fetchPoints();
    }

    getStateName = (id) => {
        const stateObject = STATES.find(state => state.value === parseInt(id, 10));
        return stateObject ? <div>{stateObject.text} <span className="circle" style={{ background: stateObject.color }}></span></div> : "-";
    }

    getDealTypeName = (id) => {
        const stateObject = DEAL_TYPES.find(state => state.value === parseInt(id, 10));
        return stateObject ? stateObject.text : "-";
    }

    createdByLabel = (data) => {
        if (data.imported_from_dynamics) {
            return "Dynamics";
        }

        return `${data.first_name} ${data.last_name}`;
    }

    render() {
        const pageContentHeaderActions = <div>
            {this.props.ruleIsValid("FellingAreas:NewFellingArea") && <div>
                <AddFellingPoint
                    refreshData={this.refreshData}
                    ruleIsValid={this.props.ruleIsValid}
                />
            </div>}
        </div>;

        const pageContentProps = {
            headerTitle: `Cirsmas (${this.state.totalRecords})`,
            headerActions: pageContentHeaderActions,
        };

        const itemMoreMenu = (record) => (
            <Menu>
                {this.props.ruleIsValid("FellingAreas:EditFellingArea") &&
                    <Menu.Item>
                        <NavLink to={`/cenas/cirsmas/labot/${record.id}`}>
                            Labot cirsmas datus
                        </NavLink>
                    </Menu.Item>}
                {this.props.ruleIsValid("FellingAreas:DeleteFellingArea") &&
                    <Menu.Item>
                        <a onClick={() => this.showDeleteConfirmPopup(record.id)}>Dzēst cirsmas datus</a>
                    </Menu.Item>}
            </Menu>
        );

        const columns = [{
            title: "Nosaukums",
            dataIndex: "felling_area_name",
            key: "felling_area_name",
            width: 250,
            render: (name, record) => (
                <NavLink to={`/cenas/cirsmas/labot/${record.id}`}>
                    {name} {record.imported_geo_data === false && <Tooltip placement="top" title="Netika atrasti dati pēc kadastra numura">
                        <Icon type="warning" style={{ color: "red" }}/>
                    </Tooltip>}
                </NavLink>
            ),
        }, {
            title: "Kadastra nr.",
            dataIndex: "cadastre_number",
            key: "cadastre_number",
        }, {
            title: "Darījuma tips",
            dataIndex: "deal_type",
            key: "deal_type",
            render: (deal_type) => (
                this.getDealTypeName(deal_type)
            ),
        }, {
            title: "Stadija",
            dataIndex: "state",
            key: "state",
            render: (value) => (
                this.getStateName(value)
            ),
        }, {
            title: <span>Plānotie m<sup>3</sup></span>,
            dataIndex: "planed_cubic_meter",
            key: "planed_cubic_meter",
        }, {
            title: "Izstrādātie m3",
            dataIndex: "develop_cubic_meter",
            key: "develop_cubic_meter",
        }, {
            title: "Izveidoja",
            dataIndex: "created_by",
            key: "created_by",
            width: 200,
            render: (_d, record) => (
                this.createdByLabel(record)
            ),
        }, {
            title: "Izveidots",
            dataIndex: "created_at",
            key: "created_at",
            width: 200,
        }, {
            title: "",
            dataIndex: "operation",
            key: "operation",
            width: 90,
            render: (_text, record) => (
                <Dropdown overlay={itemMoreMenu(record)} placement="bottomRight">
                    <span style={{ padding: "0 10px", marginLeft: 5, cursor: "pointer", color: "#ff8300" }}>Detaļas</span>
                </Dropdown>
            ),
        }];

        const renderPagination = (marginTop = true) => <PaginationWrapper marginTop={marginTop}>
            <Pagination
                total={this.state.totalRecords}
                current={this.state.currentPage}
                onChange={this.onPageChange}
                pageSize={20}
                size="small"
            />
        </PaginationWrapper>;

        return (
            <div>
                <PageContent {...pageContentProps}>
                    <Row>
                        <Col span={4}>
                            <Search
                                placeholder="Meklēt cirsmu"
                                onSearch={this.onSearch}
                                style={{ width: 250 }}
                                onPressEnter={e => e.preventDefault()}
                            />
                        </Col>
                        <Col span={14}>
                            <div style={{ marginLeft: 10, float: "left" }}>
                                Kārtot:
                                <Select
                                    defaultValue="created_at##DESC"
                                    style={{ width: 200, marginLeft: 5 }}
                                    onChange={this.handleSorting}
                                >
                                    <Option value="created_at##DESC">Jaunākie pievienotie</Option>
                                    <Option value="created_at##ASC">Vecākie pievienotie</Option>
                                </Select>
                            </div>
                            <div style={{ marginLeft: 10, float: "left" }}>
                                Darījuma tips:
                                <Select
                                    defaultValue={this.state.filters.deal_type}
                                    style={{ width: 200, marginLeft: 5 }}
                                    onChange={value => this.handleFiltering("deal_type", value)}
                                >
                                    {filterDealType.map(dealType => (
                                        <Option value={dealType.value} key={dealType.value as any}>{dealType.text}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div style={{ marginLeft: 10, float: "left" }}>
                                Stadija:
                                <Select
                                    defaultValue={this.state.filters.state}
                                    style={{ width: 200, marginLeft: 5 }}
                                    onChange={value => this.handleFiltering("state", value)}
                                >
                                    {filterState.map(dealType => (
                                        <Option value={dealType.value} key={dealType.value as any}>{dealType.text}</Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col span={6}>
                            {renderPagination(false)}
                        </Col>
                    </Row>

                    <Table
                        dataSource={this.state.fellingAreas}
                        columns={columns as any}
                        bordered={true}
                        loading={this.state.isLoading}
                        pagination={false}
                        size="small"
                        bordered={true}
                        locale={{
                            filterTitle: "Filtrēt",
                            filterConfirm: "Ok",
                            filterReset: "Nodzēst",
                            emptyText: "Nav datu",
                        }}
                    />

                    {renderPagination()}
                </PageContent>
            </div>
        );
    }
}

import { Col, Layout, Row } from "antd";
import * as React from "react";

const { Content } = Layout;

interface PageContentProps {
    showHeader?: boolean;
    headerTitle?: string | null;
    headerExtra?: any | null;
    headerActions?: any | null;
    fullScreen?: boolean;
    fullPage?: boolean;
    contentPadding?: boolean;
    headerActionsBar?: any | null;
}

export class PageContent extends React.Component<PageContentProps, {}> {

    static defaultProps = {
        showHeader: true,
        fullScreen: false,
        headerTitle: null,
        headerExtra: null,
        headerActions: null,
        fullPage: false,
        contentPadding: true,
        headerActionsBar: null,
    };

    render() {
        const padding = this.props.fullScreen ? "30px 0" : "30px 10%";

        if (this.props.fullPage) {
            return (
                <Layout>
                    {this.props.showHeader && <Content style={{ padding: "30px 63px", background: "#fff" }}>
                        <Row>
                            <Col span={16}>
                                <h1 className="page-header--title small">{this.props.headerTitle}</h1>
                                {this.props.headerExtra && <div style={{ width: "100%" }}>
                                    {this.props.headerExtra}
                                </div>}
                            </Col>
                            <Col span={8} style={{ textAlign: "right" }}>
                                {this.props.headerActions}
                            </Col>
                        </Row>
                        {this.props.headerActionsBar && <Row>
                            {this.props.headerActionsBar}
                        </Row>}
                    </Content>}

                    <Content style={{ padding: this.props.contentPadding ? "30px 60px" : 0 }}>
                        {this.props.children}
                    </Content>
                </Layout>
            );
        }

        return (
            <Layout>
                {this.props.showHeader && <Content style={{ padding: "40px 10%", background: "#fff" }}>
                    <Row>
                        <Col span={16}>
                            <h1 className="page-header--title">{this.props.headerTitle}</h1>
                            {this.props.headerExtra && <div style={{ width: "100%" }}>
                                {this.props.headerExtra}
                            </div>}
                        </Col>
                        <Col span={8} style={{ textAlign: "right" }}>
                            {this.props.headerActions}
                        </Col>
                    </Row>
                    {this.props.headerActionsBar && <Row>
                        {this.props.headerActionsBar}
                    </Row>}
                </Content>}

                <Content style={{ padding: this.props.contentPadding ? padding : 0 }}>
                    {this.props.children}
                </Content>
            </Layout>
        );
    }
}

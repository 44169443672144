import { message } from "antd";
import axios from "axios";
import * as R from "ramda";
import cookie from "react-cookies";

export namespace Helpers {
    // Get a session token
    export const getSessionId = () => cookie.load("ozols-prices-token");

    export const getCurrentApplication = () => cookie.load("brl-target-app");

    export const formatDate = (date) => {
        const dateObj = new Date(date);
        const day = padLeadingZero(dateObj.getDate());
        const month = padLeadingZero(dateObj.getMonth());
        const year = dateObj.getFullYear();

        return `${day}.${month}.${year}`;
    };

    export function logTime(date1, date2) {
        const startDate = new Date(date1);
        const endDate = new Date(date2);
        const date1_ms = startDate.getTime();
        const date2_ms = endDate.getTime();
        let difference = (date2_ms - date1_ms) / 1000;
        return (difference - (difference %= 60)) / 60 + (9 < difference ? ":" : ":0") + difference;
      }

    export const latvianCalenderTranslations = () =>
        ({
            lang: {
                placeholder: "Izvēlieties datumu",
                rangePlaceholder: ["Sākuma datums", "Beigu datums"],
                today: "Šodiena",
                now: "Tagad",
                backToToday: "Šodiena",
                ok: "Ok",
                clear: "Notīrīt",
                month: "Mēnesis",
                year: "Gads",
                timeSelect: "Izvēlēties laiku",
                dateSelect: "Izvēlēties datumu",
                weekSelect: "Izvēlēties nedēļu",
                monthSelect: "Izvēlēties mēnesi",
                yearSelect: "Izvēlēties gadu",
                decadeSelect: "Choose a decade",
                yearFormat: "YYYY",
                dateFormat: "M/D/YYYY",
                dayFormat: "D",
                dateTimeFormat: "M/D/YYYY HH:mm:ss",
                monthBeforeYear: true,
                previousMonth: "Iepriekšējais mēnesis",
                nextMonth: "Nākošais mēnesis",
                previousYear: "Iepriekšējais gads",
                nextYear: "Nākošais gads",
                previousDecade: "Last decade",
                nextDecade: "Next decade",
                previousCentury: "Last century",
                nextCentury: "Next century",
            },
            timePickerLocale: {
                placeholder: "Izvēlieties laiku",
            },
        });

    export const formatTimeAgo = (date) => {
        const seconds = Math.floor((new Date() - new Date(date)) / 1000);

        let interval = Math.floor(seconds / 31536000);

        if (interval > 1) {
            return formatDateWithTime(date);
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
            return formatDateWithTime(date);
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
            return `pirms ${interval} dienām`;
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
            return `pirms ${interval} stundām`;
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
            return `pirms ${interval} minūtēm`;
        }
        return `pirms ${Math.floor(seconds)} sekundēm`;

    };

    export const formatDateWithTime = (date) => {
        const dateObj = new Date(date);
        const newDate = new Date(dateObj.getTime() + dateObj.getTimezoneOffset() * 60 * 1000);
        const offset = dateObj.getTimezoneOffset() / 60;
        const hours = dateObj.getHours();
        newDate.setHours(hours - offset);

        const day = padLeadingZero(newDate.getUTCDate());
        const month = padLeadingZero(newDate.getUTCMonth() + 1);
        const year = newDate.getUTCFullYear();
        const hour = padLeadingZero(newDate.getUTCHours());
        const minute = padLeadingZero(newDate.getUTCMinutes());

        return `${day}.${month}.${year} ${hour}:${minute}`;
    };

    export const getUserList = () => axios.get(`${process.env.baseUrl}/users`, {
        headers: { Authorization: getSessionId() },
    }).then((result) => {
        if (result.status !== 200) {
            return [];
        }
        return result.data.data;
    }).catch(() => []);

    export const getUserMetadata = () => JSON.parse(cookie.load("BRLSESSIONUSER"));

    export const getUserFullName = () => getUserMetadata().fullName;

    export const padLeadingZero = (n) => (n < 10) ? `0${n}` : n;

    export const fullUrl = (path) => `/bundle/${path}`;

    export const openInNewTab = (url) => {
        const a = document.createElement("a");
        a.href = url;
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    export const groupObjBy = R.curry(R.pipe(
        // Call groupBy with the object as pairs, passing only the value to the key function
        R.useWith(R.groupBy, [R.useWith(R.__, [R.last]), R.toPairs]),
        R.map(R.fromPairs),
      ));

    export const dataCompare = R.pipe(
        R.useWith(R.mergeWith(R.merge), [R.map(R.objOf("oldValue")), R.map(R.objOf("newValue"))]),
        groupObjBy(R.cond([
          [
            R.both(R.has("oldValue"), R.has("newValue")),
            R.pipe(R.values, R.ifElse(R.apply(R.equals), R.always("common"), R.always("difference"))),
          ],
          [R.has("oldValue"), R.always("onlyOnOld")],
          [R.has("newValue"), R.always("onlyOnNew")],
        ])),
        R.evolve({
          common: R.map(R.prop("oldValue")),
          onlyOnLeft: R.map(R.prop("oldValue")),
          onlyOnRight: R.map(R.prop("newValue")),
        }),
    );

    export const rootPageUrl = () => {
        const newUrl = `${window.location.protocol}//${window.location.host}/`;
        window.history.pushState({path: newUrl}, "", newUrl);
    };

    export const handleAPIError = (error, errorMessage = "Kaut kas nogāja greizi") => {
        // If user token has been expired or something else is wrong with it
        if (error.response.status === 401) {
            return message.error("Iestājies sesijas noilgums!");
        }
        return message.error(errorMessage);
    };

    export const capitalizeFirstLetter = (string: string) =>
        string.charAt(0).toUpperCase() + string.slice(1);

    export const removeLastLetter = (string: string) =>
        string.slice(0, -1);

    export const formatToMoney = (data: any) => parseFloat(data).toFixed(2);

    export const flatten = (arr): any[] =>
        arr.reduce((flat, toFlatten) =>
          flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten), []);

    export const removeDuplicates = (originalArray, prop) => {
        const newArray: any[] = [];
        const lookupObject  = {};

        // tslint:disable-next-line:forin
        for (const i in originalArray) {
           lookupObject[originalArray[i][prop]] = originalArray[i];
        }

        // tslint:disable-next-line:forin
        for (const i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        return newArray;
    };

    export const scrollTopTop = () => window.scrollTo(0, 0);

    export const metersToKilometers = (meters) => Math.round(meters / 1000);

    export const convertMinsToHrsMins = (minutes) => {
        let h: any = Math.floor(minutes / 60);
        let m: any = minutes % 60;
        h = h < 10 ? "0" + h : h;
        m = m < 10 ? "0" + m : m;
        return `${h}:${m}`;
    };

    export const secondsToHms = (d) => {
        d = Number(d);
        const h = Math.floor(d / 3600);
        const m = Math.floor(d % 3600 / 60);

        const hDisplay = h > 0 ? h + " h " : "";
        const mDisplay = m > 0 ? m + " min " : "";
        return hDisplay + mDisplay;
    };

    export const calculateTransportExpenses = (meters) => {
        const kilometers = metersToKilometers(meters);

        if (kilometers <= 15) {
            return 150;
        } else if (kilometers <= 25 && kilometers > 15) {
            return 160;
        } else if (kilometers <= 35 && kilometers > 25) {
            return 175;
        } else if (kilometers <= 45 && kilometers > 35) {
            return 185;
        } else if (kilometers <= 55 && kilometers > 45) {
            return 205;
        } else if (kilometers <= 65 && kilometers > 55) {
            return 215;
        } else if (kilometers <= 75 && kilometers > 65) {
            return 225;
        } else if (kilometers <= 85 && kilometers > 75) {
            return 235;
        } else if (kilometers <= 100 && kilometers > 85) {
            return 235;
        } else if (kilometers > 100) {
            return (kilometers * 1.2 * 2).toFixed(2);
        } else {
            return 0;
        }
    };
}

export const calculateCargoValueByCategory = (category, length) => {
    let cargoVolume = 0;

    switch (category) {
        case "resnie":
            cargoVolume = (length > 3.2) ? length * 7 : length * 14;
            break;
        case "sk_tievie":
            cargoVolume = (length > 3.2) ? length * 6 : length * 12;
            break;
        case "lk_tievie":
            cargoVolume = (length > 3.2) ? length * 5 : length * 10;
            break;
        default:
            cargoVolume = 0;
    }

    return cargoVolume;
};

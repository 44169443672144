import { Button, Card, Col, Divider, Form, Icon, Input, InputNumber, message, Row, Select, Switch } from "antd";
import axios from "axios";
import { GoogleApiWrapper, Map } from "google-maps-react";
import * as React from "react";
import { PageContent } from "../../components/PageContent";
import { Helpers } from "../../utils/helpers";
import { Assortment } from "../AcceptancePointAssortment/create";

interface CreateFellingPointsState {
    isLoading: boolean;
    totalRecords: number;
    currentPage: number;
    searchKeyword: string;
    assortment: null | Assortment[];
    pointAddress: string | null;
    numberOfSets: number;
    acceptancePointsLoaded: boolean;
    acceptancePoints: null | AcceptancePoint[];
    activeAssortment: null | string;
    pointLat: null | number;
    pointLng: null | number;
    filterSets: any[];
    includeBonuses: boolean;
}

interface AcceptancePoint {
    distanceLoading: boolean;
    distance: number;
    duration: number;
    price: number;
    inCubicMeter: number;
    category: string;
    name: string;
    id: number;
    averagePrice: number;
    prices: AcceptancePointPrices[];
    address: string;
    lat: number;
    lng: number;
    plan: number;
}

interface AcceptancePointPrices {
    diameter: number;
    length: number;
    bonus: number;
}

interface CreateFellingPointsStateProps {
    form: any;
    map: any;
    google: any;
}

const FormItem = Form.Item;
const { Option } = Select;
const Search = Input.Search;

const calculateCargoValueByCategory = (category, length) => {
    let cargoVolume = 0;

    switch (category) {
        case "resnie":
            cargoVolume = (length > 3.2) ? length * 7 : length * 14;
            break;
        case "sk_tievie":
            cargoVolume = (length > 3.2) ? length * 6 : length * 12;
            break;
        case "lk_tievie":
            cargoVolume = (length > 3.2) ? length * 5 : length * 10;
            break;
        default:
            cargoVolume = 0;
    }

    return cargoVolume;
};

class CreateFellingPointsForm extends React.Component<CreateFellingPointsStateProps, CreateFellingPointsState> {
    map;
    typingTimer;
    doneTypingInterval = 600;

    constructor(props) {
        super(props);

        this.state = {
            assortment: null,
            pointAddress: null,
            pointLat: null,
            pointLng: null,
            includeBonuses: false,

            numberOfSets: 1,

            isLoading: true,
            // Search
            searchKeyword: "",
            // Pagination
            totalRecords: 0,
            currentPage: 1,

            // Acceptance points
            acceptancePointsLoaded: false,
            acceptancePoints: null,
            activeAssortment: null,

            // Length and diameter filters
            filterSets: [[{
                length: 0,
                diameter: 0,
            }, {
                length: 0,
                diameter: 0,
            }, {
                length: 0,
                diameter: 0,
            }], [{
                length: 0,
                diameter: 0,
            }, {
                length: 0,
                diameter: 0,
            }, {
                length: 0,
                diameter: 0,
            }], [{
                length: 0,
                diameter: 0,
            }, {
                length: 0,
                diameter: 0,
            }, {
                length: 0,
                diameter: 0,
            }]],
        };
    }

    componentDidMount() {
        this.fetchAssortment();
        document.title = "Pievienot cirsmu";
    }

    onAssortmentChange = (assortmentId) =>
        this.setState({ activeAssortment: assortmentId }, () => {
            this.fetchAcceptancePointsByAssortmentId();
        })

    componentDidUpdate(prevProps, prevState) {
        // Hide map if close modal
        if (this.props !== prevProps.map && this.state.pointAddress !== prevState.pointAddress) {
            // If state changed - render new map
            this.renderAddressInMap();
        }
    }

    setLength = (length, index) => {
        const tempState = this.state.filterSets;
        tempState[index] = tempState[index].map(tempStateItem => ({
            ...tempStateItem,
            length,
        }));

        this.setState({ filterSets: tempState }, () => {
            this.calculateAcceptancePointPrices();
        });
    }

    setDiameter = (diameter, index, diameterIndex) => {
        const tempState = this.state.filterSets;
        tempState[index] = tempState[index].map((tempStateItem, itemIndex) => ({
            ...tempStateItem,
            diameter: diameterIndex === itemIndex ? diameter : tempStateItem.diameter,
        }));

        this.setState({ filterSets: tempState }, () => {
            this.calculateAcceptancePointPrices();
        });
    }

    onSetsChange = (sets) =>
        this.setState({ numberOfSets: sets }, () => {
            this.calculateAcceptancePointPrices();
        })

    fetchAssortment = () => axios.get(`${process.env.baseUrl}/assortment`)
        .then(response => {
            if (response.data.success === false) {
                message.error("Neizdevās ielādēt sortimentus");
            }

            this.setState({
                assortment: response.data.data,
            });
        }).catch(() => {
            message.error("Neizdevās ielādēt sortimentu sarakstu");
        })

    getBonusPrice = (basePrice, acceptancePointData) => {
        if (!this.state.includeBonuses) {
            return basePrice.price;
        } else if (basePrice.price === 0) {
            return 0;
        } else {
            let acceptancePointBonus = 0;
            // Aprēķinam procentus par zāģētavas piemaksu
            if (acceptancePointData.bonus > 0) {
                acceptancePointBonus = (basePrice.price * acceptancePointData.bonus) / 100;
            }

            return basePrice.price + acceptancePointBonus;
        }
    }

    calculateAcceptancePointPrices = () => {
        // If there is not acceptance points then skip this step
        if (!this.state.acceptancePoints) {
            return;
        }

        const temporaryAcceptancePointsList = [...this.state.acceptancePoints];
        // Default reducer object
        const newSetWithPrices = temporaryAcceptancePointsList.map(acceptancePoint => {
            // Calculate total sum across all provided dimensions
            const rowCalculations = this.state.filterSets.reduce((averagePriceTemp, dimensions) => {
                const dimensionsCalculate = dimensions.reduce((averageLevelPrice, dimensionsLevel) => {
                    const priceObject = acceptancePoint.prices.map((diameters, index) => {
                        let diameterMax = 100000;
                        if (acceptancePoint.prices[index + 1] && diameters.length === acceptancePoint.prices[index + 1].length) {
                            diameterMax = acceptancePoint.prices[index + 1].diameter - 0.01;
                        }
                        const lengthMax = diameters.length + 0.2;
                        const lengthMin = diameters.length - 0.2;
                        return {...diameters, lengthMax, lengthMin, diameterMax};
                    }).find(priceTemp =>
                        priceTemp.diameter <= dimensionsLevel.diameter && priceTemp.diameterMax >= dimensionsLevel.diameter &&
                        priceTemp.lengthMin <= dimensionsLevel.length && priceTemp.lengthMax >= dimensionsLevel.length);

                    const priceToAdd = priceObject ? this.getBonusPrice(priceObject, acceptancePoint) : 0;

                    averageLevelPrice.sum += priceToAdd;
                    // Only sum prices that are greater than 0
                    if (priceToAdd > 0) {
                        averageLevelPrice.notNullValues += 1;
                    }

                    return averageLevelPrice;
                }, {
                    sum: 0,
                    notNullValues: 0,
                });

                averagePriceTemp.sum += dimensionsCalculate.sum;
                averagePriceTemp.notNullValues += dimensionsCalculate.notNullValues;

                return averagePriceTemp;
            }, {
                sum: 0,
                notNullValues: 0,
            });

            const calculateCargoValue = (totalCargoVolume, dimensions, index) => {
                if (index + 1 <= this.state.numberOfSets) {
                    return totalCargoVolume.cargoVolume + calculateCargoValueByCategory(acceptancePoint.category, dimensions[0].length || 0);
                }

                return totalCargoVolume.cargoVolume;
            };

            const calculateNotNullValues = (totalCargoVolume, dimensions, index) => {
                if (dimensions[0].length > 0 && (index + 1) <= this.state.numberOfSets) {
                    return totalCargoVolume.notNullValues + 1;
                }

                return totalCargoVolume.notNullValues;
            };

            const cargoVolumeReduced = this.state.filterSets.reduce((totalCargoVolume, dimensions, index) => ({
                ...totalCargoVolume,
                cargoVolume: calculateCargoValue(totalCargoVolume, dimensions, index),
                notNullValues: calculateNotNullValues(totalCargoVolume, dimensions, index),
            }), {
                cargoVolume: 0,
                notNullValues: 0,
            });

            const averageCargoVolume = cargoVolumeReduced.cargoVolume / cargoVolumeReduced.notNullValues;
            const priceInCubicMeter = averageCargoVolume > 0 ? (acceptancePoint.price / averageCargoVolume) : 0;

            // Divide sum price with total sets
            let averagePrice = 0;
            if (rowCalculations.notNullValues > 0) {
                averagePrice = (rowCalculations.sum / rowCalculations.notNullValues) - priceInCubicMeter;
            }

            return {
                ...acceptancePoint,
                inCubicMeter: priceInCubicMeter,
                averagePrice,
            };
        }) as AcceptancePoint[];

        this.setState({
            acceptancePoints: newSetWithPrices,
        });
    }

    fetchAcceptancePointsByAssortmentId = () => axios.get(`${process.env.baseUrl}/acceptance_points/assortment/${this.state.activeAssortment}`)
        .then(response => {
            if (response.data.success === false) {
                return message.error("Neizdevās ielādēt sortimentus");
            }

            return this.setState({
                acceptancePoints: response.data.data.map((acceptancePoint, index) => {
                    // Calculate distance between felling area and acceptance point
                    this.calculateAddress(acceptancePoint, (distanceResponse, distanceStatus) => {
                        if (distanceStatus === "OK") {
                            const tempPoints = [...this.state.acceptancePoints as any];
                            const distance = distanceResponse.rows[0].elements[0].distance.value;
                            const duration = distanceResponse.rows[0].elements[0].duration.value;
                            const price = Helpers.calculateTransportExpenses(distance) as number;

                            tempPoints[index] =  {
                                ...tempPoints[index],
                                inCubicMeter: 0,
                                distanceLoading: false,
                                averagePrice: 0,
                                distance,
                                duration,
                                price,
                            };

                            this.setState({
                                acceptancePoints: tempPoints,
                            }, () => {
                                this.calculateAcceptancePointPrices();
                            });
                        }
                    });

                    return {
                        ...acceptancePoint,
                        distanceLoading: true,
                        distance: 0,
                        duration: 0,
                        price: 0,
                        inCubicMeter: 0,
                        averagePrice: 0,
                    };
                }),
            });
        }).catch(_error => {
            message.error("Neizdevās ielādēt sortimentu sarakstu");
        })

    refreshAcceptancePointsDistance = () => {
        if (this.state.acceptancePoints) {
            this.setState({
                acceptancePoints: this.state.acceptancePoints.map((acceptancePoint, index) => {
                    // Calculate distance between felling area and acceptance point
                    this.calculateAddress(acceptancePoint, (distanceResponse, distanceStatus) => {
                        if (distanceStatus === "OK") {
                            const tempPoints = [...this.state.acceptancePoints as any];
                            const distance = distanceResponse.rows[0].elements[0].distance.value;
                            const duration = distanceResponse.rows[0].elements[0].duration.value;
                            const price = Helpers.calculateTransportExpenses(distance) as number;

                            tempPoints[index] =  {
                                ...tempPoints[index],
                                inCubicMeter: 0,
                                distanceLoading: false,
                                averagePrice: 0,
                                distance,
                                duration,
                                price,
                            };

                            this.setState({
                                acceptancePoints: tempPoints,
                            }, () => {
                                this.calculateAcceptancePointPrices();
                            });
                        }
                    });

                    return {
                        ...acceptancePoint,
                        distanceLoading: true,
                        distance: 0,
                        duration: 0,
                        price: 0,
                        averagePrice: 0,
                    };
                }),
            });
        }
    }

    getAcceptancePointCoefficient = (acceptancePointData: AcceptancePoint) => {
        if (!this.state.acceptancePoints) {
            return "";
        }

        const highestAveragePrice = Math.max.apply(null, this.state.acceptancePoints.map(
            acceptancePoint => acceptancePoint.averagePrice) as any);

        if (highestAveragePrice === 0) {
            return "";
        }

        const coefficient = acceptancePointData.averagePrice / highestAveragePrice;

        if (coefficient <= 0) {
            return "";
        }

        return coefficient.toFixed(2);
    }

    getAcceptanceAveragePrice = (acceptancePointData: AcceptancePoint) => {
        if (!this.state.acceptancePoints || acceptancePointData.averagePrice === 0) {
            return "";
        }

        return `${acceptancePointData.averagePrice.toFixed(2)}€`;
    }

    calculateAddress = (acceptancePoint, response) => {
        if (this.state.pointLat && this.state.pointLng) {
            const { google } = this.props;
            const service = new google.maps.DistanceMatrixService();

            service.getDistanceMatrix({
                origins: [new google.maps.LatLng(this.state.pointLat, this.state.pointLng)],
                destinations: [new google.maps.LatLng(acceptancePoint.lat, acceptancePoint.lng)],
                travelMode: "DRIVING",
            }, response);
        }

        return {};
    }

    renderAddressInMap = () => {
        const { google } = this.props;
        const mapReference = this.map;

        if (!google || !mapReference || !this.state.pointAddress) { return; }
        // Some new prototypes to make life easier
        google.maps.Map.prototype.markers = new Array();
        google.maps.Map.prototype.addMarker = function(marker) {
            this.markers[this.markers.length] = marker;
        };
        google.maps.Map.prototype.getMarkers = function() {
            return this.markers;
        };
        google.maps.Map.prototype.clearMarkers = function() {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.markers.length; i++) {
                this.markers[i].setMap(null);
            }
            this.markers = new Array();
        };

        const map = mapReference.map;
        const geocoder = new google.maps.Geocoder();
        // Trying to get location based on entered address
        geocoder.geocode({ address: this.state.pointAddress }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                // Remove old markers
                map.clearMarkers();
                // Set map center to new location
                map.setCenter(results[0].geometry.location);
                // Add new marker
                const marker = new google.maps.Marker({
                    map,
                    position: results[0].geometry.location,
                    draggable: true,
                });

                google.maps.event.addListener(marker, "dragend", () => {
                    this.setState({
                        pointLat: marker.getPosition().lat(),
                        pointLng: marker.getPosition().lng(),
                    }, () => {
                        this.refreshAcceptancePointsDistance();
                    });
                });

                map.addMarker(marker);

                this.setState({
                    pointLat: results[0].geometry.location.lat(),
                    pointLng: results[0].geometry.location.lng(),
                }, () => {
                    this.refreshAcceptancePointsDistance();
                });
            }
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const data = {
                    ...values,
                    lat: this.state.pointLat,
                    lng: this.state.pointLng,
                };

                const savingMessage = message.loading("Saglabā cirsmas datus...", 0);

                axios.post(`${process.env.baseUrl}/felling_areas/save`, data, {
                    headers: {
                        Authorization: Helpers.getSessionId(),
                    },
                })
                    .then(response => {
                        if (response.data.success === false) {
                            savingMessage();
                            return message.error("Neizdevās saglabāt cirsmu");
                        }

                        savingMessage();
                        return message.success("Cirsmas dati veiksīgi saglabāti");
                    })
                    .catch(_error => {
                        savingMessage();
                        message.error("Neizdevās saglabāt cirsmu");
                    });
            }
        });
    }

    setMapRef = (ref) => this.map = ref;

    findPriceByParameters = (acceptancePointData, setIndex, diameterIndex) => {
        const lengthData = this.state.filterSets[setIndex][diameterIndex];
        const aaa = acceptancePointData.prices.map((diameters, index) => {
            let diameterMax = 100000;
            if (acceptancePointData.prices[index + 1] && diameters.length === acceptancePointData.prices[index + 1].length) {
                diameterMax = acceptancePointData.prices[index + 1].diameter - 0.01;
            }
            const lengthMax = diameters.length + 0.2;
            const lengthMin = diameters.length - 0.2;
            return { ...diameters, lengthMax, lengthMin, diameterMax };
        });

        const priceObject = aaa.find(price =>
            price.diameter <= lengthData.diameter && price.diameterMax >= lengthData.diameter &&
            price.lengthMin <= lengthData.length && price.lengthMax >= lengthData.length);

        return priceObject ? `${this.getBonusPrice(priceObject, acceptancePointData).toFixed(2)} €` : "";
    }

    printAcceptancePointPriceTable = (acceptancePointData: AcceptancePoint) => {
        const priceTable: any[] = [];

        for (let i = 0; i < this.state.numberOfSets; i++) {
            let colSpan = 24 / this.state.numberOfSets;
            let colOffset = 0;

            if (i > 0) {
                colSpan -= 1;
                colOffset = 1;
            }

            priceTable.push(<Col span={colSpan} offset={colOffset} key={i}>
                <Col span={8} style={{ textAlign: "center" }}>
                    {this.findPriceByParameters(acceptancePointData, i, 0)}
                </Col>
                <Col span={7} offset={1} style={{ textAlign: "center" }}>
                    {this.findPriceByParameters(acceptancePointData, i, 1)}
                </Col>
                <Col span={7} offset={1} style={{ textAlign: "center" }}>
                    {this.findPriceByParameters(acceptancePointData, i, 2)}
                </Col>
            </Col>);
        }

        return priceTable;
    }

    changePointAddress = (addressInput: any) => {
        clearTimeout(this.typingTimer);

        this.typingTimer = setTimeout(
            (address) => this.setState({ pointAddress: address }),
            this.doneTypingInterval,
            addressInput.target.value,
        );
    }

    onIncludeBonusesChange = (checked) => {
        this.setState({ includeBonuses: checked }, () => {
            this.calculateAcceptancePointPrices();
        });
    }

    render() {
        const pageContentHeaderActions = <div>
            <Button type="primary" htmlType="submit">Saglabāt</Button>
        </div>;

        const pageContentProps = {
            headerTitle: "Pievienot cirsmu",
            headerActions: pageContentHeaderActions,
        };

        const { getFieldDecorator } = this.props.form;

        const mapWrapperStyle: any = {
            width: 400,
            height: 300,
            position: "relative",
        };

        const numberFieldStyle: any = {
            width: "100%",
        };

        const numberFieldProps: any = {
            min: 0,
        };

        const sideLabelStyles: any = {
            textAlign: "right",
            fontSize: 14,
            fontWeight: 500,
            paddingRight: 25,
        };

        const sizeSets: any[] = [];
        const priceTable: any[] = [];

        for (let i = 0; i < this.state.numberOfSets; i++) {
            let colSpan = 24 / this.state.numberOfSets;
            let colOffset = 0;

            if (i > 0) {
                colSpan -= 1;
                colOffset = 1;
            }

            sizeSets.push(<Col span={colSpan} offset={colOffset} key={i}>
                <Row>
                    {getFieldDecorator(`set_data[${i}][length]`, {
                            initialValue: this.state.filterSets[i][0].length,
                    })(
                        <InputNumber
                            placeholder="Garums"
                            step="0.1"
                            style={numberFieldStyle}
                            {...numberFieldProps}
                            onChange={(value) => this.setLength(value, i)}
                        />,
                    )}
                </Row>
                <Row style={{ marginTop: 10 }}>
                    <Col span={8}>
                        {getFieldDecorator(`set_data[${i}][diameter][1]`, {
                            initialValue: this.state.filterSets[i][0].diameter,
                        })(
                            <InputNumber
                                placeholder="Diametrs"
                                style={numberFieldStyle}
                                {...numberFieldProps}
                                onChange={(value) => this.setDiameter(value, i, 0)}
                            />,
                        )}
                    </Col>
                    <Col span={7} offset={1}>
                        {getFieldDecorator(`set_data[${i}][diameter][2]`, {
                            initialValue: this.state.filterSets[i][1].diameter,
                        })(
                            <InputNumber
                                placeholder="Diametrs"
                                style={numberFieldStyle}
                                {...numberFieldProps}
                                onChange={(value) => this.setDiameter(value, i, 1)}
                            />,
                        )}
                    </Col>
                    <Col span={7} offset={1}>
                        {getFieldDecorator(`set_data[${i}][diameter][3]`, {
                            initialValue: this.state.filterSets[i][2].diameter,
                        })(
                            <InputNumber
                                placeholder="Diametrs"
                                style={numberFieldStyle}
                                {...numberFieldProps}
                                onChange={(value) => this.setDiameter(value, i, 2)}
                            />,
                        )}
                    </Col>
                </Row>
            </Col>);

            priceTable.push(<Col span={colSpan} offset={colOffset} key={i}>
                <Col span={8} style={{ textAlign: "center" }}></Col>
                <Col span={7} offset={1} style={{ textAlign: "center" }}></Col>
                <Col span={7} offset={1} style={{ textAlign: "center" }}></Col>
            </Col>);
        }

        let acceptancePointsCalculations: any = null;

        if (this.state.acceptancePoints) {
            acceptancePointsCalculations = this.state.acceptancePoints.map(acceptancePointData => (
                <Row key={acceptancePointData.id} style={{ marginBottom: "2em", background: this.getAcceptancePointCoefficient(acceptancePointData) === "1.00" ? "#fde3c8" : "" }}>
                    <Col span={5} offset={1} style={sideLabelStyles}>
                        <div style={{ display: "inline-block", width: "100%" }}>{acceptancePointData.name}</div>
                        <div style={{ display: "inline-block", width: "100%" }}>
                            {acceptancePointData.distanceLoading ? <div style={{ color: "#b6b6b6", fontSize: 12 }}>Lūdzu uzgaidiet...</div>
                            : <div style={{ color: "#b6b6b6", fontSize: 12 }}>
                                <span><Icon type="arrow-right"/> {Helpers.metersToKilometers(acceptancePointData.distance)}km</span>
                                <span style={{ marginLeft: 10, display: "inline-block" }}><Icon type="wallet"/> {acceptancePointData.price}€</span>
                                {acceptancePointData.inCubicMeter > 0 &&
                                    <span style={{ marginLeft: 10, display: "inline-block" }}>m<sup>3</sup> {(acceptancePointData.inCubicMeter).toFixed(2)}€</span>
                                }
                                {acceptancePointData.plan > 0 &&
                                    <span style={{ marginLeft: 10, display: "inline-block" }}>Plāns {acceptancePointData.plan} m<sup>3</sup></span>
                                }
                            </div>}
                        </div>
                    </Col>
                    <Col span={15}>
                        {this.printAcceptancePointPriceTable(acceptancePointData)}
                    </Col>
                    <Col span={1} offset={1}>
                        {this.getAcceptanceAveragePrice(acceptancePointData)}
                    </Col>
                    <Col span={1} style={{ textAlign: "center"}}>
                        {this.getAcceptancePointCoefficient(acceptancePointData)}
                    </Col>
                </Row>
            ));
        }

        return (
            <div>
                <Form onSubmit={this.handleSubmit}>
                    <PageContent {...pageContentProps}>
                        <Card>
                                <Row>
                                    <Col span={6}>
                                        <FormItem label="Cirsmas atrašanās vieta">

                                            {getFieldDecorator("search_keyword", {
                                                rules: [{ required: true, message: "Lūdzu izvēlieties sortimentu!" }],
                                            })(
                                                <Search style={{ width: 400 }} onChange={this.changePointAddress} onPressEnter={e => e.preventDefault()}/>,
                                            )}

                                            <div style={mapWrapperStyle}>
                                                <Map
                                                    ref={this.setMapRef}
                                                    google={this.props.google}
                                                    zoom={13}
                                                    initialCenter={{
                                                        lat: 56.946285,
                                                        lng: 24.105078,
                                                    }}
                                                />
                                            </div>
                                        </FormItem>

                                        <FormItem label="Cirsmas nosaukums">
                                            {getFieldDecorator("felling_area_name")(
                                                <Input/>,
                                            )}
                                        </FormItem>

                                        <FormItem label="Sortiments">
                                            {getFieldDecorator("assortment", {
                                                rules: [{ required: true, message: "Lūdzu izvēlieties sortimentu!" }],
                                            })(
                                                <Select
                                                    showSearch
                                                    style={{ width: 400 }}
                                                    placeholder="Izvēlēties sortimentu"
                                                    optionFilterProp="children"
                                                    onChange={this.onAssortmentChange}
                                                >
                                                    {this.state.assortment && this.state.assortment.map(assortmentValue => (
                                                        <Option value={assortmentValue.id} key={assortmentValue.id}>
                                                            {assortmentValue.name} - <strong>{assortmentValue.code}</strong>
                                                        </Option>
                                                    ))}
                                                </Select>,
                                            )}
                                        </FormItem>
                                    </Col>

                                    <Col span={17} offset={1}>
                                        <Row>
                                            <Col span={2} offset={4}>
                                                <Row style={{...sideLabelStyles, top: "7.5em" }}>Garums</Row>
                                                <Row style={{...sideLabelStyles, top: "9.2em" }}>Diametrs</Row>
                                            </Col>

                                            <Col span={15}>
                                                <Row>
                                                    <Col span={3}>
                                                        <FormItem label="Setu daudzums">
                                                            {getFieldDecorator("number_of_sets", {
                                                                initialValue: this.state.numberOfSets,
                                                                onChange: this.onSetsChange,
                                                            })(<InputNumber style={{ width: 100 }} min={1} max={3}/>)}
                                                        </FormItem>
                                                    </Col>
                                                    <Col span={3} offset={2}>
                                                        <FormItem label="Iekļaut piemaksas">
                                                            {getFieldDecorator("includeBonuses")(
                                                                <Switch
                                                                    checkedChildren={<Icon type="check"/>}
                                                                    unCheckedChildren={<Icon type="cross"/>}
                                                                    defaultChecked={this.state.includeBonuses}
                                                                    onChange={this.onIncludeBonusesChange}
                                                                />,
                                                            )}
                                                        </FormItem>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    {sizeSets}
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col offset={1}><Divider/></Col>
                                            {acceptancePointsCalculations}
                                        </Row>
                                    </Col>
                                </Row>
                        </Card>
                    </PageContent>
                </Form>
            </div>
        );
    }
}

const CreateFellingPoints = Form.create()(CreateFellingPointsForm as any);
// tslint:disable-next-line:no-default-export
export default GoogleApiWrapper({
    apiKey: process.env.CLIENT_GOOGLE_MAPS_API_KEY,
    LoadingContainer: (_props) => (
        <div></div>
    ),
})(CreateFellingPoints);

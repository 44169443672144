export const FOREST_EXPERT_TYPES = [
    { text: "Meža eksperts", value: 100000000 },
    { text: "Meža meistars", value: 100000001 },
    { text: "Meža eksperts/meistars", value: 100000002 },
    { text: "Nezināms", value: null },
];

export const PRIORITIES = [
    { id: "", label: "" },
    { id: "level_a", label: "A līmenis" },
    { id: "level_b", label: "B līmenis" },
    { id: "level_c", label: "C līmenis" },
    { id: "level_d", label: "D līmenis" },
];

export const DISTANCE_EXPENSES = [
    {
        minDistance: 0,
        maxDistance: 10,
        price: 4.20,
    }, {
        minDistance: 11,
        maxDistance: 20,
        price: 4.40,
    }, {
        minDistance: 21,
        maxDistance: 30,
        price: 4.65,
    }, {
        minDistance: 31,
        maxDistance: 40,
        price: 5,
    }, {
        minDistance: 41,
        maxDistance: 50,
        price: 5.40,
    }, {
        minDistance: 51,
        maxDistance: 60,
        price: 5.85,
    }, {
        minDistance: 61,
        maxDistance: 70,
        price: 6.20,
    }, {
        minDistance: 71,
        maxDistance: 80,
        price: 6.60,
    }, {
        minDistance: 81,
        maxDistance: 90,
        price: 7.20,
    }, {
        minDistance: 91,
        maxDistance: 100,
        price: 7.90,
    }, {
        minDistance: 101,
        maxDistance: 110,
        price: 8.50,
    }, {
        minDistance: 111,
        maxDistance: 120,
        price: 9,
    }, {
        minDistance: 121,
        maxDistance: 130,
        price: 9.60,
    }, {
        minDistance: 131,
        maxDistance: 140,
        price: 10.30,
    }, {
        minDistance: 141,
        maxDistance: 150,
        price: 10.90,
    }, {
        minDistance: 151,
        maxDistance: 160,
        price: 11.70,
    }, {
        minDistance: 161,
        maxDistance: 170,
        price: 12.40,
    }, {
        minDistance: 171,
        maxDistance: 180,
        price: 13.20,
    }, {
        minDistance: 181,
        maxDistance: 190,
        price: 13.80,
    }, {
        minDistance: 191,
        maxDistance: 200,
        price: 14.20,
    }, {
        minDistance: 201,
        maxDistance: 210,
        price: 15,
    }, {
        minDistance: 211,
        maxDistance: 220,
        price: 15.80,
    }, {
        minDistance: 221,
        maxDistance: 230,
        price: 16.40,
    }, {
        minDistance: 231,
        maxDistance: 240,
        price: 17.00,
    }, {
        minDistance: 241,
        maxDistance: 250,
        price: 17.60,
    }, {
        minDistance: 251,
        maxDistance: 260,
        price: 18.50,
    }, {
        minDistance: 261,
        maxDistance: 270,
        price: 19.00,
    }, {
        minDistance: 271,
        maxDistance: 280,
        price: 19.60,
    }, {
        minDistance: 281,
        maxDistance: 290,
        price: 20.40,
    }, {
        minDistance: 291,
        maxDistance: 300,
        price: 21.00,
    }, {
        minDistance: 301,
        maxDistance: 99999999999,
        price: 21.00,
    }
]
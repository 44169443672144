export interface PolygonSateColor {
    fillColor: string;
    strokeColor: string;
}

export const POLYGON_STATE_COLORS = {
    100000000: {
        fillColor: "#e67e22",
        strokeColor: "#ffdec1",
        switchClassName: "orangeSwitch",
    },
    100000001: {
        fillColor: "#f1c40f",
        strokeColor: "#fff0b7",
        switchClassName: "yellowSwitch",
    },
    100000002: {
        fillColor: "#10ac84",
        strokeColor: "#bcffd8",
        switchClassName: "greenSwitch",
    },
    100000003: {
        fillColor: "#3498db",
        strokeColor: "#c1e6ff",
        switchClassName: "blueSwitch",
    },
    100000004: {
        fillColor: "#8e44ad",
        strokeColor: "#e3c1f2",
        switchClassName: "purpleSwitch",
    },
};

import { Button, Card, Form, Icon, Input, message } from "antd";
import { FormComponentProps } from "antd/lib/form";
import axios from "axios";
import * as React from "react";
import cookie from "react-cookies";
import * as logo from "../../assets/images/welcome_logo.svg";

interface AuthProps extends FormComponentProps {
    isAuthorized(data: any): void;
}

interface AuthState {
    waitingForResponse: boolean;
    formSubmitted: boolean;
}

const FormItem = Form.Item;

class AuthorizationClean extends React.Component<AuthProps, AuthState> {

    constructor(props) {
        super(props);

        this.state = {
            waitingForResponse: false,
            formSubmitted: false,
        };
    }

    componentDidMount() {
        document.title = "Dižozols cenu lapa | Autorizēties";
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    formSubmitted: true,
                    waitingForResponse: true,
                }, () => {
                    // Let's try to login
                    axios.post(`${process.env.baseUrl}/login`, {
                        username: values.username,
                        password: values.password,
                    }).then((result) => {
                        // If requested data was with success tag
                        if (result.data.success === true) {
                            // Change state
                            this.setState({ waitingForResponse: false }, () => {
                                const expireDate = new Date();
                                expireDate.setHours(expireDate.getHours() + 12);
                                // Set authorization cookies
                                cookie.save("ozols-prices-token", result.data.sessionId, {
                                    expires: expireDate,
                                    path: '/'
                                });

                                setTimeout(() => {
                                    this.props.isAuthorized(result.data.data);
                                }, 500);
                            });
                       } else {
                            this.props.form.setFields({
                                password: {
                                    value: "",
                                },
                            });

                            this.setState({ waitingForResponse: false }, () => {
                                message.error(result.data.error);
                            });
                       }
                   }).catch(() => {
                        this.props.form.setFields({
                            password: {
                                value: "",
                            },
                        });

                        this.setState({ waitingForResponse: false}, () => {
                            message.error("Kaut kas nogāja greizi!");
                        });
                   });
                });
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <div className="authorization-wrapper">
                <img src={logo} alt="Dižozols" width="512" style={{ marginBottom: '4rem' }}/>

                <Card title="Autorizēties cenu lapai">
                    <Form onSubmit={this.handleSubmit} className="login-form">
                        <FormItem>
                            {getFieldDecorator("username", {
                                rules: [{ required: true, message: "Lūdzu ievadiet lietotājvārdu!" }],
                            })(
                                <Input prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />} placeholder="Lietotājvārds" />,
                            )}
                        </FormItem>
                        <FormItem>
                            {getFieldDecorator("password", {
                                rules: [{ required: true, message: "Lūdzu ievadiet paroli!" }],
                            })(
                                <Input prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />} type="password" placeholder="Parole" />,
                            )}
                        </FormItem>
                        <FormItem style={{ textAlign: "center", marginBottom: 0 }}>
                            <Button htmlType="submit" type="primary" loading={this.state.waitingForResponse} >
                                Pierakstīties
                            </Button>
                        </FormItem>
                    </Form>
                </Card>
            </div>
        );
    }
}

export const Authorization = Form.create()(AuthorizationClean);

import { Button, Card, Form, Icon, InputNumber, message, Modal, Popconfirm, Select, Table } from "antd";
import { FormComponentProps } from "antd/lib/form";
import * as React from "react";

interface NewTableAttributeState {
    modalVisible: boolean;
}

interface NewTableAttributeProps {
    exportValue(value: number): void;
    buttonText: string;
    modalTitle: string;
    modalLabel: string;
}

const FormItem = Form.Item;

export class NewTableAttribute extends React.Component<NewTableAttributeProps, NewTableAttributeState> {
    formRef;
    inputRef;

    constructor(props) {
        super(props);

        this.state = {
            modalVisible: false,
        };
    }

    showModal = () => {
        this.setState({ modalVisible: true }, () => {
            this.focusInput();
        });
    }

    handleCancel = () => {
        this.setState({ modalVisible: false });
    }

    handleCreate = () => {
        const form = this.formRef;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            this.setState({ modalVisible: false }, () => {
                form.resetFields();
                this.props.exportValue(values.value);
            });
        });
    }

    saveFormRef = (formRef) => this.formRef = formRef;

    focusInput = () => {
        if (this.inputRef) {
            setTimeout(() => {
                this.inputRef.focus();
            }, 200);
        }
    }

    saveInputRef = (inputRef) => {
        this.inputRef = inputRef;
        this.focusInput();
    }

    render() {
        return (
            <div style={{ display: "inline-block", marginRight: 8 }}>
                <Button onClick={this.showModal}><Icon type="plus"/> {this.props.buttonText}</Button>
                <NewTableLengthAttribute
                    saveFormRef={this.saveFormRef}
                    saveInputRef={this.saveInputRef}
                    visible={this.state.modalVisible}
                    onCancel={this.handleCancel}
                    onCreate={this.handleCreate}
                    modalTitle={this.props.modalTitle}
                    modalLabel={this.props.modalLabel}
                />
            </div>
        );
    }
}

interface ModalFormProps extends FormComponentProps {
    visible: boolean;
    onCancel(): void;
    onCreate(): void;
    saveFormRef(ref: any): void;
    saveInputRef(ref: any): void;
    modalTitle: string;
    modalLabel: string;
}

const NewTableLengthAttribute = Form.create()((props: ModalFormProps) => {
    const { visible, onCancel, onCreate, form, saveFormRef, saveInputRef, modalTitle, modalLabel } = props;
    const { getFieldDecorator } = form;

    saveFormRef(form);

    return (
        <Modal
            visible={visible}
            title={modalTitle}
            cancelText="Atcelt"
            okText="Pievienot"
            onCancel={onCancel}
            onOk={onCreate}
        >
            <Form layout="vertical">
                <FormItem label={modalLabel}>
                    {getFieldDecorator("value")(
                        <InputNumber min={0} style={{ width: "100%" }} ref={saveInputRef} />,
                    )}
                </FormItem>
            </Form>
        </Modal>
      );
});

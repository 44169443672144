import { Spin } from "antd";
import * as classNames from "classnames";
import * as PropTypes from "prop-types";
import * as React from "react";

import "./style.scss";

interface LoadingProps {
    verticalCenter?: boolean;
    loadingText?: boolean;
    size?: "small" | "default" | "large";
    loadingTextPlaceholder?: string;
}

export class LoadingIndicator extends React.Component<LoadingProps, {}> {

    static defaultProps = {
        verticalCenter: false,
        loadingText: false,
        size: "large",
        loadingTextPlaceholder: "Lūdzu uzgaidiet",
    };

    static propTypes = {
        verticalCenter: PropTypes.bool,
        loadingText: PropTypes.bool,
        size: PropTypes.string,
    };

    render() {
        return (
            <div className={classNames("loadingIndicator", {
                ["verticalCenter"]: this.props.verticalCenter,
            })}>
                <Spin size={this.props.size}/>
                {this.props.loadingText && <span className="initText">{this.props.loadingTextPlaceholder}</span>}
            </div>
        );
    }
}

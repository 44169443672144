import { Button, Card, Checkbox, Col, Form, Icon, InputNumber, message, Popconfirm, Row } from "antd";
import axios from "axios";
import * as React from "react";
import { Redirect } from "react-router-dom";
import { BonusesForm } from "../../components/AcceptancePointAssortment/bonuses";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { PageContent } from "../../components/PageContent/index";
import { Helpers } from "../../utils/helpers";
import { Points } from "../AcceptancePoints";
import { NewTableAttribute } from "./newTableAttribute";

interface CreateAcceptancePointAssortmentState {
    redirect: boolean;
    pointId: number;
    assortmentId: number;
    isLoading: boolean;
    pointData: null | Points;
    assortment: null | Assortment[];
    lengths: number[];
    diameters: number[];
    priceData: PriceData[];

    bonus: number;
    fscBonus: number;
    fscBonus100: number;

    originalBonus: number;
    originalFscBonus: number;
    originalFscBonus100: number;

    amount1: number;
    amount2: number;
    amount3: number;
    amount4: number;
    
    bonus1: number;
    bonus2: number;
    bonus3: number;
    bonus4: number;

    originalAmount1: number;
    originalAmount2: number;
    originalAmount3: number;
    originalAmount4: number;

    originalBonus1: number;
    originalBonus2: number;
    originalBonus3: number;
    originalBonus4: number;

    active: boolean;
}

interface PriceData {
    id: number | null;
    length: number;
    diameter: number;
    price: number;
    originalPrice: number;
    needToUpdate: boolean;
}

interface Assortment {
    id: number;
    code: string;
    name: string;
    cargo_volume: number;
}

interface CreateAcceptancePointAssortmentProps {
    form: any;
    ruleIsValid(key: string): boolean;
}

const FormItem = Form.Item;

class EditAcceptancePointAssortmentForm extends React.Component<CreateAcceptancePointAssortmentProps, CreateAcceptancePointAssortmentState> {
    formRef;

    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            pointId: parseInt(props.match.params.pointId, 10),
            assortmentId: parseInt(props.match.params.assortmentId, 10),
            isLoading: true,
            pointData: null,
            assortment: null,

            lengths: [],
            diameters: [],
            priceData: [],

            bonus: 0,
            fscBonus: 0,
            fscBonus100: 0,

            originalBonus: 0,
            originalFscBonus: 0,
            originalFscBonus100: 0,

            amount1: 500,
            amount2: 1000,
            amount3: 5000,
            amount4: 10000,

            bonus1: 0,
            bonus2: 0,
            bonus3: 0,
            bonus4: 0,

            originalAmount1: 500,
            originalAmount2: 1000,
            originalAmount3: 5000,
            originalAmount4: 10000,

            originalBonus1: 0,
            originalBonus2: 0,
            originalBonus3: 0,
            originalBonus4: 0,

            active: true,
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchPoint = () => axios.get(`${process.env.baseUrl}/api/acceptance_points_fetch/${this.state.pointId}`);

    fetchAssortment = () => axios.get(`${process.env.baseUrl}/assortment`);

    fetchPointAssortment = () => axios.get(`${process.env.baseUrl}/acceptance_point/${this.state.pointId}/assortment/${this.state.assortmentId}/fetch`);

    setNewLength = (length: number) => this.setState(prevState => ({
        lengths: [...prevState.lengths, length].sort((a, b) => a - b),
    }))

    setNewDiameter = (diameter: number) => this.setState(prevState => ({
        diameters: [...prevState.diameters, diameter].sort((a, b) => a - b),
    }))

    getAssortmentName = () => {
        if (this.state.assortment) {
            const assortmentTemp = this.state.assortment.find(assortmentToFind => assortmentToFind.id === this.state.assortmentId);
            if (assortmentTemp) {
                return assortmentTemp.name;
            }
        }
        return null;
    }

    fetchData = () =>
        axios.all([
            this.fetchPoint(),
            this.fetchAssortment(),
            this.fetchPointAssortment(),
        ]).then(axios.spread((point, assortment, pointAssortment) => {
            if (point.data.success === false) {
                message.error("Neizdevās ielādēt pieņemšanas punktu");
            }

            if (assortment.data.success === false) {
                message.error("Neizdevās ielādēt sortimentus");
            }

            if (pointAssortment.data.success === false) {
                message.error("Neizdevās ielādēt pieņemšanas punkta sortimentus");
            }

            const formatedPriceData = pointAssortment.data.data.reduce((formated, priceData) => {
                const diameter = parseFloat(priceData.diameter);
                if (formated.diameters.indexOf(diameter) === -1) {
                    formated.diameters.push(diameter);
                }
                const length = parseFloat(priceData.length);
                if (formated.lengths.indexOf(length) === -1) {
                    formated.lengths.push(length);
                }

                formated.priceData.push({
                    id: parseInt(priceData.id, 10),
                    length,
                    diameter,
                    price: parseFloat(priceData.price),
                    bonus: parseFloat(priceData.bonus),
                    fscBonus: parseFloat(priceData.fsc_bonus),
                    fscBonus100: parseFloat(priceData.fsc_bonus_100),
                    needToUpdate: false,
                    active: !!priceData.active
                });

                return formated;
            }, {
                lengths: [],
                diameters: [],
                priceData: [],
            });

            this.setState({
                isLoading: false,
                pointData: point.data.data,
                assortment: assortment.data.data,
                lengths: formatedPriceData.lengths.sort((a, b) => a - b),
                diameters: formatedPriceData.diameters.sort((a, b) => a - b),
                priceData: formatedPriceData.priceData,
                bonus: formatedPriceData.priceData[0].bonus,
                fscBonus: formatedPriceData.priceData[0].fscBonus,
                fscBonus100: formatedPriceData.priceData[0].fscBonus100,
                originalBonus: formatedPriceData.priceData[0].bonus,
                originalFscBonus: formatedPriceData.priceData[0].fscBonus,
                originalFscBonus100: formatedPriceData.priceData[0].fscBonus100,
                active: formatedPriceData.priceData[0].active,
            });

            console.log(formatedPriceData)

            if(pointAssortment.data.bonuses){
                this.setState({
                    originalBonus1: pointAssortment.data.bonuses.bonus_1 || 0,
                    originalBonus2: pointAssortment.data.bonuses.bonus_2 || 0,
                    originalBonus3: pointAssortment.data.bonuses.bonus_3 || 0,
                    originalBonus4: pointAssortment.data.bonuses.bonus_4 || 0,

                    bonus1: pointAssortment.data.bonuses.bonus_1 || 0,
                    bonus2: pointAssortment.data.bonuses.bonus_2 || 0,
                    bonus3: pointAssortment.data.bonuses.bonus_3 || 0,
                    bonus4: pointAssortment.data.bonuses.bonus_4 || 0,

                    originalAmount1: pointAssortment.data.bonuses.amount_1 || 0,
                    originalAmount2: pointAssortment.data.bonuses.amount_2 || 0,
                    originalAmount3: pointAssortment.data.bonuses.amount_3 || 0,
                    originalAmount4: pointAssortment.data.bonuses.amount_4 || 0,

                    amount1: pointAssortment.data.bonuses.amount_1 || 0,
                    amount2: pointAssortment.data.bonuses.amount_2 || 0,
                    amount3: pointAssortment.data.bonuses.amount_3 || 0,
                    amount4: pointAssortment.data.bonuses.amount_4 || 0,
                })
            }else{
                this.setState({
                    originalBonus1: 0,
                    originalBonus2: 0,
                    originalBonus3: 0,
                    originalBonus4: 0,

                    bonus1: 0,
                    bonus2: 0,
                    bonus3: 0,
                    bonus4: 0,

                    originalAmount1: 500,
                    originalAmount2: 1000,
                    originalAmount3: 5000,
                    originalAmount4: 10000,

                    amount1: 500,
                    amount2: 1000,
                    amount3: 5000,
                    amount4: 10000,
                })
            }
        }))

    priceChange = (length, diameter, price) => {
        const priceDataIndex = this.state.priceData.findIndex(priceDataItem =>
            priceDataItem.length === length && priceDataItem.diameter === diameter);

        if (priceDataIndex === -1) {
            const priceDataStateCopy = this.state.priceData;
            priceDataStateCopy.push({
                id: null,
                length,
                diameter,
                price: parseFloat(price),
                originalPrice: 0,
                needToUpdate: true,
            });

            this.setState({
                priceData: priceDataStateCopy,
            });
        } else {
            let priceDataStateCopy = this.state.priceData;
            priceDataStateCopy = priceDataStateCopy.slice();
            priceDataStateCopy[priceDataIndex] = {...priceDataStateCopy[priceDataIndex]};
            priceDataStateCopy[priceDataIndex].price = parseFloat(price);
            priceDataStateCopy[priceDataIndex].needToUpdate = true;

            this.setState({
                priceData: priceDataStateCopy,
            });
        }
    }

    onBonusChange = (bonus) => {
        this.setState({ 
            bonus,
            priceData: this.state.priceData.map(priceDataItem => ({ ...priceDataItem, needToUpdate: true })) 
        });
    }
    onFscBonusChange = (fscBonus) => {
        this.setState({ 
            fscBonus,
            priceData: this.state.priceData.map(priceDataItem => ({ ...priceDataItem, needToUpdate: true })) 
        });
    }
    onFscBonus100Change = (fscBonus100) => {
        this.setState({ 
            fscBonus100,
            priceData: this.state.priceData.map(priceDataItem => ({ ...priceDataItem, needToUpdate: true })) 
        });
    }

    onAmount1Change = (amount1) => {
        this.setState({ amount1 });
    }
    onAmount2Change = (amount2) => {
        this.setState({ amount2 });
    }
    onAmount3Change = (amount3) => {
        this.setState({ amount3 });
    }
    onAmount4Change = (amount4) => {
        this.setState({ amount4 });
    }

    onBonus1Change = (bonus1) => {
        this.setState({ bonus1 });
    }
    onBonus2Change = (bonus2) => {
        this.setState({ bonus2 });
    }
    onBonus3Change = (bonus3) => {
        this.setState({ bonus3 });
    }
    onBonus4Change = (bonus4) => {
        this.setState({ bonus4 });
    }

    activeChange = (checked) => {
        this.setState({ 
            active: !checked, //checkbox is inverted - asks if assortment is inactive
            priceData: this.state.priceData.map(priceDataItem => ({ ...priceDataItem, needToUpdate: true }))
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let assortment: null | string = null;

                if (this.state.assortment) {
                    const assortmentTemp = this.state.assortment.find(assortmentT => assortmentT.id === values.assortment);
                    if (assortmentTemp) {
                        assortment = assortmentTemp.name;
                    }
                }

                const postData = {
                    items: this.state.priceData.filter(priceDataItem => priceDataItem.needToUpdate === true),
                    acceptance_point_id: this.state.pointId,
                    assortment: this.state.assortmentId,
                    assortment_name: assortment,

                    bonus: this.state.bonus,
                    fsc_bonus: this.state.fscBonus,
                    fsc_bonus_100: this.state.fscBonus100,
                    originalBonus: this.state.originalBonus,
                    originalFscBonus: this.state.originalFscBonus,
                    originalFscBonus100: this.state.originalFscBonus100,

                    bonus_1: this.state.bonus1,
                    bonus_2: this.state.bonus2,
                    bonus_3: this.state.bonus3,
                    bonus_4: this.state.bonus4,

                    original_bonus_1: this.state.originalBonus1,
                    original_bonus_2: this.state.originalBonus2,
                    original_bonus_3: this.state.originalBonus3,
                    original_bonus_4: this.state.originalBonus4,

                    amount_1: this.state.amount1,
                    amount_2: this.state.amount2,
                    amount_3: this.state.amount3,
                    amount_4: this.state.amount4,

                    original_amount_1: this.state.originalAmount1,
                    original_amount_2: this.state.originalAmount2,
                    original_amount_3: this.state.originalAmount3,
                    original_amount_4: this.state.originalAmount4,

                    active: this.state.active
                };

                console.log(postData)

                axios.put(`${process.env.baseUrl}/api/acceptance_point/assortment`, postData, {
                        headers: {
                            Authorization: Helpers.getSessionId(),
                        },
                    })
                    .then(response => {
                        if (response.data.success === false) {
                            message.error("Neizdevās saglabāt sortimentu");
                        }

                        this.setState({ redirect: true });
                    })
                    .catch(error => {
                        Helpers.handleAPIError(error, "Neizdevās saglabāt sortimentu");
                    });
            }
        });
    }

    onLengthDelete = (length) => {
        const tempLengths = [ ...this.state.lengths ];
        const lengthIndex = tempLengths.findIndex(tempLength => tempLength === parseInt(length, 10));
        tempLengths.splice(lengthIndex, 1);

        this.setState({ lengths: tempLengths }, () => {
            axios.post(`${process.env.baseUrl}/api/acceptance_point/assortment/delete/length`, {
                length,
                acceptance_point_id: this.state.pointId,
                assortment: this.state.assortmentId,
            }, {
                headers: {
                    Authorization: Helpers.getSessionId(),
                },
            });
        });
    }

    onDiameterDelete = (diameter) => {
        const tempDiameters = [ ...this.state.diameters ];
        const diameterIndex = tempDiameters.findIndex(tempDiameter => tempDiameter === parseInt(diameter, 10));
        tempDiameters.splice(diameterIndex, 1);

        this.setState({ diameters: tempDiameters }, () => {
            axios.post(`${process.env.baseUrl}/api/acceptance_point/assortment/delete/diameter`, {
                diameter,
                acceptance_point_id: this.state.pointId,
                assortment: this.state.assortmentId,
            }, {
                headers: {
                    Authorization: Helpers.getSessionId(),
                },
            });
        });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={`/cenas/acceptance_points/${this.state.pointId}/assortment`}/>;
        }

        const pageContentProps = {
            headerTitle: `Labot "${this.state.pointData && this.state.pointData.name}" sortimentu`,
        };

        const { getFieldDecorator } = this.props.form;

        return (
            <PageContent {...pageContentProps}>
                {this.state.isLoading ? <LoadingIndicator/> :
                    <Card>
                        <Form onSubmit={this.handleSubmit}>

                            <Row>
                                <Col span={7}>
                                    <FormItem label="Sortiments">
                                        <strong>{this.getAssortmentName()}</strong>
                                    </FormItem>
                                </Col>
                                
                                <Col span={17}> 
                                    <FormItem label="Piemaksas">
                                        <BonusesForm 
                                            form={this.props.form}
                                            bonus={this.state.bonus}
                                            fsc_bonus={this.state.fscBonus}
                                            fsc_bonus_100={this.state.fscBonus100}
                                            onBonusChange={this.onBonusChange}
                                            onFscBonusChange={this.onFscBonusChange}
                                            onFscBonus100Change={this.onFscBonus100Change}
                                            amount1={this.state.amount1}
                                            amount2={this.state.amount2}
                                            amount3={this.state.amount3}
                                            amount4={this.state.amount4}
                                            bonus1={this.state.bonus1}
                                            bonus2={this.state.bonus2}
                                            bonus3={this.state.bonus3}
                                            bonus4={this.state.bonus4}
                                            onAmount1Change={this.onAmount1Change}
                                            onAmount2Change={this.onAmount2Change}
                                            onAmount3Change={this.onAmount3Change}
                                            onAmount4Change={this.onAmount4Change}
                                            onBonus1Change={this.onBonus1Change}
                                            onBonus2Change={this.onBonus2Change}
                                            onBonus3Change={this.onBonus3Change}
                                            onBonus4Change={this.onBonus4Change}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>

                            

                            <FormItem label="Sortimenta cenas">
                                <NewTableAttribute
                                    exportValue={this.setNewLength}
                                    buttonText="Pievienot garumu"
                                    modalTitle="Pievienot garumu"
                                    modalLabel="Garums (m)"
                                />

                                <NewTableAttribute
                                    exportValue={this.setNewDiameter}
                                    buttonText="Pievienot diametru"
                                    modalTitle="Pievienot diametru"
                                    modalLabel="Diametrs (cm)"
                                />

                                <FormItem>
                                    <Checkbox checked={!this.state.active} onChange={(e) => this.activeChange(e.target.checked)}>Nepērk</Checkbox>
                                </FormItem>

                                {this.state.lengths.length > 0 &&
                                    <div className="ant-table assortment-price-table">
                                        <table style={{ marginTop: 15 }}>
                                            <thead className="ant-table-thead">
                                                <tr>
                                                    <th></th>
                                                    {this.state.lengths.map(length => (
                                                        <th key={length}>
                                                            {length} m

                                                            <Popconfirm
                                                                placement="top"
                                                                title="Vai tiešām vēlāties dzēst šo garumu?"
                                                                onConfirm={() => this.onLengthDelete(length)}
                                                                okText="Jā"
                                                                cancelText="Nē"
                                                            >
                                                                <Icon type="close" style={{
                                                                    color: "#e74c3c",
                                                                    paddingLeft: 4,
                                                                    fontSize: 16,
                                                                    cursor: "pointer",
                                                                }}/>
                                                            </Popconfirm>
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody className="ant-table-tbody">
                                                {this.state.diameters.length > 0 &&
                                                    this.state.diameters.map(diameter => (
                                                        <PriceTableRow
                                                            key={diameter}
                                                            getFieldDecorator={getFieldDecorator}
                                                            onDiameterDelete={this.onDiameterDelete}
                                                            data={{
                                                                diameter,
                                                                lengths: this.state.lengths,
                                                                prices: this.state.priceData,
                                                                priceChange: this.priceChange,
                                                            }}
                                                        />
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                }
                            </FormItem>

                            <FormItem>
                                <Button type="primary" htmlType="submit">Saglabāt</Button>
                            </FormItem>
                        </Form>
                    </Card>
                }
            </PageContent>
        );
    }
}

const PriceTableRow = (props) => (
    <tr>
        <td>
            { props.data.diameter } cm
            <Popconfirm
                placement="top"
                title="Vai tiešām vēlāties dzēst šo diametru?"
                onConfirm={() => props.onDiameterDelete(props.data.diameter)}
                okText="Jā"
                cancelText="Nē"
            >
                <Icon type="close" style={{
                    color: "#e74c3c",
                    paddingLeft: 4,
                    fontSize: 16,
                    cursor: "pointer",
                }}/>
            </Popconfirm>
        </td>

        {props.data.lengths && props.data.lengths.map(length => (
            <td key={length}>
                <FormItem>
                    {props.getFieldDecorator(`assortment_price[length_${length.toString().replace(".", "#")}][diameter_${props.data.diameter.toString().replace(".", "#")}]`, {
                        rules: [{ required: true, message: "Lūdzu izvēlieties cenu!" }],
                        initialValue: getSizePrice(props.data.prices, length, props.data.diameter),
                    })(
                        <InputNumber
                            style={{ width: "100%" }}
                            onChange={(price) => props.data.priceChange(length, props.data.diameter, price)}
                        />,
                    )}
                </FormItem>
            </td>
        ))}
    </tr>
);

const getSizePrice = (prices: PriceData[], length, diameter) => {
    const priceData = prices.find(priceTemp => priceTemp.diameter === diameter && priceTemp.length === length);
    return priceData ? priceData.price : null;
};

export const EditAcceptancePointAssortment = Form.create()(EditAcceptancePointAssortmentForm as any);

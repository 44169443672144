import { Card, Checkbox, Divider, Form, message, Row, Switch } from "antd";
import axios from "axios";
import * as loadGoogleMapsApi from "load-google-maps-api";
import * as React from "react";
import { LabaledField } from "../../components/LabeledField";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { Helpers } from "../../utils/helpers";
import { POLYGON_STATE_COLORS, PolygonSateColor } from "../../utils/map_settings";
import { DEAL_TYPES, STATES, STATES_MIN } from "../EditFellingPoint";
import { AcceptancePointPricePage, PricePageAssortment } from "../PricePage";
import { flatten } from 'lodash'

interface MapRootProps {
    form: any;
    map: any;
    google: any;
    ruleIsValid(ruleId: any): boolean;
}

interface MapRootState {
    acceptancePoints: any[];
    fellingAreas: any[];
    dataLoaded: boolean;
    activeMarker: any;
    showingInfoWindow: boolean;
    selectedPlace: any;
    assortment: null | PricePageAssortment[];
    allChecked: boolean;
    showAcceptancePoints: boolean;
    filters: SortimentFilterGroup[];
    allSortimentFilters: Map<string, boolean>
    markers: any[];
    initMarkers: boolean;
    enteredGeoZoomLevel: boolean;
    filterTypeFellingAreas: FellingAreaType[];
    loadingText: string;
}

interface FellingAreaType {
    type: number;
    show: boolean;
}

interface SortimentFilterGroup {
    id: string;
    title: string;
    assortments: string[];
    show: boolean;
}

function getAssortmentCodeById(id: number, assortment: any[]){
    const matchingAssortment = assortment.find(assortmentItem => {
        return assortmentItem.id == id;
    });
    return matchingAssortment ? matchingAssortment.code : null;
}

const groupFilters: SortimentFilterGroup[] = [{
    id: "group_zb",
    title: "Zāģbaļķi",
    assortments: ['E zb', 'P zb', 'P zb1', 'P zb2', 'P zbA'],
    show: true,
}, {
    id: "group_sk_pm",
    title: "Skuju papīrmalka",
    assortments: ['E pm', 'P pm', 'SK pm'],
    show: true,
}, {
    id: "group_gulsnis",
    title: "Gulsnis",
    assortments: ['Gulsnis', 'Špala', 'P gulsnis', 'E gulsnis'],
    show: true,
}, {
    id: "group_berzs",
    title: "Bērzs",
    assortments: ['B fin. A', 'B fin. B', 'B fin. C', 'B pm'],
    show: true,
}, {
    id: "group_apse",
    title: "Apse",
    assortments: ['A zb', 'A zb II klase', 'A pm'],
    show: true,
}, {
    id: "group_lk",
    title: "Vērtīgie lapu koki",
    assortments: ['MAL', 'MAL II klase', 'O', 'OS', 'O zb1', 'O zb2', 'O zbA', 'A zb1', 'A zb2', 'A zbA', 'M zb1', 'M zb2', 'M zbA'],
    show: true,
}, {
    id: "group_sk_tara",
    title: "SK tara",
    assortments: ['SK tara'],
    show: true,
}, {
    id: "group_lk_tara",
    title: "LK tara",
    assortments: ['A tara', 'B tara', 'LK tara'],
    show: true,
}, {
    id: "group_low_value",
    title: "Mazvērtīgais",
    assortments: ['SK malka', 'A malka', 'AL pm', 'LK malka', 'JK malka', 'Ozola malka'],
    show: true,
}, {
    id: "group_parejie",
    title: "Pārējie",
    assortments: ['Mieti', 'Brāķis', 'ZM', 'E mieti', 'P mieti'],
    show: true,
}];

function initiateSortimentFilters(): Map<string, boolean>{
    return new Map(flatten(groupFilters.map(group => group.assortments)).map(sortimentCode => [sortimentCode, true] as [string, boolean]))
}

class MapRoot extends React.Component<MapRootProps, MapRootState> {
    map;
    infoWindow;
    googleMaps;
    markers: any[] = [];
    polygons: any[] = [];

    constructor(props) {
        super(props);

        this.state = {
            dataLoaded: false,
            acceptancePoints: [],
            fellingAreas: [],
            activeMarker: {},
            selectedPlace: {},
            showingInfoWindow: false,
            assortment: null,
            allChecked: true,
            filters: groupFilters,
            allSortimentFilters: initiateSortimentFilters(),
            markers: [],

            showAcceptancePoints: true,
            filterTypeFellingAreas: STATES_MIN.map(state => ({
                type: state.value,
                show: true,
            } as any)),

            initMarkers: false,
            enteredGeoZoomLevel: false,

            loadingText: "Ielādē sortimentus...",
        };
    }

    componentDidMount() {
        document.title = "Dižozols - Karte";

        this.fetchData(() => {
            // Load map
            loadGoogleMapsApi({
                key: process.env.CLIENT_GOOGLE_MAPS_API_KEY,
                language: "lv",
                region: "LV",
                libraries: ["drawing"],
                v: "3.33",
            }).then((googleMaps) => {
                this.googleMaps = googleMaps;

                this.map = new this.googleMaps.Map(document.querySelector(".map-selector"), {
                    center: {
                        lat: 56.9496487,
                        lng: 24.1051865,
                    },
                    zoom: 8,
                    mapTypeId: "satellite",
                });

                this.infoWindow = new this.googleMaps.InfoWindow({
                    size: new this.googleMaps.Size(150, 50),
                });

                this.googleMaps.event.addListener(this.map, "click", () => {
                    this.infoWindow.close();
                });

                google.maps.event.addListener(this.map, "zoom_changed", () => {
                    const zoomLevel = this.map.getZoom();
                    // Show geometry if reached this zoom level
                    if (zoomLevel >= 15) {
                        this.setState({ enteredGeoZoomLevel: true }, () => {
                            if (zoomLevel === 15) {
                                this.hideFellingPointMarkers();
                            }

                            this.drawDetailedFellingAreas();
                        });
                    } else {
                        if (this.state.enteredGeoZoomLevel) {
                            this.setState({ enteredGeoZoomLevel: false }, () => {
                                this.filterMapPlaces();
                                this.clearAllPolygons();
                                this.redrawMarkers();
                            });
                        }
                    }
                });

                this.drawMarkers();

                this.setState({ initMarkers: true });

            }).catch(_error => {
                message.error("Neizdevās ielādēt karti");
            });
        });
    }

    getStateName = (id) => {
        const stateObject = STATES.find(state => state.value === parseInt(id, 10));
        return stateObject ? stateObject.text : "-";
    }

    getDealTypeName = (id) => {
        const stateObject = DEAL_TYPES.find(state => state.value === parseInt(id, 10));
        return stateObject ? stateObject.text : "-";
    }

    drawMarkers = () => {
        this.drawAcceptancePointMarkers();
        this.drawFellingPointMarkers();
    }

    redrawMarkers = () => {
        this.drawAcceptancePointMarkers();

        this.state.filterTypeFellingAreas.map(filter => {
            if (!filter.show) {
                this.clearMarkersByState(filter.type);
            } else {
                this.showMarkersByState(filter.type);
            }
        });
    }

    drawDetailedFellingAreas = (drawOnlyPolygons = false) => {
        const bounds = this.map.getBounds();
        const sw = bounds.getSouthWest();
        const ne = bounds.getNorthEast();

        const left = sw.lng();
        const down = sw.lat();
        const right = ne.lng();
        const up = ne.lat();
        const centerLng = this.map.getCenter().lng();

        const leftHalf = new this.googleMaps.LatLngBounds(
          new this.googleMaps.LatLng(down, left),
          new this.googleMaps.LatLng(up, centerLng),
        );

        const rightHalf = new this.googleMaps.LatLngBounds(
          new this.googleMaps.LatLng(down, centerLng),
          new this.googleMaps.LatLng(up, right),
        );

        let tempFellingAreas = [ ...this.state.fellingAreas ];

        tempFellingAreas = tempFellingAreas.map(fellingArea => {
            const point = new this.googleMaps.LatLng(fellingArea.map_center_lat, fellingArea.map_center_lng);
            let isInArea = leftHalf.contains(point) || rightHalf.contains(point);
            const filterType = this.state.filterTypeFellingAreas.find(filter => filter.type === fellingArea.state);

            if (filterType && !filterType.show) {
                isInArea = false;
            }

            return {
                ...fellingArea,
                show: isInArea,
            };
        });

        this.setState({ fellingAreas: tempFellingAreas }, () => {
            this.drawFellingPointMarkers(drawOnlyPolygons);
        });
    }

    drawFellingPointMarkers = (drawOnlyPolygons = false) => {
        if (this.state.fellingAreas) {
            this.state.fellingAreas.filter(point => point.show).map(point => {
                const pointLatLang = new this.googleMaps.LatLng(point.map_center_lat, point.map_center_lng);
                // Otherwise just add marker to map

                if ((!this.state.enteredGeoZoomLevel && !drawOnlyPolygons) || point.geometry.length === 0) {
                    const marker = new this.googleMaps.Marker({
                        map: this.map,
                        position: pointLatLang,
                        clickable: true,
                        icon: {
                            url: `${process.env.baseUrl}/assets/images/icons/${point.state}.png`,
                        },
                    });

                    this.markers.push({
                        id: point.id,
                        type: "felling_area",
                        state: point.state,
                        marker,
                    });

                    google.maps.event.addListener(marker, "click", () => {
                        const paragraphStyle = "margin-bottom: 4px; font-size: 15px";
                        const contentString = `<div>
                            <h3>${point.felling_area_name}</h3>
                            <p style="${paragraphStyle}">Kadastra numurs: ${point.cadastre_number}</p>
                            <p style="${paragraphStyle}">Plānotie m3: ${point.planed_cubic_meter}</p>
                            <p style="${paragraphStyle}">Izstrādātie m3: ${point.develop_cubic_meter}</p>
                            <p style="${paragraphStyle}">Darījuma tips: ${this.getDealTypeName(point.deal_type)}</p>
                            <p style="${paragraphStyle}">Stadija: ${this.getStateName(point.state)}</p>
                            <p style="${paragraphStyle}">Sortiments: ${this.getAssortmentNameById(point.sortiment_id)}</p>
                            <p style="${paragraphStyle}">
                                <a href="/cenas/cirsmas/labot/${point.id}">
                                    Apskatit cirsmu
                                </a>
                            </p>
                        </div>`;

                        this.infoWindow.setContent(contentString);
                        this.infoWindow.open(this.map, marker);
                    });
                } else {
                    point.geometry.forEach(element => {
                        const stateSettings = POLYGON_STATE_COLORS[point.state] as PolygonSateColor;

                        if (stateSettings) {
                            const strokeColor = stateSettings.strokeColor;
                            const strokeWeight = 1;

                            const kdElement = new this.googleMaps.Polygon({
                                paths: element.coordinates,
                                fillColor: stateSettings.fillColor,
                                strokeColor,
                                strokeOpacity: 0.5,
                                fillOpacity: 0.4,
                                strokeWeight,
                            });

                            kdElement.setMap(this.map);

                            this.polygons.push({
                                id: point.id,
                                state: point.state,
                                polygon: kdElement,
                            });

                            const showArrays = (event) => {
                                const paragraphStyle = "margin-bottom: 4px; font-size: 15px";
                                const contentString = `<div>
                                    <h3>${point.felling_area_name}</h3>
                                    <p style="${paragraphStyle}">Kadastra numurs: ${point.cadastre_number}</p>
                                    <p style="${paragraphStyle}">Plānotie m3: ${point.planed_cubic_meter}</p>
                                    <p style="${paragraphStyle}">Izstrādātie m3: ${point.develop_cubic_meter}</p>
                                    <p style="${paragraphStyle}">Darījuma tips: ${this.getDealTypeName(point.deal_type)}</p>
                                    <p style="${paragraphStyle}">Kvartāls: ${element.kvartals}</p>
                                    <p style="${paragraphStyle}">Nogabals: ${element.nogabals}</p>
                                    <p style="${paragraphStyle}">
                                        <a href="/cenas/cirsmas/labot/${point.id}">
                                            Apskatit cirsmu
                                        </a>
                                    </p>
                                </div>`;

                                this.infoWindow.setContent(contentString);
                                this.infoWindow.setPosition(event.latLng);
                                this.infoWindow.open(this.map);
                            };

                            kdElement.addListener("click", showArrays);
                        }
                    });
                }
            });
        }
    }

    drawAcceptancePointMarkers = () => {
        if (this.state.acceptancePoints && this.state.showAcceptancePoints) {
            this.state.acceptancePoints.filter(point => !!point.show).forEach(point => {
                const pointLatLang = new this.googleMaps.LatLng(point.lat, point.lng);
                // Otherwise just add marker to map
                const marker = new this.googleMaps.Marker({
                    map: this.map,
                    position: pointLatLang,
                    clickable: true,
                    icon: {
                        url: `${process.env.baseUrl}/assets/images/icons/1.png`,
                    },
                });

                this.markers.push({
                    id: point.id,
                    type: "acceptance_point",
                    marker,
                });

                google.maps.event.addListener(marker, "click", () => {
                    const paragraphStyle = "margin-bottom: 4px; font-size: 15px";
                    const contentString = `<div>
                        <h3>${point.name}</h3>
                        <p style="${paragraphStyle}">Adrese: ${point.address}</p>
                        <p style="${paragraphStyle}">Plāns: ${point.plan}</p>
                        <p style="${paragraphStyle}">Pēdējās izmaiņas: ${Helpers.formatDateWithTime(point.updated_at)}</p>
                        <p style="${paragraphStyle}">
                            <a href="/cenas/acceptance_points/${point.id}/assortment">
                                Apskatit sortimentu
                            </a>
                        </p>
                    </div>`;

                    this.infoWindow.setContent(contentString);
                    this.infoWindow.open(this.map, marker);
                });
            });
        }
    }

    hideFellingPointMarkers = () => {
        this.markers.filter(marker => marker.type === "felling_area").forEach(point => {
            point.marker.setMap(null);
        });
    }

    clearAllPolygons = () => {
        this.polygons.map(point => {
            point.polygon.setMap(null);
        });

        this.polygons = [];
    }

    showAllAvailablePoints = () => {
        const tempFellingAreas = [ ...this.state.fellingAreas ];
        const modifiedFellingAreas = tempFellingAreas.map(acceptancePoint => ({
            ...acceptancePoint,
            show: true,
        }));

        this.setState({ fellingAreas: modifiedFellingAreas }, () => {
            
            this.state.fellingAreas.map(pointData => {
                const marker = this.markers.find(markerItem => markerItem.type === "felling_area" && markerItem.id === pointData.id);

                if (marker) {
                    if (pointData.show && this.isFellingPointGroupActive(marker.state)) {
                        if (!marker.marker.getMap()) {
                            marker.marker.setMap(this.map);
                        }
                    } else {
                        marker.marker.setMap(null);
                    }
                }
            });
        });
    }

    clearAllAvailablePoints = () => {
        const tempFellingAreas = [ ...this.state.fellingAreas ];
        const modifiedFellingAreas = tempFellingAreas.map(acceptancePoint => ({
            ...acceptancePoint,
            show: false,
        }));

        this.setState({ fellingAreas: modifiedFellingAreas }, () => {
            this.markers.filter(marker => marker.type === 'felling_area').map(point => {
                point.marker.setMap(null);
            });
        });
    }

    clearMarkersByState = (state) => {
        this.markers.filter(marker => marker.state === state).map(point => {
            point.marker.setMap(null);
        });
    }

    clearPolygonsByState = (state) => {
        this.polygons.filter(marker => marker.state === state).map(point => {
            point.polygon.setMap(null);
        });
    }

    showMarkersByState = (state) => {
        this.markers.filter(marker => marker.state === state && this.isFellingPointVisible(marker.id)).map(point => {
            point.marker.setMap(this.map);
        });
    }

    isFellingPointVisible = (markerId) => {
        const fellingArea = this.state.fellingAreas.find(pointData => pointData.id === markerId);
        return fellingArea ? fellingArea.show : true;
    }

    showPolygonsByState = (state) => {
        this.polygons.filter(marker => marker.state === state && this.isFellingPointVisible(marker.id)).map(point => {
            point.polygon.setMap(this.map);
        });
    }

    filterCheckedByType = (type) => {
        const tempFeelingAreaStates = [ ...this.state.filters ];
        const typeIndex = tempFeelingAreaStates.findIndex(state => state.id === type);

        if (typeIndex > -1) {
            return tempFeelingAreaStates[typeIndex].show;
        }

        return false;
    }

    // tslint:disable-next-line:cyclomatic-complexity
    showFellingAreaCategory(fellingArea): boolean{

        for(let [code, show] of Array.from(this.state.allSortimentFilters.entries())){
            if(show){
                if(['E zb', 'E pm', 'SK pm', 'SK tara', 'SK malka', 'Špala', 'Mieti', 'Brāķis', 'ZM'].includes(code)){
                    if(fellingArea.egle_type_value > Number.EPSILON){
                        return true
                    }
                }
                if(['P zb', 'P pm', 'SK pm', 'SK tara', 'SK malka', 'Špala', 'P gulsnis', 'Mieti', 'Brāķis', 'ZM'].includes(code)){
                    if(fellingArea.priede_type_value > Number.EPSILON){
                        return true
                    }
                }
                if(['B fin. A', 'B fin. B', 'B fin. C', 'B pm', 'B tara', 'LK tara', 'LK malka', 'Mieti', 'Brāķis', 'ZM'].includes(code)){
                    if(fellingArea.berzs_type_value > Number.EPSILON){
                        return true
                    }
                }
                if(['A zb', 'A zb II klase', 'A malka', 'A pm', 'LK tara', 'LK malka', 'Mieti', 'Brāķis', 'ZM'].includes(code)){
                    if(fellingArea.apse_type_value > Number.EPSILON){
                        return true
                    }
                }
                if(['MAL', 'MAL II klase', 'LK tara', 'LK malka', 'Mieti', 'Brāķis', 'ZM'].includes(code)){
                    if(fellingArea.melnalksnis_type_value > Number.EPSILON){
                        return true
                    }
                }
                if(['O', 'OS', 'LK tara', 'LK malka', 'Mieti', 'Brāķis', 'ZM'].includes(code)){
                    if(fellingArea.ozols_type_value > Number.EPSILON){
                        return true
                    }
                }
                if(['AL pm', 'LK tara', 'LK malka', 'Mieti', 'Brāķis', 'ZM'].includes(code)){
                    if(fellingArea.baltalksnis_type_value > Number.EPSILON){
                        return true
                    }
                }
                if(['ZM', 'Mieti', 'Brāķis']){
                    if(fellingArea.other_type_value > Number.EPSILON){
                        return true
                    } 
                }
            }
        }
        return false
    }

    fetchData = (cb) => {
        axios.get(`${process.env.baseUrl}/assortment`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(assortment => {
            if (assortment.status !== 200 || assortment.data.success === false) {
                throw new Error();
            }

            const assortmentData = assortment.data.data.map(assortmentItem => ({
                ...assortmentItem,
                show: true,
            }));

            this.setState({ assortment: assortmentData, loadingText: "Ielādē pieņemšanas punktus..." }, () => {
                // Load acceptance points
                axios.get(`${process.env.baseUrl}/api/map/acceptance_points`, {
                    headers: {
                        Authorization: Helpers.getSessionId(),
                    },
                })
                .then(acceptancePoints => {
                    if (acceptancePoints.status !== 200 || acceptancePoints.data.success === false) {
                        throw new Error();
                    }

                    const acceptancePointData = (acceptancePoints.data.data as AcceptancePointPricePage[]).map(acceptancePoint => ({
                        ...acceptancePoint,
                        show: acceptancePoint.assortments.some(item => this.showAssortmentInit(item, assortmentData)),
                    }));

                    this.setState({ acceptancePoints: acceptancePointData, loadingText: "Ielādē cirsmas..."  }, () => {
                        // Load acceptance points
                        axios.get(`${process.env.baseUrl}/api/felling_areas`, {
                            headers: {
                                Authorization: Helpers.getSessionId(),
                            },
                        })
                        .then(fellingAreas => {
                            if (fellingAreas.status !== 200 || fellingAreas.data.success === false) {
                                throw new Error();
                            }

                            const fellingAreasData = fellingAreas.data.data.map(assortmentItem => ({
                                ...assortmentItem,
                                show: this.showFellingAreaCategory(assortmentItem),
                            }));

                            this.setState({ fellingAreas: fellingAreasData, dataLoaded: true }, () => {
                                cb();
                            });
                        })
                        .catch(_error => {
                            message.error("Neizdevās ielādēt cirsmas");

                            this.setState({
                                dataLoaded: true,
                            });
                        });
                    });
                })
                .catch(_error => {
                    message.error("Neizdevās ielādēt sortimentu sarakstu");

                    this.setState({
                        dataLoaded: true,
                    });
                });
            });
        })
        .catch(_error => {
            message.error("Neizdevās ielādēt sortimentu sarakstu");

            this.setState({
                dataLoaded: true,
            });
        });
    }

    fetchFellingAreas = () => axios.get(`${process.env.baseUrl}/api/felling_areas`, {
        headers: {
            Authorization: Helpers.getSessionId(),
        },
    })

    fetchAssortment = () => axios.get(`${process.env.baseUrl}/assortment`, {
        headers: {
            Authorization: Helpers.getSessionId(),
        },
    })

    fetchAcceptancePoints = () => axios.get(`${process.env.baseUrl}/api/map/acceptance_points`, {
        headers: {
            Authorization: Helpers.getSessionId(),
        },
    })

    showAssortmentInit = (assortmentId, data) => {
        const filter = data.find(assortmentItem => assortmentItem.id === assortmentId);

        if (!filter) {
            return false;
        }

        return filter.show;
    }

    showAssortment = (assortmentId) => {
        if (!this.state.assortment) {
            return false;
        }

        const filter = this.state.assortment.find(assortmentItem => assortmentItem.id === assortmentId);

        if (!filter) {
            return false;
        }

        return filter.show;
    }

    setMapRef = (ref) => this.map = ref;

    onMarkerClick = (props, marker) =>
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
        })

    onMapClicked = () => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null,
            });
        }
    }

    toggleAllAssortment = (show: boolean) => {
        // let tempAssortment = [...this.state.assortment!];
        // tempAssortment = tempAssortment.map(item => ({
        //     ...item,
        //     show: e.target.checked,
        // }));
        let tempFilters = [...this.state.filters!];
        tempFilters = tempFilters.map(item => ({
            ...item,
            show: show,
        }));
        for(let key of Array.from(this.state.allSortimentFilters.keys())){
            this.state.allSortimentFilters.set(key, show)
        }

        this.setState({ allChecked: show, filters: tempFilters }, () => {
            this.filterMapPlaces()
        });
    }

    toggleAssortmentGroup(group: SortimentFilterGroup, show: boolean): void{
        group.assortments.forEach(sortiment => {
            this.state.allSortimentFilters.set(sortiment, show)
        })
        group.show = show;
        this.filterMapPlaces();
    }

    filterMapPlaces = () => {
        let tempAcceptancePoints = [...this.state.acceptancePoints] as any;
        let tempFellingAreas = [...this.state.fellingAreas] as any;

        if (this.state.acceptancePoints) {
            
            tempAcceptancePoints = tempAcceptancePoints.map(acceptancePoint => ({
                ...acceptancePoint,
                show: acceptancePoint.assortments.some(sortimentId => {
                    return this.state.allSortimentFilters.get(getAssortmentCodeById(sortimentId, this.state.assortment || []))
                }),
            }));

            tempAcceptancePoints.map(pointData => {
                const markerIndex = this.markers.findIndex(marker => marker.type === "acceptance_point" && marker.id === pointData.id);

                if (markerIndex > -1) {
                    if (pointData.show && this.state.showAcceptancePoints) {
                        if (!this.markers[markerIndex].marker.getMap()) {
                            this.markers[markerIndex].marker.setMap(this.map);
                        }
                    } else {
                        this.markers[markerIndex].marker.setMap(null);
                    }
                }
            });
        }

        if (this.state.fellingAreas) {
            tempFellingAreas = tempFellingAreas.map(acceptancePoint => ({
                ...acceptancePoint,
                show: this.showFellingAreaCategory(acceptancePoint),
            }));

            tempFellingAreas.map(pointData => {
                const marker = this.markers.find(markerItem => markerItem.type === "felling_area" && markerItem.id === pointData.id);

                if (marker) {
                    if (pointData.show && this.isFellingPointGroupActive(marker.state)) {
                        if (!marker.marker.getMap()) {
                            marker.marker.setMap(this.map);
                        }
                    } else {
                        marker.marker.setMap(null);
                    }
                }
            });
        }

        this.setState({
            acceptancePoints: tempAcceptancePoints,
            fellingAreas: tempFellingAreas,
        });
    }

    toggleAssortment = (filterGroup: SortimentFilterGroup, sortiment: PricePageAssortment, _show: boolean) => {
        this.state.allSortimentFilters.set(sortiment.code, _show)
        // filterGroup.assortments.set(sortiment.code, _show);
        //const tempAssortment = [...this.state.assortment!];
        //const assortmentIndex = tempAssortment.findIndex(assortmentItem => assortmentItem.id === assortmentId);
        //tempAssortment[assortmentIndex].show = !tempAssortment[assortmentIndex].show;

        // this.setState({ assortment: tempAssortment }, () => {
        this.filterMapPlaces();
        // });
    }

    filterFellingAreasByType = (checked, type) => {
        const tempFeelingAreaStates = [ ...this.state.filterTypeFellingAreas ];
        const typeIndex = tempFeelingAreaStates.findIndex(state => state.type === type);

        if (typeIndex > -1) {
            tempFeelingAreaStates[typeIndex].show = checked;

            if (!checked) {
                if (!this.state.enteredGeoZoomLevel) {
                    this.clearMarkersByState(type);
                } else {
                    this.clearPolygonsByState(type);
                }
            } else {
                if (!this.state.enteredGeoZoomLevel) {
                    this.showMarkersByState(type);
                } else {
                    this.drawDetailedFellingAreas();
                    this.showPolygonsByState(type);
                }
            }

            this.setState({ filterTypeFellingAreas: tempFeelingAreaStates });
        }
    }

    isFellingPointGroupActive = (type) => {
        const fellingAreaState = this.state.filterTypeFellingAreas.find(state => state.type === type);
        return fellingAreaState ? fellingAreaState.show : true;
    }

    getAssortmentNameById = (id) => {
        const assortment = this.state.assortment!.find(assortmentItem => assortmentItem.id === id);
        return assortment ? assortment.name : "-";
    }

    toggleAcceptancePoints(checked){
        this.state.acceptancePoints.forEach(acceptancePoint => {
            acceptancePoint.show = checked;
        });
        this.markers.filter(marker => marker.type === 'acceptance_point').map(point => {
            if(checked){
                point.marker.setMap(this.map);
            }else{
                point.marker.setMap(null);
            }
        });
        this.setState({showAcceptancePoints: checked}, () => {
            this.drawAcceptancePointMarkers();
        });
    }

    render() {
        if (!this.state.dataLoaded) {
            return (
                <LoadingIndicator loadingText={true} loadingTextPlaceholder={this.state.loadingText} verticalCenter={true}/>
            );
        }

        const mapWrapperStyle: any = {
            width: "100%",
            height: "calc(100% - 64px)",
            position: "absolute",
        };

        const filterBoxStyle: any = {
            position: "absolute",
            left: 30,
            bottom: 30,
            width: 350,
            zIndex: 9999,
        };

        const filterRowStyle: any = {
            marginBottom: 10,
        };

        const groupDropdownStyle: any = {
            borderLeft: "1px solid #c3c3c3",
            paddingLeft: 16,
            marginLeft: 7,
            marginTop: 6,
        };

        const assortmentPreScrollContent = <div>
            <Checkbox
                onChange={show => this.toggleAllAssortment(show.target.checked)}
                checked={this.state.allChecked}
            >
                Parādīt visus
            </Checkbox>

            <Divider style={{ marginBottom: 10, marginTop: 10 }}/>
        </div>;

        const getGroupAssortments = (group: SortimentFilterGroup) => {

            if (!group || !group.show) {
                return <div></div>;
            }

            return <div style={groupDropdownStyle}>
                {this.state.assortment!
                    .filter(assortment => group.assortments.indexOf(assortment.code.trim()) > - 1)
                    .map(assortmentValue => (
                        <Row key={assortmentValue.code} style={{ marginBottom: 5 }}>
                            <Checkbox
                                onChange={(e) => this.toggleAssortment(group, assortmentValue, e.target.checked)}
                                checked={this.state.allSortimentFilters.get(assortmentValue.code)}
                            >
                                {assortmentValue.name} - {assortmentValue.code}
                            </Checkbox>
                        </Row>
                    ))}
            </div>;
        };

        return (
            <div style={mapWrapperStyle}>
                <div className="map-selector" style={{ height: "calc(100vh - 64px)" }}></div>
                <div>
                    {this.props.ruleIsValid("Map:AllowFilters") && <div style={filterBoxStyle}>
                        <Card>
                            {STATES_MIN.map(state => (
                                <Row style={filterRowStyle} key={state.value}>
                                    <LabaledField title={state.text}>
                                        <Switch
                                            size="small"
                                            className={POLYGON_STATE_COLORS[state.value].switchClassName}
                                            defaultChecked
                                            onChange={checked => this.filterFellingAreasByType(checked, state.value)}
                                        />
                                    </LabaledField>
                                </Row>
                            ))}

                            <Row style={{ marginTop: 30 }}>
                                <LabaledField title="Pieņemšanas punkti">
                                    <Switch
                                        size="small"
                                        className="blueSwitch"
                                        checked={this.state.showAcceptancePoints}
                                        onChange={checked => this.toggleAcceptancePoints(checked)}
                                    />
                                </LabaledField>
                            </Row>
                            <Row>
                                <LabaledField title="" nextLine={true} maxHeight={400} preScrollContent={assortmentPreScrollContent}>
                                    {this.state.assortment && this.state.filters.map(filterGroup => (
                                            <Row key={filterGroup.id} style={{ marginBottom: 5 }}>
                                                <Checkbox
                                                    onChange={(show) => this.toggleAssortmentGroup(filterGroup, show.target.checked)}
                                                    checked={filterGroup.show}
                                                    className=""
                                                >
                                                    {filterGroup.title}
                                                </Checkbox>

                                                {getGroupAssortments(filterGroup)}
                                            </Row>
                                        ))
                                    }
                                </LabaledField>
                            </Row>
                        </Card>
                    </div>}
                </div>
            </div>
        );
    }
}

// tslint:disable-next-line:no-default-export
export default Form.create()(MapRoot as any);

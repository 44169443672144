import { Button, Checkbox, Form, Input, Modal, Row } from "antd";
import * as React from "react";

const FormItem = Form.Item;

const accessRules: any[] = [{
    groupId: "Pages",
    type: "group",
    label: "Sadaļas",
    subRules: [{
        ruleId: "Pages:AcceptancePoints",
        label: "Pieņemšanas punkti",
        type: "rule",
        subRules: [{
            ruleId: "AcceptancePoints:NewPoint",
            label: "Pievienot pieņemšanas punktu",
            type: "rule",
        }, {
            ruleId: "AcceptancePoints:EditPoint",
            label: "Labot pieņemšanas punktu",
            type: "rule",
        }, {
            ruleId: "AcceptancePoints:DeletePoint",
            label: "Dzēst pieņemšanas punktu",
            type: "rule",
        }, {
            ruleId: "AcceptancePoints:ViewAssortment",
            label: "Apskatīt pieņemšanas punkta sortimentus",
            type: "rule",
        }, {
            ruleId: "AcceptancePoints:NewAssortment",
            label: "Pievienot pieņemšanas punkta sortimentus",
            type: "rule",
        }, {
            ruleId: "AcceptancePoints:EditAssortment",
            label: "Labot pieņemšanas punkta sortimentus",
            type: "rule",
        }, {
            ruleId: "AcceptancePoints:ViewAssortmentHistory",
            label: "Apskatīt pieņemšanas punkta sortimentu vēsturi",
            type: "rule",
        }, {
            ruleId: "AcceptancePoints:ViewBonuses",
            label: "Apskatīt/Labot piemaksas",
            type: "rule",
        }],
    },
    {
        ruleId: "Pages:Matrix",
        label: "Matrica",
        type: "rule",
        subRules: [],
    },
    {
        ruleId: "Pages:Map",
        label: "Karte",
        type: "rule",
        subRules: [{
            ruleId: "Map:AllowFilters",
            label: "Rādīt kartes filtrus",
            type: "rule",
        }],
    }, {
        ruleId: "Pages:FellingAreas",
        label: "Cirsmas",
        type: "rule",
        subRules: [{
            ruleId: "FellingAreas:NewFellingArea",
            label: "Pievienot cirsmu",
            type: "rule",
        }, {
            ruleId: "FellingAreas:EditFellingArea",
            label: "Labot cirsmas datus",
            type: "rule",
        }, {
            ruleId: "FellingAreas:DeleteFellingArea",
            label: "Dzēst cirsmas datus",
            type: "rule",
        }],
    }, {
        ruleId: "Pages:PricePage",
        label: "Cenu lapa",
        type: "rule",
        subRules: [{
            ruleId: "PricePage:PricePageAllowSearch",
            label: "Atļaut norādīt atrašanās vietu",
            type: "rule",
        }, {
            ruleId: "PricePage:AllowIncludeBonuses",
            label: "Atļaut apskatīt cenas ar bonusiem",
            type: "rule",
        }, {
            ruleId: "PricePage:ExtraPriceAllow",
            label: "Atļaut norādīt zāgēšanas un ievešanas izmaksas",
            type: "rule",
        }, {
            ruleId: "PricePage:HighlightHighestPrice",
            label: "Atļaut izcelt augstāko cenu",
            type: "rule",
        }, {
            ruleId: "PricePage:ShowOnlyChecked",
            label: "Atļaut filtrēt tikai atzīmētos punktus",
            type: "rule",
        }, {
            ruleId: "PricePage:ShowTransportExpenses",
            label: "Atļaut apskatīt transporta izmaksas",
            type: "rule",
        }, {
            ruleId: "PricePage:AllowCoefficient",
            label: "Atļaut apskatīt cenas ar uzmērījuma koeficientu",
            type: "rule",
        }, {
            ruleId: "PricePage:AllowFSC100Bonus",
            label: "Atļaut apskatīt cenas ar FSC 100% piemaksu",
            type: "rule",
        }, {
            ruleId: "PricePage:AllowFSCBonus",
            label: "Atļaut apskatīt cenas ar FSC piemaksu",
            type: "rule",
        }, {
            ruleId: "PricePage:AllowAcceptancePointBonus",
            label: "Atļaut apskatīt cenas ar pieņemšanas punkta piemaksu",
            type: "rule",
        }],
    }, {
        ruleId: "Pages:PricePageClient",
        label: "Cenu lapa klientam",
        type: "rule",
        subRules: [{
            ruleId: "PricePageClient:PricePageAllowSearch",
            label: "Atļaut norādīt atrašanās vietu",
            type: "rule",
        }, {
            ruleId: "PricePageClient:AllowIncludeBonuses",
            label: "Atļaut apskatīt cenas ar bonusiem",
            type: "rule",
        }, {
            ruleId: "PricePageClient:ExtraPriceAllow",
            label: "Atļaut norādīt zāgēšanas un ievešanas izmaksas",
            type: "rule",
        }, {
            ruleId: "PricePageClient:HighlightHighestPrice",
            label: "Atļaut izcelt augstāko cenu",
            type: "rule",
        }, {
            ruleId: "PricePageClient:ShowOnlyChecked",
            label: "Atļaut filtrēt tikai atzīmētos punktus",
            type: "rule",
        }, {
            ruleId: "PricePageClient:ShowTransportExpenses",
            label: "Atļaut apskatīt transporta izmaksas",
            type: "rule",
        }, {
            ruleId: "PricePageClient:AllowCoefficient",
            label: "Atļaut apskatīt cenas ar uzmērījuma koeficientu",
            type: "rule",
        }, {
            ruleId: "PricePageClient:AllowFSC100Bonus",
            label: "Atļaut apskatīt cenas ar FSC 100% piemaksu",
            type: "rule",
        }, {
            ruleId: "PricePageClient:AllowFSCBonus",
            label: "Atļaut apskatīt cenas ar FSC piemaksu",
            type: "rule",
        }, {
            ruleId: "PricePageClient:AllowAcceptancePointBonus",
            label: "Atļaut apskatīt cenas ar pieņemšanas punkta piemaksu",
            type: "rule",
        }],
    }],
}];

// Not best solution but google maps are not working so well for react, yet
export interface AcceptencePointFormProps {
    visible: boolean;
    modalTitle: string;
    modalOkText: string;
    onCancel(): void;
    onCreate(): void;
    saveFormRef(ref: any): void;
    isProcessingDatabase: boolean;
    modalInitdata: any;
}

interface AcceptencePointFormState {
    ruleSets: string[];
}

export class SecuritRulesModal extends React.Component<AcceptencePointFormProps, AcceptencePointFormState> {
    map;
    form;

    constructor(props) {
        super(props);

        this.state = {
            ruleSets: this.props.modalInitdata ? this.props.modalInitdata.rules || [] : [],
        };
    }

    setMapRef = (ref) => this.map = ref;

    componentWillUnmount() {
        this.form.access_rules = this.state.ruleSets;
    }

    setFormRef = (ref) => {
        this.form = ref;
        this.props.saveFormRef(ref);
    }

    ruleEnabled = (ruleId) => this.state.ruleSets.findIndex(rule => rule === ruleId) > -1;

    addRule = (ruleId) => {
        const tempRuleSets = [...this.state.ruleSets];
        tempRuleSets.push(ruleId);

        this.setState({ ruleSets: tempRuleSets });
    }

    removeRule = (ruleId) => {
        const tempRuleSets = [...this.state.ruleSets];
        const ruleIndex = tempRuleSets.findIndex(rule => rule === ruleId);
        tempRuleSets.splice(ruleIndex, 1);

        this.setState({ ruleSets: tempRuleSets });
    }

    render() {
        return <AcceptencePointForm
            {...this.props}
            setFormRef={this.setFormRef}
            addRuleSet={this.addRule}
            haveRuleEnabled={this.ruleEnabled}
            removeRule={this.removeRule}
        />;
    }
}

const AcceptencePointForm = Form.create()((props: any) => {
    const { visible, onCancel, onCreate, form, modalTitle, setFormRef, modalOkText,
            isProcessingDatabase, modalInitdata, addRuleSet, haveRuleEnabled, removeRule } = props;
    const { getFieldDecorator } = form;

    setFormRef(form);

    const checkBoxHandler = (e) => {
        if (e.target.checked) {
            addRuleSet(e.target.value);
        } else {
            removeRule(e.target.value);
        }
    };

    const generateAccessRules = (rules): any =>
        rules.map(rule => {
            const content = [] as any[];

            if (rule.type === "group") {
                if (rule.subRules) {
                    content.push(generateAccessRules(rule.subRules));
                }
            } else {
                content.push(<Row>
                    <Checkbox
                        key={rule.ruleId}
                        value={rule.ruleId}
                        checked={haveRuleEnabled(rule.ruleId)}
                        onChange={checkBoxHandler}
                    >
                        {rule.label}
                    </Checkbox>
                </Row>);

                if (rule.subRules && haveRuleEnabled(rule.ruleId)) {
                    content.push(<div style={{
                        margin: "5px 0px 5px 7px",
                        paddingLeft: 18,
                        borderLeft: "1px solid #b6b6b6",
                    }}>{generateAccessRules(rule.subRules)}</div>);
                }
            }

            return content;
        });

    return (
        <Modal
            wrapClassName="vertical-center-modal"
            visible={visible}
            title={modalTitle}
            destroyOnClose={true}
            width={750}
            maskClosable={false}
            onCancel={onCancel}
            footer={[
                <Button key="back" onClick={onCancel}>Atcelt</Button>,
                <Button key="submit" type="primary" loading={isProcessingDatabase} onClick={onCreate}>
                    {modalOkText}
                </Button>,
            ]}
        >
            <Form layout="vertical">
                <FormItem label="Nosaukums">
                    {getFieldDecorator("name", {
                        rules: [{
                            required: true,
                            message: "Lūdzu norādiet nosaukumu!",
                        }],
                        initialValue: modalInitdata ? modalInitdata.name : null,
                    })(<Input/>)}
                </FormItem>
                <FormItem label="Pieejas">
                    {generateAccessRules(accessRules)}
                </FormItem>
            </Form>
        </Modal>
    );
});

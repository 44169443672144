import { Button, Dropdown, Icon, Input, Menu, message, Modal, Table, Tabs, Tooltip } from "antd";
import axios from "axios";
import * as React from "react";
import { FOREST_EXPERT_TYPES } from "../../../shared/constants";
import { PageContent } from "../../components/PageContent/index";
import { TABLE_TRANSLATIONS, USER_TYPES } from "../../utils/constants";
import { Helpers } from "../../utils/helpers";
import { UserData } from "./userData";

interface AcceptancePointsState {
    isLoading: boolean;
    isProcessingDatabase: boolean;
    modalVisible: boolean;
    users: Users[];
    editModePointId: null | number;
    securityGroups: any[];
    canAddUser: boolean;
    searchKeyword: string;
    userType: string;
}

export interface Users {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    username: string;
    last_login: string | null;
    access_group: number;
    forest_page_access: boolean;
    type: string;
    mezueksperts_tips: string;
}

const confirm = Modal.confirm;
const TabPane = Tabs.TabPane;
const Search = Input.Search;

export class UsersPage extends React.Component<{}, AcceptancePointsState> {
    formRef;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isProcessingDatabase: false,
            editModePointId: null,
            modalVisible: false,
            users: [],
            securityGroups: [],
            canAddUser: true,
            searchKeyword: "",
            userType: "pricePage",
        };
    }

    componentDidMount() {
        this.fetchSecurityRules();
        this.fetchUsers();
        document.title = "Lietotāji";
    }

    saveFormRef = (formRef) => this.formRef = formRef;

    fetchSecurityRules = () => {
        axios.get(`${process.env.baseUrl}/api/admin/security_rules`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            if (response.data.success === false) {
                message.error("Neizdevās pieeju grupas");
            }

            this.setState({
                securityGroups: response.data.data,
            });

        })
        .catch(() => {
            message.error("Neizdevās pieeju grupas");
        });
    }

    showModal = () =>
        this.setState({ modalVisible: true })

    handleCancel = () =>
        this.setState({ modalVisible: false, editModePointId: null }, () => {
            this.formRef.resetFields();
        })

    deleteUser = (userId: number) => {
        this.setState({
            isProcessingDatabase: true,
            isLoading: true,
        }, () => {
            axios.delete(`${process.env.baseUrl}/api/admin/users/${userId}`, {
                headers: {
                    Authorization: Helpers.getSessionId(),
                },
            })
            .then(response => {
                if (response.data.success === false) {
                    message.error("Neizdevās dzēst lietotāju");
                }

                this.setState({
                    isProcessingDatabase: false,
                    isLoading: false,
                    users: this.state.users.filter(user => user.id !== userId),
                });
            })
            .catch(() => {
                message.error("Neizdevās dzēst lietotāju");
            });
        });
    }

    fetchUsers = () => {
        this.setState({ isLoading: true }, () => {
            axios.get(`${process.env.baseUrl}/api/admin/users`, {
                headers: {
                    Authorization: Helpers.getSessionId(),
                },
            })
            .then(response => {
                if (response.data.success === false) {
                    return this.setState({ isLoading: false }, () => {
                        message.error("Neizdevās ielādēt lietotājus");
                    });
                }

                this.setState({
                    isLoading: false,
                    users: response.data.data,
                });

            })
            .catch(() => {
                message.error("Neizdevās ielādēt lietotājus");
            });
        });
    }

    tabNameToType = (tab) => {
        switch (tab) {
            case "pricePage":
                return USER_TYPES.PRICE_PAGE;
            case "forestPage":
                return USER_TYPES.FOREST_PAGE;
            case "logisticPage":
                return USER_TYPES.LOGISTICS_PAGE;
            default:
                return "";
        }
    }

    handleCreate = () => {
        const form = this.formRef;

        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            this.setState({ isProcessingDatabase: true, modalVisible: false }, () => {
                // If we need to update point
                if (this.state.editModePointId) {
                    return axios.put(`${process.env.baseUrl}/api/admin/users`, {
                        id: this.state.editModePointId,
                        userType: this.tabNameToType(this.state.userType),
                        ...values,
                    }, {
                        headers: {
                            Authorization: Helpers.getSessionId(),
                        },
                    })
                    .then(response => {
                        if (response.data.success === false) {
                            return message.error("Neizdevās saglabāt lietotāju");
                        }

                        form.resetFields();

                        const currentUsers = [...this.state.users];
                        const userIndex = currentUsers.findIndex(currentPoint => currentPoint.id === this.state.editModePointId);

                        currentUsers[userIndex] = {
                            ...currentUsers[userIndex],
                            first_name: values.first_name,
                            last_name: values.last_name,
                            email: values.email,
                            username: values.username,
                            access_group: values.access_group,
                            type: this.tabNameToType(this.state.userType),
                        };

                        return this.setState({
                            isProcessingDatabase: false,
                            modalVisible: false,
                            editModePointId: null,
                            users: currentUsers,
                        });
                    })
                    .catch(error => {
                        this.setState({ isProcessingDatabase: false }, () => {
                            Helpers.handleAPIError(error, "Neizdevās saglabāt lietotāju");
                        });
                    });
                } else {
                    return axios.post(`${process.env.baseUrl}/api/admin/users`, {
                        userType: this.tabNameToType(this.state.userType),
                        ...values,
                    }, {
                        headers: {
                            Authorization: Helpers.getSessionId(),
                        },
                    })
                    .then(response => {
                        if (response.data.success === false) {
                            message.error("Neizdevās saglabāt lietotāju");
                        }

                        form.resetFields();

                        this.setState({
                            isProcessingDatabase: false,
                            users: [...this.state.users, {
                                id: response.data.pointId,
                                first_name: values.first_name,
                                last_name: values.last_name,
                                email: values.email,
                                username: values.username,
                                last_login: null,
                                access_group: values.access_group,
                                type: this.tabNameToType(this.state.userType),
                                forest_page_access: false,
                                mezueksperts_tips: "",
                            }],
                        });
                    })
                    .catch(error => {
                        this.setState({ isProcessingDatabase: false }, () => {
                            Helpers.handleAPIError(error, "Neizdevās saglabāt lietotāju");
                        });
                    });
                }
            });
        });
    }

    editUser = (pointId: number) => {
        this.setState({ editModePointId: pointId }, () => {
            this.showModal();
        });
    }

    showDeleteConfirmPopup = (acceptancePointId) => {
        // tslint:disable-next-line:no-this-assignment
        const self = this;

        confirm({
            title: "Dzēst lietotāju",
            content: "Vai tiešām vēlaties dzēst šo lietotāju?",
            okType: "danger",
            cancelText: "Atcelt",
            okText: "Dzēst",
            onOk() {
                self.deleteUser(acceptancePointId);
            },
          });
    }

    forestExpertType = (type) => {
        const typeObject = FOREST_EXPERT_TYPES.find(expertType => expertType.value === type);
        return typeObject ? typeObject.text : "Nezināms";
    }

    formatLastLoginDate = (date, noDataPlaceholder = "-") => {
        if (!date) {
            return <i>{noDataPlaceholder}</i>;
        }

        return <Tooltip placement="top" title={Helpers.formatTimeAgo(date)}>
            {Helpers.formatDateWithTime(date)}
        </Tooltip>;
    }

    onTabChange = (type) => {
        this.setState({
            userType: type,
        });
    }

    setSearchKeyword = (search) => {
        this.setState({
            searchKeyword: search,
        });
    }

    filterUsers = (data: Users[]) => {
        if (this.state.searchKeyword.length === 0) {
            return data;
        }

        return data.filter(data =>
            data.first_name.indexOf(this.state.searchKeyword) > -1 ||
            data.last_name.indexOf(this.state.searchKeyword) > -1 ||
            data.email.indexOf(this.state.searchKeyword) > -1,
        );
    }

    render() {
        const pageContentHeaderActions = <div>
            <Button size="large" onClick={this.showModal}>
                <Icon type="plus" style={{ paddingRight: 5 }}/> Pievienot lietotāju
            </Button>
        </div>;

        const pageContentProps = {
            headerTitle: "Lietotāji",
            headerActions: this.state.canAddUser ? pageContentHeaderActions : null,
        };

        const pricePageUsers = this.filterUsers(this.state.users.filter(user => user.type === USER_TYPES.PRICE_PAGE));
        const forestPageUsers = this.filterUsers(this.state.users.filter(user => user.type === USER_TYPES.FOREST_PAGE));
        const logisticUsers = this.filterUsers(this.state.users.filter(user => user.type === USER_TYPES.LOGISTICS_PAGE));

        const itemMoreMenu = (record) => (
            <Menu>
                <Menu.Item>
                    <a onClick={() => this.editUser(record.id)}><Icon type="edit" /> Labot lietotāju</a>
                </Menu.Item>
                <Menu.Item>
                    <a onClick={() => this.showDeleteConfirmPopup(record.id)}><Icon type="delete" /> Dzēst lietotāju</a>
                </Menu.Item>
            </Menu>
        );

        const getGroupName = (groupId) => {
            if (!this.state.securityGroups) {
                return "-";
            }

            const groupData = this.state.securityGroups.find(group => group.id === groupId);
            return groupData ? groupData.name : "-";
        };

        const forestPageColumns = [{
            title: "Vārds",
            dataIndex: "first_name",
            key: "first_name",
        }, {
            title: "Uzvārds",
            dataIndex: "last_name",
            key: "last_name",
        }, {
            title: "Lietotājvārds",
            dataIndex: "username",
            key: "username",
            editable: true,
        }, {
            title: "Meža eksperta id",
            dataIndex: "new_mezuekspertsid",
            key: "new_mezuekspertsid",
        }, {
            title: "Tips",
            dataIndex: "mezueksperts_tips",
            key: "mezueksperts_tips",
            render: (type) => (
                this.forestExpertType(type)
            ),
        }, {
            title: "Pēdējā pieslēgšanās",
            dataIndex: "last_login",
            key: "last_login",
            render: (last_login) => (
                this.formatLastLoginDate(last_login, "Nav pieslēdzies")
            ),
        }, {
            title: "",
            dataIndex: "operation",
            key: "operation",
            width: 80,
            render: (_text, record) => (
                <Dropdown overlay={itemMoreMenu(record)} placement="bottomRight">
                    <Icon type="ellipsis" style={{ fontSize: 18 }}/>
                </Dropdown>
            ),
        }];

        const logisticsColumns = [{
            title: "Vārds",
            dataIndex: "first_name",
            key: "first_name",
        }, {
            title: "Uzvārds",
            dataIndex: "last_name",
            key: "last_name",
        }, {
            title: "E-pasts",
            dataIndex: "email",
            key: "email",
        }, {
            title: "Lietotājvārds",
            dataIndex: "username",
            key: "username",
        }, {
            title: "Automašīnas numurs",
            dataIndex: "car_number",
            key: "car_number",
        }, {
            title: "Pēdējā pieslēgšanās",
            dataIndex: "last_login",
            key: "last_login",
            render: (last_login) => (
                this.formatLastLoginDate(last_login)
            ),
        }, {
            title: "",
            dataIndex: "operation",
            key: "operation",
            width: 80,
            render: (_text, record) => (
                <Dropdown overlay={itemMoreMenu(record)} placement="bottomRight">
                    <Icon type="ellipsis" style={{ fontSize: 18 }}/>
                </Dropdown>
            ),
        }];

        const pricePageColumns = [{
            title: "Vārds",
            dataIndex: "first_name",
            key: "first_name",
        }, {
            title: "Uzvārds",
            dataIndex: "last_name",
            key: "last_name",
        }, {
            title: "E-pasts",
            dataIndex: "email",
            key: "email",
        }, {
            title: "Lietotājvārds",
            dataIndex: "username",
            key: "username",
        }, {
            title: "Pieejas",
            dataIndex: "access_group",
            key: "access_group",
            render: (text) => (
                <span>{getGroupName(text)}</span>
            ),
        }, {
            title: "Pēdējā pieslēgšanās",
            dataIndex: "last_login",
            key: "last_login",
            render: (last_login) => (
                this.formatLastLoginDate(last_login)
            ),
        }, {
            title: "",
            dataIndex: "operation",
            key: "operation",
            width: 80,
            render: (_text, record) => (
                <Dropdown overlay={itemMoreMenu(record)} placement="bottomRight">
                    <Icon type="ellipsis" style={{ fontSize: 18 }}/>
                </Dropdown>
            ),
        }];

        const operations = <div style={{ padding: "10px 10px" }}>
            <Search
                placeholder="meklēt lietotāju"
                onSearch={this.setSearchKeyword}
                style={{ width: 200 }}
            />
        </div>;

        return (
            <div>
                <PageContent {...pageContentProps}>

                    <Tabs defaultActiveKey="pricePage" animated={false} className="fullTabs" onChange={this.onTabChange} tabBarExtraContent={operations}>
                        <TabPane tab={`Cenu lapa (${pricePageUsers.length})`} key="pricePage">
                            <Table
                                dataSource={pricePageUsers}
                                columns={pricePageColumns}
                                loading={this.state.isLoading}
                                pagination={false}
                                size="middle"
                                locale={TABLE_TRANSLATIONS}
                                style={{ marginTop: 15 }}
                            />
                        </TabPane>
                        <TabPane tab={`Meža lapa (${forestPageUsers.length})`} key="forestPage">
                            <Table
                                dataSource={forestPageUsers}
                                columns={forestPageColumns}
                                loading={this.state.isLoading}
                                pagination={false}
                                size="middle"
                                locale={TABLE_TRANSLATIONS}
                                style={{ marginTop: 15 }}
                            />
                        </TabPane>
                        <TabPane tab={`Loģistika (${logisticUsers.length})`} key="logisticPage">
                            <Table
                                dataSource={logisticUsers}
                                columns={logisticsColumns}
                                loading={this.state.isLoading}
                                pagination={false}
                                size="middle"
                                locale={TABLE_TRANSLATIONS}
                                style={{ marginTop: 15 }}
                            />
                        </TabPane>
                    </Tabs>

                    {this.state.modalVisible && <UserData
                        visible={this.state.modalVisible}
                        userType={this.state.userType}
                        isProcessingDatabase={this.state.isProcessingDatabase}
                        saveFormRef={this.saveFormRef}
                        onCancel={this.handleCancel}
                        onCreate={this.handleCreate}
                        securityGroups={this.state.securityGroups}
                        modalTitle={this.state.editModePointId
                            ? "Labot lietotāja datus"
                            : "Pievienot jaunu lietotāju"}
                        modalOkText={this.state.editModePointId
                            ? "Labot"
                            : "Pievienot"}
                        modalInitdata={this.state.editModePointId
                            ? this.state.users.find(point => point.id === this.state.editModePointId)
                            : null}
                    />}
                </PageContent>
            </div>
        );
    }
}

import { Badge, Checkbox, Tooltip } from "antd";
import * as classNames from "classnames";
import * as React from "react";
import { getPriceByDistance } from "../../constants/distance-expenses";
import { BonusesFields, FilterFields } from "../../containers/AssortmentPage";
import { AcceptancePointPricePage, PricePageAssortment } from "../../containers/PricePage";
import { Helpers } from "../../utils/helpers";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { NavLink } from "react-router-dom";

interface AssortmentPricePageProps {
    acceptancePoints: AcceptancePointPricePage[];
    assortment: PricePageAssortment[];
    bonuses: BonusesFields;
    distanceFilter: number[];
    highlightHighestPrice: boolean;
    checkPoint(a: boolean, b: string): void;
    showOnlyChecked: boolean;
    filterFields: FilterFields;
    ruleIsValid(ruleId: any): boolean;
    allChecked: boolean;
    onAllChecked(allChecked: boolean): void;
}

export class AssortmentPricePage extends React.Component<AssortmentPricePageProps, {}> {

    constructor(props) {
        super(props);
    }

    kindColumnFormat = (assortmentItem) => {
        const assortmentLengths = assortmentItem.assortments.reduce((totalLengths, assortmentItemR) =>
            totalLengths + assortmentItemR.lengths.length, 0);

        const assortmentDiameters = assortmentItem.assortments.reduce((totalDiameters, assortmentItemR) => {
            const diametersForLength = assortmentItemR.lengths.reduce((totalDiametersR, assortmentItemRD) =>
            totalDiametersR + assortmentItemRD.diameters.length, 0);
            return totalDiameters + diametersForLength;
        }, 0);

        const assortments = assortmentItem.assortments.length + 1;
        const spanSize = assortmentLengths + assortmentDiameters + assortments;
        return assortmentItem.assortments.length > 0 ? { rowSpan: spanSize } : { colSpan: spanSize  };
    }

    lengthColumnFormat = (assortmentData) => ({
        rowSpan: assortmentData.diameters.length + 1,
    })

    checkPoints = (e, hash) => {
        this.props.checkPoint(e.target.checked, hash);
    }

    assortmentColumnFormat = (assortmentItem) => {
        const diametersForLength = assortmentItem.lengths.reduce((totalDiametersR, assortmentItemRD) =>
            totalDiametersR + assortmentItemRD.diameters.length, 0);
        const lengths = assortmentItem.lengths.length + 1;
        const rowSpan = diametersForLength + lengths;

        return { rowSpan };
    }

    checkAllPoints(e: CheckboxChangeEvent){
        this.props.onAllChecked(e.target.checked);
    }
    

    render() {
        let acceptancePointsFilteredByMaxAmount = this.props.acceptancePoints;
        
        const renderTableContent = () => {
            if (acceptancePointsFilteredByMaxAmount.length === 0) {
                return <tr key="table-content-no-data">
                    <td colSpan={7} style={{ textAlign: "center" }}>Nav datu</td>
                </tr>;
            }

            return acceptancePointsFilteredByMaxAmount.map((assortmentItem, i) => (
                <React.Fragment>
                    <tr>
                        <td {...this.kindColumnFormat(assortmentItem)} className="centeredText">
                            {assortmentItem.kind}
                        </td>
                    </tr>

                    {assortmentItem.assortments.map((assortmentData, i) => (
                        <React.Fragment>
                            <tr>
                                <td {...this.assortmentColumnFormat(assortmentData)} className="centeredText">
                                    {assortmentData.assortment}
                                </td>
                            </tr>

                            {assortmentData.lengths && assortmentData.lengths.map((assortmentDataLengths, i) => (
                                    <React.Fragment>
                                        <tr>
                                            <td {...this.lengthColumnFormat(assortmentDataLengths)} className="centeredText">
                                                {assortmentDataLengths.length.toFixed(1)}m
                                            </td>
                                        </tr>

                                        {assortmentDataLengths.diameters && assortmentDataLengths.diameters.map((assortmentDataDiameters, index) => (
                                            <tr className={classNames({
                                                "highest-price": index === 0 && this.props.highlightHighestPrice,
                                            })}>
                                                <td className="centeredText">
                                                    {assortmentDataDiameters.diameter.toFixed(2)} - {assortmentDataDiameters.diameterMax.toFixed(2)}
                                                </td>

                                                {this.props.filterFields.transportCost &&
                                                    <td className="centeredText">
                                                        €{getPriceByDistance((assortmentDataDiameters as any).distance).toFixed(2)}
                                                        {assortmentDataDiameters.calculationError && <Badge status="error" style={{ marginLeft: 5 }}/>}
                                                    </td>
                                                }

                                                <td className="centeredText">
                                                    <Tooltip placement="top" title={`Pēdējās izmaiņas: ${Helpers.formatTimeAgo(assortmentDataDiameters.updated_at)}`}>
                                                        €{assortmentDataDiameters.price.toFixed(2)}

                                                        {assortmentDataDiameters.calculationError && <Badge status="error" style={{ marginLeft: 5 }}/>}
                                                    </Tooltip>
                                                </td>
                                                <td>
                                                    <NavLink to={`/cenas/acceptance_points/${assortmentDataDiameters.id}/assortment`} target="_blank">
                                                        {assortmentDataDiameters.name}
                                                    </NavLink>
                                                </td>
                                                {(!this.props.showOnlyChecked && this.props.ruleIsValid("PricePage:ShowOnlyChecked")) && <td style={{ textAlign: "center" }}>
                                                    <Checkbox
                                                        checked={assortmentDataDiameters.checked}
                                                        onChange={(e) => this.checkPoints(e, assortmentDataDiameters.hash)}
                                                    />
                                                </td>}
                                            </tr>
                                        ))}
                                    </React.Fragment>
                            ))}
                        </React.Fragment>
                    ))}
                </React.Fragment>
            ));
        };

        return (
            <table className="price-table">
                <thead>
                    <tr>
                        <th>Suga</th>
                        <th>Sortiments</th>
                        <th>Garums, m</th>
                        <th>Diametrs, cm</th>
                        {this.props.filterFields.transportCost && <th>Transporta izmaksas</th>}
                        <th>Cena</th>
                        <th>Piezīmes</th>
                        {(!this.props.showOnlyChecked && this.props.ruleIsValid("PricePage:ShowOnlyChecked")) && <th>
                            <Checkbox checked={this.props.allChecked} onChange={(e) => this.checkAllPoints(e)}></Checkbox>
                        </th>}
                    </tr>
                </thead>
                <tbody>
                    {renderTableContent()}
                </tbody>
            </table>
        );
    }
}

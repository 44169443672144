import * as React from "react";
import { Scrollbars } from "react-custom-scrollbars";

interface LabaledFieldProps {
    title?: string | null;
    description?: string | null;
    large?: boolean;
    nextLine?: boolean;
    maxHeight?: number | null;
    preScrollContent?: any;
}

export class LabaledField extends React.Component<LabaledFieldProps, {}> {

    static defaultProps = {
        title: null,
        description: null,
        large: false,
        nextLine: false,
        maxHeight: null,
        preScrollContent: null,
    };

    render() {
        const sizes = this.props.nextLine ? {
            leftContainer: "100%",
            rightContainer: "100%",
        } : this.props.large ? {
            leftContainer: "65%",
            rightContainer: "35%",
        } : {
            leftContainer: "85%",
            rightContainer: "15%",
        };

        return (
            <div className="labeledField">
                <div className="titleWrapper" style={{ width: sizes.leftContainer }}>
                    <span className="labeledFieldTitle">{this.props.title}</span>
                    {this.props.description && <span className="labeledFieldDescription">{this.props.description}</span>}
                </div>
                <div
                    className="content"
                    style={{
                        width: sizes.rightContainer,
                        textAlign: this.props.nextLine ? "left" : "right",
                        marginTop: this.props.nextLine ? 7 : 0,
                    }}
                >
                    {this.props.preScrollContent && this.props.preScrollContent}

                    {this.props.maxHeight && this.props.maxHeight > 0 ?
                        <Scrollbars style={{ height: this.props.maxHeight }}>
                            {this.props.children}
                        </Scrollbars>
                    : this.props.children}
                </div>
            </div>
        );
    }
}

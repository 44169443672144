import { Badge, Checkbox, Select, Tooltip } from "antd";
import * as classNames from "classnames";
import * as React from "react";
import * as uniqid from "uniqid";
import { AcceptancePointAssortmentPageV2 } from "../../containers/AssortmentPage";
import { BonusesFields, FilterFields } from "../../containers/AssortmentPage/index";
import { Helpers } from "../../utils/helpers";
import { getPriceByDistance } from "../../constants/distance-expenses";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { NavLink } from "react-router-dom";

interface AssortmentPricePageProps {
    acceptancePoints: AcceptancePointAssortmentPageV2[];
    bonuses: BonusesFields;
    distanceFilter: number[];
    assortmentLimitPerDiameter: number;
    highlightHighestPrice: boolean;
    checkPoint(a: boolean, b: string): void;
    showOnlyChecked: boolean;
    changeComment(a: any, b: any): void;
    filterFields: FilterFields;
    ruleIsValid(ruleId: any): boolean;
    allChecked: boolean;
    onAllChecked(allChecked: boolean): void;
}

const groupKinds = [{
    code: "egle",
    title: "Egle",
}, {
    code: "priede",
    title: "Priede",
}, {
    code: "apse",
    title: "Apse",
}, {
    code: "berzs",
    title: "Bērzs",
}, {
    code: "baltalksnis",
    title: "Baltalksnins",
}, {
    code: "melnalksnis",
    title: "Melnalksnins",
}, {
    code: "ozols",
    title: "Ozols",
}, {
    code: "osis",
    title: "Osis",
}];

const comments = [{
    id: "0",
    name: "",
}, {
    id: "1",
    name: "Nodalīt no tievā sortimenta",
}, {
    id: "2",
    name: "Nodalīt no resnā sortimenta",
}, {
    id: "3",
    name: "Nelikt kopā ar lapu koku sortimentu",
}, {
    id: "4",
    name: "Nelikt kopā ar skuju koku sortimentu",
}];

const Option = Select.Option;

export class AssortmentPageTable extends React.Component<AssortmentPricePageProps, {}> {

    constructor(props) {
        super(props);
    }

    kindColumnFormat = (assortmentItem: AcceptancePointAssortmentPageV2) => {
        const assortmentLengths = assortmentItem.diameters.length;
        const assortmentDiameters = assortmentItem.diameters.reduce((totalLengths, assortmentItemR) =>
        totalLengths + assortmentItemR.lengths.length, 0);
        const spanSize = assortmentLengths + assortmentDiameters + 1;

        return { rowSpan: spanSize };
    }

    lengthColumnFormat = (assortmentData) => ({
        rowSpan: assortmentData.lengths.length + 1,
    })

    assortmentColumnFormat = (assortmentItem) => {
        const diametersForLength = assortmentItem.lengths.reduce((totalDiametersR, assortmentItemRD) =>
            totalDiametersR + assortmentItemRD.diameters.length, 0);
        const lengths = assortmentItem.lengths.length + 1;
        const rowSpan = diametersForLength + lengths;

        return { rowSpan };
    }

    getKindTitle = (code) => {
        const kindTitleObject = groupKinds.find(kind => kind.code === code);
        return kindTitleObject ? kindTitleObject.title : code;
    }

    checkPoints = (e, hash) => {
        this.props.checkPoint(e.target.checked, hash);
    }

    checkAllPoints(e: CheckboxChangeEvent){
        this.props.onAllChecked(e.target.checked);
    }

    render() {
        const acceptancePoints = this.props.acceptancePoints;

        const renderTableContent = () => {
            if (acceptancePoints.length === 0) {
                return <tr key="table-content-no-data">
                    <td colSpan={7} style={{ textAlign: "center" }}>Nav datu</td>
                </tr>;
            }

            return acceptancePoints.map(assortmentItem => (
                <React.Fragment key={assortmentItem.kind}>
                    <tr>
                        <td {...this.kindColumnFormat(assortmentItem)} className="centeredText">
                            {this.getKindTitle(assortmentItem.kind)}
                        </td>
                    </tr>

                    {assortmentItem.diameters.map(diameterData => (
                        <React.Fragment key={uniqid()}>
                            <tr>
                                <td {...this.lengthColumnFormat(diameterData)} className="centeredText">
                                    {diameterData.min} - {diameterData.max}
                                </td>
                            </tr>

                            {diameterData.lengths && diameterData.lengths.slice(0, this.props.assortmentLimitPerDiameter)
                                .map((assortmentDataDiameters, index) => (
                                    <tr key={uniqid(assortmentDataDiameters.name)} className={classNames({
                                        "stripe-bg": index % 2,
                                        "highest-price": index === 0 && this.props.highlightHighestPrice,
                                    })}>
                                        <td className="centeredText">
                                            {assortmentDataDiameters.length}
                                        </td>
                                        {this.props.filterFields.transportCost &&
                                            <td className="centeredText">
                                                €{getPriceByDistance((assortmentDataDiameters as any).distance).toFixed(2)}
                                                {assortmentDataDiameters.calculationError && <Badge status="error" style={{ marginLeft: 5 }}/>}
                                            </td>
                                        }
                                        <td className="centeredText">
                                            <Tooltip placement="top" title={`Pēdējās izmaiņas: ${Helpers.formatTimeAgo(assortmentDataDiameters.updated_at)}`}>
                                                €{assortmentDataDiameters.calculatedPrice.toFixed(2)}

                                                {assortmentDataDiameters.calculationError && <Badge status="error" style={{ marginLeft: 5 }}/>}
                                            </Tooltip>
                                        </td>
                                        <td>
                                            <NavLink to={`/cenas/acceptance_points/${assortmentDataDiameters.id}/assortment`} target="_blank">
                                                {assortmentDataDiameters.name}
                                            </NavLink>
                                        </td>
                                        <td>
                                            {assortmentDataDiameters.assortmentCode}
                                        </td>

                                        {(!this.props.showOnlyChecked && this.props.ruleIsValid("AssortmentPage:ShowOnlyChecked")) && <td style={{ textAlign: "center" }}>
                                            <Checkbox
                                                checked={assortmentDataDiameters.checked}
                                                onChange={(e) => this.checkPoints(e, assortmentDataDiameters.hash)}
                                            />
                                        </td>}

                                        {(this.props.showOnlyChecked && this.props.ruleIsValid("AssortmentPage:ShowOnlyChecked")) && <td style={{ textAlign: "center", width: 270 }} className="comment-select-column">
                                            <Select
                                                defaultValue={assortmentDataDiameters.comment || "0"}
                                                style={{ width: 270, float: "left" }}
                                                onChange={(value) => this.props.changeComment(value, assortmentDataDiameters.hash)}
                                            >
                                                {comments.map(comment => (
                                                    <Option value={comment.id} key={comment.id}>
                                                        {comment.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </td>}
                                    </tr>
                            ))}
                        </React.Fragment>
                    ))}
                </React.Fragment>
            ));
        };

        return (
            <table className="price-table">
                <thead>
                    <tr>
                        <th>Suga</th>
                        <th>Diametrs, cm</th>
                        <th>Garums, m</th>
                        {this.props.filterFields.transportCost && <th>Transporta izmaksas</th>}
                        <th>Cena</th>
                        <th>Piezīmes</th>
                        <th>Sortiments</th>
                        {(!this.props.showOnlyChecked && this.props.ruleIsValid("AssortmentPage:ShowOnlyChecked")) && <th>
                            <Checkbox checked={this.props.allChecked} onChange={(e) => this.checkAllPoints(e)}></Checkbox>
                        </th>}
                        {(this.props.showOnlyChecked && this.props.ruleIsValid("AssortmentPage:ShowOnlyChecked")) && <th>Komentārs</th>}
                    </tr>
                </thead>
                <tbody>
                    {renderTableContent()}
                </tbody>
            </table>
        );
    }
}

import { Layout } from "antd";
import * as React from "react";
import cookie from "react-cookies";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { GlobalHeader } from "../../components/GlobalHeader";
import { AcceptancePointAssortment } from "../AcceptancePointAssortment";
import { CreateAcceptancePointAssortment } from "../AcceptancePointAssortment/create";
import { EditAcceptancePointAssortment } from "../AcceptancePointAssortment/edit";
import { AcceptancePoints } from "../AcceptancePoints";
import Matrix from "../Matrix";
import { ApiSettings } from "../ApiSettings";
import AssortmentPage from "../AssortmentPage";
import CreateFellingPoints from "../CreateFellingPoint";
import { DynamicsImportLogs } from "../DynamicsImportLogs";
import EditFellingPoint from "../EditFellingPoint";
import { FellingPoints } from "../FellingPoints";
import FullMap from "../FullMap";
import Map from "../Map";
import PricePage from "../PricePage";
import PricePageClient from "../PricePageClient";
import { SecurityRules } from "../SecurityRules";
import { UsersPage } from "../UsersPage";
const { Header, Content } = Layout;

interface LayoutState {
    homepageRedirect: boolean;
}

interface LayoutProps {
    activeUser: any;
    resetAppState(): void;
    redirectPath: string
}

export class LayoutWrapper extends React.Component<LayoutProps, LayoutState> {

    constructor(props) {
        super(props);
    }

    onMenuClick = (event) => {
        // Delete current bundle
        switch (event.key) {
            case "logout":
                cookie.remove("ozols-prices-token");
                this.props.resetAppState();
                break;
            default:
        }
    }

    ruleIsValid = (ruleId) => this.props.activeUser.accessRules.findIndex(rule => rule === ruleId) > -1;

    render() {
        return (
            <Router>
                
                {this.props.redirectPath ? <Redirect to={this.props.redirectPath}></Redirect> : null}
                <Layout style={{height: "100vh"}}>
                    <Header style={{ padding: "0 10%"}}>
                        <GlobalHeader
                            activeUser={this.props.activeUser}
                            ruleIsValid={this.ruleIsValid}
                            onMenuClick={this.onMenuClick}
                        />
                    </Header>
                    <Content>
                        <Switch>
                            <Route path="/cenas/acceptance_points/:pointId/assortment/edit/:assortmentId" component={routeProps => <EditAcceptancePointAssortment {...routeProps} ruleIsValid={this.ruleIsValid}/>}/>
                            <Route path="/cenas/matrix" component={routeProps => <Matrix {...routeProps} ruleIsValid={this.ruleIsValid}/>}/>
                            <Route path="/cenas/acceptance_points/:pointId/assortment/create" component={routeProps => <CreateAcceptancePointAssortment {...routeProps} ruleIsValid={this.ruleIsValid}/>}/>
                            <Route path="/cenas/acceptance_points/:pointId/assortment" component={routeProps => <AcceptancePointAssortment {...routeProps} ruleIsValid={this.ruleIsValid}/>}/>
                            <Route path="/cenas/acceptance_points" component={routeProps => <AcceptancePoints {...routeProps} ruleIsValid={this.ruleIsValid}/>}/>
                            <Route path="/cenas/cirsmas/labot/:fellingAreaId" component={routeProps => <EditFellingPoint {...routeProps} ruleIsValid={this.ruleIsValid}/>}/>
                            <Route path="/cenas/cirsmas/create" component={routeProps => <CreateFellingPoints {...routeProps} ruleIsValid={this.ruleIsValid}/>}/>
                            <Route path="/cenas/cirsmas/:pageNumber" component={routeProps => <FellingPoints {...routeProps} ruleIsValid={this.ruleIsValid}/>}/>
                            <Route path="/cenas/cirsmas" component={routeProps => <FellingPoints {...routeProps} ruleIsValid={this.ruleIsValid}/>}/>
                            <Route path="/cenas/price_page" component={routeProps => <PricePage {...routeProps} ruleIsValid={this.ruleIsValid}/>}/>
                            <Route path="/cenas/price_page_client" component={routeProps => <PricePageClient {...routeProps} ruleIsValid={this.ruleIsValid}/>}/>
                            <Route path="/cenas/assortment_page" component={routeProps => <AssortmentPage {...routeProps} ruleIsValid={this.ruleIsValid}/>}/>
                            <Route path="/cenas/admin/users" component={routeProps => <UsersPage {...routeProps} ruleIsValid={this.ruleIsValid}/>}/>
                            <Route path="/cenas/admin/security_rules" component={routeProps => <SecurityRules {...routeProps} ruleIsValid={this.ruleIsValid}/>}/>
                            <Route path="/cenas/admin/api_settings" component={routeProps => <ApiSettings {...routeProps} ruleIsValid={this.ruleIsValid}/>}/>
                            <Route path="/cenas/admin/dynamics/logs" component={routeProps => <DynamicsImportLogs {...routeProps} ruleIsValid={this.ruleIsValid}/>}/>
                            <Route path="/cenas/map" component={routeProps => <Map {...routeProps} ruleIsValid={this.ruleIsValid}/>}/>
                            <Route path="/cenas/audita_ipasumi" component={routeProps => <FullMap {...routeProps} ruleIsValid={this.ruleIsValid}/>}/>
                            <Route exact path="/cenas" component={routeProps => <AcceptancePoints {...routeProps} ruleIsValid={this.ruleIsValid}/>}/>
                            <Route exact path="/" component={routeProps => <AcceptancePoints {...routeProps} ruleIsValid={this.ruleIsValid}/>}/>
                        </Switch>
                    </Content>
                </Layout>
            </Router>
        );
    }
}

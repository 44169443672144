import { DISTANCE_EXPENSES as DistanceExpenses } from '../../shared/constants';
 
export const getPriceByDistance = (distance) => {
    const distanceInKm = Math.round(distance / 1000);
    const distanceExpense = DistanceExpenses.find(distanceRange =>
        distanceRange.minDistance <= distanceInKm && distanceRange.maxDistance >= distanceInKm);

    if (!distanceExpense) {
        return 0;
    }

    return distanceExpense.price;
};

import * as classNames from "classnames";
import * as React from "react";

export class PaginationWrapper extends React.PureComponent<{ marginTop?: boolean }, {}> {
    render() {
      const wrapperClass = classNames({
        paginationWrapper: true,
        paginationWrapperMarginTop: !!this.props.marginTop,
      });

      return (
        <div className={wrapperClass}>
            {this.props.children}
        </div>
      );
    }
}

import { Alert, Button, Col, Divider, Icon, message, Modal, Row, Timeline, Tooltip, Form, Checkbox } from "antd";
import axios from "axios";
import * as React from "react";
import { NavLink } from "react-router-dom";
import { PageContent } from "../../components/PageContent/index";
import { Helpers } from "../../utils/helpers";
import { Points } from "../AcceptancePoints/index";
import { HistoryDataItems, AssortmentHistory } from "../../components/AssortmentHistory";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

function nullCoalesce(...args){
    for(let arg of args){
        if(arg !== null && arg !== undefined){
            return arg;
        }
    }
    return null;
}

interface AcceptancePointAssortmentState {
    pointId: number;
    isLoading: boolean;
    pointAssortmentData: null | any[];
    historyModalVisible: boolean;
    historyModalTitle: null | string;
    historyData: HistoryDataItems[];
    pointData: null | Points;

    showBonusHistory: boolean;
    showPriceHistory: boolean;
}

export class AcceptancePointAssortment extends React.Component<{ ruleIsValid(ruleId: any): boolean }, AcceptancePointAssortmentState> {
    formRef;

    constructor(props) {
        super(props);

        this.state = {
            pointId: props.match.params.pointId,
            isLoading: false,
            pointAssortmentData: null,
            pointData: null,
            // History modal
            historyModalVisible: false,
            historyModalTitle: null,
            historyData: [],

            showBonusHistory: true,
            showPriceHistory: true,
        };
    }

    componentDidMount() {
        this.fetchPointAssortment();
        this.fetchPoint();
    }

    priceIndexName = (data) => `${data.diameter}_${data.length}`;

    fetchPoint = () => axios.get(`${process.env.baseUrl}/api/acceptance_points_fetch/${this.state.pointId}`)
        .then(response => {
            if (response.data.success === false) {
                message.error("Neizdevās ielādēt pieņemšanas punktu");
            }

            this.setState({
                pointData: response.data.data,
            });
        }).catch(() => {
            message.error("Neizdevās ielādēt pieņemšanas punktu");
        })

    fetchPointAssortment = () => axios.get(`${process.env.baseUrl}/api/acceptance_point/assortment/${this.state.pointId}/fetch`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            if (response.data.success === false) {
                message.error("Neizdevās ielādēt pieņemšanas punkta sortimentu");
            }

            const tempData: any[] = [];

            response.data.data.forEach(dataItem => {
                const sortimentIndex = tempData.findIndex(tempDataItem => tempDataItem.sortiment_id === dataItem.sortiment_id);
                // If sortiment already exist
                if (sortimentIndex > -1) {
                    const diameter = parseFloat(dataItem.diameter);

                    if (tempData[sortimentIndex].diameters.indexOf(diameter) === -1) {
                        tempData[sortimentIndex].diameters.push(diameter);
                        tempData[sortimentIndex].diameters.sort((a, b) => a - b);
                    }
                    const length = parseFloat(dataItem.length);
                    if (tempData[sortimentIndex].lengths.indexOf(length) === -1) {
                        tempData[sortimentIndex].lengths.push(length);
                        tempData[sortimentIndex].lengths.sort((a, b) => a - b);
                    }
                    tempData[sortimentIndex].prices = {
                        ...tempData[sortimentIndex].prices,
                        [this.priceIndexName(dataItem)]: parseFloat(dataItem.price),
                    };
                    tempData[sortimentIndex].updates = {
                        ...tempData[sortimentIndex].updates,
                        [this.priceIndexName(dataItem)]: dataItem.updated_at,
                    };

                    // Check cached time
                    if (givenTimeIsGreaterThanCached(dataItem.updated_at, tempData[sortimentIndex].latest_update)) {
                        tempData[sortimentIndex].latest_update = dataItem.updated_at;
                    }
                } else {
                    tempData.push({
                        sortiment_id: dataItem.sortiment_id,
                        sortiment_name: dataItem.sortiment_name,
                        sortiment_code: dataItem.sortiment_code,
                        bonus: dataItem.bonus,
                        fsc_bonus: dataItem.fsc_bonus,
                        fsc_bonus_100: dataItem.fsc_bonus_100,
                        amount1: nullCoalesce(dataItem.amount_1, 500),
                        amount2: nullCoalesce(dataItem.amount_2, 1000),
                        amount3: nullCoalesce(dataItem.amount_3, 5000),
                        amount4: nullCoalesce(dataItem.amount_4, 10000),
                        bonus1: dataItem.bonus_1 || 0,
                        bonus2: dataItem.bonus_2 || 0,
                        bonus3: dataItem.bonus_3 || 0,
                        bonus4: dataItem.bonus_4 || 0,
                        latest_update: dataItem.updated_at,
                        diameters: [parseFloat(dataItem.diameter)],
                        lengths: [parseFloat(dataItem.length)],
                        active: dataItem.active,
                        prices: {
                            [this.priceIndexName(dataItem)]: parseFloat(dataItem.price),
                        },
                        updates: {
                            [this.priceIndexName(dataItem)]: dataItem.updated_at,
                        },
                    });
                }
            });

            this.setState({
                isLoading: true,
                pointAssortmentData: tempData,
            });
        })
        .catch(error => {
            Helpers.handleAPIError(error, "Neizdevās ielādēt pieņemšanas punkta sortimentu");
        })

    showHistory = (assortmentId: number) => {
        axios.get(`${process.env.baseUrl}/acceptance_point/${this.state.pointId}/assortment/${assortmentId}/history`)
            .then(response => {
                if (response.data.success === false) {
                    message.error("Neizdevās ielādēt sortimenta vēsturi");
                }

                this.setState({ historyData: response.data.data }, () => {
                    this.setState({
                        historyModalVisible: true,
                        historyModalTitle: "Vēsture",
                    });
                });
            }).catch(() => {
                message.error("Neizdevās ielādēt sortimenta vēsturi");
            });
    }

    handleCancel = () => {
        this.setState({ historyModalVisible: false });
    }


    /* Filter out history logs that match current filter */
    filterHistoryData(historyData: HistoryDataItems[]): HistoryDataItems[]{
        return historyData.map(historyDataItem => ({
            ...historyDataItem,
            history_log: historyDataItem.history_log.filter(historyItem => {
                switch(historyItem.type){
                    case 'new_value':
                    case 'updated_value':
                        return this.state.showPriceHistory;
                    case 'updated_bonus':
                    case 'updated_fsc_bonus':
                    case 'updated_fsc_bonus_100':
                    case 'updated_bonus_500':
                    case 'updated_bonus_1000':
                    case 'updated_bonus_5000':
                    case 'updated_bonus_10000':
                    case 'updated_bonus_1':
                    case 'updated_bonus_2':
                    case 'updated_bonus_3':
                    case 'updated_bonus_4':
                    case 'updated_amount_1':
                    case 'updated_amount_2':
                    case 'updated_amount_3':
                    case 'updated_amount_4':
                        return this.state.showBonusHistory;
                    default:
                        return false;
                }
            })
        })).filter(historyDataItem => historyDataItem.history_log.length);
    }

    toggleBonusHistory = (e: CheckboxChangeEvent) => {
        this.setState({
            showBonusHistory: e.target.checked,
        });
    }

    togglePriceHistory = (e: CheckboxChangeEvent) => {
        this.setState({
            showPriceHistory: e.target.checked,
        });
    }

    render() {
        const pageContentHeaderActions = <div>
            {this.props.ruleIsValid("AcceptancePoints:NewAssortment") &&
                <NavLink
                    to={`/cenas/acceptance_points/${this.state.pointId}/assortment/create`}
                    className="ant-btn ant-btn-lg"
                >
                    <Icon type="plus" style={{ paddingRight: 5 }}/> Pievienot sortimentu
                </NavLink>}
        </div>;

        const pageContentProps = {
            headerTitle: this.state.pointData ? `Sortiments - ${this.state.pointData.name}` : null,
            headerActions: pageContentHeaderActions,
        };

        if (this.state.isLoading && (!this.state.pointAssortmentData || this.state.pointAssortmentData.length === 0)) {
            return (
                <PageContent {...pageContentProps}>
                    <Alert message="Nav datu" type="info" />
                </PageContent>
            );
        }

        return (
            <PageContent {...pageContentProps}>
                {this.state.isLoading && this.state.pointAssortmentData &&
                    <div>
                        {this.props.ruleIsValid("AcceptancePoints:ViewBonuses") && this.state.pointData &&
                            <div style={{ color: "#9b9b9b", fontSize: "16px" }}>
                                <span style={{ marginRight: 15 }}>Plāns: <strong>{this.state.pointData.plan} m<sup>3</sup></strong></span>
                                <span>Piemaksa: <strong>{this.state.pointData.bonus}%</strong></span>
                            </div>
                        }

                        {this.state.pointAssortmentData.map(pointAssortment => (
                            <div style={{ marginTop: 50 }} key={pointAssortment.sortiment_name}>
                                <Row>
                                    <Col span={18} style={{ lineHeight: "24px" }}>
                                        <h3 style={{ marginLeft: 0, display: "inline-block" }}>{pointAssortment.sortiment_name} - {pointAssortment.sortiment_code}</h3>
                                        {!pointAssortment.active && <span style={{ color: "#ff0000", fontWeight: 300, paddingLeft: 10 }}>Nepērk</span>}
                                        <div style={{ display: "inline-block" }}>
                                            <Divider type="vertical" />
                                            <table style={{ color: "#9b9b9b", fontWeight: 300, display: 'inline-block', verticalAlign: 'top'}}>
                                                <tr>
                                                    <td style={{textAlign: 'end'}}>Piemaksa:</td>
                                                    <td style={{paddingRight: 10}}><strong>{Helpers.formatToMoney(pointAssortment.bonus)}€</strong></td>
                                                    <td style={{textAlign: 'end'}}>FSC Piemaksa:</td>
                                                    <td style={{paddingRight: 10}}><strong>{Helpers.formatToMoney(pointAssortment.fsc_bonus)}€</strong></td>
                                                    <td style={{textAlign: 'end'}}>FSC Piemaksa 100%:</td>
                                                    <td style={{paddingRight: 10}}><strong>{Helpers.formatToMoney(pointAssortment.fsc_bonus_100)}€</strong></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>   
                                                <tr>
                                                    <td style={{textAlign: 'end'}}>Apjoms {pointAssortment.amount1}:</td>
                                                    <td style={{paddingRight: 10}}><strong>{Helpers.formatToMoney(pointAssortment.bonus1)}€</strong></td>
                                                    <td style={{textAlign: 'end'}}>Apjoms {pointAssortment.amount2}:</td>
                                                    <td style={{paddingRight: 10}}><strong>{Helpers.formatToMoney(pointAssortment.bonus2)}€</strong></td>
                                                    <td style={{textAlign: 'end'}}>Apjoms {pointAssortment.amount3}:</td>
                                                    <td style={{paddingRight: 10}}><strong>{Helpers.formatToMoney(pointAssortment.bonus3)}€</strong></td>
                                                    <td style={{textAlign: 'end'}}>Apjoms {pointAssortment.amount4}:</td>
                                                    <td style={{paddingRight: 10}}><strong>{Helpers.formatToMoney(pointAssortment.bonus4)}€</strong></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </Col>
                                    <Col span={2} style={{ lineHeight: "25px" }}>
                                        <Icon type="clock-circle-o" /> {Helpers.formatDateWithTime(pointAssortment.latest_update)}
                                    </Col>
                                    <Col span={4} style={{ textAlign: "right" }}>
                                        {this.props.ruleIsValid("AcceptancePoints:EditAssortment") &&
                                            <NavLink
                                                className="ant-btn ant-btn-sm"
                                                to={`/cenas/acceptance_points/${this.state.pointId}/assortment/edit/${pointAssortment.sortiment_id}`}
                                            >
                                                Labot sortimentu
                                            </NavLink>
                                        }
                                        {this.props.ruleIsValid("AcceptancePoints:ViewAssortmentHistory") &&
                                            <Button size="small" style={{ marginLeft: 5 }} onClick={() => this.showHistory(pointAssortment.sortiment_id)}>Vēsture</Button>
                                        }
                                    </Col>
                                </Row>

                                <div className="ant-table assortment-price-table">
                                    <table style={{ marginTop: 15 }}>
                                        <thead className="ant-table-thead">
                                            <tr>
                                                <th></th>
                                                {pointAssortment.lengths.map(length => (
                                                    <th key={length}>{length} m</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody className="ant-table-tbody">
                                            {pointAssortment.diameters.length > 0 &&
                                                pointAssortment.diameters.map(diameter => (
                                                    <PriceTableRow
                                                        key={diameter}
                                                        data={{
                                                            diameter,
                                                            pointAssortment,
                                                        }}
                                                    />
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ))}
                    </div>
                }
                <Modal
                    visible={this.state.historyModalVisible}
                    title={this.state.historyModalTitle}
                    onCancel={this.handleCancel}
                    footer={[]}
                    width={900}
                >
                    {this.state.historyData.length === 0 ? 
                    <Alert message="Nav ierakstu" type="info" /> :
                    <React.Fragment>
                        <p> Filtri </p>
                        <Row style={{ marginBottom: '1em' }}>
                            <Col span={12}>
                                <Checkbox 
                                    onChange={this.toggleBonusHistory} 
                                    checked={this.state.showBonusHistory}
                                    value="showBonusHistory">
                                    Rādīt piemaksu vēsturi
                                </Checkbox>
                            </Col>
                            <Col span={12}>
                                <Checkbox 
                                    onChange={this.togglePriceHistory} 
                                    checked={this.state.showPriceHistory} 
                                    value="showPriceHistory">
                                    Rādīt cenu vēsturi
                                </Checkbox>
                            </Col>
                        </Row>
                        <Row>
                            <AssortmentHistory historyData={this.filterHistoryData(this.state.historyData)} />
                        </Row>
                    </React.Fragment>}
                </Modal>
            </PageContent>
        );
    }
}

const PriceTableRow = (props) => (
    <tr>
        <td>{ props.data.diameter } cm</td>

        {props.data.pointAssortment.lengths && props.data.pointAssortment.lengths.map(length => (
            <td key={length}>
                <Tooltip title={getPriceLastUpdate(props.data.pointAssortment, length, props.data.diameter)}>
                    {getSizePrice(props.data.pointAssortment, length, props.data.diameter)} €
                </Tooltip>
            </td>
        ))}
    </tr>
);

const getSizePrice = (pointAssortment, length, diameter) =>
    pointAssortment.prices[`${diameter}_${length}`];

const getPriceLastUpdate = (pointAssortment, length, diameter) =>
    `Pēdējās izmaiņas: ${Helpers.formatDateWithTime(pointAssortment.updates[`${diameter}_${length}`])}`;

const givenTimeIsGreaterThanCached = (givenTime, cachedTime) => {
    const date1 = new Date(givenTime);
    const date2 = new Date(cachedTime);

    return date1.getTime() > date2.getTime();
};

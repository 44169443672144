import * as React from "react";
import * as ReactDOM from "react-dom";
import { App } from "./components/App";

import "./assets/styles/base.scss";

const rootEl = document.getElementById("root");
ReactDOM.render(<App/>, rootEl);

// Hot Module Replacement API
declare let module: {
    hot: any;
};

if (module.hot) {
    module.hot.accept(() => {
        ReactDOM.render(<App/>, rootEl);
    });
}

import { Button, Card, Col, Form, Icon, Input, message, Popover, Row, Select, Switch } from "antd";
import axios from "axios";
import * as loadGoogleMapsApi from "load-google-maps-api";
import * as React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { LabaledField } from "../../components/LabeledField";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { PageContent } from "../../components/PageContent";
import { Helpers } from "../../utils/helpers";
import { POLYGON_STATE_COLORS, PolygonSateColor } from "../../utils/map_settings";

interface EditFellingPointsState {
    isLoading: boolean;
    fellingPointId: any;
    showSidebar: boolean;
    fellingArea: any;
    polygons: any[];
    routes: any[];
    enableRouteEdit: boolean;
    divideLines: boolean;
}

interface EditFellingPointsStateProps {
    form: any;
}

const FormItem = Form.Item;
const Option = Select.Option;

export const STATES_MIN = [
    { text: "Plānotais fonds", value: 100000000 },
    { text: "Sagatavotais fonds", value: 100000001 },
    { text: "Izstrāde iesākta", value: 100000002 },
    { text: "Izstrāde pabeigta", value: 100000003 },
    { text: "Meža atjaunošana", value: 100000004 },
];

export const STATES_MATRIX = [
    { text: "Plānotais fonds", value: 100000000 },
    { text: "Sagatavotais fonds", value: 100000001 },
    { text: "Izstrāde iesākta", value: 100000002 },
];

export const STATES = [
    { text: "Plānotais fonds", value: 100000000, color: "#e67e22" },
    { text: "Sagatavotais fonds", value: 100000001, color: "#f1c40f" },
    { text: "Izstrāde iesākta", value: 100000002, color: "#10ac84" },
    { text: "Izstrāde pabeigta", value: 100000003, color: "#3498db" },
    { text: "Meža atjaunošana", value: 100000004, color: "#8e44ad" },
    { text: "Sadarbība noslēgta", value: 100000005, color: "#ffffff" },
    { text: "Nepieciešams pārskats", value: 100000006, color: "#ffffff" },
];

export const DEAL_TYPES = [
    { text: "Apsaimniekošana", value: 100000000 },
    { text: "Apsaimniekošana (Skoga)", value: 100000001 },
    { text: "Ciršanas tiesības", value: 100000003 },
    { text: "Īpašuma iegāde", value: 100000002 },
    { text: "Apaļkoks pie ceļa", value: 100000005 },
    { text: "Finansējums", value: 100000006 },
    { text: "Cits", value: 100000008 },
];

class EditFellingPointsForm extends React.Component<EditFellingPointsStateProps, EditFellingPointsState> {
    map;
    infoWindow;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            fellingPointId: parseInt(props.match.params.fellingAreaId, 10),
            showSidebar: true,
            fellingArea: null,
            polygons: [],
            routes: [],
            enableRouteEdit: false,
            divideLines: true,
        };
    }

    componentDidMount() {
        this.fetchFellingPointData().then(() => {
            // Load map
            loadGoogleMapsApi({
                key: process.env.CLIENT_GOOGLE_MAPS_API_KEY,
                language: "lv",
                region: "LV",
                libraries: ["drawing"],
                v: "3.33",
            }).then((googleMaps) => {
                this.map = new googleMaps.Map(document.querySelector(".map-selector"), {
                    center: {
                        lat: 57.0592960738637,
                        lng: 27.1608882502698,
                    },
                    zoom: 15,
                    mapTypeId: "satellite",
                });

                this.infoWindow = new googleMaps.InfoWindow({
                    size: new googleMaps.Size(150, 50),
                });

                googleMaps.event.addListener(this.map, "click", () => {
                    this.infoWindow.close();
                });

                this.map.data.setStyle((feature) => ({
                    fillColor: feature.getProperty("fillColor"),
                    strokeWeight: 1,
                }));

                const drawingManager = new googleMaps.drawing.DrawingManager({
                    drawingControl: true,
                    drawingControlOptions: {
                        position: google.maps.ControlPosition.TOP_RIGHT,
                        drawingModes: ["polyline"],
                    },
                    polylineOptions: {
                        editable: this.state.enableRouteEdit,
                        strokeColor: "#ff4d4d",
                        strokeOpacity: 1,
                    },
                });

                googleMaps.event.addListener(drawingManager, "polylinecomplete", (event) => {
                    this.setState(prevState  => ({
                        routes: prevState.routes.concat(event),
                    }));
                });

                drawingManager.setMap(this.map);

                // Try to fetch geometry data if exist
                if (this.state.fellingArea.geometry_exist) {
                    this.fetchGeometry(this.state.fellingArea.cadastre_number).then((data: any) => {
                        const polygonList = data.data.map(element => {
                            const stateSettings = POLYGON_STATE_COLORS[this.state.fellingArea.state] as PolygonSateColor;
                            const strokeColor = this.state.divideLines ? stateSettings.strokeColor : stateSettings.fillColor;
                            const strokeWeight = this.state.divideLines ? 1 : 0;

                            const kdElement = new googleMaps.Polygon({
                                paths: element.coordinates,
                                fillColor: stateSettings.fillColor,
                                strokeColor,
                                strokeOpacity: 0.5,
                                fillOpacity: 0.4,
                                strokeWeight,
                            });

                            kdElement.setMap(this.map);

                            const showArrays = (event) => {
                                const contentString = `Nogabals: ${element.nogabals}<br/>
                                Kvartāls: ${element.kvartals}<br/>
                                Nosaukums: ${element.ip_nos}`;

                                this.infoWindow.setContent(contentString);
                                this.infoWindow.setPosition(event.latLng);
                                this.infoWindow.open(this.map);
                            };

                            kdElement.addListener("click", showArrays);

                            return kdElement;
                        });

                        this.setState({ polygons: polygonList });

                        this.map.setCenter(new googleMaps.LatLng(data.center.lat, data.center.lng));
                    })
                    .catch((error) => {
                        message.error(error);
                    });
                } else {
                    const pointLatLang = new googleMaps.LatLng(
                        this.state.fellingArea.map_center_lat,
                        this.state.fellingArea.map_center_lng,
                    );

                    // Otherwise just add marker to map
                    const marker = new googleMaps.Marker({
                        map: this.map,
                        position: pointLatLang,
                        clickable: true,
                    });

                    google.maps.event.addListener(marker, "click", () => {
                        const contentString = `Nosaukums: ${this.state.fellingArea.felling_area_name}<br/>
                        Kadastra numurs: ${this.state.fellingArea.cadastre_number}`;

                        this.infoWindow.setContent(contentString);
                        this.infoWindow.open(this.map, marker);
                    });

                    this.map.setCenter(pointLatLang);
                }

            }).catch(_error => {
                message.error("Neizdevās ielādēt karti");
            });
        });

        document.title = "Dižozols - Cirsmas dati";
    }

    fetchGeometry = (pointId) =>
        new Promise((resolve, reject) =>
            axios.get(`${process.env.baseUrl}/api/vs/geometry/${pointId}`)
                .then(response => {
                    if (response.data.success === false) {
                        return reject("Neizdevās ielādēt sortimentus");
                    }

                    return resolve({
                        data: response.data.data,
                        center: response.data.center,
                    } as any);
                })
                .catch(() => reject("Neizdevās ielādēt sortimentu sarakstu")))

    fetchFellingPointData = () =>
        new Promise((resolve) => {
            axios.get(`${process.env.baseUrl}/api/felling_areas/${this.state.fellingPointId}`, {
                headers: {
                    Authorization: Helpers.getSessionId(),
                },
            })
            .then(response => {
                if (response.data.success === false) {
                    message.error("Neizdevās ielādēt cirsmas datus");
                }

                this.setState({
                    isLoading: false,
                    fellingArea: response.data.data,
                }, () => {
                    resolve();
                });
            }).catch(() => {
                message.error("Neizdevās ielādēt cirsmas datus");
            });
        })

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (err) {
                message.error("Neizdevās saglabāt cirsmas datus");
                return;
            }

            const hide = message.loading("Saglabā cirsmas datus...", 0);

            axios.put(`${process.env.baseUrl}/api/felling_points`, {
                ...values,
                fellingPointId: this.state.fellingPointId,
            }, {
                headers: {
                    Authorization: Helpers.getSessionId(),
                },
            })
            .then(response => {
                hide();

                if (response.data.success === false) {
                    message.error("Neizdevās saglabāt cirsmas datus");
                }
            })
            .catch(error => {
                hide();
                Helpers.handleAPIError(error, "Neizdevās saglabāt cirsmas datus");
            });
        });
    }

    toggleSidebar = () => {
        this.setState({
            showSidebar: !this.state.showSidebar,
        });
    }

    toggleLines = (enabled) => {
        this.setState({
            divideLines: enabled,
        }, () => {
            this.changePolygonOptions();
        });
    }

    onEnableEditRoute = (enabled) => {
        this.setState({ enableRouteEdit: enabled }, () => {
            this.state.routes.map(route => {
                route.setOptions({
                    editable: this.state.enableRouteEdit,
                });
            });
        });
    }

    onStateChange = (state) => {
        const tempFeelingArea = { ...this.state.fellingArea };
        tempFeelingArea.state = state;

        this.setState({
            fellingArea: tempFeelingArea,
        }, () => {
            // Reset polygon styles on the map
            this.changePolygonOptions();
        });
    }

    changePolygonOptions = () => {
        const stateSettings = POLYGON_STATE_COLORS[this.state.fellingArea.state] as PolygonSateColor;
        const strokeColor = this.state.divideLines ? stateSettings.strokeColor : stateSettings.fillColor;
        const strokeWeight = this.state.divideLines ? 1 : 0;

        this.state.polygons.map(polygon => {
            polygon.setOptions({
                fillColor: stateSettings.fillColor,
                strokeColor,
                strokeWeight,
            });
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const popoverContent = (
            <React.Fragment>
                <Row style={{ marginBottom: 15 }}>
                    <LabaledField title="Labot ceļus" description="Iespējot norādīto ceļu labošanu" large={true}>
                        <Switch size="small" onChange={this.onEnableEditRoute} checked={this.state.enableRouteEdit}/>
                    </LabaledField>
                </Row>
                <Row style={{ marginBottom: 15 }}>
                    <LabaledField title={this.state.showSidebar ? "Paslēpt sānjoslu" : "Parādīt sānjoslu"} description="Parādīt vai paslēpt iestatījumu sānjoslu" large={true}>
                        <Switch size="small" onChange={this.toggleSidebar} checked={this.state.showSidebar}/>
                    </LabaledField>
                </Row>
                <Row style={{ marginBottom: 15 }}>
                    <LabaledField title="Nodalīt kvartālu nogabalus" description="Parādīit vai paslēpt nogabalus" large={true}>
                        <Switch size="small" onChange={this.toggleLines} checked={this.state.divideLines}/>
                    </LabaledField>
                </Row>
            </React.Fragment>
        );

        const pageContentHeaderActions = <div>
             <Popover placement="bottomRight" content={popoverContent} trigger="click">
                <Button style={{ marginRight: 8 }}><Icon type="setting" /></Button>
            </Popover>

            {/* <Button type="primary" htmlType="submit">Saglabāt</Button> */}
        </div>;

        const pageContentProps = {
            headerTitle: "Cirsmas dati",
            fullPage: true,
            showHeader: true,
            headerActions: pageContentHeaderActions,
        };

        if (this.state.isLoading) {
            return (
                <PageContent {...pageContentProps}>
                    <LoadingIndicator loadingText={true}/>
                </PageContent>
            );
        }

        return (
            <div>
                <Form onSubmit={this.handleSubmit}>
                    <PageContent {...pageContentProps}>
                        <Col span={this.state.showSidebar ? 18 : 24}>
                            <div className="map-selector" style={{ height: "75vh" }}></div>
                        </Col>
                        <Col span={this.state.showSidebar ? 6 : 0}>
                            <Card>
                                <Scrollbars style={{ height: "70vh" }}>
                                    <div style={{ paddingRight: 25 }}>
                                        <FormItem label="Cirsmas nosaukums" style={{ marginBottom: 0 }}>
                                            {getFieldDecorator("felling_area_name", {
                                                 initialValue: this.state.fellingArea.felling_area_name,
                                            })(
                                                <Input/>,
                                            )}
                                        </FormItem>

                                        <FormItem label="Kadastra numurs" style={{ marginBottom: 0 }}>
                                            {getFieldDecorator("cadastre_number", {
                                                 initialValue: this.state.fellingArea.cadastre_number,
                                            })(
                                                <Input/>,
                                            )}
                                        </FormItem>

                                        <FormItem label="Darījuma tips" style={{ marginBottom: 0 }}>
                                            {getFieldDecorator("deal_type", {
                                                 initialValue: this.state.fellingArea.deal_type,
                                            })(
                                                <Select>
                                                    {DEAL_TYPES.map(dealType => (
                                                        <Option value={dealType.value} key={dealType.value}>{dealType.text}</Option>
                                                    ))}
                                                </Select>,
                                            )}
                                        </FormItem>

                                        <FormItem label="Stadija" style={{ marginBottom: 0 }}>
                                            {getFieldDecorator("state", {
                                                 initialValue: this.state.fellingArea.state,
                                            })(
                                                <Select onChange={this.onStateChange}>
                                                    {STATES.map(dealType => (
                                                        <Option value={dealType.value} key={dealType.value}>{dealType.text}</Option>
                                                    ))}
                                                </Select>,
                                            )}
                                        </FormItem>

                                        <FormItem label="Priede" style={{ marginBottom: 0 }}>
                                            {getFieldDecorator("priede_type_value", {
                                                 initialValue: this.state.fellingArea.priede_type_value,
                                            })(
                                                <Input/>,
                                            )}
                                        </FormItem>

                                        <FormItem label="Egle" style={{ marginBottom: 0 }}>
                                            {getFieldDecorator("egle_type_value", {
                                                 initialValue: this.state.fellingArea.egle_type_value,
                                            })(
                                                <Input/>,
                                            )}
                                        </FormItem>

                                        <FormItem label="Bērzs" style={{ marginBottom: 0 }}>
                                            {getFieldDecorator("berzs_type_value", {
                                                 initialValue: this.state.fellingArea.berzs_type_value,
                                            })(
                                                <Input/>,
                                            )}
                                        </FormItem>

                                        <FormItem label="Apse" style={{ marginBottom: 0 }}>
                                            {getFieldDecorator("apse_type_value", {
                                                 initialValue: this.state.fellingArea.apse_type_value,
                                            })(
                                                <Input/>,
                                            )}
                                        </FormItem>

                                        <FormItem label="Baltalksnis" style={{ marginBottom: 0 }}>
                                            {getFieldDecorator("baltalksnis_type_value", {
                                                 initialValue: this.state.fellingArea.baltalksnis_type_value,
                                            })(
                                                <Input/>,
                                            )}
                                        </FormItem>

                                        <FormItem label="Ozols, Osis" style={{ marginBottom: 0 }}>
                                            {getFieldDecorator("ozols_type_value", {
                                                 initialValue: this.state.fellingArea.ozols_type_value,
                                            })(
                                                <Input/>,
                                            )}
                                        </FormItem>

                                        <FormItem label="Melnalksnins" style={{ marginBottom: 0 }}>
                                            {getFieldDecorator("melnalksnis_type_value", {
                                                 initialValue: this.state.fellingArea.melnalksnis_type_value,
                                            })(
                                                <Input/>,
                                            )}
                                        </FormItem>

                                        <FormItem label="Cits" style={{ marginBottom: 0 }}>
                                            {getFieldDecorator("other_type_value", {
                                                 initialValue: this.state.fellingArea.other_type_value,
                                            })(
                                                <Input/>,
                                            )}
                                        </FormItem>

                                        <FormItem label="Kopā" style={{ marginBottom: 0 }}>
                                            {getFieldDecorator("total_type_value", {
                                                 initialValue: this.state.fellingArea.total_type_value,
                                            })(
                                                <Input/>,
                                            )}
                                        </FormItem>

                                        <FormItem label="Izstrādātie m3" style={{ marginBottom: 0 }}>
                                            {getFieldDecorator("develop_cubic_meter", {
                                                 initialValue: this.state.fellingArea.develop_cubic_meter,
                                            })(
                                                <Input/>,
                                            )}
                                        </FormItem>

                                        <FormItem label="Atlikušie %" style={{ marginBottom: 0 }}>
                                            {getFieldDecorator("percents_left", {
                                                 initialValue: this.state.fellingArea.percents_left,
                                            })(
                                                <Input/>,
                                            )}
                                        </FormItem>
                                    </div>
                                </Scrollbars>
                            </Card>
                        </Col>
                    </PageContent>
                </Form>
            </div>
        );
    }
}

// tslint:disable-next-line:no-default-export
export default Form.create()(EditFellingPointsForm as any);

import * as React from 'react';
import { Alert, Timeline, Form, Radio, Checkbox } from 'antd';
import { Helpers } from '../../utils/helpers';

export interface HistoryDataItems {
    created_at: string;
    history_log: HistoryItem[];
}

interface HistoryItem {
    type: 'new_value' | 'updated_value' | 'updated_bonus' | 'updated_fsc_bonus' | 'updated_fsc_bonus_100' | 'updated_details' | 'updated_bonus_500' | 'updated_bonus_1000' | 'updated_bonus_5000' | 'updated_bonus_10000' | 'updated_bonus_1' | 'updated_bonus_2' | 'updated_bonus_3' | 'updated_bonus_4' | 'updated_amount_1' | 'updated_amount_2' | 'updated_amount_3' | 'updated_amount_4';
    updatedBy: string | null;
    sortiment_id: number;
}

interface NewValueHistoryItem extends HistoryItem {
    diameter: number;
    length: number;
    price: number;
}

interface UpdatedValueHistoryItem extends HistoryItem {
    diameter: number;
    length: number;
    originalPrice: number;
    price: number;
}

interface UpdatedBonusHistoryItem extends HistoryItem {
    originalBonus: number;
    bonus: number;
}

interface UpdatedFscBonusHistoryItem extends HistoryItem {
    originalFscBonus: number;
    fscBonus: number;
}

interface UpdatedFscBonus100HistoryItem extends HistoryItem {
    originalFscBonus100: number;
    fscBonus100: number;
}

interface UpdatedBonus500HistoryItem extends HistoryItem {
    originalBonus500: number;
    bonus500: number;
}
interface UpdatedBonus1000HistoryItem extends HistoryItem {
    originalBonus1000: number;
    bonus1000: number;
}
interface UpdatedBonus5000HistoryItem extends HistoryItem {
    originalBonus5000: number;
    bonus5000: number;
}
interface UpdatedBonus10000HistoryItem extends HistoryItem {
    originalBonus10000: number;
    bonus10000: number;
}

interface UpdatedBonus1HistoryItem extends HistoryItem {
    originalBonus1: number;
    bonus1: number;
    amount1: number;
}
interface UpdatedBonus2HistoryItem extends HistoryItem {
    originalBonus2: number;
    bonus2: number;
    amount2: number;
}
interface UpdatedBonus3HistoryItem extends HistoryItem {
    originalBonus3: number;
    bonus3: number;
    amount3: number;
}
interface UpdatedBonus4HistoryItem extends HistoryItem {
    originalBonus4: number;
    bonus4: number;
    amount4: number;
}

interface UpdatedAmount1HistoryItem extends HistoryItem {
    originalAmount1: number;
    amount1: number;
}
interface UpdatedAmount2HistoryItem extends HistoryItem {
    originalAmount2: number;
    amount2: number;
}
interface UpdatedAmount3HistoryItem extends HistoryItem {
    originalAmount3: number;
    amount3: number;
}
interface UpdatedAmount4HistoryItem extends HistoryItem {
    originalAmount4: number;
    amount4: number;
}

interface UpdatedCustomFieldHistoryItem extends HistoryItem {
    field: string;
    oldValue: string;
    newValue: string;
}

interface AssortmentHistoryProps {
    historyData: HistoryDataItems[]
}   

export class AssortmentHistory extends React.Component<AssortmentHistoryProps>{

    updatedByCheck = (updatedBy) => {
        if (!updatedBy) {
            return <b>Nezināms lietotājs</b>;
        }

        return <b>{updatedBy.fullName}</b>;
    }

    historyLogIsNewPrice(historyLog: HistoryItem): historyLog is NewValueHistoryItem {
        return historyLog.type === 'new_value';
    }

    historyLogIsUpdatedPrice(historyLog: HistoryItem): historyLog is UpdatedValueHistoryItem {
        return historyLog.type === 'updated_value';
    }

    historyLogIsUpdatedBonus(historyLog: HistoryItem): historyLog is UpdatedBonusHistoryItem {
        return historyLog.type === 'updated_bonus';
    }

    historyLogIsUpdatedFscBonus(historyLog: HistoryItem): historyLog is UpdatedFscBonusHistoryItem {
        return historyLog.type === 'updated_fsc_bonus';
    }

    historyLogIsUpdatedFscBonus100(historyLog: HistoryItem): historyLog is UpdatedFscBonus100HistoryItem {
        return historyLog.type === 'updated_fsc_bonus_100';
    }

    historyLogIsUpdatedBonus500(historyLog: HistoryItem): historyLog is UpdatedBonus500HistoryItem {
        return historyLog.type === 'updated_bonus_500';
    }

    historyLogIsUpdatedBonus1000(historyLog: HistoryItem): historyLog is UpdatedBonus1000HistoryItem {
        return historyLog.type === 'updated_bonus_1000';
    }

    historyLogIsUpdatedBonus5000(historyLog: HistoryItem): historyLog is UpdatedBonus5000HistoryItem {
        return historyLog.type === 'updated_bonus_5000';
    }

    historyLogIsUpdatedBonus10000(historyLog: HistoryItem): historyLog is UpdatedBonus10000HistoryItem {
        return historyLog.type === 'updated_bonus_10000';
    }

    historyLogIsUpdatedBonus1(historyLog: HistoryItem): historyLog is UpdatedBonus1HistoryItem {
        return historyLog.type === 'updated_bonus_1';
    }

    historyLogIsUpdatedBonus2(historyLog: HistoryItem): historyLog is UpdatedBonus2HistoryItem {
        return historyLog.type === 'updated_bonus_2';
    }

    historyLogIsUpdatedBonus3(historyLog: HistoryItem): historyLog is UpdatedBonus3HistoryItem {
        return historyLog.type === 'updated_bonus_3';
    }

    historyLogIsUpdatedBonus4(historyLog: HistoryItem): historyLog is UpdatedBonus4HistoryItem {
        return historyLog.type === 'updated_bonus_4';
    }

    historyLogIsUpdatedAmount1(historyLog: HistoryItem): historyLog is UpdatedAmount1HistoryItem {
        return historyLog.type === 'updated_amount_1';
    }

    historyLogIsUpdatedAmount2(historyLog: HistoryItem): historyLog is UpdatedAmount2HistoryItem {
        return historyLog.type === 'updated_amount_2';
    }

    historyLogIsUpdatedAmount3(historyLog: HistoryItem): historyLog is UpdatedAmount3HistoryItem {
        return historyLog.type === 'updated_amount_3';
    }

    historyLogIsUpdatedAmount4(historyLog: HistoryItem): historyLog is UpdatedAmount4HistoryItem {
        return historyLog.type === 'updated_amount_4';
    }

    historyLogIsCustomField(historyLog: HistoryItem): historyLog is UpdatedCustomFieldHistoryItem {
        return historyLog.type === 'updated_details';
    }

    render() {
        return <Timeline>
            {this.props.historyData.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()).map(historyItem => (
                <Timeline.Item key={historyItem.created_at}>
                    <p>{Helpers.formatDateWithTime(historyItem.created_at)}</p>
                    {historyItem.history_log.map(historyLogItem => {
                        if(this.historyLogIsNewPrice(historyLogItem)){
                            return <div key={`${historyLogItem.diameter}${historyLogItem.length}`}>
                                <p>{this.updatedByCheck(historyLogItem.updatedBy)} pievienoja jaunu sortimenta cenu priekš: {historyLogItem.diameter}x{historyLogItem.length}:
                                <span className="newPrice">{historyLogItem.price}€</span></p>
                            </div>
                        }
                        if(this.historyLogIsUpdatedPrice(historyLogItem)){
                            return <div key={`${historyLogItem.diameter}${historyLogItem.length}`}>
                                <p>{this.updatedByCheck(historyLogItem.updatedBy)} laboja sortimenta cenu priekš: {historyLogItem.diameter}x{historyLogItem.length}:
                                <span className="oldPrice">{historyLogItem.originalPrice}€</span>
                                <span className="newPrice">{historyLogItem.price}€</span></p>
                            </div>
                        }
                        if(this.historyLogIsUpdatedBonus(historyLogItem)){
                            return <div>
                                <p>{this.updatedByCheck(historyLogItem.updatedBy)} laboja sortimenta piemaksu:
                                <span className="oldPrice">{historyLogItem.originalBonus}€</span>
                                <span className="newPrice">{historyLogItem.bonus}€</span></p>
                            </div>
                        }

                        if(this.historyLogIsUpdatedFscBonus(historyLogItem)){
                            return <div>
                                <p>{this.updatedByCheck(historyLogItem.updatedBy)} laboja sortimenta FSC piemaksu:
                                <span className="oldPrice">{historyLogItem.originalFscBonus}€</span>
                                <span className="newPrice">{historyLogItem.fscBonus}€</span></p>
                            </div>
                        }

                        if(this.historyLogIsUpdatedFscBonus100(historyLogItem)){
                            return <div>
                                <p>{this.updatedByCheck(historyLogItem.updatedBy)} laboja sortimenta FSC 100 piemaksu:
                                <span className="oldPrice">{historyLogItem.originalFscBonus100}€</span>
                                <span className="newPrice">{historyLogItem.fscBonus100}€</span></p>
                            </div>
                        }

                        if(this.historyLogIsUpdatedBonus500(historyLogItem)){
                            return <div>
                                <p>{this.updatedByCheck(historyLogItem.updatedBy)} laboja sortimenta piemaksu virs 500:
                                <span className="oldPrice">{historyLogItem.originalBonus500}€</span>
                                <span className="newPrice">{historyLogItem.bonus500}€</span></p>
                            </div>
                        }

                        if(this.historyLogIsUpdatedBonus1000(historyLogItem)){
                            return <div>
                                <p>{this.updatedByCheck(historyLogItem.updatedBy)} laboja sortimenta piemaksu virs 1000:
                                <span className="oldPrice">{historyLogItem.originalBonus1000}€</span>
                                <span className="newPrice">{historyLogItem.bonus1000}€</span></p>
                            </div>
                        }

                        if(this.historyLogIsUpdatedBonus5000(historyLogItem)){
                            return <div>
                                <p>{this.updatedByCheck(historyLogItem.updatedBy)} laboja sortimenta piemaksu virs 5000:
                                <span className="oldPrice">{historyLogItem.originalBonus5000}€</span>
                                <span className="newPrice">{historyLogItem.bonus5000}€</span></p>
                            </div>
                        }

                        if(this.historyLogIsUpdatedBonus10000(historyLogItem)){
                            return <div>
                                <p>{this.updatedByCheck(historyLogItem.updatedBy)} laboja sortimenta piemaksu virs 10000:
                                <span className="oldPrice">{historyLogItem.originalBonus10000}€</span>
                                <span className="newPrice">{historyLogItem.bonus10000}€</span></p>
                            </div>
                        }

                        if(this.historyLogIsUpdatedBonus1(historyLogItem)){
                            return <div>
                                <p>{this.updatedByCheck(historyLogItem.updatedBy)} laboja sortimenta piemaksu virs {historyLogItem.amount1}:
                                <span className="oldPrice">{historyLogItem.originalBonus1}€</span>
                                <span className="newPrice">{historyLogItem.bonus1}€</span></p>
                            </div>
                        }
                        if(this.historyLogIsUpdatedBonus2(historyLogItem)){
                            return <div>
                                <p>{this.updatedByCheck(historyLogItem.updatedBy)} laboja sortimenta piemaksu virs {historyLogItem.amount2}:
                                <span className="oldPrice">{historyLogItem.originalBonus2}€</span>
                                <span className="newPrice">{historyLogItem.bonus2}€</span></p>
                            </div>
                        }
                        if(this.historyLogIsUpdatedBonus3(historyLogItem)){
                            return <div>
                                <p>{this.updatedByCheck(historyLogItem.updatedBy)} laboja sortimenta piemaksu virs {historyLogItem.amount3}:
                                <span className="oldPrice">{historyLogItem.originalBonus3}€</span>
                                <span className="newPrice">{historyLogItem.bonus3}€</span></p>
                            </div>
                        }
                        if(this.historyLogIsUpdatedBonus4(historyLogItem)){
                            return <div>
                                <p>{this.updatedByCheck(historyLogItem.updatedBy)} laboja sortimenta piemaksu virs {historyLogItem.amount4}:
                                <span className="oldPrice">{historyLogItem.originalBonus4}€</span>
                                <span className="newPrice">{historyLogItem.bonus4}€</span></p>
                            </div>
                        }
                        if(this.historyLogIsUpdatedAmount1(historyLogItem)){
                            return <div>
                                <p>{this.updatedByCheck(historyLogItem.updatedBy)} laboja sortimenta piemaksas apjomu:
                                <span className="oldPrice">{historyLogItem.originalAmount1}</span>
                                <span className="newPrice">{historyLogItem.amount1}</span></p>
                            </div>
                        }
                        if(this.historyLogIsUpdatedAmount2(historyLogItem)){
                            return <div>
                                <p>{this.updatedByCheck(historyLogItem.updatedBy)} laboja sortimenta piemaksas apjomu:
                                <span className="oldPrice">{historyLogItem.originalAmount2}</span>
                                <span className="newPrice">{historyLogItem.amount2}</span></p>
                            </div>
                        }
                        if(this.historyLogIsUpdatedAmount3(historyLogItem)){
                            return <div>
                                <p>{this.updatedByCheck(historyLogItem.updatedBy)} laboja sortimenta piemaksas apjomu:
                                <span className="oldPrice">{historyLogItem.originalAmount3}</span>
                                <span className="newPrice">{historyLogItem.amount3}</span></p>
                            </div>
                        }
                        if(this.historyLogIsUpdatedAmount4(historyLogItem)){
                            return <div>
                                <p>{this.updatedByCheck(historyLogItem.updatedBy)} laboja sortimenta piemaksas apjomu:
                                <span className="oldPrice">{historyLogItem.originalAmount4}</span>
                                <span className="newPrice">{historyLogItem.amount4}</span></p>
                            </div>
                        }
                        
                        
                        if(this.historyLogIsCustomField(historyLogItem)){
                            return <div>
                                <p>{this.updatedByCheck(historyLogItem.updatedBy)} laboja {historyLogItem.field}:
                                <span className="oldPrice">{historyLogItem.oldValue}</span>
                                <span className="newPrice">{historyLogItem.newValue}</span></p>
                            </div>
                        }
                    })}
                </Timeline.Item>
            ))}
        </Timeline>
    }
}
import { values, groupBy, clone } from 'lodash';


/* groups items from the same acceptance point into one item and creates price based on weighted availability */
export default function groupByAcceptancePointsWeighted(minDiameter: number, maxDiameter: number, items: any[]): any[]{
    const groups = values(groupBy(items, 'id'));
    const totalDiameterInterval = maxDiameter - minDiameter;
    const result = [];
    for(let group of groups){
        result.push(group.reduce((acc, current) => {
            
            if(current.maxDiameter < minDiameter || current.diameter > maxDiameter){ /* In case current item is outside of diameter range */
                return acc; 
            }
            const matchingDiameterInterval = Math.min(maxDiameter, current.maxDiameter) - Math.max(minDiameter, current.diameter);
            const weightedPricePart = (matchingDiameterInterval / totalDiameterInterval) * current.price;
            const weightedCalculatedPricePart = (matchingDiameterInterval / totalDiameterInterval) * current.calculated_price;
            const weightedFullPricePart = (matchingDiameterInterval / totalDiameterInterval) * current.full_price;
            
            if(!acc){
                acc = clone(current);
                acc.price = 0;
                acc.calculated_price = 0;
                acc.full_price = 0;
            }

            acc.price += weightedPricePart;
            acc.calculated_price += weightedCalculatedPricePart;
            acc.full_price += weightedFullPricePart;
            
            return acc;
        }, null));
    }
    return result;
}
import { Button, Form, Input, Modal, Select } from "antd";
import * as React from "react";
import { Users } from ".";
import { USER_TYPES } from "../../utils/constants";

const FormItem = Form.Item;

// Not best solution but google maps are not working so well for react, yet
export interface AcceptencePointFormProps {
    visible: boolean;
    modalTitle: string;
    modalOkText: string;
    onCancel(): void;
    onCreate(): void;
    saveFormRef(ref: any): void;
    isProcessingDatabase: boolean;
    modalInitdata: null | Users | undefined;
    securityGroups: any[];
    userType: string;
}

const { Option } = Select;

export class UserData extends React.Component<AcceptencePointFormProps, {}> {
    map;
    form;

    constructor(props) {
        super(props);
    }

    setMapRef = (ref) => this.map = ref;

    setFormRef = (ref) => {
        this.form = ref;
        this.props.saveFormRef(ref);
    }

    render() {
        return <AcceptencePointForm
            {...this.props}
            setFormRef={this.setFormRef}
            securityGroups={this.props.securityGroups}
        />;
    }
}

const AcceptencePointForm = Form.create()((props: any) => {
    const { visible, onCancel, onCreate, form, modalTitle, setFormRef, modalOkText,
            isProcessingDatabase, modalInitdata, securityGroups, userType } = props;
    const { getFieldDecorator } = form;

    setFormRef(form);

    return (
        <Modal
            wrapClassName="vertical-center-modal"
            visible={visible}
            title={modalTitle}
            destroyOnClose={true}
            width={750}
            maskClosable={false}
            onCancel={onCancel}
            footer={[
                <Button key="back" onClick={onCancel}>Atcelt</Button>,
                <Button key="submit" type="primary" loading={isProcessingDatabase} onClick={onCreate}>
                    {modalOkText}
                </Button>,
            ]}
        >
            <Form layout="vertical">
                <FormItem label="Vārds">
                    {getFieldDecorator("first_name", {
                        rules: [{
                            required: true,
                            message: "Lūdzu norādiet vārdu!",
                        }],
                        initialValue: modalInitdata ? modalInitdata.first_name : null,
                    })(<Input/>)}
                </FormItem>
                <FormItem label="Uzvārds">
                    {getFieldDecorator("last_name", {
                        initialValue: modalInitdata ? modalInitdata.last_name : null,
                    })(<Input/>)}
                </FormItem>
                <FormItem label="Lietotājvārds">
                    {getFieldDecorator("username", {
                        rules: [{
                            required: true,
                            message: "Lūdzu norādiet lietotājvārdu!",
                        }],
                        initialValue: modalInitdata ? modalInitdata.username : null,
                    })(<Input/>)}
                </FormItem>
                <FormItem label="E-pasts">
                    {getFieldDecorator("email", {
                        initialValue: modalInitdata ? modalInitdata.email : null,
                    })(<Input/>)}
                </FormItem>
                <FormItem label="Parole">
                    {getFieldDecorator("password", {
                        initialValue: null,
                    })(<Input type="password"/>)}
                </FormItem>
                {(!modalInitdata || !modalInitdata.mezueksperts_tips) && userType === "pricePage" && <FormItem label="Pieejas grupa">
                    {getFieldDecorator("access_group", {
                        initialValue: modalInitdata ? modalInitdata.access_group : null,
                    })(<Select>
                        {securityGroups && securityGroups.map(group => (
                            <Option key={group.id} value={group.id}>{group.name}</Option>
                        ))}
                    </Select>)}
                </FormItem>}
                {userType === "logisticPage" && <FormItem label="Automašīnas numurs">
                    {getFieldDecorator("car_number", {
                        initialValue: modalInitdata ? modalInitdata.car_number || null : null,
                    })(
                        <Input/>,
                    )}
                </FormItem>}
            </Form>
        </Modal>
    );
});

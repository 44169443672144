import { Alert, Button, Col, Dropdown, Icon, Input, Menu, message, Modal, Pagination, Row, Select, Table, Timeline } from "antd";
import axios from "axios";
import * as React from "react";
import { NavLink } from "react-router-dom";
import * as uniqid from "uniqid";
import { PRIORITIES } from "../../../shared/constants";
import { PageContent } from "../../components/PageContent/index";
import { PaginationWrapper } from "../../components/Wrappers";
import { Helpers } from "../../utils/helpers";
import AcceptencePoint from "./acceptencePointForm";
import { AssortmentHistory, HistoryDataItems } from "../../components/AssortmentHistory";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";

interface AcceptancePointsState {
    isLoading: boolean;
    isProcessingDatabase: boolean;
    modalVisible: boolean;
    points: Points[];
    editModePointId: null | number;
    totalRecords: number;
    currentPage: number;
    searchKeyword: string;
    historyData: any[];
    historyModalVisible: boolean;
    historyModalTitle: null | string;

    showPriceHistory: boolean;
    showBonusHistory: boolean;
    showUtilityHistory: boolean;
}

export interface Points {
    id: number;
    name: string;
    address: string;
    lat: number;
    lng: number;
    bonus: number;
    updatedAt: string;
    createdAt: string;
    plan: number;
    surface_coefficient: number;
    priority: string;
}

const Search = Input.Search;
const confirm = Modal.confirm;
const Option = Select.Option;

export class AcceptancePoints extends React.Component<{ ruleIsValid(ruleId: any): boolean }, AcceptancePointsState> {
    formRef;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isProcessingDatabase: false,
            editModePointId: null,
            modalVisible: false,
            points: [],
            // History log
            historyData: [],
            historyModalVisible: false,
            historyModalTitle: null,
            // Search
            searchKeyword: "",
            // Pagination
            totalRecords: 0,
            currentPage: 1,

            showBonusHistory: true,
            showPriceHistory: true,
            showUtilityHistory: true,
        };
    }

    componentDidMount() {
        this.fetchPoints();
        document.title = "Pieņemšanas punkti";
    }

    saveFormRef = (formRef) => this.formRef = formRef;

    showModal = () =>
        this.setState({ modalVisible: true })

    handleCancel = () =>
        this.setState({ modalVisible: false, editModePointId: null }, () => {
            this.formRef.resetFields();
        })

    onPageChange = (page) =>
        this.setState({ currentPage: page, isLoading: true }, () => {
            this.fetchPoints();
            Helpers.scrollTopTop();
        })

    onSearch = (searchKeyword) =>
        this.setState({ currentPage: 1, isLoading: true, searchKeyword  }, () => {
            this.fetchPoints();
            Helpers.scrollTopTop();
        })

    deletePoint = (pointId: number) => {
        this.setState({
            isProcessingDatabase: true,
            isLoading: true,
        }, () => {
            axios.delete(`${process.env.baseUrl}/api/acceptance_points_fetch/${pointId}`)
                .then(response => {
                    if (response.data.success === false) {
                        message.error("Neizdevās dzēst pieņemšanas punktu");
                    }

                    this.setState({
                        isProcessingDatabase: false,
                        isLoading: false,
                        points: this.state.points.filter(filterPoint => filterPoint.id !== pointId),
                    });
                }).catch(() => {
                    message.error("Neizdevās saglabāt pieņemšanas punktu");
                });
        });
    }

    fetchPoints = (sortData = {}) => {
        this.setState({ isLoading: true }, () => {
            axios.post(`${process.env.baseUrl}/api/acceptance_points_fetch/list/`, {
                pageNumber: this.state.currentPage,
                searchKeyword: this.state.searchKeyword,
                ...sortData,
            }).then(response => {
                if (response.data.success === false) {
                    return this.setState({ isLoading: false }, () => {
                        message.error("Neizdevās ielādēt pieņemšanas punktus");
                    });
                }

                this.setState({
                    isLoading: false,
                    totalRecords: parseInt(response.data.data.totalRecords, 10),
                    points: response.data.data.acceptance_points.map(itemData => ({
                        id: itemData.id,
                        name: itemData.name.trim(),
                        address: itemData.address.trim(),
                        lat: itemData.lat,
                        lng: itemData.lng,
                        bonus: itemData.bonus,
                        plan: itemData.plan,
                        updatedAt: Helpers.formatDateWithTime(itemData.updated_at),
                        createdAt: Helpers.formatDateWithTime(itemData.created_at),
                        surface_coefficient: itemData.surface_coefficient,
                        priority: itemData.priority,
                    })),
                });

            }).catch(() => {
                message.error("Neizdevās ielādēt pieņemšanas punktus");
            });
        });
    }

    getAcceptancePointKeyDataFromObject = (object) => ({
        name: object.name,
        address: object.address,
        lat: object.address_lat || object.lat,
        lng: object.address_lng || object.lng,
        bonus: object.bonus,
        plan: object.plan,
        surface_coefficient: object.surface_coefficient,
        priority: object.priority,
    })

    handleCreate = () => {
        const form = this.formRef;

        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            this.setState({ isProcessingDatabase: true }, () => {
                // If we need to update point
                if (this.state.editModePointId) {
                    return axios.put(`${process.env.baseUrl}/api/acceptance_points_fetch`, {
                        id: this.state.editModePointId,
                        name: values.name,
                        address: values.address,
                        lat: values.address_lat,
                        lng: values.address_lng,
                        bonus: values.bonus,
                        plan: values.plan,
                        surface_coefficient: values.surface_coefficient,
                        priority: values.priority,
                        diff: Helpers.dataCompare(
                            this.getAcceptancePointKeyDataFromObject(this.state.points.find(point =>
                                point.id === this.state.editModePointId)),
                            this.getAcceptancePointKeyDataFromObject(values),
                        ),
                    }, {
                        headers: {
                            Authorization: Helpers.getSessionId(),
                        },
                    })
                    .then(response => {
                        if (response.data.success === false) {
                            return message.error("Neizdevās saglabāt pieņemšanas punktu");
                        }

                        form.resetFields();

                        const currentPoints = this.state.points;
                        const pointIndex = currentPoints.findIndex(currentPoint =>
                            currentPoint.id === this.state.editModePointId);

                        currentPoints[pointIndex] = {
                            ...currentPoints[pointIndex],
                            name: values.name,
                            address: values.address,
                            lat: values.address_lat,
                            lng: values.address_lng,
                            bonus: values.bonus,
                            plan: values.plan,
                            surface_coefficient: values.surface_coefficient,
                            updatedAt: Helpers.formatDateWithTime(Date.now()),
                            priority: values.priority,
                        };

                        return this.setState({
                            isProcessingDatabase: false,
                            modalVisible: false,
                            editModePointId: null,
                            points: currentPoints,
                        });
                    })
                    .catch(error => {
                        this.setState({ isProcessingDatabase: false }, () => {
                            Helpers.handleAPIError(error, "Neizdevās saglabāt pieņemšanas punktu");
                        });
                    });
                } else {
                    return axios.post(`${process.env.baseUrl}/api/acceptance_points_fetch`, {
                        name: values.name,
                        address: values.address,
                        lat: values.address_lat,
                        lng: values.address_lng,
                        bonus: values.bonus,
                        plan: values.plan,
                        surface_coefficient: values.surface_coefficient,
                        priority: values.priority,
                    }, {
                        headers: {
                            Authorization: Helpers.getSessionId(),
                        },
                    })
                    .then(response => {
                        if (response.data.success === false) {
                            message.error("Neizdevās saglabāt pieņemšanas punktu");
                        }

                        form.resetFields();

                        this.setState({
                            isProcessingDatabase: false,
                            modalVisible: false,
                            points: [...this.state.points, {
                                id: response.data.pointId,
                                name: values.name,
                                address: values.address,
                                lat: values.address_lat,
                                lng: values.address_lng,
                                updatedAt: Helpers.formatDateWithTime(Date.now()),
                                createdAt: Helpers.formatDateWithTime(Date.now()),
                                bonus: values.bonus,
                                plan: values.plan,
                                surface_coefficient: values.surface_coefficient,
                                priority: values.priority,
                            }],
                        });
                    })
                    .catch(error => {
                        this.setState({ isProcessingDatabase: false }, () => {
                            Helpers.handleAPIError(error, "Neizdevās saglabāt pieņemšanas punktu");
                        });
                    });
                }
            });
        });
    }

    editPoint = (pointId: number) => {
        this.setState({ editModePointId: pointId }, () => {
            this.showModal();
        });
    }

    showAcceptancePointChangeLog = (acceptancePointId, acceptancePointName) =>
        axios.get(`${process.env.baseUrl}/acceptance_point/${acceptancePointId}/history`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            if (response.data.success === false) {
                message.error("Neizdevās ielādēt pieņemšanas punkta vēsturi");
            }

            this.setState({ historyData: response.data.data }, () => {
                this.setState({
                    historyModalVisible: true,
                    historyModalTitle: `${acceptancePointName} Vēsture`,
                });
            });
        }).catch(() => {
            message.error("Neizdevās ielādēt pieņemšanas punkta vēsturi");
        })

    showDeleteConfirmPopup = (acceptancePointId) => {
        // tslint:disable-next-line:no-this-assignment
        const self = this;

        confirm({
            title: "Dzēst pieņemšanas punktu",
            content: "Vai tiešām vēlaties dzēst šo pieņemšanas punktu?",
            okType: "danger",
            cancelText: "Atcelt",
            okText: "Dzēst",
            onOk() {
                self.deletePoint(acceptancePointId);
            },
          });
    }

    handleSorting = (value) => {
        const splitValue = value.split("##");
        const sortField = splitValue[0];
        const sortOrder = splitValue[1];

        this.fetchPoints({ sortField, sortOrder });
    }

    handleHistoryCancel = () => {
        this.setState({ historyModalVisible: false });
    }

    priorityLabel = (id) => {
        const priorityObj = PRIORITIES.find(priority => priority.id === id);
        return priorityObj ? priorityObj.label : "";
    }

    /* Filter out history logs that match current filter */
    filterHistoryData(historyData: HistoryDataItems[]): HistoryDataItems[]{
        return historyData.map(historyDataItem => ({
            ...historyDataItem,
            history_log: historyDataItem.history_log.filter(historyItem => {
                switch(historyItem.type){
                    case 'new_value':
                    case 'updated_value':
                        return this.state.showPriceHistory;
                    case 'updated_bonus':
                    case 'updated_fsc_bonus':
                    case 'updated_fsc_bonus_100':
                        return this.state.showBonusHistory;
                    default:
                        return this.state.showUtilityHistory;
                }
            })
        })).filter(historyDataItem => historyDataItem.history_log.length);
    }

    toggleBonusHistory = (e: CheckboxChangeEvent) => {
        this.setState({
            showBonusHistory: e.target.checked,
        });
    }

    togglePriceHistory = (e: CheckboxChangeEvent) => {
        this.setState({
            showPriceHistory: e.target.checked,
        });
    }

    toggleUtilityHistory = (e: CheckboxChangeEvent) => {
        this.setState({
            showUtilityHistory: e.target.checked,
        });
    }

    render() {
        const pageContentHeaderActions = <div>
            {this.props.ruleIsValid("AcceptancePoints:NewPoint") && <Button size="large" onClick={this.showModal}>
                <Icon type="plus" style={{ paddingRight: 5 }}/> Pievienot pieņemšanas punktu
            </Button>}
        </div>;

        const pageContentProps = {
            headerTitle: "Pieņemšas punkti",
            headerActions: pageContentHeaderActions,
        };

        const itemMoreMenu = (record) => (
            <Menu>
                 {this.props.ruleIsValid("AcceptancePoints:EditPoint") && <Menu.Item>
                    <a onClick={() => this.editPoint(record.id)}>Labot pieņemšanas punktu</a>
                </Menu.Item>}
                {this.props.ruleIsValid("AcceptancePoints:DeletePoint") &&
                <Menu.Item>
                    <a onClick={() => this.showDeleteConfirmPopup(record.id)}>Dzēst pieņemšans punktu</a>
                </Menu.Item>}
                {this.props.ruleIsValid("AcceptancePoints:ViewAssortmentHistory") && <Menu.Divider/> }
                {this.props.ruleIsValid("AcceptancePoints:ViewAssortmentHistory") && <Menu.Item>
                    <a onClick={() => this.showAcceptancePointChangeLog(record.id, record.name)}>
                        <Icon type="profile" style={{ marginRight: 5 }}/>
                        Vēsture
                    </a>
                </Menu.Item>}
            </Menu>
        );

        const columns = [{
            title: "Nosaukums",
            dataIndex: "name",
            key: "name",
            width: 250,
        }, {
            title: "Adrese / Koordinātas",
            dataIndex: "address",
            key: "address",
        }, {
            title: "Plāns",
            dataIndex: "plan",
            key: "plan",
            width: 120,
            render: (_text, record) => (
                (record.plan && record.plan > 0) ? <span>{`${record.plan} m`}<sup>3</sup></span>  : "-"
            ),
        }, {
            title: "Piemaksas",
            dataIndex: "bonus",
            key: "bonus",
            width: 120,
        }, {
            title: "Prioritāte",
            dataIndex: "priority",
            key: "priority",
            width: 120,
            render: (text) => (
                this.priorityLabel(text)
            ),
        }, {
            title: "Pēdējās izmaiņas",
            dataIndex: "updated_at",
            key: "updated_at",
            width: 200,
        }, {
            title: "",
            dataIndex: "operation",
            key: "operation",
            width: 150,
            render: (_text, record) =>
                (
                    <div>
                        {this.props.ruleIsValid("AcceptancePoints:ViewAssortment") &&
                            <NavLink to={`/cenas/acceptance_points/${record.id}/assortment`} className="ant-btn ant-btn-sm ant-btn-gray">
                                Sortiments
                            </NavLink>}
                        <Dropdown overlay={itemMoreMenu(record)} placement="bottomRight">
                            <Button size="small" style={{ padding: "0 10px", marginLeft: 5 }}>...</Button>
                        </Dropdown>
                    </div>
                ),
        }];

        const renderPagination = (marginTop = true) => <PaginationWrapper marginTop={marginTop}>
            <Pagination
                showTotal={(total, range) => `${range[0]}-${range[1]} no ${total} ierakstiem`}
                total={this.state.totalRecords}
                current={this.state.currentPage}
                onChange={this.onPageChange}
                pageSize={20}
                size="small"
            />
        </PaginationWrapper>;

        return (
            <div>
                <PageContent {...pageContentProps}>
                    <Row>
                        <Col span={4}>
                            <Search
                                placeholder="Meklēt pieņemšanas punktu"
                                onSearch={this.onSearch}
                                style={{ width: 250 }}
                            />
                        </Col>
                        <Col span={6}>
                            Kārtot:
                            <Select
                                defaultValue="created_at##DESC"
                                style={{ width: 200, marginLeft: 5 }}
                                onChange={this.handleSorting}
                            >
                                <Option value="created_at##DESC">Jaunākie pievienotie</Option>
                                <Option value="created_at##ASC">Vecākie pievienotie</Option>
                                <Option value="updated_at##DESC">Jaunākās izmaiņas</Option>
                                <Option value="updated_at##ASC">Vecākās izmaiņas</Option>
                            </Select>
                        </Col>
                        <Col span={14}>
                            {renderPagination(false)}
                        </Col>
                    </Row>

                    <Table
                        bordered={true}
                        dataSource={this.state.points.map(point => ({
                            key: point.id,
                            id: point.id,
                            name: point.name,
                            bonus: (point.bonus && point.bonus > 0) ? `${point.bonus}%` : "-",
                            plan: point.plan,
                            address: point.address,
                            updated_at: point.updatedAt,
                            priority: point.priority,
                        })) as any}
                        columns={columns}
                        loading={this.state.isLoading}
                        pagination={false}
                        size="small"
                        locale={{
                            filterTitle: "Filtrēt",
                            filterConfirm: "Ok",
                            filterReset: "Nodzēst",
                            emptyText: "Nav datu",
                        }}
                    />

                    {renderPagination()}
                </PageContent>

                {this.state.modalVisible && <AcceptencePoint
                    visible={this.state.modalVisible}
                    isProcessingDatabase={this.state.isProcessingDatabase}
                    saveFormRef={this.saveFormRef}
                    onCancel={this.handleCancel}
                    onCreate={this.handleCreate}
                    ruleIsValid={this.props.ruleIsValid}
                    modalTitle={this.state.editModePointId
                        ? "Labot pieņemšas punktu"
                        : "Pievienot pieņemšas punktu"}
                    modalOkText={this.state.editModePointId
                        ? "Labot"
                        : "Pievienot"}
                    modalInitdata={this.state.editModePointId
                        ? this.state.points.find(point => point.id === this.state.editModePointId)
                        : null}
                />}

                <Modal
                    visible={this.state.historyModalVisible}
                    title={this.state.historyModalTitle}
                    onCancel={this.handleHistoryCancel}
                    footer={[]}
                    width={900}
                >
                    {this.state.historyData.length === 0 ? 
                    <Alert message="Nav ierakstu" type="info" /> :
                    <React.Fragment>
                        <p> Filtri </p>
                        <Row style={{ marginBottom: '1em' }}>
                            <Col span={8}>
                                <Checkbox 
                                    onChange={this.toggleBonusHistory} 
                                    checked={this.state.showBonusHistory}
                                    value="showBonusHistory">
                                    Rādīt sortimentu piemaksu vēsturi
                                </Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox 
                                    onChange={this.togglePriceHistory} 
                                    checked={this.state.showPriceHistory} 
                                    value="showPriceHistory">
                                    Rādīt soritmentu cenu vēsturi
                                </Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox 
                                    onChange={this.toggleUtilityHistory} 
                                    checked={this.state.showUtilityHistory} 
                                    value="showUtilityHistory">
                                    Rādīt punkta vēsturi
                                </Checkbox>
                            </Col>
                        </Row>
                        <Row>
                            <AssortmentHistory historyData={this.filterHistoryData(this.state.historyData)} />
                        </Row>
                    </React.Fragment>}
                </Modal>
            </div>
        );
    }
}

import { Dropdown, Icon, Menu, Spin } from "antd";
import * as React from "react";
import { NavLink } from "react-router-dom";

import "./style.scss";

const { Item } = Menu;

interface GlobalHeaderProps {
    onMenuClick?(event: any): void;
    ruleIsValid(ruleId: any): boolean;
    activeUser?: any;
}

export class GlobalHeader extends React.Component<GlobalHeaderProps, {}> {

    constructor(props) {
        super(props);
    }

    onMenuClick = (item) => {
        if (this.props.onMenuClick) {
            this.props.onMenuClick(item);
        }
    }

    render() {
        const { activeUser } = this.props;

        const dropdownMenu = (
            <Menu selectedKeys={[]} onClick={this.onMenuClick}>
                {activeUser.accessLevel === 3 &&
                    <Item key="users">
                        <NavLink exact to={"/cenas/admin/users"}>
                            <Icon type="user" style={{ marginRight: 10 }}/>
                            Lietotāji
                        </NavLink>
                    </Item>
                }
                {activeUser.accessLevel === 3 &&
                    <Item key="security_rules">
                        <NavLink exact to={"/cenas/admin/security_rules"}>
                            <Icon type="lock" style={{ marginRight: 10 }}/>
                            Pieeju grupas
                        </NavLink>
                    </Item>

                }
                {activeUser.accessLevel === 3 &&
                    <Item key="import_history">
                        <NavLink exact to={"/cenas/admin/dynamics/logs"}>
                            <Icon type="database" style={{ marginRight: 10 }}/>
                            Importēšanas vēsture
                        </NavLink>
                    </Item>

                }
                {activeUser.accessLevel === 3 &&
                    <Item key="api_settings">
                        <NavLink exact to={"/cenas/admin/api_settings"}>
                            <Icon type="setting" style={{ marginRight: 10 }}/>
                            API iestatījumi
                        </NavLink>
                    </Item>

                }
                <Item key="logout"><Icon type="logout"/> Iziet</Item>
            </Menu>
        );

        return (
           <div className="globalHeader">
                <Menu
                    mode="horizontal"
                    style={{ lineHeight: "64px", float: "left", width: "auto" }}
                >
                    {this.props.ruleIsValid("Pages:AcceptancePoints") && <Item key="AcceptancePoints">
                        <NavLink exact to={"/cenas/acceptance_points"}>Pieņemšanas punkti</NavLink>
                    </Item>}
                    {this.props.ruleIsValid("Pages:PricePageClient") && <Item key="PricePageClient">
                        <NavLink exact to={"/cenas/price_page_client"}>Cenu lapa klientam</NavLink>
                    </Item>}
                    {this.props.ruleIsValid("Pages:Matrix") && <Item key="Matrix">
                        <NavLink exact to={"/cenas/matrix"}>Matrica</NavLink>
                    </Item>}
                    {this.props.ruleIsValid("Pages:PricePage") && <Item key="PricePage">
                        <NavLink exact to={"/cenas/price_page"}>Palags</NavLink>
                    </Item>}
                    {this.props.ruleIsValid("Pages:Map") && <Item key="Map">
                        <NavLink exact to={"/cenas/map"}>Karte</NavLink>
                    </Item>}
                    {this.props.ruleIsValid("Pages:FellingAreas") && <Item key="FellingAreas">
                        <NavLink exact to={"/cenas/cirsmas"}>Cirsmas</NavLink>
                    </Item>}
                </Menu>

                <div className="right">
                    {activeUser ? (
                        <Dropdown overlay={dropdownMenu} placement="bottomRight">
                            <span className="action account">
                                <span>
                                    {activeUser.firstName} {activeUser.lastName}
                                    <Icon type="down" style={{ marginLeft: 7 }}/>
                                </span>
                            </span>
                        </Dropdown>
                    ) : <Spin size="small" style={{ marginLeft: 8 }} />}
                </div>
           </div>
        );
    }
}
